<div class="contentMain">
  <!-- breadcrumb -->
  <!-- <div class="row">
      <div class="col-md-6">
          <h5>UOM</h5>
      </div>
      <div class="col-md-6 ">
          <ol class="breadcrum d-flex justify-content-end">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
              <li class="breadcrumb-item active" aria-current="page">UOM</li>
          </ol>
      </div>
  </div> -->
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <!-- card -->
    <div class="card">
      <div class="card-header mainheader">
        <h6>UOM - New Mode</h6>
      </div>
      <div class="card-body">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="form-group">
              <div class="form-group">
                <label>UOM Name</label>
                <input type="text" class="form-control" placeholder="UOM Name">
              </div>
            </div>
          </div>
          <!--/ col -->
          <!-- col -->
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="form-group">
              <div class="form-group">
                <label>UOM Code</label>
                <input type="text" class="form-control" placeholder="UOM Code">
              </div>
            </div>
          </div>
          <!--/ col -->
          <!-- col -->
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="form-group">
              <label>UOM Under</label>
              <select class="form-control">
                <option>Select UOM Under</option>
                <option>Select Item1</option>
                <option>Select Item2</option>
                <option>Select Item3</option>
                <option>Select Item4</option>
              </select>
            </div>
          </div>
          <!--/ col -->
          <!-- col -->
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="form-group">
              <label>UQC</label>
              <select class="form-control">
                <option>Select UQC</option>
                <option>Select Item1</option>
                <option>Select Item2</option>
                <option>Select Item3</option>
                <option>Select Item4</option>
              </select>
            </div>
          </div>
          <!--/ col -->
          <!-- col -->
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="form-group">
              <div class="form-group">
                <label>Conversion Value</label>
                <input type="text" class="form-control" placeholder="Conversion Value">
              </div>
            </div>
          </div>
          <!--/ col -->
          <!-- col -->
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="form-group">
              <label>No. of Decimals</label>
              <select class="form-control">
                <option>2 </option>
                <option>3 </option>
                <option>4 </option>
                <option>5 </option>
                <option>6 </option>
              </select>
            </div>
          </div>
          <!--/ col -->
        </div>
        <!-- /row -->
      </div>
      <div class="card-footer text-left w-100">
        <button class="btn redbtn" type="button">New</button>
        <button class="btn redbtn" type="button">Save</button>
        <button class="btn redbtn" type="button">Edit</button>
        <button class="btn redbtn" type="button">Delete</button>
        <button class="btn redbtn" type="button">Clear</button>
        <button class="btn redbtn" type="button">Cancel</button>
        <button class="btn redbtn" type="button">Save</button>
        <button class="btn redbtn" type="button">Help</button>
      </div>
    </div>
    <!--/ card -->
  </main>
  <!-- main body -->
</div>
