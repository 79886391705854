<style>
  .table1 > tr > td {
    font-size: 11px;
    line-height: 8px;
    height: 8px;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    padding-top: 2px;
  }
  .table1 > tr > th {
    line-height: 8px;
    height: 8px;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    padding-top: 2px;
  }
</style>
<div class="contentMain">
  <div class="row">
    <div class="col-md-12 mainheader header-container">
      <h5>Customer Enquiry</h5>
    </div>
  </div>
  <main class="row">
    <div class="card">
      <form class="form" [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm($event,0)">
        <div class="card-body">
          <div class="row" style="margin-top:-20px;">
            <div class="col-lg-9 col-md-9 col-sm-9">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label title="{{actidtitle}}">A/C Code</label>
                    <div class="form-group">
                      <input type="text" formControlName="AccountCode" placeholder="A/C Code" class="form-control" (input)="keypressAccountCode($event,0)" (change)="changeAccountCode($event,0)" (click)="clickAccountCode($event,0)" style="width: 100%; font-size: 11px;" />
                      <div class="combobox-options" *ngIf="filteredAccountCodeList.length>0 && flagAccountCode===true">
                        <ul style=" width:100%;">
                          <li *ngFor="let item of filteredAccountCodeList" (click)="onselectAccountCode(item,0)">
                            <a>{{item.actCode}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>A/C Name</label>
                    <div class="form-group">
                      <input type="text" formControlName="AccountName" placeholder="A/C Name" class="form-control" (input)="keypressAccountName($event,0)" (change)="changeAccountName($event,0)" (click)="clickAccountName($event,0)" style="width: 100%; font-size: 11px;" />
                      <div class="combobox-options" *ngIf="filteredAccountNameList.length>0 && flagAccountName===true">
                        <ul style=" width:100%;">
                          <li *ngFor="let item of filteredAccountNameList" (click)="onselectAccountName(item,0)">
                            <a>{{item.actName}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="row">
                <div class="col-lg-5 col-md-5 col-sm-5">
                  <div class="form-group">
                    <label style="margin-top:50px"></label>
                    <button class="btn redbtn" type="submit">Show</button>
                  </div>
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7">
                  <div class="form-group">
                    <label style="margin-top:50px"></label>
                    <!--<button class="btn redbtn" type="submit">Show & Print</button>-->
                    <button printTitle="Print Title" styleSheetFile="{{cssPrintingPath}}"
                            [printStyle]="{h4 : {'text-align': 'center'}, span : {'font-size': 'small'}}"
                            printSectionId="print-section" ngxPrint>
                      <img src="../../../../../assets/images/printer.jpg" style="width:40px;height:30px;" />
                    </button>
                    <img (click)="SaveOrSubmitForm($event,1)" src="../../../../../assets/images/excel.jpg" style="width: 40px; height: 30px;" />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="OrdersList.length===0" style="text-align: center; margin-top: -25px;" class="text-center text-danger">
            <label style=" border: 0px solid black;">No Data Found for Selection Criteria</label>
          </div>
          <br />
          <div *ngIf="OrdersList.length > 0" class="row" style="margin-top:-20px;width:100%;">
            <div id="print-section" style="margin-top: -5px; width: 100%;" #TABLE #table>
              <!--<div style="width: 100%; border: 0px solid black;margin-top:-5px;">
                <table class="daywiseheadtable" style="width: 98%; border: 0px solid black;">
                  <thead>
                    <tr>
                      <th style="text-align: center;width:100%;">{{CompName}}</th>
                    </tr>
                    <tr>
                      <th style="text-align: center;width:100%;">{{Address1}}</th>
                    </tr>
                    <tr>
                      <th style="text-align: center;width:100%;">{{daywisebytexttitle}} </th>
                    </tr>
                  </thead>
                </table>
              </div>-->
              <div style="width: 100%; overflow: auto;font-size:12px;">
                <div style="width: 100%; border: 0px solid black;margin-top:-5px;">
                  <table class="daywiseheadtable" style="width: 98%; border: 0px solid black;">
                    <thead>
                      <tr>
                        <th style="text-align: center;width:100%;">{{CompName}}</th>
                      </tr>
                      <tr>
                        <th style="text-align: center;width:100%;">{{Address1}}</th>
                      </tr>
                      <tr>
                        <th style="text-align: center;width:100%;">{{daywisebytexttitle}} </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <table class="daywisetable" style="width: 100%; border: 1px solid black;">
                  <thead>
                    <tr>
                      <th style="text-align: right; width: 25%; border: 1px solid black; ">Cust Id : </th>
                      <td style="text-align: left; width: 25%; border: 1px solid black; ">{{custid}}</td>
                      <th style="text-align: right; width: 25%; border: 1px solid black; ">Cust Name : </th>
                      <td style="text-align: left; width: 25%; border: 1px solid black; ">{{custname}}</td>
                    </tr>
                    <tr>
                      <th style="text-align: right; width: 25%; border: 1px solid black; ">Town : </th>
                      <td style="text-align: left; width: 25%; border: 1px solid black; ">{{townname}}</td>
                      <th style="text-align: right; width: 25%; border: 1px solid black; ">Mobile : </th>
                      <td style="text-align: left; width: 25%; border: 1px solid black; ">{{mobile}}</td>
                    </tr>
                  </thead>
                </table>
              </div>
              <br />
              <div style="width: 100%; font-size:11px;margin-top:-20px;">
                <table class="daywisetable" style="width: 100%; border: 1px solid black;">
                  <thead>
                    <tr>
                      <th style="text-align: left; width: 20%; border: 1px solid black; ">Ord.Date</th>
                      <th style="text-align: left; width: 20%; border: 1px solid black; ">Trail.Date</th>
                      <th style="text-align: left; width: 20%; border: 1px solid black; ">Delv.Date</th>
                      <th style="text-align: left; width: 20%; border: 1px solid black; ">Item Name</th>
                      <th style="text-align: right; width: 20%; border: 1px solid black;">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of OrdersList; let i = index">
                      <td style="text-align: left; width: 20%; border: 1px solid black; ">{{list.orderdate}}</td>
                      <td style="text-align: left; width: 20%; border: 1px solid black; ">{{list.traildate}}</td>
                      <td style="text-align: left; width: 20%; border: 1px solid black; ">{{list.delvdate}}</td>
                      <td style="text-align: left; width: 20%; border: 1px solid black; ">{{list.itemname}}</td>
                      <td style="text-align: right; width: 20%; border: 1px solid black; ">{{list.qty | currency:'INR':''}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div style="width: 100%; font-size:11px;margin-top:-20px;">
                <table class="daywisetable" style="width: 100%; border: 1px solid black;">
                  <thead>
                    <tr>
                      <th style="text-align: left; width: 20%; border: 1px solid black; ">Cutt.Master Name</th>
                      <th style="text-align: left; width: 20%; border: 1px solid black; ">Date</th>
                      <th style="text-align: left; width: 20%; border: 1px solid black; ">Item Name</th>
                      <th style="text-align: right; width: 10%; border: 1px solid black; ">Delv to Cutt.Master</th>
                      <th style="text-align: right; width: 10%; border: 1px solid black; ">Recd From Cutt.Master</th>
                      <th style="text-align: right; width:20%; border: 1px solid black; ">Bal.Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of CuttingMasterList; let i = index">
                      <td style="text-align: left; width: 20%; border: 1px solid black; ">{{list.actname}}</td>
                      <td style="text-align: left; width: 20%; border: 1px solid black; ">{{list.date}}</td>
                      <td style="text-align: left; width: 20%; border: 1px solid black; ">{{list.itemname}}</td>
                      <td style="text-align: right; width: 10%; border: 1px solid black; ">{{list.rqty | currency:'INR':''}}</td>
                      <td style="text-align: right; width: 10%; border: 1px solid black; ">{{list.sqty | currency:'INR':''}}</td>
                      <td style="text-align: right; width: 20%; border: 1px solid black; ">{{list.balqty | currency:'INR':''}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div style="width: 100%; font-size:11px;margin-top:-20px;">
                <table class="daywisetable" style="width: 100%; border: 1px solid black;">
                  <thead>
                    <tr>
                      <th style="text-align: left; width: 20%; border: 1px solid black; ">Stit.Master Name</th>
                      <th style="text-align: left; width: 20%; border: 1px solid black; ">Date</th>
                      <th style="text-align: left; width: 20%; border: 1px solid black; ">Item Name</th>
                      <th style="text-align: right; width: 10%; border: 1px solid black; ">Delv to Stit.Master</th>
                      <th style="text-align: right; width: 10%; border: 1px solid black; ">Recd From Stit.Master</th>
                      <th style="text-align: right; width:20%; border: 1px solid black; ">Bal.Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of StitchingMasterlist; let i = index">
                      <td style="text-align: left; width: 20%; border: 1px solid black; ">{{list.actname}}</td>
                      <td style="text-align: left; width: 20%; border: 1px solid black; ">{{list.date}}</td>
                      <td style="text-align: left; width: 20%; border: 1px solid black; ">{{list.itemname}}</td>
                      <td style="text-align: right; width: 10%; border: 1px solid black; ">{{list.rqty | currency:'INR':''}}</td>
                      <td style="text-align: right; width: 10%; border: 1px solid black; ">{{list.sqty | currency:'INR':''}}</td>
                      <td style="text-align: right; width: 20%; border: 1px solid black; ">{{list.balqty | currency:'INR':''}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div style="width: 100%; font-size:11px;margin-top:-20px;">
                <table class="daywisetable" style="width: 100%; border: 1px solid black;">
                  <thead>
                    <tr>
                      <th style="text-align: left; width: 20%; border: 1px solid black; ">Ready To Delv.</th>
                      <th style="text-align: left; width: 20%; border: 1px solid black; ">Item Name</th>
                      <th style="text-align: right; width: 20%; border: 1px solid black; ">Recd From Stit.Master</th>
                      <th style="text-align: right; width: 20%; border: 1px solid black; ">&nbsp;</th>
                      <th style="text-align: right; width:20%; border: 1px solid black; ">Bal.Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of ReadyToDelvlist; let i = index">
                      <td style="text-align: left; width: 20%; border: 1px solid black; ">{{list.actname}}</td>
                      <td style="text-align: left; width: 20%; border: 1px solid black; ">{{list.itemname}}</td>
                      <td style="text-align: right; width: 20%; border: 1px solid black; ">{{list.rqty | currency:'INR':''}}</td>
                      <td style="text-align: right; width: 20%; border: 1px solid black; ">&nbsp;</td>
                      <td style="text-align: right; width: 20%; border: 1px solid black; ">{{list.rqty | currency:'INR':''}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div style="width: 100%; font-size:11px;margin-top:-20px;">
                <table class="daywisetable" style="width: 100%; border: 1px solid black;">
                  <thead>
                    <tr>
                      <th style="text-align: left; width: 10%; border: 1px solid black; ">
                        Bal. in Shop Qty
                      </th>
                      <th style="text-align: left; width: 18%; border: 1px solid black; ">Item Name</th>
                      <th style="text-align: right; width: 12%; border: 1px solid black; ">
                        Order in Bal.
                      </th>
                      <th style="text-align: right; width: 12%; border: 1px solid black; ">
                        Bal. in Cutt.
                      </th>
                      <th style="text-align: right; width: 12%; border: 1px solid black; ">
                        Bal. in Stit.
                      </th>
                      <th style="text-align: right; width: 12%; border: 1px solid black; ">
                        Bal. in Shop.
                      </th>
                      <th style="text-align: right; width: 12%; border: 1px solid black; ">
                        Ready to Delv.
                      </th>
                      <th style="text-align: right; width:12%; border: 1px solid black; ">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of BalinShoplist; let i = index">
                      <td style="text-align: left; width: 10%; border: 1px solid black; ">{{list.actname}}</td>
                      <td style="text-align: left; width: 18%; border: 1px solid black; ">{{list.itemname}}</td>
                      <td style="text-align: right; width: 12%; border: 1px solid black; ">{{list.rqty | currency:'INR':''}}</td>
                      <td style="text-align: right; width: 12%; border: 1px solid black; ">{{list.sqty | currency:'INR':''}}</td>
                      <td style="text-align: right; width: 12%; border: 1px solid black; ">{{list.balqty | currency:'INR':''}}</td>
                      <td style="text-align: right; width: 12%; border: 1px solid black; ">{{list.stockinbranch | currency:'INR':''}}</td>
                      <td style="text-align: right; width: 12%; border: 1px solid black; ">{{list.readydelv | currency:'INR':''}}</td>
                      <td style="text-align: right; width: 12%; border: 1px solid black; ">{{list.totqty | currency:'INR':''}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>
    <!--<div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="border p-3 h-100">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Customer ID</label>
                    <select class="form-control">
                      <option>Select</option>
                      <option>Select Item1</option>
                      <option>Select Item2</option>
                      <option>Select Item3</option>
                      <option>Select Item4</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Name</label>
                    <select class="form-control">
                      <option>Select</option>
                      <option>Select Item1</option>
                      <option>Select Item2</option>
                      <option>Select Item3</option>
                      <option>Select Item4</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>Address</label>
                    <input type="text"
                           class="form-control"
                           placeholder="Order Number" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Town</label>
                    <input type="text"
                           class="form-control"
                           placeholder="Order Number" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Mobile No</label>
                    <input type="text"
                           class="form-control"
                           placeholder="Order Number" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="border p-3 h-100">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Order No</label>
                    <select class="form-control">
                      <option>Select</option>
                      <option>Select Item1</option>
                      <option>Select Item2</option>
                      <option>Select Item3</option>
                      <option>Select Item4</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Order Date</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                      </div>
                      <input type="text" class="form-control datepicker"
                             placeholder="Delivery Date">
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Delivery Date</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                      </div>
                      <input type="text" class="form-control datepicker"
                             placeholder="Delivery Date">
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Date of Birth</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                      </div>
                      <input type="text" class="form-control datepicker"
                             placeholder="Delivery Date">
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-12 table-responsive">
            <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
              <thead>
                <tr>
                  <th><input id="remember_me" type="checkbox" /></th>
                  <th>S No</th>
                  <th>Item Name</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Branch</th>
                  <th>Stock Point</th>
                  <th>Master</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input id="remember_me" type="checkbox" />
                  </td>
                  <td>1</td>
                  <td>Shirts</td>
                  <td>9/3/2021</td>
                  <td>SHIRT</td>
                  <td>3.00</td>
                  <td>1.00</td>
                </tr>
                <tr>
                  <td>
                    <input id="remember_me" type="checkbox" />
                  </td>
                  <td>2</td>
                  <td>Trousers</td>
                  <td>9/3/2021</td>
                  <td>SHIRT</td>
                  <td>3.00</td>
                  <td>1.00</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class=" " style="float: right;">
            <div class="row">

              <div class="mr-4">
                <h6>Total Amt</h6>
                <p><b>500.00</b></p>
              </div><br>
              <div class="mr-4">
                <h6>Advance Recive</h6>
                <p><b>-100.00</b></p>
              </div><br>
              <div class="mr-4">
                <h6>Balance Amt</h6>
                <p><b>-100.00</b></p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="col-md-12 table-responsive">
          <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
            <thead>
              <tr>
                <th><input id="remember_me" type="checkbox" /></th>
                <th>Type</th>
                <th> Name</th>
                <th>Place</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input id="remember_me" type="checkbox" />
                </td>
                <td>CUTTING MASTER NAME</td>
                <td>sri</td>
                <td>TO STICHING MAS</td>
                <td>SHIRT</td>
              </tr>
              <tr>
                <td>
                  <input id="remember_me" type="checkbox" />
                </td>
                <td>STICHING MASTER NAME</td>
                <td>prasad</td>
                <td>TO CUT MAS DATE</td>
                <td>SHIRT</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-footer text-left w-100">
      <button class="btn redbtn" type="button">Customer</button>
      <button class="btn redbtn" type="button">Settlement</button>
      <button class="btn redbtn" type="button">Save</button>
      <button class="btn redbtn" type="button">Save & Print</button>
      <button class="btn redbtn" type="button">Refresh</button>
      <button class="btn redbtn" type="button">Close</button>
      <button class="btn redbtn" type="button">Save</button>
    </div>-->
  </main>
</div>
