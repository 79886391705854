<div class="contentMain">
  <!-- breadcrumb -->
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <!-- card -->
    <div class="card">
      <div class="card-header mainheader header-container">
        <h5>Manufacture - New Mode</h5>

      </div>
      <div class="card-body">
        <!-- row -->
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="d-flex">
              <p class="pb-0 mb-0">Status</p>
              <div class="checkbox ml-3">
                <input id="activestatus" type="checkbox">
                <label for="activestatus"> Active </label>
              </div>
            </div>
          </div>
          <!-- col -->

          <div class="col-lg-9 col-md-9 col-sm-6">
            <div class="form-group">
              <label>Manufacture Name</label>
              <input type="text" class="form-control" placeholder="Branch Name">
            </div>
          </div>

          <!-- /col -->
          <!-- col -->

          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group">
              <label>Manufacture Code</label>
              <input type="text" class="form-control" placeholder="Branch Code">
            </div>
          </div>


          <div class="row">
            <div class="col-12">
              <h5 class="py-3">Address </h5>
            </div>
            <!-- col -->
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>Address Line 1</label>
                <input type="text" class="form-control" placeholder="Address Line 1">
              </div>
            </div>
            <!-- /col -->
            <!-- col -->
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>Address Line 2</label>
                <input type="text" class="form-control" placeholder="Address Line 2">
              </div>
            </div>
            <!-- /col -->
            <!-- col -->
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="form-group">
                <label>Area</label>
                <input type="text" class="form-control" placeholder="Area Name">
              </div>
            </div>
            <!-- /col -->
            <!-- col -->
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="form-group">
                <label>District</label>
                <div class="select2-container form-control show-tick ms select2"
                     id="s2id_autogen33">
                  <a href="javascript:void(0)" class="select2-choice"
                     tabindex="-1">
                    <span class="select2-chosen"
                          id="select2-chosen-34">Select District</span><abbr class="select2-search-choice-close"></abbr> <span class="select2-arrow" role="presentation">
                      <b role="presentation"></b>
                    </span>
                  </a><label for="s2id_autogen34"
                             class="select2-offscreen"></label><input class="select2-focusser select2-offscreen" type="text"
                                                                      aria-haspopup="true" role="button" aria-labelledby="select2-chosen-34"
                                                                      id="s2id_autogen34">
                  <div class="select2-drop select2-display-none select2-with-searchbox">
                    <div class="select2-search">
                      <label for="s2id_autogen34_search"
                             class="select2-offscreen"></label> <input type="text"
                                                                       autocomplete="off" autocorrect="off" autocapitalize="off"
                                                                       spellcheck="false" class="select2-input" role="combobox"
                                                                       aria-expanded="true" aria-autocomplete="list"
                                                                       aria-owns="select2-results-34" id="s2id_autogen34_search"
                                                                       placeholder="">
                    </div>
                    <ul class="select2-results" role="listbox" id="select2-results-34">
                    </ul>
                  </div>
                </div><select class="form-control show-tick ms select2"
                              data-placeholder="Select" tabindex="-1" title="" style="display: none;">
                  <option>Select District</option>
                  <option>Select Item1</option>
                  <option>Select Item2</option>
                  <option>Select Item3</option>
                  <option>Select Item4</option>
                </select>
              </div>
            </div>
            <!-- /col -->
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="form-group">
                <label>State</label>
                <div class="select2-container form-control show-tick ms select2"
                     id="s2id_autogen35">
                  <a href="javascript:void(0)" class="select2-choice"
                     tabindex="-1">
                    <span class="select2-chosen"
                          id="select2-chosen-36">Select State</span><abbr class="select2-search-choice-close"></abbr> <span class="select2-arrow" role="presentation">
                      <b role="presentation"></b>
                    </span>
                  </a><label for="s2id_autogen36"
                             class="select2-offscreen"></label><input class="select2-focusser select2-offscreen" type="text"
                                                                      aria-haspopup="true" role="button" aria-labelledby="select2-chosen-36"
                                                                      id="s2id_autogen36">
                  <div class="select2-drop select2-display-none select2-with-searchbox">
                    <div class="select2-search">
                      <label for="s2id_autogen36_search"
                             class="select2-offscreen"></label> <input type="text"
                                                                       autocomplete="off" autocorrect="off" autocapitalize="off"
                                                                       spellcheck="false" class="select2-input" role="combobox"
                                                                       aria-expanded="true" aria-autocomplete="list"
                                                                       aria-owns="select2-results-36" id="s2id_autogen36_search"
                                                                       placeholder="">
                    </div>
                    <ul class="select2-results" role="listbox" id="select2-results-36">
                    </ul>
                  </div>
                </div><select class="form-control show-tick ms select2"
                              data-placeholder="Select" tabindex="-1" title="" style="display: none;">
                  <option>Select State</option>
                  <option>Select Item1</option>
                  <option>Select Item2</option>
                  <option>Select Item3</option>
                  <option>Select Item4</option>
                </select>
              </div>
            </div>
            <!--/ col -->
            <!-- col -->
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="form-group">
                <label>Country</label>
                <div class="select2-container form-control show-tick ms select2"
                     id="s2id_autogen37">
                  <a href="javascript:void(0)" class="select2-choice"
                     tabindex="-1">
                    <span class="select2-chosen"
                          id="select2-chosen-38">Select Country</span><abbr class="select2-search-choice-close"></abbr> <span class="select2-arrow" role="presentation">
                      <b role="presentation"></b>
                    </span>
                  </a><label for="s2id_autogen38"
                             class="select2-offscreen"></label><input class="select2-focusser select2-offscreen" type="text"
                                                                      aria-haspopup="true" role="button" aria-labelledby="select2-chosen-38"
                                                                      id="s2id_autogen38">
                  <div class="select2-drop select2-display-none select2-with-searchbox">
                    <div class="select2-search">
                      <label for="s2id_autogen38_search"
                             class="select2-offscreen"></label> <input type="text"
                                                                       autocomplete="off" autocorrect="off" autocapitalize="off"
                                                                       spellcheck="false" class="select2-input" role="combobox"
                                                                       aria-expanded="true" aria-autocomplete="list"
                                                                       aria-owns="select2-results-38" id="s2id_autogen38_search"
                                                                       placeholder="">
                    </div>
                    <ul class="select2-results" role="listbox" id="select2-results-38">
                    </ul>
                  </div>
                </div><select class="form-control show-tick ms select2"
                              data-placeholder="Select" tabindex="-1" title="" style="display: none;">
                  <option>Select Country</option>
                  <option>Select Item1</option>
                  <option>Select Item2</option>
                  <option>Select Item3</option>
                  <option>Select Item4</option>
                </select>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="form-group">
                <label>Pincode</label>
                <input type="text" class="form-control" placeholder="Pincode&quot;">
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="form-group">
                <label>Fax No</label>
                <input type="text" class="form-control" placeholder="Fax No">
              </div>
            </div>
            <!-- /col -->
            <!-- col -->
            <!--/ col -->
            <!-- col -->
            <!--/ col -->
            <!-- col -->
            <!-- /col -->
            <!-- col -->
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="form-group">
                <label>Phone Number 1</label>
                <input type="text" class="form-control" placeholder="Phone Number">
              </div>
            </div>
            <!-- /col -->
            <!-- col -->
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="form-group">
                <label> Phone Number 2</label>
                <input type="text" class="form-control" placeholder="Alternative Phone Number">
              </div>
            </div>
            <!-- /col -->
            <!-- col -->
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="form-group">
                <label>Mobile </label>
                <input type="text" class="form-control" placeholder="Mobile Number">
              </div>
            </div>
            <!-- /col -->
            <!-- col -->
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="form-group">
                <label>PAN Number</label>
                <input type="text" class="form-control" placeholder="PAN Number">
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="form-group">
                <label>Email ID</label>
                <input type="text" class="form-control" placeholder="Email ID">
              </div>
            </div>
            <!-- /col -->
            <!-- col -->
            <!-- /col -->
            <!-- col -->
            <!-- /col -->
          </div>
          <!--/ col -->
        </div>
        <!-- /row -->
      </div>
      <div class="card-footer text-left w-100">



        <button class="btn redbtn" type="button">save</button>

        <button class="btn redbtn" [routerLink]="['/createArea']" type="button">cancel</button>
        <button class="btn redbtn" type="button">Help</button>
      </div>
    </div>
  </main>
</div>
