import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../http.service';
import { DatePipe, formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  AttendMonth: any = "12-Jan-2021";
  public isLoading: boolean = false;
  form1: FormGroup | undefined;
  ShiftList: any = [];
  CompanyList: any = [];
  FYList: any = [];
  constructor(private _fb: FormBuilder, private _services: HttpService, private datePipe: DatePipe, private router: Router) {
    let date = new Date();
    let previousmonth = new Date();
    this.AttendMonth = previousmonth;
    this.form1 = this._fb.group({
      UserName: ['', [Validators.required]],
      Password: ['', [Validators.required]],
      CompID: [0, [Validators.required]],
      FYID: [0, [Validators.required]],
      LoginDate: ['12-Jan-2022', [Validators.required]],
      SFTID: [0],
      remember_me: [0]
    });
    this.getShiftList();
    this.bind();    
  }

  ngOnInit(): void {
    if (Number(localStorage.getItem("isrefresh")) == 1) {
      localStorage.setItem("isrefresh", "0");
      window.location.reload();
    }
  }
  onStartDateTimeChange(dt: any) {
    this.AttendMonth = dt;
  
  }
  getfy() {
    this.AttendMonth = new Date();
    var CompID = this.form1?.get('CompID')?.value;    
    const request = {
      method: 'GET',
      action_url: "getfy?compid=" + CompID
    };

    this._services.doHttp(request).subscribe(
      res => {
        var result: any = res;
        this.FYList = result.listfys;
        if (this.FYList != null) {
          if (this.FYList.length > 0) {
            this.form1?.patchValue({
              FYID: this.FYList[0].fyId,
            });           
            this.setLoginDate();
          }
        }

      },
      error => {
        console.log(error);
      });
  }
  SaveOrSubmitForm() {
   
    if (this.form1?.invalid) {
      return;
    }    
    var uname = this.form1?.get('UserName')?.value;
    var pwd = this.form1?.get('Password')?.value;
    var CompID = this.form1?.get('CompID')?.value;
    var FyID = this.form1?.get('FYID')?.value;
    if (isNullOrUndefined(uname) || isNullOrUndefined(pwd) || isNullOrUndefined(CompID) || isNullOrUndefined(FyID)) {
      return;
    }
    if (uname == "" || pwd == "" || Number(CompID) == 0 || Number(FyID) == 0) {
      return;
    }
    this.isLoading = true;
    let msg: string = "";
    const request = {
      params: { uname: uname, pwd: pwd, CompID: Number(CompID), FyID: Number(FyID) },
      method: 'POST',
      action_url: 'LoginPost'
    };
    this._services.doHttp(request).subscribe(
      res => {
        var result: any = res;
        if (result != null) {
          if (Number(result.res) > 0) {
            localStorage.setItem("Uid", (result['msg']));
            localStorage.setItem("CompID", CompID);
            localStorage.setItem("FyID", FyID);
            this.router.navigateByUrl('/kliq');
          }
          else {
          
          }
        }       
      },
      error => {
        console.log(error); //this._service.Uid = 0; localStorage.clear(); this._service.token = "";
      });
  }
  clearform() {
  }
  bind() {
    this.AttendMonth = new Date();
    this.form1?.patchValue({
      CompID: 0,
      FYID: 0
    });
    var uname = this.form1?.get('UserName')?.value;
    var pwd = this.form1?.get('Password')?.value;
    if (isNullOrUndefined(uname) || isNullOrUndefined(pwd)) {
      return;
    }
    if (uname == "" || pwd == "") {
      return;
    }
    const request = {
      method: 'GET',
      action_url: "bind?uname=" + uname + "&pwd=" + pwd
    };
    this._services.doHttp(request).subscribe(
      res => {
        var result: any = res;
        if (result != null) {
          this.CompanyList = result.listcomp;
          this.FYList = result.listfys;
          if (this.CompanyList != null) {
            if (this.CompanyList.length > 1) {
              this.form1?.patchValue({
                CompID: this.CompanyList[1].compID,
              });
              if (this.FYList != null) {
                if (this.FYList.length > 0) {
                  this.form1?.patchValue({
                    FYID: this.FYList[0].fyId,
                  });
                  this.setLoginDate();
                }
              }
            }
          }
        }
      },
      error => {
        console.log(error);
        this.form1?.patchValue({
          CompID: 0,
          FYID:0
        });
      });
  }
  getFormatedDate(date: Date, format: string) {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, format);
  }
  convertMonthMMMtoMM(MMM: string='') {
    let MM: string = '01';
    switch (MMM) {
      case 'Jan':
        MM = '01';
        break;
      case 'Feb':
        MM = '02';
        break;
      case 'Mar':
        MM = '03';
        break;
      case 'Apr':
        MM = '04';
        break;
      case 'May':
        MM = '05';
        break;
      case 'Jun':
        MM = '06';
        break;
      case 'Jul':
        MM = '07';
        break;
      case 'Aug':
        MM = '08';
        break;
      case 'Sep':
        MM = '09';
        break;
      case 'Oct':
        MM = '10';
        break;
      case 'Nov':
        MM = '11';
        break;
      case 'Dec':
        MM = '12';
        break;
      default:
        MM = '01';
        break;
    }
    return MM;
  }
  setLoginDate() {

    this.form1?.patchValue({
      LoginDate: this.datePipe.transform(new Date(), "dd-MMM-yyyy")//this.getFormatedDate(new Date(), "dd-MMM-yyyy")
    });
    this.AttendMonth = new Date();
    if (this.FYList != null) {
      if (this.FYList.length > 0) {
        var FyID = this.form1?.get('FYID')?.value;

        var cmbFy = this.FYList.find(x => x.fyId == FyID).fStartEndDate.toString().trim();
        var a0 = "", a1 = "", a2 = "", a3 = "", a4 = "", a5 = "", FyFdt = "", FyEdt = "";
        let StrSplite: any; StrSplite = cmbFy.split('-');
        a0 = (StrSplite[0]); a1 = (StrSplite[1]); a2 = (StrSplite[2]);
        a3 = (StrSplite[3]); a4 = (StrSplite[4]); a5 = (StrSplite[5]);
        //FyFdt = a0 + "-" + a1 + "-" + a2; FyEdt = a3 + "-" + a4 + "-" + a5;
        let FMM = this.convertMonthMMMtoMM(a1); let TMM = this.convertMonthMMMtoMM(a4);
        FyFdt = a2.toString().trim() + "-" + FMM.toString().trim() + "-" + a0.toString().trim();
        FyEdt = a5.toString().trim() + "-" + TMM.toString().trim() + "-" + a3.toString().trim();
        let fdtS = formatDate(new Date(FyFdt.toString().trim()), 'yyyy-MM-dd', 'en_US');
        let edtS = formatDate(new Date(FyEdt.toString().trim()), 'yyyy-MM-dd', 'en_US');
        let todaydtStr = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
        let fdt = new Date(fdtS); let edt = new Date(edtS);
        let d = new Date(todaydtStr);
        let today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        let datefrom = new Date(fdt.getFullYear(), fdt.getMonth(), fdt.getDate());
        let dateto = new Date(edt.getFullYear(), edt.getMonth(), edt.getDate());
        if (((today >= datefrom) && (today <= dateto))) {
          this.form1?.patchValue({
            LoginDate: todaydtStr
          });
          this.AttendMonth = new Date();
        }
        else {
          this.form1?.patchValue({
            LoginDate: edtS
          });
          this.AttendMonth = dateto;//this.datePipe.transform(new Date(FyEdt), "dd-MMM-yyyy");
        }
      }
    }
    
  }
  /*Shifts List*/
  getShiftList() {
    const request = {
      method: 'GET',
      action_url: 'GetShifts'
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.ShiftList = res;
        if (this.ShiftList != null) {
          this.form1?.patchValue({
            SFTID: this.ShiftList[0].sftid
          });
        }
      },
      error => {
        console.log(error);
      });
  }
  
}
