<div class="authentication">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <form [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm()" class="card auth_form">
          <div class="header">
            <img class="logo" src="./assets/images/logo.png" alt="">
          </div>
          <div class="body">
            <div class="row">
              <div class="col-md-6 input-group">
                <div class="form-group mb-1">
                  <label>User Name </label>
                  <input type="text" class="form-control" placeholder="User Name" (keyup)="bind()" formControlName="UserName">
                </div>
              </div>
              <div class="col-md-6 input-group">
                <div class="form-group mb-1">
                  <label>Password</label>
                  <input type="password" class="form-control" placeholder="Password" (keyup)="bind()" formControlName="Password">
                </div>
              </div>
            </div>
            <div class="input-group">
              <div class="form-group mb-1">
                <label>Select Company</label>
                <select class="form-control show-tick ms" data-placeholder="Select" (change)="getfy()" formControlName="CompID">
                  <option *ngFor="let item of CompanyList"
                          [value]="item.compID">
                    {{item.compName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="input-group ">
              <div class="form-group mb-1">
                <label>Select FY</label>
                <select class="form-control show-tick ms" data-placeholder="Select" (change)="setLoginDate()" formControlName="FYID">
                  <option *ngFor="let item of FYList"
                          [value]="item.fyId">
                    {{item.fStartEndDate}}
                  </option>
                </select>
              </div>
            </div>
            <input type="text" class="form-control" formControlName="LoginDate" style="display:none;"/>
            <div class="input-group">
              <div class="form-group mb-1">
                <label>Login Date</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                  </div>
                  <input type="text" style="width: 60%;" id="AttendMonth" [(ngModel)]="AttendMonth"  name="AttendMonth" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange($event)" [bsValue]="AttendMonth" bsDatepicker  [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }" >
                </div>
              </div>
            </div>           
            <div class="input-group ">
              <div class="form-group mb-1">
                <label>Select Shift</label>
                <select class="form-control show-tick ms" data-placeholder="Select" formControlName="SFTID">
                  <option *ngFor="let item of ShiftList"
                          [value]="item.sftid">
                    {{item.sftname}}
                  </option>
                </select>
              </div>
            </div>
            <div class="checkbox">
              <input id="remember_me" type="checkbox" formControlName="remember_me">
              <label for="remember_me">Remember Me Next Time</label>
            </div>
            <div class="divbtnlogin">
              <table class="tblbtnlogin">
                <tr>
                  <td class="width-50"><button class="btn redbtn" type="submit">Login</button></td>
                  <td class="width-50"><button class="btn brdbtn" type="button" (click)="clearform()">Cancel</button></td>
                </tr>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

