import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hsn-creation',
  templateUrl: './hsn-creation.component.html',
  styleUrls: ['./hsn-creation.component.scss']
})
export class HsnCreationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
