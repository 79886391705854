import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpService } from '../../../../http.service';

@Component({
  selector: 'app-branch-creation',
  templateUrl: './branch-creation.component.html',
  styleUrls: ['./branch-creation.component.scss']
})
export class BranchCreationComponent implements OnInit {
  form1: FormGroup | undefined;
  @ViewChild('myname') input;
  public btntextNew = { state: { id: 0, text: 'New', obj: null } };
  public itemlist: any=[];
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private elRef: ElementRef, private router: Router) {
    //form Defining Start
    this.form1 = this._fb.group({
      BranchName: [''],
      BranchCode: ['']
    });
    //End form Defining
    this.bindlist();
  }
  ngOnInit(): void {
    //var CompID = Number(localStorage.getItem("CompID"));
  }
  ngAfterViewInit() {
    //Focus Start
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    //End
  }
  bindlist() {
    const request = {
      method: 'GET',
      action_url: 'GetBranch'
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.itemlist = res;
        }        
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  editformbyid(item: any) {
  /*  console.log(item);*/
    this.router.navigateByUrl('/newBranch', { state: { id: item.branchId, text: 'Edit', obj: item } });
  }
  deleteformbyid(item: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        //
      //  console.log(item);
        var jsondata = { id: Number(item) };
        this.httpClient.post(this._services.APIUrl + '/DeleteBranch', Number(item)).subscribe(res => {
          var result: any = res;
        //  console.log(result.msg);
          if (result.msg != null) {
            if (result.msg.toString().includes('Successfull')) {
              Swal.fire('Deleted!', result.msg.toString(), 'success');
              this.bindlist();
            }
          }
        },
          error => {
            console.log(error);
          });
        //
      }
    });

  }
  SearchOrSubmitForm() {
    var name = this.form1?.get('BranchName')?.value;
    var short = this.form1?.get('BranchCode')?.value;
    const request = {
      method: 'GET',
      action_url: 'GetSeletedBranch?Code=' + short + '&Name=' + name + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.itemlist = res;
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
}
