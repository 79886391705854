<div class="contentMain">
  <!-- breadcrumb -->
  <div class="row">
    <div class="col-md-12 card-header mainheader header-container">
      <h5>Branch Creation - {{titletextneworedit}} Mode</h5>
    </div>
    <!-- <div class="col-md-6 ">
            <ol class="breadcrum d-flex justify-content-end">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Branch Creation</li>
            </ol>
        </div> -->
  </div>
  <main class="row">
    <div class="card">
      <form class="form" [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm()">
        <div class="card-body">
          <!-- bootstrap tabs -->
          <div class="kliqtab">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs p-0 mb-3">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#General">General</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#StockPoint">Stock Point</a>
              </li>
            </ul>


            <!-- Tab panes -->
            <div class="tab-content">
              <div role="tabpanel" class="tab-pane in active p-3" id="General">
                <!-- general -->
                <!--/ row -->
                <div class="row">
                  <div class="col-lg-9 col-md-9 col-sm-6">
                    <div class="form-group">
                      <label>Branch Name</label>
                      <input type="text" class="form-control" placeholder="Branch Name" [ngClass]="{ 'is-invalid': submitted && fc.BranchName.errors }" (focusout)="onFocusOutEvent($event)" formControlName="BranchName" #myname />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 checkbox pt-4">
                    <div class="form-group">
                      <input id="Status" type="checkbox" formControlName="Status" />
                      <label for="Status"> Status </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="form-group">
                      <label>Branch Code</label>
                      <input type="text" class="form-control" placeholder="Branch Code" [ngClass]="{ 'is-invalid': submitted && fc.BranchCode.errors }" formControlName="BranchCode" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="form-group">
                      <label>Branch Alias</label>
                      <input type="text" class="form-control" placeholder="Branch Alias" formControlName="BranchAlias" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 checkbox pt-md-4">
                    <div class="form-group">
                      <input id="BranchDefault" type="checkbox" formControlName="BranchDefault" />
                      <label for="BranchDefault"> Set Default Branch </label>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 checkbox pt-md-4">
                    <div class="form-group">
                      <input id="BranchIsMain" type="checkbox" formControlName="BranchIsMain" />
                      <label for="BranchIsMain"> Set Main Branch</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <h5 class="py-3">Address</h5>
                  </div>
                  <!-- col -->
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Address Line 1</label>
                      <input type="text" class="form-control" placeholder="Address Line 1" formControlName="Address1" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Address Line 2</label>
                      <input type="text" class="form-control" placeholder="Address Line 2" formControlName="Address2" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Area</label>
                      <select class="form-control" (change)="changedistState($event)" formControlName="TownID">
                        <option [value]="0">Select Area</option>
                        <option *ngFor="let item of AreaList"
                                [value]="item.townId">
                          {{item.townName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>District</label>
                      <select class="form-control" formControlName="DistID">
                        <option [value]="0">Select District</option>
                        <option *ngFor="let item of DistList"
                                [value]="item.distId">
                          {{item.distName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- /col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>State</label>
                      <select class="form-control" formControlName="StateID">
                        <option [value]="0">Select State</option>
                        <option *ngFor="let item of StateList"
                                [value]="item.stateId">
                          {{item.stateName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Country</label>
                      <input type="text" class="form-control" placeholder="Country" formControlName="Country" />
                    </div>
                  </div>
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Pincode</label>
                      <input type="text" class="form-control" placeholder='Pincode' formControlName="Pin" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>UPI ID</label>
                      <input type="text" class="form-control" placeholder="UPI ID" formControlName="Fax" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Phone Number 1</label>
                      <input type="text" class="form-control" placeholder="Phone Number" formControlName="Phone" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label> Phone Number 2</label>
                      <input type="text" class="form-control" placeholder="Alternative Phone Number" formControlName="Phone1" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Mobile </label>
                      <input type="text" class="form-control" placeholder="Mobile Number" formControlName="Mobile" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>PAN Number</label>
                      <input type="text" class="form-control" placeholder="PAN Number" formControlName="PANNo" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Email ID</label>
                      <input type="text" class="form-control" placeholder="Email ID" formControlName="EmailId" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>TIN No</label>
                      <input type="text" class="form-control" placeholder="TIN No" formControlName="TINNo" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>MFL No</label>
                      <input type="text" class="form-control" placeholder="MFL No" formControlName="MFLNo" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>TAN No</label>
                      <input type="text" class="form-control" placeholder="TAN No" formControlName="TANNO" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>CST No</label>
                      <input type="text" class="form-control" placeholder="CST No" formControlName="CSTNo" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>DL20B</label>
                      <input type="text" class="form-control" placeholder="DL20B" formControlName="DL20B" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>DL21B</label>
                      <input type="text" class="form-control" placeholder="DL21B" formControlName="DL21B" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Registration No</label>
                      <select class="form-control" formControlName="RegType">
                        <option value="Composition">Composition</option>
                        <option value="Consumer">Consumer</option>
                        <option value="Regular">Regular</option>
                        <option value="Unregistered">Unregistered</option>
                      </select>
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>GSTIN Number</label>
                      <input type="text" class="form-control" placeholder="GSTIN Number" formControlName="GSTINNO" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Branch Incharge</label>
                      <input type="text" class="form-control" placeholder="Branch Incharge" formControlName="Incharge" />
                    </div>
                  </div>
                  <!-- /col -->
                  <!-- col -->
                  <!-- /col -->
                </div>
                <!--/ row -->
                <!--/ general -->
              </div>
              <div role="tabpanel" class="tab-pane p-3" id="StockPoint">
                <!-- Stock point -->
                <div class="form-group" style="border: 0px solid silver;">
                  <div *ngFor="let data of SPList; let i=index;">
                    <!--<label>
                      <input type="checkbox" [checked]="data.state==1" [value]="data.spid" (change)="onCheckboxChange($event,data)" />
                      {{data.spname}}
                    </label>-->
                    <div class="checkbox">
                      <input id="chkstkpoint{{i}}" type="checkbox" [checked]="data.state==1" [value]="data.spid" (change)="onCheckboxChange($event,data)" />
                      <label for="chkstkpoint{{i}}">  {{data.spname}} </label>
                    </div>
                  </div>
                  <input type="text" value="" class="form-control" [ngClass]="{ 'is-invalid': submitted && fc.StkPId.errors }" formControlName="StkPId" style="display: none;" />
                  <div *ngIf="submitted && fc.StkPId.errors" class="invalid-feedback">
                    <div *ngIf="fc.StkPId.errors.required">* Plz Select Atleast One Stock Point</div>
                  </div>
                </div>
                <!--<div>
                  <div class="checkbox">
                    <input id="active1" type="checkbox" />
                    <label for="active1"> BO SP </label>
                  </div>
                  <div class="checkbox">
                    <input id="active2" type="checkbox" />
                    <label for="active2"> HO SP </label>
                  </div>
                  <div class="checkbox">
                    <input id="active3" type="checkbox" />
                    <label for="active3"> Main Road SP </label>
                  </div>
                  <div class="checkbox">
                    <input id="active4" type="checkbox" />
                    <label for="active4"> Palaklur SP Road </label>
                  </div>
                </div>-->
                <!--/ Stock Points -->
              </div>
            </div>
          </div>
          <!--/ bootstrap tabs -->
        </div>
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="button" (click)="clearform()">New</button>
          <button class="btn redbtn" type="submit">Save</button>
          <button class="btn redbtn" type="button" (click)="clearform()">Clear</button>
          <button class="btn redbtn" type="button">Help</button>
        </div>
      </form>
    </div>
  </main>
</div>
