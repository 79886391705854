<style>
  tr > td {
    line-height: 8px;
    height: 8px;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    padding-top: 2px;
    /*white-space: nowrap;*/
  }
  /*.rowitems > td {
    line-height: 8px;
    height: 8px;
    text-align: center;
    padding-top: 3px;
    white-space: nowrap;
  }*/

</style>
<form class="form-inline custom-form-inline" [formGroup]="form1" (ngSubmit)="SearchOrSubmitForm()" style="margin-top:-20px;">
  <label>Branch :</label>
  <select class="form-control" formControlName="BranchID">
    <option [value]="0">All Branches</option>
    <option *ngFor="let Brlist of BranchList;" [value]="Brlist.branchId">{{Brlist.branchName }}</option>
  </select>
  <label>Date Range:</label>
  <input type="text" class="form-control" placeholder="From Date" style="width:150px;" id="Fromdate" [(ngModel)]="Fromdate" name="Fromdate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange($event)" [bsValue]="Fromdate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
  <input type="text" class="form-control" placeholder="To Date" style="width:150px;" id="Todate" [(ngModel)]="Todate" name="Todate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange2($event)" [bsValue]="Todate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
 <input type="checkbox" class="form-control" id="DuedateWise" name="DuedateWise" (change)="checkCheckBoxvalue($event)" *ngIf="(daywisebytext==='pds')" />
  <label for="DuedateWise">Delv Date Wise</label>
  <button class="btn redbtn" type="submit">Search</button> &nbsp;
  <button printTitle="Print Title" styleSheetFile="{{cssPrintingPath}}"
          [printStyle]="{h4 : {'text-align': 'center'}, span : {'font-size': 'small'}}"
          printSectionId="print-section" ngxPrint>
    <img src="../../../../../assets/images/printer.jpg" style="width:40px;height:30px;" />
  </button>
  <img (click)="generateExcel()" src="../../../../../assets/images/excel.jpg" style="width: 40px; height: 30px;" />
</form>
<br />
<!--<div style="width: 100%; text-align: center; font-weight: bold; font-size: 20px; color: indianred; margin-top: -25px;">
  <label>{{daywisebytexttitle}} </label>
</div>-->
<div id="print-section" style="margin-top:-5px;" #TABLE #table>
  <div style="width: 100%; border: 0px solid black;margin-top:-5px;">
    <table class="daywiseheadtable" style="width: 98%; border: 0px solid black;">
      <thead>
        <tr>
          <th style="text-align: center;width:100%;">{{CompName}}</th>
        </tr>
        <tr>
          <th style="text-align: center;width:100%;">{{Address1}}</th>
        </tr>
        <tr>
          <th style="text-align: center;width:100%;">{{daywisebytexttitle}} </th>
        </tr>
      </thead>
    </table>
  </div>
  <div *ngIf="(daywisebytext==='' || daywisebytext==='ord')" style="width: 100%; overflow: auto;font-size:11px;">
    <table class="daywisetable" style="width: 98%; border: 1px solid black;">
      <thead>
        <tr>
          <th style="text-align: left; width: 3%; border-left: 1px solid black;">S.No</th>
          <th style="text-align: left; width: 10%; border-left: 1px solid black;">Ord.No</th>
          <th style="text-align: left; width: 11%; border-left: 1px solid black; ">Cust Id</th>
          <th style="text-align: left; width: 10%; border-left: 1px solid black; ">Ord.Date</th>
          <th style="text-align: left; width: 10%; border-left: 1px solid black; ">Trail.Date</th>
          <th style="text-align: left; width: 10%; border-left: 1px solid black; ">Delv.Date</th>
          <th style="text-align: left; width: 17%; border-left: 1px solid black; ">Item Name</th>
          <th style="text-align: right; width: 8%; border-left: 1px solid black; ">Qty</th>
          <th style="text-align: right; width: 10%; border-left: 1px solid black; ">Rate</th>
          <th style="text-align: right; width: 11%; border-left: 1px solid black; ">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let list of itemlist; let i = index">
          <td colspan="10" style=" border: 1px solid black;">
            <table style="width: 100%; overflow: auto;">
              <tr>
                <td style="text-align: left; width: 3%;"><label style="font-weight:bold;">{{i+1}}</label></td>
                <td style="text-align: left; width: 10%;"><label style="font-weight:bold;">{{list.objh.billNo}}</label></td>
                <td colspan="8" style="text-align: left; width: 87%;"><label style="font-weight:bold;">{{list.objh.actName}}</label></td>
              </tr>
              <tr>
                <td style="text-align: left; width: 3%; vertical-align: top;">&nbsp;</td>
                <td style="text-align: left; width: 10%; vertical-align: top;">&nbsp;</td>
                <td style="text-align: left; width: 11%; vertical-align: top;"><label>{{list.objh.actCode}}</label></td>
                <td style="text-align: left; width: 10%; vertical-align: top;"><label>{{list.objh.docDate}}</label></td>
                <td style="text-align: left; width: 10%; vertical-align: top;"><label>{{list.objh.docDuedate}}</label></td>
                <td style="text-align: left; width: 10%; vertical-align: top;"><label>{{list.objh.suppBilldate}}</label></td>
                <td style="text-align: left; width: 46%;" colspan="4">
                  <table style="width: 100%; overflow: auto;">
                    <tr *ngFor="let item of list.listi; let j = index">
                      <td style="text-align: left; width: 40%; border: 0px solid black;"><label>{{item.itemname}}</label></td>
                      <td style="text-align: right; width: 15%; border: 0px solid black;"><label>{{item.qty | currency:'INR':''}}</label></td>
                      <td style="text-align: right; width: 22%; border: 0px solid black;"><label>{{item.rate | currency:'INR':''}}</label></td>
                      <td style="text-align: right; width: 23%; border: 0px solid black;"><label>{{item.netValue | currency:'INR':''}}</label></td>
                    </tr>
                    <tr *ngIf="list.listi.length>0">
                      <td>&nbsp;</td>
                      <th style="text-align: right;"><label>{{list.totqty | currency:'INR':''}}</label></th>
                      <td>&nbsp;</td>
                      <th style="text-align: right;"><label>{{list.totnetvalue | currency:'INR':''}}</label></th>
                    </tr>
                    <tr *ngIf="list.listi.length>0">
                      <th style="text-align: right; "><label>Adv. Amt</label></th>
                      <th style="text-align: right; "><label>{{list.advanceamt | currency:'INR':''}}</label></th>
                      <th style="text-align: right; "><label>Due Amt</label></th>
                      <th style="text-align: right; "><label>{{list.billamt | currency:'INR':''}}</label></th>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr *ngIf="itemlist.length===0">
          <td style=" border-top: 1px solid black;font-weight:bold;font-size:medium; height:30px;" colspan="10" class="text-center text-danger">No Data Found for Selection Criteria</td>
        </tr>
        <!--<tr *ngIf="itemlist.length>0">
          <td style="text-align: left; width: 3%;"><label style="font-weight: bold; font-size: 13px;color:indianred;">{{totbillscount}}</label></td>
          <td colspan="8" style="text-align: right; width: 86%;"><label style="font-weight: bold; font-size: 13px; color: indianred; ">Total Amount </label></td>
          <td style="text-align: right; width: 11%;"><label style="font-weight: bold; font-size: 13px; color: indianred; ">{{totnetvalue | currency:'INR':''}}</label></td>
        </tr>-->
        <tr *ngFor="let list of bottomitemlist; let i = index">
          <td style="text-align: left; width: 3%;"><label *ngIf="i===0" style="font-weight: bold; font-size: 13px;color:indianred;">{{totbillscount}}</label><label *ngIf="i>0"></label></td>
          <td colspan="5" style="width:51%;">&nbsp;</td>
          <td style="text-align: left; width: 17%;"><label style="font-weight:bold;">{{list.itemname}}</label></td>
          <td style="text-align: right; width: 8%;"><label style="font-weight:bold;">{{list.qty}}</label></td>
          <td style="width:10%;">&nbsp;</td>
          <td style="text-align: right; width: 11%;"><label *ngIf="i===0" style="font-weight: bold; font-size: 13px; color: indianred; ">{{totnetvalue | currency:'INR':''}}</label><label *ngIf="i>0"></label></td>
        </tr>
        <tr *ngIf="itemlist.length>0">
          <td colspan="8" style="text-align: right; width: 89%;"><label style="font-weight: bold; font-size: 13px; color: indianred;">{{totqty}}</label></td>
          <td colspan="2" style="width:11%;"> &nbsp;</td>
        </tr>
      </tbody>
    </table>
    <!--<br />
    <table class="daywisetable" style="width: 98%;margin-top:-20px; border: 1px solid black;">
      <tbody>
        <tr *ngFor="let list of bottomitemlist; let i = index">
          <td style="text-align: left; width: 3%;"><label style="font-weight:bold;">{{i+1}}</label></td>
          <td style="text-align: left; width: 10%;"><label style="font-weight:bold;">{{list.itemname}}</label></td>
          <td colspan="8" style="text-align: right; width: 87%;"><label style="font-weight:bold;">{{list.qty}}</label></td>
        </tr>
      </tbody>
    </table>-->
  </div>
  <!-- WORK Entry Transactions -->
  <div *ngIf="(daywisebytext==='ic' || daywisebytext==='rc' || daywisebytext==='is' || daywisebytext==='rs' )" style="width: 100%; overflow: auto;font-size:11px;">
    <table class="daywisetable" style="width: 98%; border: 1px solid black;">
      <thead>
        <tr>
          <th style="text-align: left; border-left: 1px solid black; width: 3%;">S.No</th>
          <th style="text-align: left; border-left: 1px solid black; width: 10%;">Bill No</th>
          <th style="text-align: left; border-left: 1px solid black; width: 11%; ">Cust Id</th>
          <th style="text-align: left; border-left: 1px solid black; width: 9%; ">Bill Date</th>
          <th style="text-align: left; border-left: 1px solid black; width: 10%;">Ord.No</th>
          <th style="text-align: left; border-left: 1px solid black; width: 9%; ">Ord.Date</th>
          <th style="text-align: left; border-left: 1px solid black; width: 10%; ">Cutter/Stitcher</th>
          <th style="text-align: left; border-left: 1px solid black; width: 12%; ">Item Name</th>
          <th style="text-align: right; border-left: 1px solid black; width: 8%; ">Qty</th>
          <th style="text-align: right; border-left: 1px solid black; width: 8%; ">Rate</th>
          <th style="text-align: right; border-left: 1px solid black; width: 10%; ">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let list of itemlist; let i = index">
          <td colspan="11" style=" border: 1px solid black;">
            <table style="width: 100%; overflow: auto;">
              <tr style="border: 0px solid black;">
                <td style="text-align: left;  width: 3%;">{{i+1}}</td>
                <td style="text-align: left;  width: 10%;"><label style="font-weight:bold;">{{list.objh.billNo}}</label></td>
                <td colspan="9" style="text-align: left; width: 87%;"><label style="font-weight:bold;">{{list.objh.actName}}</label></td>
              </tr>
              <tr>
                <td style="text-align: left;  width: 3%;">&nbsp;</td>
                <td style="text-align: left; width: 10%;">&nbsp;</td>
                <td style="text-align: left; width: 11%; vertical-align: top; "><label>{{list.objh.actCode}}</label></td>
                <td style="text-align: left; width: 9%; vertical-align: top; "><label>{{list.objh.docDate}}</label></td>
                <td style="text-align: left; width: 10%; vertical-align: top; "><label>{{list.objh.orderno}}</label></td>
                <td style="text-align: left; width: 9%; vertical-align: top; "><label>{{list.objh.orderdate}}</label></td>
                <td style="text-align: left; width: 10%; vertical-align: top; "><label>{{list.objh.cuttorStitName}}</label></td>
                <td style="text-align: left; width: 38%;" colspan="4">
                  <table style="width: 100%; overflow: auto;">
                    <tr class="rowitems" *ngFor="let item of list.listi; let j = index">
                      <td style="text-align: left; width: 32%; vertical-align: middle;"><label>{{item.itemname}}</label></td>
                      <td style="text-align: right; width: 21%; vertical-align: middle;"><label>{{item.qty | currency:'INR':''}}</label></td>
                      <td style="text-align: right; width: 22%; vertical-align: middle;"><label>{{item.rate | currency:'INR':''}}</label></td>
                      <td style="text-align: right; width: 25%; vertical-align: middle;"><label>{{item.netValue | currency:'INR':''}}</label></td>
                    </tr>
                    <tr *ngIf="list.listi.length>0">
                      <td>&nbsp;</td>
                      <th style="text-align: right; vertical-align: middle;"><label>{{list.totqty | currency:'INR':''}}</label></th>
                      <td>&nbsp;</td>
                      <th style="text-align: right; vertical-align: middle;"><label>{{list.totnetvalue | currency:'INR':''}}</label></th>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr *ngIf="itemlist.length===0">
          <td style=" border-top: 1px solid black;font-weight:bold;font-size:medium; height:30px;" colspan="11" class="text-center text-danger">No Data Found for Selection Criteria</td>
        </tr>
        <tr *ngFor="let list of bottomitemlist; let i = index">
          <td style="text-align: left; width: 3%;"><label *ngIf="i===0" style="font-weight: bold; font-size: 13px;color:indianred;">{{totbillscount}}</label><label *ngIf="i>0"></label></td>
          <td colspan="6" style="width:59%;">&nbsp;</td>
          <td style="text-align: left; width: 12%;"><label style="font-weight:bold;">{{list.itemname}}</label></td>
          <td style="text-align: right; width: 8%;"><label style="font-weight:bold;">{{list.qty}}</label></td>
          <td style="width:8%;">&nbsp;</td>
          <td style="text-align: right; width: 10%;"><label *ngIf="i===0" style="font-weight: bold; font-size: 13px; color: indianred; ">{{totnetvalue | currency:'INR':''}}</label><label *ngIf="i>0"></label></td>
        </tr>
        <tr *ngIf="itemlist.length>0">
          <td colspan="9" style="text-align: right; width: 89%;"><label style="font-weight: bold; font-size: 13px; color: indianred;">{{totqty}}</label></td>
          <td colspan="2" style="width:11%;"> &nbsp;</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- WORK Entry Transactions -->
  <!-- Delivery Transactions -->
  <div *ngIf="(daywisebytext==='pds')" style="width: 100%; overflow: auto;font-size:11px;">
    <table class="daywisetable" style="width: 98%; border: 1px solid black;">
      <thead>
        <tr>
          <!--<th style="text-align: left; border-left: 1px solid black; width: 3%;">S.No</th>-->
          <th style="text-align: left; border: 1px solid black;width:10%;">Order No</th>
          <th style="text-align: center; border: 1px solid black; width: 10%; ">Cust Id</th>
          <th style="text-align: left; border: 1px solid black; width: 10%; ">Order Date</th>
          <th style="text-align: left; border: 1px solid black; width: 10%; ">Trail.Date</th>
          <th style="text-align: left; border: 1px solid black; width: 10%; ">Delv.Date</th>
          <th style="text-align: center; border: 1px solid black; width: 16%; ">Item Name</th>
          <th style="text-align: left; border: 1px solid black; width: 8%; ">Qty</th>
          <th style="text-align: left; border: 1px solid black; width: 12%; "> </th>
          <!-- Rate -->
          <th style="text-align: left; border: 1px solid black; width: 14%; "> </th>
          <!-- Amount -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let list of itemlist; let i = index">
          <td colspan="9" style=" border: 1px solid black;">
            <table style="width: 100%; overflow: auto;">
              <tr style="border: 0px solid black;">
                <td style="text-align: left; border: 0px solid black; width: 12%;"><label style="font-weight:bold;">{{list.objh.billNo}}</label></td>
                <td colspan="8" style="text-align: left;  width: 88%;"><label style="font-weight:bold;">{{list.objh.actName}}</label></td>
              </tr>
              <tr>
                <td style="text-align: left; border: 0px solid black; width: 10%; vertical-align: top;">&nbsp;</td>
                <td style="text-align: left; border: 0px solid black; width: 10%; vertical-align: top; "><label>{{list.objh.actCode}}</label></td>
                <td style="text-align: left; border: 0px solid black; width: 10%; vertical-align: top; "><label>{{list.objh.docDate}}</label></td>
                <td style="text-align: left; border: 0px solid black; width: 10%; vertical-align: top; "><label>{{list.objh.docDuedate}}</label></td>
                <td style="text-align: left; border: 0px solid black; width: 10%; vertical-align: top; "><label>{{list.objh.suppBilldate}} </label></td>
                <td style="text-align: left; border: 0px solid black; width: 50%;" colspan="2">
                  <table style="width: 100%; overflow: auto;">
                    <tr *ngFor="let item of list.listi; let j = index">
                      <td style="text-align: left; border: 0px solid black; width: 30%;"><label>{{item.itemname}}</label></td>
                      <td style="text-align: right; border: 0px solid black; width: 16%;"><label>{{item.qty | currency:'INR':''}}</label></td>
                      <td style="text-align: right; border: 0px solid black; width: 23%;"><label> </label></td>
                      <!-- {{item.rate | currency:'INR':''}} -->
                      <td style="text-align: right; border: 0px solid black; width: 28%;"><label> </label></td>
                      <!--{{item.netValue | currency:'INR':''}}-->
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr *ngIf="itemlist.length===0">
          <td style=" border: 0px solid black;" colspan="8" class="text-center text-danger">No Data Found for Selection Criteria</td>
        </tr>
        <tr *ngFor="let list of bottomitemlist; let i = index">
          <td style="text-align: left; width: 3%;"><label *ngIf="i===0" style="font-weight: bold; font-size: 13px;color:indianred;">{{totbillscount}}</label><label *ngIf="i>0"></label></td>
          <td colspan="4" style="width:59%;">&nbsp;</td>
          <td style="text-align: left; width: 12%;"><label style="font-weight:bold;">{{list.itemname}}</label></td>
          <td style="text-align: right; width: 8%;"><label style="font-weight:bold;">{{list.qty}}</label></td>
          <td style="width:8%;">&nbsp; </td>

          <!--<td style="text-align: right; width: 10%;"><label *ngIf="i===0" style="font-weight: bold; font-size: 13px; color: indianred; ">{{totnetvalue | currency:'INR':''}}</label><label *ngIf="i>0"></label></td>-->
        </tr>
        <!--<tr *ngIf="itemlist.length>0">
          <td colspan="8" style="text-align: right; width: 89%;"><label style="font-weight: bold; font-size: 13px; color: indianred;">{{totqty}}</label></td>
          <td colspan="2" style="width:11%;"> &nbsp;</td>
        </tr>-->
        <tr>
    <td colspan="7" style="text-align: right; width: 90%;"><label style="font-weight: bold; font-size: 13px; color: indianred;">{{totqty}}</label></td>
    <td colspan="1" style="width:10%;"> &nbsp;</td>
  </tr>
      </tbody>
    </table>
  </div>
  <!-- Delivery Transactions -->
</div>
