import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpService } from '../../../../http.service';

@Component({
  selector: 'app-addnew-branch',
  templateUrl: './addnew-branch.component.html',
  styleUrls: ['./addnew-branch.component.scss']
})
export class AddnewBranchComponent implements OnInit {
  AreaList: any = [];
  DistList: any = [];
  StateList: any = [];
  SPList: any = [];
  form1: FormGroup | undefined;
  @ViewChild('myname') input;
  get fc(): any | undefined { return this.form1?.controls; }
  titletextneworedit: string = "New";
  submitted = false;
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    var CompID = Number(localStorage.getItem("CompID"));
    //form Defining Start
    this.form1 = this._fb.group({
      BranchName: ['', [Validators.required]],
      BranchId: [0],
      BranchCode: ['', [Validators.required, Validators.maxLength(5)]],
      BranchAlias: [''],
      CompID: [CompID],
      Status: [true],
      BranchDefault: [true],
      BranchIsMain: [false],
      Address1: [''],
      Address2: [''],
      TownID: [0],
      DistID: [0],
      StateID: [0],
      Country: ['India'],
      Pin: [''],
      Fax: [''],
      Phone: [''],
      Phone1: [''],
      Mobile: [''],
      PANNo: [''],
      EmailId: [''],
      TINNo: [''],
      MFLNo: [''],
      TANNO: [''],
      CSTNo: [''],
      DL20B: [''],
      DL21B: [''],
      RegnId: [0],
      RegType: ['Composition'],
      GSTINNO: [''],
      Incharge: [''],
      CreatedBy: [1],
      ModifiedBy: [1],
      CreatedDate: [''],
      ModifiedDate: [''],
      ActId: [0],
      StkPId: ['', [Validators.required]],
      BranchIDs: [''],
      IsSalesman: [false],
      BranchPrevBalSign: [''],
      BranchPrevBal: [0],
      AccessBranchIdsArray: this._fb.array([]),
    });
    this.getAreaList();
    this.getDistList();
    this.getStateList();
    if (this.router.getCurrentNavigation().extras.state.id === undefined) {
      this.getSPList();
    }
    //End form Defining
    //Edit Start
    if (this.router.getCurrentNavigation().extras.state.id != undefined) {
      this.titletextneworedit = this.router.getCurrentNavigation().extras.state.text;
      var objdata = this.router.getCurrentNavigation().extras.state.obj;
      if (objdata != null) {
      
        this.form1?.patchValue({
          BranchName: objdata.branchName,
          BranchId: objdata.branchId,
          BranchCode: objdata.branchCode,
          BranchAlias: objdata.branchAlias,
          CompID: objdata.compId,
          Status: objdata.status,
          BranchDefault: (objdata.branchDefault == 1 ? true : false),
          BranchIsMain: (objdata.branchIsMain == 1 ? true : false),
          Address1: objdata.address1,
          Address2: objdata.address2,
          TownID: objdata.townID,
          DistID: objdata.distID,
          StateID: objdata.stateID,
          Country: objdata.country,
          Pin: objdata.pin,
          Fax: objdata.fax,
          Phone: objdata.phone,
          Phone1: objdata.phone1,
          Mobile: objdata.mobile,
          PANNo: objdata.panno,
          EmailId: objdata.emailId,
          TINNo: objdata.tinno,
          MFLNo: objdata.mflno,
          TANNO: objdata.tanno,
          CSTNo: objdata.cstno,
          DL20B: objdata.dl20b,
          DL21B: objdata.dl21b,
          RegnId: objdata.regnId,
          RegType: objdata.regType,
          GSTINNO: objdata.gstinno,
          Incharge: objdata.incharge,
          ActId: objdata.actId,
          StkPId: objdata.stkPid,
          BranchIDs: objdata.branchIds,
          IsSalesman: objdata.isSalesman,
          BranchPrevBalSign: objdata.branchPrevBalSign,
          BranchPrevBal: objdata.branchPrevBal,
        });
        const request = {
          method: 'GET',
          action_url: 'GetStockPoints'
        };
        this._services.doHttp(request).subscribe(
          res => {
            this.SPList = res;
            if (this.SPList != null) {
              //
              this.SPList.forEach((x: { state: number; }) => x.state = 0);
              if (objdata.stkPid != null) {
                const AccessBranchIdsArray: FormArray = this.form1?.get('AccessBranchIdsArray') as FormArray;
                let str: any; str = objdata.stkPid.split(",");
                if (str.length > 0) {
                  for (var x = 0; x < str.length; x++) {
                    for (var y = 0; y < this.SPList.length; y++) {
                      if (str[x] == this.SPList[y].spid) {
                        this.SPList[y].state = 1;
                        AccessBranchIdsArray.push(new FormControl(this.SPList[y].spid));
                      }
                    }
                  }
                }
                //console.log(this.SPList);
              }        //
            }
          },
          error => {
            console.log(error);
          });
       
      }
    }
    //End Edit
  };
  ngOnInit(): void {
    //var CompID = Number(localStorage.getItem("CompID"));
  }
  ngAfterViewInit() {
    //Focus Start
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    //End
  }
  SaveOrSubmitForm() {
    this.submitted = true;
    let myDateTime: any = new Date();
    let sqlTimeAsString = formatDate(new Date(), "yyyy-MM-dd", "en_US");
    if (this.form1?.invalid) {
      return;
    }
    var TownID = this.form1?.get('TownID')?.value;
    var DistID = this.form1?.get('DistID')?.value;
    var StateID = this.form1?.get('StateID')?.value;
    //
    let BranchDefault: any = this.form1?.get('BranchDefault')?.value;
    let BranchIsMain: any = this.form1?.get('BranchIsMain')?.value;   
    this.form1?.patchValue({
      CreatedBy: Number(localStorage.getItem("Uid")),
      ModifiedBy: Number(localStorage.getItem("Uid")),
      CreatedDate: sqlTimeAsString,
      ModifiedDate: sqlTimeAsString,
      AccessBranchIdsArray: [''],
      TownID: Number(TownID),
      DistID: Number(DistID),
      StateID: Number(StateID),
      BranchDefault: (BranchDefault == true ? 1 : (BranchDefault == false ? 0 : BranchDefault)),
      BranchIsMain: (BranchIsMain == true ? 1 : (BranchIsMain == false ? 0 : BranchIsMain))      
    });
    //    
    var postvalue = this.form1?.value;
    //console.log(this.form1?.value);   
    let options;
    options = new HttpHeaders()
      .set('Content-Type', 'application/json;charset=utf-8')
      .set('pragma', 'no-cache')
      .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0');
    this.httpClient.post(this._services.APIUrl + '/InsertBranch', JSON.stringify(postvalue), {
      headers: options
    }).subscribe(res => {
      var result: any = res;
      //console.log(result.msg);
      if (result.msg != null) {
        if (result.msg.toString().includes('Successfull')) {
          Swal.fire('Branch Creation', result.msg.toString(), 'success');
          this.clearform();
        }
      }
    },
      error => {
        console.log(error);
      });
  }
  clearform() {
    this.form1?.patchValue({
      BranchName: '',
      BranchId: 0,
      BranchCode: '',
      BranchAlias: '',
      CompID: 0,
      Status: true,
      BranchDefault: true,
      BranchIsMain: false,
      Address1: '',
      Address2: '',
      TownID: 0,
      DistID: 0,
      StateID: 0,
      Country: 'India',
      Pin: '',
      Fax: '',
      Phone: '',
      Phone1: '',
      Mobile: '',
      PANNo: '',
      EmailId: '',
      TINNo: '',
      MFLNo: '',
      TANNO: '',
      CSTNo: '',
      DL20B: '',
      DL21B: '',
      RegnId: 0,
      RegType: 'Composition',
      GSTINNO: '',
      Incharge: '',
      CreatedBy: 1,
      ModifiedBy: 1,
      CreatedDate: '',
      ModifiedDate: '',
      ActId: 0,
      StkPId: '',
      BranchIDs: '',
      IsSalesman: false,
      BranchPrevBalSign: '',
      BranchPrevBal: 0,
    });
    this.titletextneworedit = "New";
    this.submitted = false;    
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    this.getSPList();
    this.SPList.forEach((x: { state: number; }) => x.state = 0);
  }
  onFocusOutEvent(event: any) {
    var short = this.form1?.get('BranchCode')?.value;
    if (short.length == 0) {
      var txtname = event.target.value;
      let strname: string = "";
      if (txtname != null) {
        if (txtname.length == 0) {
          this.form1?.patchValue({
            BranchCode: ''
          });
          return;
        }
        if (txtname.length <= 5) {
          strname = txtname;
        }
        else {
          strname = txtname.slice(0, 5);
        }
        this.form1?.patchValue({
          BranchCode: strname
        });
      }
    }
  }
  changedistState(event: any) {
    let stateId: any = 0; let distId: any = 0;
    if (this.AreaList.find((item: { townId: any; }) => item.townId == Number(event.target.value)) != undefined) {
      try {
        distId = Number(this.AreaList.find((item: { townId: any; }) => item.townId == Number(event.target.value)).distId);
      } catch { distId = 0; }
      try {
        stateId = Number(this.AreaList.find((item: { townId: any; }) => item.townId == Number(event.target.value)).stateId);
      } catch { stateId = 0; }
    }
    this.form1?.patchValue({
      DistID: distId,
      StateID: stateId
    });
  }
  changeState(event: any) {
    let stateId: any = 0;
    if (this.DistList.find((item: { distId: any; }) => item.distId == Number(event.target.value)) != undefined) {
      try {
        stateId = Number(this.DistList.find((item: { distId: any; }) => item.distId == Number(event.target.value)).stateId);
      } catch { stateId = 0; }
    }
    this.form1?.patchValue({
      StateID: stateId
    });
  }
  onCheckboxChange(e: any, data: any) {
    //
    const AccessBranchIdsArray: FormArray = this.form1?.get('AccessBranchIdsArray') as FormArray;
    let AccessBranchIds: string = "";
    if (e.target.checked) {
      AccessBranchIdsArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      AccessBranchIdsArray.controls.forEach((item: AbstractControl) => {
        if (item.value == e.target.value) {
          AccessBranchIdsArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    this.SPList.find((item: { spid: any; }) => item.spid == e.target.value).state = data.state;
    //
    var array = AccessBranchIdsArray.value;
    if (array != null) {
      if (array.length > 0) {
        for (var i = 0; i < array.length; i++) {
          AccessBranchIds += array[i] + ",";
        }
      }
      if (AccessBranchIds.trim().length > 0) {
        let str = AccessBranchIds.trim();
        str = str.substring(0, str.length - 1);
        AccessBranchIds = str;
      }
    }
    this.form1?.patchValue({
      StkPId: AccessBranchIds
    });
    var StkPId = this.form1?.get('StkPId')?.value;
    //console.log('onCheckboxChange : '+StkPId);
  }
  getAreaList() {
    const request = {
      method: 'GET',
      action_url: 'GetTowns'
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.AreaList = res;
      },
      error => {
        console.log(error);
      });
  }
  getDistList() {
    const request = {
      method: 'GET',
      action_url: 'GetDistricts'
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.DistList = res;
      },
      error => {
        console.log(error);
      });
  }
  getStateList() {
    const request = {
      method: 'GET',
      action_url: 'GetStates'
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.StateList = res;
      },
      error => {
        console.log(error);
      });
  }
  getSPList() {
    const request = {
      method: 'GET',
      action_url: 'GetStockPoints'
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.SPList = res;
      },
      error => {
        console.log(error);
      });
  }
}
