<div class="contentMain">
  <main class="row">
    <div class="card">
      <form class="form" [formGroup]="form1" >
        <div class="card-body">
          <div class="row border h-50 py-2">
            <div class="col-md-3">
              <button #fileInput style="visibility:hidden" class="btn" type="button" (click)="cancelImageasDefaultedit()">Cancel</button>
              <input type="file" style="visibility: hidden; border: 1px solid red;" id="img" #Image accept="image/*"  (change)="onSelectFile($event)" />
              <figure class="position-relative" style="border: 1px solid silver; margin-top: -55px;">
                <span class="closeicon position-absolute text-center" style="background-color:whitesmoke;color:red;font-weight:bold;" (click)="cancelImageasDefault()">X</span>
                <img [src]="imgUrl" class="img-fluid w-100" alt="Profile Image" onerror="this.src='./assets/images/1.jpg'">
              </figure>
              <table style="width:100%;">
                <tr>
                  <td style="text-align:center;">
                    <label for="img" class="btn btn-outline-primary redbtn rounded-pill">Select Image</label>
                  </td>
                </tr>
              </table>
              <!--<figure class="position-relative">
            <span class="closeicon position-absolute">X</span>
            <img src="assets/images/1.jpg" alt="" class="img-fluid w-100">
          </figure>-->
            </div>
            <div class="col-md-3">
              <figure class="position-relative">
                <span class="closeicon position-absolute">X</span>
                <img src="assets/images/1.jpg" alt="" class="img-fluid w-100">
              </figure>
            </div>
            <div class="col-md-3">
              <figure class="position-relative">
                <span class="closeicon position-absolute">X</span>
                <img src="assets/images/1.jpg" alt="" class="img-fluid w-100">
              </figure>
            </div>
            <div class="col-md-3">
              <figure class="position-relative">
                <span class="closeicon position-absolute">X</span>
                <img src="assets/images/1.jpg" alt="" class="img-fluid w-100">
              </figure>
            </div>
          </div>
        </div>
        </form>
    </div>
  </main>
</div>
