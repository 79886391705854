<div class="overlay"></div>
<aside id="leftsidebar" class="sidebar">
  <div class="navbar-brand">
    <a [routerLink]="['dashboard']">
      <img src="./assets/images/logo.png" alt="" />
    </a>
    <button class="btn-menu ls-toggle-btn" type="button">
      <span class="icon-bars" (click)="toggleMenu($event)"></span>
    </button>
  </div>
  <div class="menu">
    <ul class="list">
      <li>
        <div class="user-info">
          <br />
          <div class="detail">
            <h4>Restaurant Name</h4>
            <small>Human Resource Department</small>
          </div>
        </div>
      </li>
      <li *ngFor="let menuOption of menuOptions; let i = index"
          (click)="toggleDropdown($event, menuOption)">
        <a *ngIf="menuOption.routeLink" [routerLink]="[menuOption.routeLink]">
          <i class="{{ 'zmdi' + ' ' + menuOption.icon }}"></i>
          <span>{{ menuOption.menuText }}</span>
        </a>
        <a href="javascript:void(0);"
           *ngIf="!menuOption.routeLink"
           [class.toggled]="menuOption.isClicked"
           [class.menu-toggle]="menuOption.extras.length">
          <i class="{{ 'zmdi' + ' ' + menuOption.icon }}"></i>
          <span>{{ menuOption.menuText }}</span>
        </a>
        <ng-container [ngTemplateOutlet]="subMenu"
                      [ngTemplateOutletContext]="{ menu: menuOption }"></ng-container>
      </li>
    </ul>
  </div>
</aside>
<ng-template #subMenu let-menu="menu">
  <ul class="ml-menu sub-list"
      [class.d-block]="menu.isClicked"
      *ngIf="menu.extras && menu.extras.length">
    <li *ngFor="let sub of menu.extras; let index = index"
        (click)="toggleDropdown($event, sub)">

      <div *ngIf="(sub.menuText !=='WorkEntry-CUTTING' && sub.menuText !=='WorkEntry-STITCH' && sub.menuText !=='Order' && sub.menuText !=='Issue To Cutting' && sub.menuText !=='Receive From Cutting' && sub.menuText !=='Issue To Stitching' && sub.menuText !=='Receive From Stitching' && sub.menuText !=='Delivery')">
        <a *ngIf="sub.routeLink" [routerLink]="[sub.routeLink]">
          {{ sub.menuText }}
        </a>
        <a href="javascript:void(0);"
           class="sub"
           *ngIf="!sub.routeLink"
           [class.toggled]="sub.isClicked"
           [class.menu-toggle]="sub.extras && sub.extras.length">
          {{ sub.menuText }}
        </a>
        <ng-container [ngTemplateOutlet]="subMenu"
                      [ngTemplateOutletContext]="{ menu: sub }"></ng-container>
      </div>

      <div *ngIf="sub.menuText ==='WorkEntry-CUTTING' && WorkEntryCUTTING === 1">
        <a *ngIf="sub.routeLink" (click)="navigatepage(sub.routeLink,0)">
          {{ sub.menuText }}
        </a>
        <a href="javascript:void(0);"
           class="sub"
           *ngIf="!sub.routeLink"
           [class.toggled]="sub.isClicked"
           [class.menu-toggle]="sub.extras && sub.extras.length">
          {{ sub.menuText }}
        </a>
        <ng-container [ngTemplateOutlet]="subMenu"
                      [ngTemplateOutletContext]="{ menu: sub }"></ng-container>
      </div>

      <div *ngIf="sub.menuText ==='WorkEntry-STITCH' && WorkEntrySTITCHING === 1">
        <a *ngIf="sub.routeLink" (click)="navigatepage(sub.routeLink,1)">
          {{ sub.menuText }}
        </a>
        <a href="javascript:void(0);"
           class="sub"
           *ngIf="!sub.routeLink"
           [class.toggled]="sub.isClicked"
           [class.menu-toggle]="sub.extras && sub.extras.length">
          {{ sub.menuText }}
        </a>
        <ng-container [ngTemplateOutlet]="subMenu"
                      [ngTemplateOutletContext]="{ menu: sub }"></ng-container>
      </div>
      <div *ngIf="sub.menuText ==='Order'">
        <a *ngIf="sub.routeLink" (click)="navigatepagedaywise(sub.routeLink,'ord')">
          {{ sub.menuText }}
        </a>
      </div>
      <div *ngIf="sub.menuText ==='Issue To Cutting'">
        <a *ngIf="sub.routeLink" (click)="navigatepagedaywise(sub.routeLink,'ic')">
          {{ sub.menuText }}
        </a>
      </div>
      <div *ngIf="sub.menuText ==='Receive From Cutting'">
        <a *ngIf="sub.routeLink" (click)="navigatepagedaywise(sub.routeLink,'rc')">
          {{ sub.menuText }}
        </a>
      </div>
      <div *ngIf="sub.menuText ==='Issue To Stitching'">
        <a *ngIf="sub.routeLink" (click)="navigatepagedaywise(sub.routeLink,'is')">
          {{ sub.menuText }}
        </a>
      </div>
      <div *ngIf="sub.menuText ==='Receive From Stitching'">
        <a *ngIf="sub.routeLink" (click)="navigatepagedaywise(sub.routeLink,'rs')">
          {{ sub.menuText }}
        </a>
      </div>
      <div *ngIf="sub.menuText ==='Delivery'">
        <a *ngIf="sub.routeLink" (click)="navigatepagedaywise(sub.routeLink,'del')">
          {{ sub.menuText }}
        </a>
      </div>
      <!--<div *ngIf="sub.menuText ==='Pending Detail Report'">
        <a *ngIf="sub.routeLink" (click)="navigatepagedaywise(sub.routeLink,'pdr')">
          {{ sub.menuText }}
        </a>
      </div>-->

    </li>
  </ul>
</ng-template>
