import { Component, ElementRef, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from 'src/app/http.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
declare var require: any;
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { ExcelService } from '../../../excel.service';
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-customer-enquiry',
  templateUrl: './customer-enquiry.component.html',
  styleUrls: ['./customer-enquiry.component.scss']
})
export class CustomerEnquiryComponent implements OnInit {
  daywisebytext: any = "ord";
  daywisebytexttitle: any = "Customer Enquiry Report";
  form1: FormGroup | undefined;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title: any = 'Customer Enquiry Report';
  cssPrintingPath: any = "./assets/fonts/Printing.css";
  CompName: any = ""; Address1: any = "";
  OrdersList: any = []; CuttingMasterList: any = []; StitchingMasterlist: any = []; ReadyToDelvlist: any = []; BalinShoplist: any = [];
  accountList: any = [];
  custid: any = ""; custname: any = ""; townname: any = ""; mobile: any = "";
  filteredAccountCodeList: any = [];
  flagAccountCode: any = false;
  filteredAccountNameList: any = [];
  flagAccountName: any = false;
  actidtitle: any = "0";
  //
  @ViewChild('myname') input;
  get fc(): any | undefined { return this.form1?.controls; }
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private datePipe: DatePipe, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute, private excelService: ExcelService) {
    this.form1 = this._fb.group({
      ActID: [0],
      AccountCode: '',
      AccountName: '',
    });
    let fyid: any = 0; let compid: any = 0;
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }
    //when page Load function
    const request = {
      method: 'GET',
      action_url: 'GetCustomerEnquiryload?compid=' + compid + '&fyid=' + fyid + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.accountList = resobj.listaccounts;
      },
      error => {
        console.log(error);
      });
    //End
  }
  clearAccounts() {
    this.custid = ""; this.custname = ""; this.townname = ""; this.mobile= "";
  }
  SaveOrSubmitForm(evn: any, printexcel: any) {
    this.clearAccounts();
    let AccountCode = this.form1?.get('AccountCode').value;
    let AccountName = this.form1?.get('AccountName').value;
    if ((AccountCode.length == 0) && (AccountName.length == 0)) {
      Swal.fire('Warning', 'Please Select Account Code & Name ..', 'warning');
      this.clearAccounts();
      this.OrdersList = []; this.CuttingMasterList = []; this.StitchingMasterlist = []; this.ReadyToDelvlist = []; this.BalinShoplist = [];
      return;
    }
    if (this.actidtitle == "0" || this.actidtitle == "") {
      Swal.fire('Warning', 'Please Select Account Code & Name ..', 'warning');
      this.clearAccounts();
      this.OrdersList = []; this.CuttingMasterList = []; this.StitchingMasterlist = []; this.ReadyToDelvlist = []; this.BalinShoplist = [];
      return;
    }
    let ActID: any = Number(this.form1?.get('ActID').value);
    let fyid: any = 0; let compid: any = 0;
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }
    const request = {
      method: 'GET',
      action_url: 'GetCustomerEnquiry?id=' + ActID + '&compid=' + compid + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        if (resobj != null) {
          if (resobj.objcustomer != null) {
            this.custid = resobj.objcustomer.actcode; this.custname = resobj.objcustomer.actname; this.townname = resobj.objcustomer.townname; this.mobile = resobj.objcustomer.actmob1;
          }
          if (resobj.objcomp != null) {
            this.CompName = resobj.objcomp.compName; this.Address1 = resobj.objcomp.address1; 
          }
          this.OrdersList = resobj.listorders;
          this.CuttingMasterList = resobj.listcutttrans;
          this.StitchingMasterlist = resobj.liststittrans;
          this.ReadyToDelvlist = resobj.listreadytodelvtrans;
          this.BalinShoplist = resobj.listbalqtyshoptrans;
        }
        else {
          this.OrdersList = []; this.CuttingMasterList = []; this.StitchingMasterlist = []; this.ReadyToDelvlist = []; this.BalinShoplist = [];
        }
        if (printexcel == 1) {
          this.excelService.generateExcelForCustomerEnquiry(resobj, this.daywisebytext, this.daywisebytexttitle, this.CompName, this.Address1);
        }
      },
      error => {
        console.log(error);
      });
  }
  generateExcel() {

    //const request = {
    //  method: 'GET',
    //  action_url: 'GetOrdersItemwiseDetailedExcel?brid=' + brid + '&Fdate=' + Fromdate + '&Tdate=' + todate + '&daywiseby=' + this.daywisebytext + ''
    //};
    //this._services.doHttp(request).subscribe(
    //  res => {
    //    if (res != null) {
    //      let resobj: any = res;
    //      if (resobj != null) {
    //        if (this.daywisebytext == "" || this.daywisebytext == "ord") {

    //          this.excelService.generateExcel(resobj, this.daywisebytext, this.daywisebytexttitle, this.CompName, this.Address1);
    //        }
    //        else if (this.daywisebytext == "ic" || this.daywisebytext == "rc" || this.daywisebytext == "is" || this.daywisebytext == "rs") {
    //          this.excelService.generateExcelWorkEntryTransactions(resobj, this.daywisebytext, this.daywisebytexttitle, this.CompName, this.Address1);
    //        }
    //        else if (this.daywisebytext == "del") {
    //          this.excelService.generateExcelDeliveryTransactions(resobj, this.daywisebytext, this.daywisebytexttitle, this.CompName, this.Address1);
    //        }
    //      }
    //    }
    //  },
    //  error => {
    //    console.log(error);
    //    this._services.errorMsg(error);
    //  });

  }
  //Filter AccountCode
  onselectAccountCode(item: any, isname: any) {
    this.actidtitle = item.actId.toString();
    this.form1?.patchValue({
      AccountCode: item.actCode,
      AccountName: item.actName,
      ActID: item.actId.toString(),
    });
    this.flagAccountCode = false;
  }
  changeAccountCode(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredAccountCodeList = [];
      this.flagAccountCode = false;
    }
  }
  clickAccountCode(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredAccountCodeList = [];
      this.flagAccountCode = false;
    }
  }
  keypressAccountCode(event: any, isname: any) {
    this.form1?.patchValue({
      ActID: "0",
    });
    if (event.target.value == "") {
      this.filteredAccountCodeList = [];
      if (isname == 0) {
        this.flagAccountCode = false;
      }
      else {
        this.flagAccountCode = false;
      }
    }
    else {
      this.flagAccountCode = false;
      this.filteredAccountCodeList = this.accountList.filter((x: { actType: any; actCode: any; }) => x.actCode.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredAccountCodeList != null) {
        if (this.filteredAccountCodeList.length > 0) {
          this.flagAccountCode = true;
        }
      }
    }
  }
  //End Filter AccountCode
  //Filter AccountName
  onselectAccountName(item: any, isname: any) {
    this.actidtitle = item.actId.toString();
    this.form1?.patchValue({
      AccountCode: item.actCode,
      AccountName: item.actName,
      ActID: item.actId.toString(),
    });
    this.flagAccountName = false;
  }
  changeAccountName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredAccountNameList = [];
      this.flagAccountName = false;
    }
  }
  clickAccountName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredAccountNameList = [];
      this.flagAccountName = false;
    }
  }
  keypressAccountName(event: any, isname: any) {
    this.form1?.patchValue({
      ActID: "0",
    });
    if (event.target.value == "") {
      this.filteredAccountNameList = [];
      if (isname == 0) {
        this.flagAccountName = false;
      }
      else {
        this.flagAccountName = false;
      }
    }
    else {
      this.flagAccountName = false;
      this.filteredAccountNameList = this.accountList.filter((x: { actType: any; actName: any; }) => x.actName.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredAccountNameList != null) {
        if (this.filteredAccountNameList.length > 0) {
          this.flagAccountName = true;
        }
      }
    }
  }
  //Filter AccountName
  ngOnInit(): void {
  }

}
