import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs/dist/exceljs.min.js';
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  constructor() { }
  async generateExcel(itemlist: any, daywiseby: any, daywisebytexttitle: any, companyname: any, addr: any) {
    if (itemlist != null) {
      if (itemlist.length == 0) {
        return;
      }
    }
    const title = daywisebytexttitle;
    const header = [
      'S.No',
      'Ord.No',
      'Cust Id',
      'Ord.Date',
      'Trail.Date',
      'Delv.Date',
      'Item Name',
      'Qty',
      'Rate',
      'Amount',
    ];
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("");
    const compname = worksheet.addRow(['', '', '', '', companyname]);
    compname.font = {
      size: 12,
      bold: true,
    };
    const address = worksheet.addRow(['', '', '', '', addr]);
    address.font = {
      size: 12,
      bold: true,
    };
    const titleRow = worksheet.addRow(['', '', '', '', title]);
    titleRow.font = {
      size: 12,
      bold: true,
    };
    //worksheet.mergeCells('A1:H2');
    //worksheet.alignment = { vertical: 'middle', horizontal: 'center' };    
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    itemlist.forEach((d, index: any) => {

      let objres: any = d;
      const roworder = worksheet.addRow([
        objres.order.colu0,
        objres.order.colu1,
        objres.order.colu2,
        objres.order.colu3,
        objres.order.colu4,
        '',
        objres.order.colu6,
        objres.order.colu7,
        objres.order.colu8,
        objres.order.colu9,
      ]);
      roworder.font = {
        size: 12,
        bold: true,
      };
      const itemscust: any = objres.customer;
      const items: any = itemscust.colu5;
      const totalcalc: any = objres.totalcalc;
      items.forEach((i: any, index: any) => {
        if (index == 0) {
          worksheet.addRow([
            itemscust.colu0,
            itemscust.colu1,
            itemscust.colu2,
            itemscust.colu3,
            itemscust.colu9,
            itemscust.colu4,
            i.colu5,
            i.colu6,
            i.colu7,
            i.colu8,
          ]);
        } else {
          worksheet.addRow([
            i.colu0,
            i.colu1,
            i.colu2,
            i.colu3,
            i.colu9,
            i.colu4,
            i.colu5,
            i.colu6,
            i.colu7,
            i.colu8,
          ]);
        }
      });
      totalcalc.forEach((i: any, index: any) => {
        const totrow = worksheet.addRow([
          i.colu0,
          i.colu1,
          i.colu2,
          i.colu3,
          i.colu9,
          i.colu4,
          i.colu5,
          i.colu6,
          i.colu7,
          i.colu8,
        ]);
        totrow.font = {
          size: 11,
          bold: true,
        };
      });
      const itemscustb: any = objres.objbottom;
      if (itemscustb != null) {
        const itemsb: any = itemscustb.colu5;
        if (itemsb != null) {
          if (itemsb.length > 0) {
            itemsb.forEach((i: any, index: any) => {
              if (index == 0) {
                const totrow = worksheet.addRow([
                  itemscustb.colu0,
                  '',
                  '',
                  '',
                  '',
                  '',
                  i.colu5,
                  i.colu6,
                  '',
                  itemscustb.colu8,
                ]);
                totrow.font = {
                  size: 11,
                  bold: true,
                };
              }
              else {
                const totrow = worksheet.addRow([
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  i.colu5,
                  i.colu6,
                  '',
                  '',
                ]);
                totrow.font = {
                  size: 11,
                  bold: true,
                };
              }
            });
            const totrow = worksheet.addRow([
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              itemscustb.colu1,
              '',
              '',
            ]);
            totrow.font = {
              size: 11,
              bold: true,
            };
          }
        }
      }

    });

    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, 'DayWiseReportOrder.xlsx');
    });
  }
  async generateExcelWorkEntryTransactions(itemlist: any, daywiseby: any, daywisebytexttitle: any, companyname: any, addr: any) {
    if (itemlist != null) {
      if (itemlist.length == 0) {
        return;
      }
    }
    const title = daywisebytexttitle;
    const header = [
      'S.No',
      'Bill No',
      'Cust Id',
      'Bill Date',
      'Ord.No',
      'Ord.Date',
      'Cutter/Stitcher',
      'Item Name',
      'Qty',
      'Rate',
      'Amount',
    ];
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("");
    const compname = worksheet.addRow(['', '', '', companyname]);
    compname.font = {
      size: 12,
      bold: true,
    };
    const address = worksheet.addRow(['', '', '', addr]);
    address.font = {
      size: 12,
      bold: true,
    };
    const titleRow = worksheet.addRow(['', '', '', title]);
    titleRow.font = {
      size: 12,
      bold: true,
    };
    //const worksheet = workbook.addWorksheet(daywisebytexttitle);    
    //const titleRow = worksheet.addRow([title]);
    //titleRow.font = {
    //  name: 'Corbel',
    //  family: 4,
    //  size: 16,
    //  underline: 'single',
    //  bold: true,
    //};
    //worksheet.mergeCells('A1:H2');
    //worksheet.alignment = { vertical: 'middle', horizontal: 'center' };
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    itemlist.forEach((d) => {
      let objres: any = d;
      const roworder = worksheet.addRow([
        objres.order.colu0,
        objres.order.colu1,
        objres.order.colu2,
        objres.order.colu3,
        objres.order.colu4,
        objres.order.colu5,
        objres.order.colu6,
        '',
        objres.order.colu8,
        objres.order.colu9,
        objres.order.colu10,
      ]);
      roworder.font = {
        size: 12,
        bold: true,
      };
      const itemscust: any = objres.customerForWorkEntry;
      const items: any = itemscust.colu7;
      const totalcalc: any = objres.totalcalc;
      items.forEach((i: any, index: any) => {
        if (index == 0) {
          worksheet.addRow([
            itemscust.colu0,
            itemscust.colu1,
            itemscust.colu2,
            itemscust.colu3,
            itemscust.colu4,
            itemscust.colu5,
            itemscust.colu6,
            i.colu7,
            i.colu8,
            i.colu9,
            i.colu10,
          ]);
        } else {
          worksheet.addRow([
            i.colu0,
            i.colu1,
            i.colu2,
            i.colu3,
            i.colu4,
            i.colu5,
            i.colu6,
            i.colu7,
            i.colu8,
            i.colu9,
            i.colu10,
          ]);
        }
      });

      totalcalc.forEach((i: any, index: any) => {
        const totrow = worksheet.addRow([
          i.colu0,
          i.colu1,
          i.colu2,
          i.colu3,
          i.colu4,
          i.colu5,
          i.colu6,
          i.colu7,
          i.colu8,
          i.colu9,
          i.colu10,
        ]);
        totrow.font = {
          size: 11,
          bold: true,
        };
      });
      const itemscustb: any = objres.objbottom;
      if (itemscustb != null) {
        const itemsb: any = itemscustb.colu5;
        if (itemsb != null) {
          if (itemsb.length > 0) {
            itemsb.forEach((i: any, index: any) => {
              if (index == 0) {
                const totrow = worksheet.addRow([
                  itemscustb.colu0,
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  i.colu5,
                  i.colu6,
                  '',
                  itemscustb.colu8,
                ]);
                totrow.font = {
                  size: 11,
                  bold: true,
                };
              }
              else {
                const totrow = worksheet.addRow([
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  i.colu5,
                  i.colu6,
                  '',
                  '',
                ]);
                totrow.font = {
                  size: 11,
                  bold: true,
                };
              }
            });
            const totrow = worksheet.addRow([
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              itemscustb.colu1,
              '',
              '',
            ]);
            totrow.font = {
              size: 11,
              bold: true,
            };
          }
        }
      }
    });
    let xltitle: any = "DayWiseReportIssueToCutting.xlsx";
    if (daywiseby == "ic") {
      xltitle = "DayWiseReportIssueToCutting.xlsx";
    }
    if (daywiseby == "rc") {
      xltitle = "DayWiseReportReceiveFromCutting.xlsx";
    }
    if (daywiseby == "is") {
      xltitle = "DayWiseReportIssueToStitching.xlsx";
    }
    if (daywiseby == "rs") {
      xltitle = "DayWiseReportReceiveFromStitching.xlsx";
    }
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, xltitle);
    });
  }
  async generateExcelDeliveryTransactions(itemlist: any, daywiseby: any, daywisebytexttitle: any, companyname: any, addr: any) {
    if (itemlist != null) {
      if (itemlist.length == 0) {
        return;
      }
    }
    const title = daywisebytexttitle;
    const header = [
      'S.No',
      'Delv.No',
      'Cust Id',
      'Delv.Date',
      'Ord.No',
      'Ord.Date',
      'Item Name',
      'Qty',
      'Rate',
      'Amount',
    ];
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("");
    const compname = worksheet.addRow(['', '', '', companyname]);
    compname.font = {
      size: 12,
      bold: true,
    };
    const address = worksheet.addRow(['', '', '', addr]);
    address.font = {
      size: 12,
      bold: true,
    };
    const titleRow = worksheet.addRow(['', '', '', title]);
    titleRow.font = {
      size: 12,
      bold: true,
    };
    //const worksheet = workbook.addWorksheet(daywisebytexttitle);
    //const titleRow = worksheet.addRow([title]);
    //titleRow.font = {
    //  name: 'Corbel',
    //  family: 4,
    //  size: 16,
    //  underline: 'single',
    //  bold: true,
    //};
    //worksheet.mergeCells('A1:H2');
    //worksheet.alignment = { vertical: 'middle', horizontal: 'center' };
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    itemlist.forEach((d) => {
      let objres: any = d;
      const roworder = worksheet.addRow([
        objres.order.colu0,
        objres.order.colu1,
        objres.order.colu2,
        objres.order.colu3,
        objres.order.colu4,
        objres.order.colu5,
        '',
        objres.order.colu7,
        objres.order.colu8,
        objres.order.colu9,
      ]);
      roworder.font = {
        size: 12,
        bold: true,
      };
      const itemscust: any = objres.customerForDelivery;
      const items: any = itemscust.colu6;
      const totalcalc: any = objres.totalcalc;
      items.forEach((i: any, index: any) => {
        if (index == 0) {
          worksheet.addRow([
            itemscust.colu0,
            itemscust.colu1,
            itemscust.colu2,
            itemscust.colu3,
            itemscust.colu4,
            itemscust.colu5,
            i.colu6,
            i.colu7,
            i.colu8,
            i.colu9,
          ]);
        } else {
          worksheet.addRow([
            i.colu0,
            i.colu1,
            i.colu2,
            i.colu3,
            i.colu4,
            i.colu5,
            i.colu6,
            i.colu7,
            i.colu8,
            i.colu9,
          ]);
        }
      });

      totalcalc.forEach((i: any, index: any) => {
        const totrow = worksheet.addRow([
          i.colu0,
          i.colu1,
          i.colu2,
          i.colu3,
          i.colu4,
          i.colu5,
          i.colu6,
          i.colu7,
          i.colu8,
          i.colu9,
        ]);
        totrow.font = {
          size: 11,
          bold: true,
        };
      });
      const itemscustb: any = objres.objbottom;
      if (itemscustb != null) {
        const itemsb: any = itemscustb.colu5;
        if (itemsb != null) {
          if (itemsb.length > 0) {
            itemsb.forEach((i: any, index: any) => {
              if (index == 0) {
                const totrow = worksheet.addRow([
                  itemscustb.colu0,
                  '',
                  '',
                  '',
                  '',
                  '',
                  i.colu5,
                  i.colu6,
                  '',
                  itemscustb.colu8,
                ]);
                totrow.font = {
                  size: 11,
                  bold: true,
                };
              }
              else {
                const totrow = worksheet.addRow([
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  i.colu5,
                  i.colu6,
                  '',
                  '',
                ]);
                totrow.font = {
                  size: 11,
                  bold: true,
                };
              }
            });
            const totrow = worksheet.addRow([
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              itemscustb.colu1,
              '',
              '',
            ]);
            totrow.font = {
              size: 11,
              bold: true,
            };
          }
        }
      }

    });

    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, 'DayWiseReportDelivery.xlsx');
    });
  }
  async generateExcelForCustomerEnquiry(itemlist: any, daywiseby: any, daywisebytexttitle: any, companyname: any, addr: any) {
    if (itemlist == null) {
      return;
    }
    const title = daywisebytexttitle;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("");
    const compname = worksheet.addRow(['', '', companyname, '', '', '', '']);
    compname.font = {
      size: 12,
      bold: true,
    };
    const address = worksheet.addRow(['', '', addr, '', '', '', '']);
    address.font = {
      size: 12,
      bold: true,
    };
    const titleRow = worksheet.addRow(['', '', title, '', '', '', '']);
    titleRow.font = {
      size: 12,
      bold: true,
    };
    //Customer Details
    if (itemlist.objcustomer != null) {
      const roworder = worksheet.addRow([
        '',
        'Cust Id : ' + itemlist.objcustomer.actcode,
        '',
        'Cust Name : ' + itemlist.objcustomer.actname
        , '', '', ''
      ]);
      roworder.font = {
        size: 11,
        bold: false,
      };
      const roworder2 = worksheet.addRow([
        '',
        'Town : ' + itemlist.objcustomer.townname,
        '',
        'Mobile : ' + itemlist.objcustomer.actmob1
        , '', '', ''
      ]);
      roworder2.font = {
        size: 11,
        bold: false,
      };
    }
    //End
    //Order Details
    if (itemlist.listorders != null) {
      if (itemlist.listorders.length > 0) {
        itemlist.listorders.forEach((d, index: any) => {
          let objres: any = d;
          if (index == 0) {
            const roworder = worksheet.addRow([
              'Ord.Date',
              'Trail.Date',
              'Delv.Date',
              'Item Name',
              'Qty',
              '', ''
            ]);
            roworder.font = {
              size: 12,
              bold: true,
              fgColor: { argb: 'FFFFFF00' },
            };
          }
          const roworder = worksheet.addRow([
            objres.orderdate,
            objres.traildate,
            objres.delvdate,
            objres.itemname,
            objres.qty
            , '', ''
          ]);
          roworder.font = {
            size: 11,
            bold: false,
          };
        });
      }
    }
    //End
    //Cutter Details
    if (itemlist.listcutttrans != null) {
      if (itemlist.listcutttrans.length > 0) {
        itemlist.listcutttrans.forEach((d, index: any) => {
          let objres: any = d;
          if (index == 0) {
            const roworder = worksheet.addRow([
              'Cutt.Master Name',
              'Item Name',
              'Delv to Cutt.Master	',
              'Recd From Cutt.Master',
              'Bal.Qty',
              '', ''
            ]);
            roworder.font = {
              size: 12,
              bold: true,
              fgColor: { argb: 'FFFFFF00' },
            };
          }
          const roworder = worksheet.addRow([
            objres.actname,
            objres.itemname,
            objres.rqty,
            objres.sqty,
            objres.balqty
            , '', ''
          ]);
          roworder.font = {
            size: 11,
            bold: false,
          };
        });
      }
    }
    //End
    //Stitcher Details
    if (itemlist.liststittrans != null) {
      if (itemlist.liststittrans.length > 0) {
        itemlist.liststittrans.forEach((d, index: any) => {
          let objres: any = d;
          if (index == 0) {
            const roworder = worksheet.addRow([
              'Stit.Master Name',
              'Item Name',
              'Delv to Stit.Master',
              'Recd From Stit.Master',
              'Bal.Qty',
              '', ''
            ]);
            roworder.font = {
              size: 12,
              bold: true,
              fgColor: { argb: 'FFFFFF00' },
            };
          }
          const roworder = worksheet.addRow([
            objres.actname,
            objres.itemname,
            objres.rqty,
            objres.sqty,
            objres.balqty
            , '', ''
          ]);
          roworder.font = {
            size: 11,
            bold: false,
          };
        });
      }
    }
    //End
    //Ready to Delv. Details
    if (itemlist.listreadytodelvtrans != null) {
      if (itemlist.listreadytodelvtrans.length > 0) {
        itemlist.listreadytodelvtrans.forEach((d, index: any) => {
          let objres: any = d;
          if (index == 0) {
            const roworder = worksheet.addRow([
              'Ready To Delv.',
              'Item Name',
              'Recd From Stit.Master',
              '',
              'Bal.Qty',
              '', ''
            ]);
            roworder.font = {
              size: 12,
              bold: true,
              fgColor: { argb: 'FFFFFF00' },
            };
          }
          const roworder = worksheet.addRow([
            objres.actname,
            objres.itemname,
            objres.rqty,
            objres.sqty,
            objres.balqty
            , '', ''
          ]);
          roworder.font = {
            size: 11,
            bold: false,
          };
        });
      }
    }
    //End
    //Balance in Shop Details
    if (itemlist.listbalqtyshoptrans != null) {
      if (itemlist.listbalqtyshoptrans.length > 0) {
        itemlist.listbalqtyshoptrans.forEach((d, index: any) => {
          let objres: any = d;
          if (index == 0) {
            const roworder = worksheet.addRow([
              'Bal. in Shop Qty',
              'Item Name',
              'Order in Bal.',
              'Bal. in Cutt.',
              'Bal. in Stit.',
              'Ready to Delv.', 'Total'
            ]);
            roworder.font = {
              size: 12,
              bold: true,
              fgColor: { argb: 'FFFFFF00' },
            };
          }
          const roworder = worksheet.addRow([
            objres.actname,
            objres.itemname,
            objres.rqty,
            objres.sqty,
            objres.balqty
            , objres.readydelv, objres.totqty
          ]);
          roworder.font = {
            size: 11,
            bold: false,
          };
        });
      }
    }
    //End
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, 'CustomerEnquiry.xlsx');
    });
  }
  async generateExcelForPending1(itemlist: any, daywiseby: any, daywisebytexttitle: any, companyname: any, addr: any) {
    if (itemlist != null) {
      if (itemlist.length == 0) {
        return;
      }
    }
    const title = daywisebytexttitle;
    const header = [
      'Ord.No',
      'Cust Id',
      'Ord.Date',
      'Trail.Date',
      'Delv.Date',
      'Item Name',
      'Qty',
      'Rate',
      'Amount',
    ];
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("");
    const compname = worksheet.addRow(['', '', '', '', companyname]);
    compname.font = {
      size: 12,
      bold: true,
    };
    const address = worksheet.addRow(['', '', '', '', addr]);
    address.font = {
      size: 12,
      bold: true,
    };
    const titleRow = worksheet.addRow(['', '', '', '', title]);
    titleRow.font = {
      size: 12,
      bold: true,
    };
    //worksheet.mergeCells('A1:H2');
    //worksheet.alignment = { vertical: 'middle', horizontal: 'center' };    
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    itemlist.forEach((d, index: any) => {

      let objres: any = d;
      const roworder = worksheet.addRow([
        objres.order.colu0,
        objres.order.colu1,
        objres.order.colu2,
        objres.order.colu3,
        objres.order.colu4,
        objres.order.colu5,
        objres.order.colu6,
        objres.order.colu7,
        objres.order.colu8,
        objres.order.colu9,
      ]);
      roworder.font = {
        size: 12,
        bold: true,
      };
      const itemscust: any = objres.customer;
      const items: any = itemscust.colu5;
      const totalcalc: any = objres.totalcalc;
      items.forEach((i: any, index: any) => {
        if (index == 0) {
          worksheet.addRow([
            itemscust.colu0,
            itemscust.colu1,
            itemscust.colu2,
            itemscust.colu3,
            itemscust.colu9,
            itemscust.colu4,
            i.colu5,
            i.colu6,
            i.colu7,
            i.colu8,
          ]);
        } else {
          worksheet.addRow([
            i.colu0,
            i.colu1,
            i.colu2,
            i.colu3,
            i.colu4,
            i.colu5,
            i.colu6,
            i.colu7,
            i.colu8,
            i.colu9,
          ]);
        }
      });
      //totalcalc.forEach((i: any, index: any) => {
      //  const totrow = worksheet.addRow([
      //    i.colu0,
      //    i.colu1,
      //    i.colu2,
      //    i.colu3,
      //    i.colu9,
      //    i.colu4,
      //    i.colu5,
      //    i.colu6,
      //    i.colu7,
      //    i.colu8,
      //  ]);
      //  totrow.font = {
      //    size: 11,
      //    bold: true,
      //  };
      //});
      const itemscustb: any = objres.objbottom;
      if (itemscustb != null) {
        const itemsb: any = itemscustb.colu5;
        if (itemsb != null) {
          if (itemsb.length > 0) {
            itemsb.forEach((i: any, index: any) => {
              if (index == 0) {
                const totrow = worksheet.addRow([
                  itemscustb.colu0,
                  '',
                  '',
                  '',
                  '',
                  '',
                  i.colu5,
                  i.colu6,
                  '',
                  itemscustb.colu8,
                ]);
                totrow.font = {
                  size: 11,
                  bold: true,
                };
              }
              else {
                const totrow = worksheet.addRow([
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  i.colu5,
                  i.colu6,
                  '',
                  '',
                ]);
                totrow.font = {
                  size: 11,
                  bold: true,
                };
              }
            });
            const totrow = worksheet.addRow([
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              itemscustb.colu1,
              '',
              '',
            ]);
            totrow.font = {
              size: 11,
              bold: true,
            };
          }
        }
      }

    });

    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, 'PendingDetailsReport.xlsx');
    });
  }
  async generateExcelForPending(itemlist: any, daywiseby: any, daywisebytexttitle: any, companyname: any, addr: any) {
    if (itemlist != null) {
      if (itemlist.length == 0) {
        return;
      }
    }
    const title = daywisebytexttitle;
    const header = [
      'S.No',
      'Ord.No',
      'Cust Id',
      'Ord.Date',
      'Trail.Date',
      'Delv.Date',
      'Item Name',
      'Qty',
      'Rate',
      'Amount',
    ];
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("");
    const compname = worksheet.addRow(['', '', '', '', companyname]);
    compname.font = {
      size: 12,
      bold: true,
    };
    const address = worksheet.addRow(['', '', '', '', addr]);
    address.font = {
      size: 12,
      bold: true,
    };
    const titleRow = worksheet.addRow(['', '', '', '', title]);
    titleRow.font = {
      size: 12,
      bold: true,
    };
    //worksheet.mergeCells('A1:H2');
    //worksheet.alignment = { vertical: 'middle', horizontal: 'center' };    
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    itemlist.forEach((d, index: any) => {

      let objres: any = d;
      const roworder = worksheet.addRow([
        objres.order.colu0,
        objres.order.colu1,
        objres.order.colu2,
        objres.order.colu3,
        objres.order.colu4,
        '',
        objres.order.colu6,
        objres.order.colu7,
        objres.order.colu8,
        objres.order.colu9,
      ]);
      roworder.font = {
        size: 12,
        bold: true,
      };
      const itemscust: any = objres.customer;
      const items: any = itemscust.colu5;
      const totalcalc: any = objres.totalcalc;
      items.forEach((i: any, index: any) => {
        if (index == 0) {
          worksheet.addRow([
            itemscust.colu0,
            itemscust.colu1,
            itemscust.colu2,
            itemscust.colu3,
            itemscust.colu9,
            itemscust.colu4,
            i.colu5,
            i.colu6,
            i.colu7,
            i.colu8,
          ]);
        } else {
          worksheet.addRow([
            i.colu0,
            i.colu1,
            i.colu2,
            i.colu3,
            i.colu9,
            i.colu4,
            i.colu5,
            i.colu6,
            i.colu7,
            i.colu8,
          ]);
        }
      });
      //totalcalc.forEach((i: any, index: any) => {
      //  const totrow = worksheet.addRow([
      //    i.colu0,
      //    i.colu1,
      //    i.colu2,
      //    i.colu3,
      //    i.colu9,
      //    i.colu4,
      //    i.colu5,
      //    i.colu6,
      //    i.colu7,
      //    i.colu8,
      //  ]);
      //  totrow.font = {
      //    size: 11,
      //    bold: true,
      //  };
      //});
      const itemscustb: any = objres.objbottom;
      if (itemscustb != null) {
        const itemsb: any = itemscustb.colu5;
        if (itemsb != null) {
          if (itemsb.length > 0) {
            itemsb.forEach((i: any, index: any) => {
              if (index == 0) {
                const totrow = worksheet.addRow([
                  itemscustb.colu0,
                  '',
                  '',
                  '',
                  '',
                  '',
                  i.colu5,
                  i.colu6,
                  '',
                  itemscustb.colu8,
                ]);
                totrow.font = {
                  size: 11,
                  bold: true,
                };
              }
              else {
                const totrow = worksheet.addRow([
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  i.colu5,
                  i.colu6,
                  '',
                  '',
                ]);
                totrow.font = {
                  size: 11,
                  bold: true,
                };
              }
            });
            const totrow = worksheet.addRow([
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              itemscustb.colu1,
              '',
              '',
            ]);
            totrow.font = {
              size: 11,
              bold: true,
            };
          }
        }
      }

    });

    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, 'DayWiseReportOrder.xlsx');
    });
  }

}
