<div class="contentMain">
  <div class="row">
    <div class="col-md-12 card-header mainheader header-container-btn">
      <h5><span>Size Creation - {{titletextneworedit}} Mode</span></h5>
    </div>
  </div>
  <main class="row">
   
    <div class="card">
      <form class="form" [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm()">
        <div class="card-body">
          <div class="checkbox text-right">
            <input id="Status" type="checkbox" name="Status" formControlName="Status" />
            <label for="Status">Status </label>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="form-group">
                <label>Size Name</label>
                <input type="text" class="form-control" placeholder="Size Name" [ngClass]="{ 'is-invalid': submitted && fc.SizeName.errors }" (focusout)="onFocusOutEvent($event)" formControlName="SizeName" #myname />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="form-group">
                <label>Size Code</label>
                <input type="text" class="form-control" placeholder="Size Code" [ngClass]="{ 'is-invalid': submitted && fc.SizeCode.errors }" formControlName="SizeCode" /> 
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="form-group">
                <label>Size Order</label>
                <input type="number" class="form-control text-right" placeholder="Size Order" [ngClass]="{ 'is-invalid': submitted && fc.SizeOrder.errors }" formControlName="SizeOrder" />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="form-group">
                <label>Translate Text</label>
                <input type="text" class="form-control" placeholder="Translate Text" formControlName="TranslateText" />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="button" (click)="clearform();">New</button>
          <button class="btn redbtn" type="submit">Save</button>
          <!--<button class="btn redbtn" type="button">Edit</button>-->
          <!--<button class="btn redbtn" type="button">Delete</button>-->
          <button class="btn redbtn" type="button" (click)="clearform();">Clear</button>
          <button class="btn redbtn" type="button">Help</button>
        </div>
      </form>
    </div>
    
  </main>
</div>







<!--<div class="modal fade" id="newsizecreation" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="title" id="defaultModalLabel">Create New Size</h4>
      </div>
      <div class="modal-body">
        <form class="form">
          <div class="checkbox">
            <input id="active" type="checkbox" />
            <label for="active">Active </label>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" placeholder="Name" />
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <label>Code</label>
                <input type="text" class="form-control" placeholder="Code" />
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <label>Default Value</label>
                <input type="text"
                       class="form-control"
                       placeholder="Default Value" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn redbtn waves-effect">
          SAVE CHANGES
        </button>
        <button type="button"
                class="btn redbtn waves-effect"
                data-dismiss="modal">
          CLOSE
        </button>
      </div>
    </div>
  </div>
</div>-->
