<html>
<head>
  <title></title>
</head>
<body>
  <div class="contentMain">
    <div class="row">
      <div class="col-md-12 mainheader header-container">
        <h5 title="{{editid}}">Orders Entry - {{titletextneworedit}} Mode</h5>
      </div>
    </div>
    <main class="row">
      <div class="card">
        <form class="form" [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm($event)">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <div class="border p-3 ">
                  <div class="row">
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Branch</label>
                        <select class="form-control" [attr.disabled]="!editable ? '' : null" (change)="changeBranch($event)" formControlName="BranchID" #myname>
                          <option *ngFor="let Brlist of BranchList" [value]="Brlist.branchId">{{Brlist.branchName }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Stock point</label>
                        <select class="form-control" [attr.disabled]="!editable ? '' : null" formControlName="SpId">
                          <option *ngFor="let item of SPList" [value]="item.spid">{{item.spname }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Ord. Series</label>
                        <input type="text" class="form-control" [attr.readonly]="!editable ? '' : null" placeholder="Order Series" [ngClass]="{ 'is-invalid': submitted && fc.TSeries.errors }" formControlName="TSeries">
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Ord. Number</label>
                        <input type="text" class="form-control" [attr.readonly]="!editable ? '' : null" placeholder="Order Number" [ngClass]="{ 'is-invalid': submitted && fc.TNo.errors }" formControlName="TNo">
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Booking Date</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                          </div>
                          <input type="text" class="form-control" [attr.readonly]="!editdisabled ? '' : null" style="width: 60%;" id="Fromdate" [(ngModel)]="DocDate" name="DocDate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChangeDocDate($event)" [bsValue]="DocDate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Trail Date</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                          </div>
                          <input type="text" class="form-control" [attr.readonly]="!editdisabled ? '' : null" style="width: 60%;" id="SuppBilldate" [(ngModel)]="SuppBilldate" name="SuppBilldate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChangeSuppBilldate($event)" [bsValue]="SuppBilldate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Delivery Date</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                          </div>
                          <input type="text" class="form-control" [attr.readonly]="!editdisabled ? '' : null" style="width: 60%;" id="DocDuedate" [(ngModel)]="DocDuedate" name="Delvdate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChangeDocDuedate($event)" [bsValue]="DocDuedate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12">
                      <div class="form-group">
                        <label>Notes</label>
                        <input type="text" class="form-control" [attr.readonly]="!editdisabled ? '' : null" placeholder="Notes" formControlName="Narration" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6">
                <div class="border p-3 ">
                  <div class="row">
                    <div class="col-md-6 col-sm-6">
                      <!--<div class="form-group">
                        <label>Customer Id</label>
                        <input type="text" class="form-control" [attr.readonly]="!editable ? '' : null" placeholder="Customer Id" formControlName="AccountCode">
                      </div>-->
                      <label>Customer Id</label>
                      <div class="form-group">
                        <input type="text" formControlName="AccountCode" placeholder="Customer Id" [attr.readonly]="!editable ? '' : null" class="form-control" (input)="keypressCustId($event,0)" (change)="changeCustId($event,0)" (click)="clickCustId($event,0)" style="width: 100%;" />
                        <div class="combobox-options" *ngIf="filteredaccountList.length>0 && flag3===true">
                          <ul style=" width:100%;">
                            <li *ngFor="let item of filteredaccountList" (click)="onselectCustId(item,0)">
                              <a>{{item.actCode}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <label>Mobile No</label>
                      <div class="form-group">
                        <input type="text" class="form-control" [attr.readonly]="!editable ? '' : null" placeholder="Mobile No" formControlName="mobile" (input)="keypressAccountMobile($event,0)" (change)="changeAccountMobile($event,0)" (click)="clickAccountMobile($event,0)" style="width: 100%;">
                        <div class="combobox-options" *ngIf="filteredaccountList.length>0 && flag1===true">
                          <ul style=" width:100%;">
                            <li *ngFor="let item of filteredaccountList" (click)="onselectAccountMobile(item,0)">
                              <a>{{item.actMob1}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div *ngIf="fc.mobile.touched && fc.mobile.invalid" class="alert alert-danger">
                        <div *ngIf="fc.mobile.errors?.pattern">Enter only number</div>
                        <div *ngIf="fc.mobile.errors?.minlength">Must be 10 characters</div>
                        <div *ngIf="fc.mobile.errors?.maxlength">Must be 10 characters</div>
                      </div>
                    </div>
                    <div class="col-md-9 col-sm-9">
                      <!--<div class="form-group">
                        <label>Account Name</label>
                        <input type="text" class="form-control" [attr.readonly]="!editable ? '' : null" placeholder="Account Name" formControlName="AccountName">
                      </div>-->
                      <label>Account Name</label>
                      <div class="form-group">
                        <input type="text" formControlName="AccountName" placeholder="Account Name" [attr.readonly]="!editable ? '' : null" class="form-control" (input)="keypressAccountName($event,0)" (change)="changeAccountName($event,0)" (click)="clickAccountName($event,0)" style="width: 100%;" />
                        <div class="combobox-options" *ngIf="filteredaccountList.length>0 && flag2===true">
                          <ul style=" width:100%;">
                            <li *ngFor="let item of filteredaccountList" (click)="onselectAccountName(item,0)">
                              <a>{{item.actName}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!--<div class="col-md-9 col-sm-9">
                      <div class="form-group">
                        <label>Account Name</label>
                        <input style="width:100%;" type="text" class="form-control" (input)="keypressAccountCodeName($event,1)" (change)="changeAccountCodeName($event,1)" (click)="clickAccountCodeName($event,1)" formControlName="AccountName" [attr.readonly]="!editable ? '' : null" placeholder="Account Name" />
                        <div class="combobox-options" *ngIf="filteredList.length>0 && flag2===true">
                          <ul>
                            <li *ngFor="let item of filteredList" (click)="onselectAccountCodeName(item,1)">
                              <a>{{item.actName}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>-->
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <div class="form-group">
                        <label style="margin-top:50px"></label>
                        <button class="btn redbtn" type="button" [class.disabled]="!editable" (click)="btnAccountSearch(editable)">Search</button>
                        <!--[style.visibility]="h1HdocId > 0 && editid==0 ? 'visible' : 'hidden'"-->
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12">
                      <div class="form-group">
                        <label>Account Name, Address-1, Address-2, Town, Phone, Mobile, Email</label>
                        <textarea class="form-control" [attr.readonly]="!editable ? '' : null" rows="4" formControlName="AccountFullAddress"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <a href="javascript:void(0)" [class.disabled]="!editdisabled" [style.visibility]="h1HdocId > 0 && editid==0 ? 'visible' : 'hidden'" (click)="clickEvent(h1HdocId)" class="btn redbtn">
                      <!--[attr.disabled]="h1HdocId == 0 ? true : null" data-toggle="modal" data-target="#AccountHistoryPreview"-->
                      H1
                    </a>
                    <a href="javascript:void(0)" [class.disabled]="!editdisabled" [style.visibility]="h2HdocId > 0 && editid==0 ? 'visible' : 'hidden'" (click)="clickEvent(h2HdocId)" class="btn redbtn">
                      H2
                    </a>
                    <a href="javascript:void(0)" [class.disabled]="!editdisabled" [style.visibility]="h3HdocId > 0 && editid==0 ? 'visible' : 'hidden'" (click)="clickEvent(h3HdocId)" class="btn redbtn">
                      H3
                    </a>
                    <button class="btn redbtn" [class.disabled]="!editable" type="button" (click)="cancelaccountsearch(editable)">Cancel</button>
                    <a href="javascript:void(0)" [class.disabled]="!editdisabled" class="btn redbtn" data-toggle="modal" data-target="#uploadmultipleimages">
                      Add Images
                    </a>
                    <button [disabled]="!editdisabled" class="btn redbtn" [routerLink]="['/newAccount']" type="button">Create</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <!--<div class="p-3 border h-100 my-3">
              <div class="row py-3">-->
              <div class="col-md-12">
                <h4 class="text-center mb-3">Select Items</h4>
                <div class="dressItems row justify-content-center" style="width:100%;">
                  <div class="text-center" *ngFor="let item of GetItems">
                    <a href="javascript:void(0)" (click)="additem(item)" [class.disabled]="!editdisabled">
                      <img src="{{item.imageUrl}}" alt="" style="height: 70px; width: 70px;" />
                      <span>{{item.itemName}}</span>
                    </a>
                  </div>
                </div>
              </div>
              <!--</div>
              </div>-->
            </div>
            <div class="row">
              <div class="col-md-4 col-lg-4 col-sm-4 col-xl-4 " style=" overflow-y: scroll; height: 450px; width: 100%;">
                <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable" style="overflow-y: scroll;width:100%;">
                  <thead>
                    <tr>
                      <th>Shirt Size</th>
                      <th>Shirt Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of SizeList2">
                      <td>{{item.sizeName}}</td>
                      <td><input type="text" class="form-control text-right" [attr.readonly]="!editdisabled ? '' : null" [value]="item.sizeValue" (focusout)="changeitemsize($event.target.value,item)"> </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-8 col-lg-8 col-sm-8 col-xl-8 " style="width: 100%; height: 450px; overflow-y: scroll;">
                <div style="width: 100%;height:100%;">
                  <div style="width: 100%; height: 250px; overflow-y: scroll;overflow-x:hidden;">
                    <table class="tableclass table table-bordered table-striped table-hover js-basic-example dataTable kliqtable" style="overflow-x: hidden; overflow-y: scroll; width: 100%;">
                      <thead>
                        <tr style="width:100%">
                          <th style="width:10%">QTY</th>
                          <th style="width:45%">Item Name</th>
                          <th style="width:20%">Rate</th>
                          <th style="width:20%">Amount</th>
                          <th style="width:5%">Del</th>
                          <th style="width:5%">P</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of dynamicArray;let i=index;" (click)="tablerowclick(item,i)" [class.active]="i == HighlightRow">
                          <td>
                            <input type="text" id="txtAmt" [attr.readonly]="!editdisabled ? '' : null" [value]="item.qty" (focusout)="changeitemqty($event.target.value,item)" style="text-align: right; width: 100%" />
                          </td>
                          <td>{{item.itemName}}</td>
                          <td class="text-right">
                            <input type="text" id="txtrate" [attr.readonly]="!editdisabled ? '' : null" [value]="item.rate" (focusout)="changeitemrate($event.target.value,item)" style="text-align: right; width: 100%" />
                          </td>
                          <td class="text-right">
                            {{(item.amt)  | currency:'INR':'' }}
                          </td>
                          <td>
                            <a [class.disabled]="!editdisabled" (click)="removeitem(item,i)"> <img src="../../../../../assets/images/delete.png" /></a>
                          </td>
                          <td>
                            <a [class.disabled]="!editdisabled" (click)="printbyitemwiseWithSizes(item.itemId)"> <img src="../../../../../assets/images/printer3.png" /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row" style="width: 100%; height: 100px;overflow:auto;">
                    <div class="col-md-2 col-sm-4 text-right">
                      <table style="width:100%;">
                        <tr>
                          <td><label>Tot. Items </label></td>
                        </tr>
                        <tr>
                          <td> <p><b><label class="form-check-label">{{totalitemscount | currency:'INR':''}}</label>  </b></p></td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-md-2 col-sm-4 text-right">
                      <table style="width:100%;">
                        <tr>
                          <td><label>Total Qty </label></td>
                        </tr>
                        <tr>
                          <td> <p><b><label class="form-check-label">{{totalitemsqty | currency:'INR':''}}</label>  </b></p></td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-md-3 col-sm-4 text-right">
                      <table style="width:100%;">
                        <tr>
                          <td><label>Total Amount </label></td>
                        </tr>
                        <tr>
                          <td> <p><b><label class="form-check-label">{{totalitemsamount | currency:'INR':''}}</label>  </b></p></td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-md-2 col-sm-4 text-right">
                      <table style="width:100%;">
                        <tr>
                          <td><label>Adv.Amount </label></td>
                        </tr>
                        <tr>
                          <td> <p><b><input type="text" class="form-control" [attr.readonly]="!editdisabled ? '' : null" style="text-align: right" formControlName="CalculatedAmt" (focusout)="focusoutadvamt()" /> </b></p></td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-md-3 col-sm-4 text-right">
                      <table style="width:100%;">
                        <tr>
                          <td><label>Bill Amount </label></td>
                        </tr>
                        <tr>
                          <td> <p><b><label class="form-check-label">{{totalitemsbillamount | currency:'INR':''}}</label>  </b></p></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="row" style="width: 100%; height: 100px;">
                    <div class="col-md-12 col-lg-12">
                      <div class="form-group" style="width: 100%;">
                        <label>SMS Message</label>
                        <textarea rows="1" class="form-control" [attr.readonly]="!editdisabled ? '' : null" formControlName="Message"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-left w-100" align="center">
            <button class="btn redbtn" type="submit" value="Save">Save</button>
            <button class="btn redbtn" type="submit" value="Save & Print">Save & Print</button>
            <button class="btn redbtn" type="submit" value="Save & Print Item Wise">Save & Print Item Wise</button>
            <button class="btn redbtn" type="button" (click)="clearform()">Refresh</button>
            <button class="btn redbtn" type="button">Close</button>
          </div>
        </form>
      </div>
    </main>
    <!-- Bootstrap Modal for Upload Multiple images -->
    <div class="modal fade" id="uploadmultipleimages" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="mainheader">
            <h4 class="title" id="largeModalLabel"> Add Images</h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="contentMain">
                <main class="row">
                  <div class="card">
                    <form class="form">
                      <div class="card-body">
                        <div class="row border h-50 py-2">
                          <div class="col-md-3">
                            <button #fileInput style="visibility:hidden" class="btn" type="button" (click)="cancelImageasDefaultedit()">Cancel</button>
                            <input type="file" style="visibility: hidden; border: 1px solid red;" id="img" #Image accept="image/*" maxFileSize="50" (change)="onSelectFile($event)" />
                            <figure class="position-relative" style="border: 1px solid silver; margin-top: -55px;">
                              <span class="closeicon position-absolute text-center" style="background-color:whitesmoke;color:red;font-weight:bold;" (click)="cancelImageasDefault()">X</span>
                              <img [src]="imgUrl" class="img-fluid w-100" alt="Profile Image" onerror="this.src='./assets/images/1.jpg'">
                            </figure>
                            <table style="width:100%;">
                              <tr>
                                <td style="text-align:center;">
                                  <label for="img" class="btn btn-outline-primary redbtn rounded-pill">Select Image</label>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div class="col-md-3">
                            <button #fileInput2 style="visibility:hidden" class="btn" type="button" (click)="cancelImageasDefaultedit2()">Cancel</button>
                            <input type="file" style="visibility: hidden; border: 1px solid red;" id="img2" #Image accept="image/*" (change)="onSelectFile2($event)" />
                            <figure class="position-relative" style="border: 1px solid silver; margin-top: -55px;">
                              <span class="closeicon position-absolute text-center" style="background-color:whitesmoke;color:red;font-weight:bold;" (click)="cancelImageasDefault2()">X</span>
                              <img [src]="imgUrl2" class="img-fluid w-100" alt="Profile Image" onerror="this.src='./assets/images/1.jpg'">
                            </figure>
                            <table style="width:100%;">
                              <tr>
                                <td style="text-align:center;">
                                  <label for="img2" class="btn btn-outline-primary redbtn rounded-pill">Select Image</label>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div class="col-md-3">
                            <button #fileInput3 style="visibility:hidden" class="btn" type="button" (click)="cancelImageasDefaultedit3()">Cancel</button>
                            <input type="file" style="visibility: hidden; border: 1px solid red;" id="img3" #Image accept="image/*" (change)="onSelectFile3($event)" />
                            <figure class="position-relative" style="border: 1px solid silver; margin-top: -55px;">
                              <span class="closeicon position-absolute text-center" style="background-color:whitesmoke;color:red;font-weight:bold;" (click)="cancelImageasDefault3()">X</span>
                              <img [src]="imgUrl3" class="img-fluid w-100" alt="Profile Image" onerror="this.src='./assets/images/1.jpg'">
                            </figure>
                            <table style="width:100%;">
                              <tr>
                                <td style="text-align:center;">
                                  <label for="img3" class="btn btn-outline-primary redbtn rounded-pill">Select Image</label>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div class="col-md-3">
                            <button #fileInput4 style="visibility:hidden" class="btn" type="button" (click)="cancelImageasDefaultedit4()">Cancel</button>
                            <input type="file" style="visibility: hidden; border: 1px solid red;" id="img4" #Image accept="image/*" (change)="onSelectFile4($event)" />
                            <figure class="position-relative" style="border: 1px solid silver; margin-top: -55px;">
                              <span class="closeicon position-absolute text-center" style="background-color:whitesmoke;color:red;font-weight:bold;" (click)="cancelImageasDefault4()">X</span>
                              <img [src]="imgUrl4" class="img-fluid w-100" alt="Profile Image" onerror="this.src='./assets/images/1.jpg'">
                            </figure>
                            <table style="width:100%;">
                              <tr>
                                <td style="text-align:center;">
                                  <label for="img4" class="btn btn-outline-primary redbtn rounded-pill">Select Image</label>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <div class="card-footer text-right w-100">
            <button printTitle="Print Title" class="btn redbtn" styleSheetFile="{{cssPrintingPath}}" [printStyle]="{h4 : {'text-align': 'center'}, span : {'font-size': 'small'}}" printSectionId="print-section" ngxPrint>
              Print
            </button>
            <button class="btn redbtn" data-dismiss="modal" type="button">Close</button>
          </div>
          <div id="print-section" *ngIf="editid>0" style="visibility:hidden;">
            <table style="height: 100%; width: 100%; ">
              <tr style="height: 50%; width: 100%; ">
                <td style="height: 50%; width: 50%;">
                  <img [src]="imgUrl" style="height: 350px; width: 100%;" alt="Profile Image 1" onerror="this.src='./assets/images/1.jpg'">
                </td>
                <td style="height: 50%; width: 50%;">
                  <img [src]="imgUrl2" style="height: 350px; width: 100%; " alt="Profile Image 2" onerror="this.src='./assets/images/1.jpg'">
                </td>
              </tr>
              <tr style="height: 50%; width: 100%; ">
                <td style="height: 50%; width: 50%;">
                  <img [src]="imgUrl3" style="height: 350px; width: 100%; " alt="Profile Image 3" onerror="this.src='./assets/images/1.jpg'">
                </td>
                <td style="height: 50%; width: 50%;">
                  <img [src]="imgUrl4" style="height: 350px; width: 100%; " alt="Profile Image 4" onerror="this.src='./assets/images/1.jpg'">
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Bootstrap Modal for Upload Multiple images -->
    <!-- Bootstrap Modal for Account History Preview -->
    <div class="modal fade" id="AccountHistoryPreview" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <!--<div class="mainheader">
            <h4 class="title text-center"> {{actName}} </h4>
          </div>-->
          <div class="modal-body">
            <
            <div class="row">
              <div class="contentMain">
                <main class="row">
                  <form class="form">
                    <div style="width:100%;">
                      00

                      <!--<h4>{{title}}</h4>-->
                      <table class="" style="width:100%;border:none;margin-top:-35px;">
                        <thead>
                          <tr>
                            <th><label>Cust Id :</label></th>
                            <td><label>{{actCode}}</label></td>
                            <th><label>Account Name :</label></th>
                            <td><label>{{actName}}</label></td>
                          </tr>
                          <tr>
                            <th><label>Town Name :</label></th>
                            <td><label>{{acttownname}}</label></td>
                            <th><label>Mobile No :</label></th>
                            <td><label>{{actmob1}}</label></td>
                          </tr>
                          <tr>
                            <th><label>Order No :</label></th>
                            <td><label>{{billNo}}</label></td>
                            <th><label>Order Date :</label></th>
                            <td><label>{{docDate}}</label></td>
                          </tr>
                          <tr>
                            <th><label>Delivery Date :</label></th>
                            <td><label>{{docDuedate}}</label></td>
                            <th><label>Trail Date :</label></th>
                            <td><label>{{suppBilldate}}</label></td>
                          </tr>
                        </thead>
                      </table>
                      <div *ngFor="let list of itemlistH; let i = index" style="width: 100%;">
                        <div class="row" style="width: 100%;">
                          <table class="" style="width: 100%; border: 0px solid black;">
                            <thead>
                              <tr>
                                <th style="text-align: left; width: 70%;border: 1px dashed black;"><label>{{list.itemname}}</label></th>
                                <th style="text-align: right; width: 15%; border: 1px dashed black;"><label>{{list.qty | currency:'INR':''}}</label></th>
                                <th style="text-align: right; width: 15%; border: 1px dashed black;"><label>{{list.netValue | currency:'INR':''}}</label></th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <div class="row" style="width: 100%;">
                          <table class="" style="width:100%;">
                            <tr *ngFor="let item of list.listsizes; let j = index;">
                              <td *ngFor="let subitem of item.listssizes; let k = index;">
                                <table style="width:100%;">
                                  <tr>
                                    <td *ngIf="subitem.sizeName.length==0; else elsecond1">
                                      <span>
                                        &nbsp;
                                      </span>
                                    </td>
                                    <ng-template #elsecond1>
                                      <td>
                                        <span>
                                          {{subitem.translateText}}
                                        </span>
                                      </td>
                                    </ng-template>
                                  </tr>
                                  <tr>
                                    <td *ngIf="(subitem.sizeValue.length==0 && subitem.sizeName.length==0); else elsecond2">
                                      <span>
                                        &nbsp;
                                      </span>
                                    </td>
                                    <ng-template #elsecond2>
                                      <td style="border-bottom:1px solid black;">
                                        <span>
                                          {{subitem.sizeValue}}
                                        </span>
                                      </td>
                                    </ng-template>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <br />
                      <div style="width:100%;">
                        <table class="" style="width:100%;border:1px dotted black;">
                          <thead>
                            <tr>
                              <th><label>Tot.Items Count :</label></th>
                              <td style="text-align: center;color:indianred;"><label>{{totitemscount | currency:'INR':''}}</label></td>
                              <th><label>Tot.Item Qty :</label></th>
                              <td style="text-align: center; color: indianred;"><label>{{totqty | currency:'INR':''}}</label></td>
                              <th><label>Tot.Item Amount :</label></th>
                              <td style="text-align: right; color: indianred;"><label>{{totnetvalue | currency:'INR':''}}</label></td>
                            </tr>
                            <tr>
                              <th>&nbsp;</th>
                              <td>&nbsp;</td>
                              <th>&nbsp;</th>
                              <td>&nbsp;</td>
                              <th><label>Advance Amount :</label></th>
                              <td style="text-align: right; color: indianred;"><label>{{advanceamt | currency:'INR':''}}</label></td>
                            </tr>
                            <tr>
                              <th>&nbsp;</th>
                              <td>&nbsp;</td>
                              <th>&nbsp;</th>
                              <td>&nbsp;</td>
                              <th><label>Bill Amount :</label></th>
                              <td style="text-align: right; color: indianred;"><label>{{billamt | currency:'INR':''}}</label></td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </form>
                </main>
              </div>
            </div>
          </div>
          <div class="card-footer text-right w-100">
            <button class="btn redbtn" data-dismiss="modal" type="button">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Bootstrap Modal for Account History Preview -->
  </div>
</body>
</html>



