import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpService } from '../../../../http.service';

@Component({
  selector: 'app-account-creation',
  templateUrl: './account-creation.component.html',
  styleUrls: ['./account-creation.component.scss']
})
export class AccountCreationComponent implements OnInit {
  form1: FormGroup | undefined;
  @ViewChild('myname') input;
  public itemlist: any = [];
  public btntextNew = { state: { id: 0, text: 'New', obj: null } };
  public Branchlist: any = [];
  public Acttypeslist: any = [];
  public Actgrouplist: any = [];
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private elRef: ElementRef, private router: Router) {
    //form Defining Start
    this.form1 = this._fb.group({
      accountgroup: [0],
      accounttype: [0],
      branchid: [0],
      actcode: [''],
      actname: [''],
      status:[0]
    });
    //End form Defining
    this.bindlist();
  }
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    //Focus Start
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    //End
  }
  bindlist() {
    const request = {
      method: 'GET',
      action_url: 'GetAccountSearchList'
    };
    this._services.doHttp(request).subscribe(
      res => {
        let reslist: any = res;
        //console.log(reslist);
        this.itemlist = reslist.listactmsts;
        this.Branchlist = reslist.listbranch;
        this.Acttypeslist = reslist.listacttypes;
        this.Actgrouplist = reslist.listactgroups;
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  editformbyid(item: any) {
    this.router.navigateByUrl('/newAccount', { state: { id: item.actId, text: 'Edit', obj: item } });   
  }
  deleteformbyid(item: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        //      
        var jsondata = { id: Number(item) };
        this.httpClient.post(this._services.APIUrl + '/DeleteAccounts', Number(item)).subscribe(res => {
          var result: any = res;

          if (result.msg != null) {
            if (result.msg.toString().includes('Successfull')) {
              Swal.fire('Deleted!', result.msg.toString(), 'success');
              this.bindlist();
            }
            else {
              Swal.fire('Failed to Delete!', result.msg.toString(), 'error');
            }
          }
        },
          error => {
            console.log(error);
          });
        //
      }
    });

  }
  SearchOrSubmitForm() {

    let type: any = ""; let typef: any = 0;
    var group = this.form1?.get('accountgroup')?.value;
    typef = this.form1?.get('accounttype')?.value;
    var branchid = this.form1?.get('branchid')?.value;
    var actcode = this.form1?.get('actcode')?.value;
    var actname = this.form1?.get('actname')?.value;
    var status = this.form1?.get('status')?.value;
    //
    try {
      if (this.Acttypeslist.find((item: { actTypeId: any; }) => item.actTypeId == typef) != undefined) {
        type = (this.Acttypeslist.find((item: { actTypeId: any; }) => item.actTypeId == typef).actType);
      }
    } catch { type = ""; }
    //
    //this.form1?.patchValue({
    //  accountgroup: Number(group),
    //  accounttype: type,
    //  branchid: Number(branchid),
    //  status: Number(status)
    //});
    const request = {
      method: 'GET',
      action_url: 'GetSeletedAccount?Code=' + actcode + '&Name=' + actname + '&type=' + type + '&groupid=' + Number(group) + '&branchid=' + Number(branchid) + '&status=' + Number(status)+''
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.itemlist = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });

  }
}
