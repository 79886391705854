<div class="contentMain">
  <!-- breadcrumb -->
  <div class="row">
    <div class="col-md-12 mainheader  header-container">
      <h5>Item Category</h5>
    </div>
    <!-- <div class="col-md-6 ">
        <ol class="breadcrum d-flex justify-content-end">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">Item Creation</li>
        </ol>
    </div> -->
  </div>
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <div class="card">
      <!-- card body -->
      <div class="card-body">
        <!-- bootstrap tabs -->
        <!-- general continue-->
        <div class="p-3 border mb-3">
          <!-- general -->
          <div class="row">
            <div class="col-md-6">
              <!--/ row -->
              <div class="row">
                <!-- col -->
                <div class="col-lg-12 col-md-12 col-sm-6">
                  <div class="form-group">
                    <label>Category Name</label>
                    <input type="text" class="form-control" placeholder="Category Name">
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-12 col-md-12 col-sm-6">
                  <div class="form-group">
                    <label>Category Code</label>
                    <input type="text" class="form-control" placeholder="Category Code">
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-12 col-md-12 col-sm-6">
                  <div class="form-group">
                    <label>Category Higher</label>
                    <input type="text" class="form-control" placeholder="Category Higher">
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Description</label>
                    <div class="form-line">
                      <textarea rows="4" class="form-control no-resize"
                                placeholder="Please type Description..."></textarea>
                    </div>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <!--/ col -->
              </div>
              <!--/ row -->
            </div>
            <!-- col -->

            <div class="col-md-6">
              <!-- buttons -->
              <div class="d-grid gap-2 d-md-block text-center">
                <button class="btn btn-secondary disabled rounded-pill" type="button">Active</button>
                <button class="btn btn-primary redbtn rounded-pill" type="button">Block</button>
              </div>
              <!--/ buttons -->
              <figure class="avatarImg text-center">
                <img src="./assets/images/avatar1.jpg" class="rounded-circle" alt="Profile Image">
              </figure>
              <!-- buttons -->
              <div class="d-grid gap-2 d-md-block text-center">
                <button class="btn btn-outline-primary redbtn rounded-pill" type="button">
                  Web
                  Cam
                </button>
                <button class="btn btn-outline-primary redbtn rounded-pill" type="button">
                  Select
                  Image
                </button>
              </div>
              <!--/ buttons -->
            </div>
            <!--/ col -->
            <!-- col-->
            <!--/ col -->
            <!-- col -->
            <!--/ col -->
            <!--/ bootstrap tabs -->
            <!--/ card body -->

          </div>
        </div>
        <div class="card-footer text-left w-100">

          <button class="btn redbtn" type="button">Another</button>
          <button class="btn redbtn" type="button">Save</button>
          <button class="btn redbtn" type="button">Find</button>

          <button class="btn redbtn" type="button">Clear</button>
          <button class="btn redbtn" type="button">Close</button>
          <button class="btn redbtn" type="button">Help</button>
        </div>
      </div>
    </div>
  </main>

  <!-- main body -->
</div>
