<div class="contentMain">
  <!-- breadcrumb -->
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <!-- card -->
    <div class="card">
      <div class="card-header mainheader header-container-btn">
        <h5>Size Creation</h5>
        <button class="btn redbtn" [routerLink]="['/newSize']" [state]=btntextNew type="button">
          Create New
        </button>
      </div>

      <!-- <button class="btn redbtn" [routerLink]="/newSize" type="button">New</button> -->

      <form class="form-inline custom-form-inline" [formGroup]="form1" (ngSubmit)="SearchOrSubmitForm()">
        <label>Size Name:</label>
        <input type="text" class="form-control" placeholder="Size Name" formControlName="SizeName" #myname/>
        <label>Size code:</label>
        <input type="text" class="form-control" placeholder="Size Code" formControlName="SizeCode"/>
        <button class="btn redbtn" type="submit">Search</button>
      </form>
      <div class="table-responsive">
        <table class="
            table table-bordered table-striped table-hover
            js-basic-example
            dataTable
            kliqtable
          ">
          <thead>
            <tr>
              <!--<th><input id="remember_me" type="checkbox" /> Select</th>-->
              <th>S.No</th>
              <th>Size Code</th>
              <th>Size Name</th>
              <th>Translate Text</th>
              <th>Edit | Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of itemlist; let i = index">
              <!--<td>
    <input id="activestatus" type="checkbox" />
  </td>-->
              <td>{{i+1}}</td>
              <td>{{ item.sizeCode }}</td>
              <td>{{ item.sizeName }}</td>
              <td>{{ item.translateText }}</td>
              <td>
                <a [routerLink]="['/newSize']" (click)="editformbyid(item)">
                  <img src="../../../../../assets/images/edit-small.png" />
                </a>
                <a (click)="deleteformbyid(item.sizeId)"> <img src="../../../../../assets/images/delete.png" /></a>
                <!--<a>
      <img src="../../../../../assets/images/visibility.png" />
    </a>-->
              </td>
            </tr>
            <tr *ngIf="itemlist.length===0">
              <td colspan="5" class="text-center text-danger">No Data Found for Selection Criteria</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination-container">
          <div class="show ml-8">
            <span>Show</span>
            <select class="ms form-control">
              <option selected value="10">10</option>
              <option value="25">25</option>
            </select>
          </div>
          <div>
            <nav aria-label="Page navigation example" class="float-right mr-8">
              <ul class="pagination pagination-primary">
                <li class="page-item">
                  <a class="page-link" href="">Previous</a>
                </li>
                <li class="page-item"><a class="page-link" href="">1</a></li>
                <li class="page-item"><a class="page-link" href="">2</a></li>
                <li class="page-item"><a class="page-link" href="">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--<div class="card-footer text-left w-100">
        <button class="btn redbtn" type="button">Clear</button>
        <button class="btn redbtn"
                [routerLink]="['/createSize']"
                type="button">
          Cancel
        </button>

        <button class="btn redbtn" type="button">Help</button>
      </div>-->
    </div>
    <!--/ card -->
  </main>
  <!-- main body -->
</div>
<script>
  $(function () {
  //  $("#example1").DataTable({
  //    "responsive": true, "lengthChange": false, "autoWidth": false,
  //    "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
  //  }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
    //$('.kliqtable').DataTable({
    //  "paging": true,
    //  "lengthChange": false,
    //  "searching": false,
    //  "ordering": true,
    //  "info": true,
    //  "autoWidth": false,
    //  "responsive": true,
    //});
  });

</script>
