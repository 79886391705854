import { Component, ElementRef, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from '../../../../http.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-new-salesman',
  templateUrl: './new-salesman.component.html',
  styleUrls: ['./new-salesman.component.scss']
})
export class NewSalesmanComponent implements OnInit {
  AttendMonth: any = "12-Jan-2021";
  SmDegnList: any = [];
  SmUnderList: any = [];
  AreaList: any = [];
  DistList: any = [];
  StateList: any = [];
  AccountsList: any = [];
  CommTypeList: any = [];
  BranchList: any = [];

  form1: FormGroup | undefined;
  @ViewChild('myname') input;
  get fc(): any | undefined { return this.form1?.controls; }
  titletextneworedit: string = "New";
  submitted = false;
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private datePipe: DatePipe, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    let date = new Date();
    let previousmonth = new Date();
    this.AttendMonth = previousmonth;
    //form Defining Start
    this.form1 = this._fb.group({
      SMId: [0],
      SMName: ['', [Validators.required]],
      SMCode: ['', [Validators.required, Validators.maxLength(5)]],
      SMType: [1],
      SmUnder: [0],
      SMDegn: [0],
      DOB: ['12-Jan-2022'],
      Address1: [''],
      Address2: [''],
      Address3: [''],
      Town: ['0'],
      Mandal: [''],
      DistID: [0],
      StateID: [0],
      Country: ['India'],
      Zipcode: [''],
      PhoneNo: [''],
      MobileNo: [''],
      EmailId: [''],
      Fax: [''],
      ActId: [0],
      PayScale: [0],
      BranchIds: ['', [Validators.required]],
      CreatedBy: [1],
      CreatedDate: [''],
      ModifiedBy: [1],
      ModifiedDate: [''],
      Status: [true],
      SMImg: [null],
      CommType: ['0'],
      RptSel: [0],
      AprT: [0],
      MayT: [0],
      JunT: [0],
      JulT: [0],
      AugT: [0],
      SepT: [0],
      OctT: [0],
      NovT: [0],
      DecT: [0],
      JanT: [0],
      FebT: [0],
      MarT: [0],
      AprI: [0],
      MayI: [0],
      JunI: [0],
      JulI: [0],
      AugI: [0],
      SepI: [0],
      OctI: [0],
      NovI: [0],
      DecI: [0],
      JanI: [0],
      FebI: [0],
      MarI: [0],
      AccessBranchIdsArray: this._fb.array([]),
    });
    this.setLoginDate();
    let objdata: any = null;
    let SMDegn: any = "0";
    let SMDegnf: any;
    let Town: any="0";
    let Townf: any;
    if (this.router.getCurrentNavigation() === null) {
    }
    else {
      if (this.router.getCurrentNavigation().extras.state.id != undefined) {
        this.titletextneworedit = this.router.getCurrentNavigation().extras.state.text;
        objdata = this.router.getCurrentNavigation().extras.state.obj;
      }
    }
    if (objdata != null) {
      SMDegnf = objdata.smdegn;
      Townf = objdata.town;
      let DOBf: any = objdata.dob.slice(0, 10);      
      let dtS = formatDate(new Date(DOBf.toString().trim()), 'yyyy-MM-dd', 'en_US');
      let dobdt = new Date(dtS);
      let dateto = new Date(dobdt.getFullYear(), dobdt.getMonth(), dobdt.getDate());
      this.form1?.patchValue({
        DOB: dtS
      });
      this.AttendMonth = dateto;
    }
    //when page Load function
    const request = {
      method: 'GET',
      action_url: 'GetSalesmanLoad'
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.SmDegnList = resobj.listsmdegn;
        this.SmUnderList = resobj.listsmunder;
        this.AreaList = resobj.listtowns;       
        this.DistList = resobj.listdistricts;
        this.StateList = resobj.liststates;
        this.AccountsList = resobj.listaccounts;
        this.BranchList = resobj.listbranches;
        if (this.BranchList != null) {
          this.BranchList.forEach((x: { state: number; }) => x.state = 0);
          if (objdata != null) {
            if (objdata.branchIds != null) {
              const AccessBranchIdsArray: FormArray = this.form1?.get('AccessBranchIdsArray') as FormArray;
              let str: any; str = objdata.branchIds.split(",");
              if (str.length > 0) {
                for (var x = 0; x < str.length; x++) {
                  for (var y = 0; y < this.BranchList.length; y++) {
                    if (str[x] == this.BranchList[y].branchId) {
                      this.BranchList[y].state = 1;
                      AccessBranchIdsArray.push(new FormControl(this.BranchList[y].branchId));
                    }
                  }
                }
              }
            }
          }
        }
        //
        try {
          if (this.SmDegnList.find((item: { smdesn: any; }) => item.smdesn == SMDegnf) != undefined) {
            SMDegn = (this.SmDegnList.find((item: { smdesn: any; }) => item.smdesn == SMDegnf).smdesnid);
          }
        } catch { SMDegn = "0"; }
        try {
          if (this.AreaList.find((item: { townName: any; }) => item.townName == Townf) != undefined) {
            Town = (this.AreaList.find((item: { townName: any; }) => item.townName == Townf).townId);
          }
        } catch { Town = "0"; }
        //
        if (objdata != null) {
          this.form1?.patchValue({
            SMId: objdata.smid,
            SMName: objdata.smname,
            SMCode: objdata.smcode,
            SMType: objdata.smtype,
            SmUnder: objdata.smUnder,
            SMDegn: Number(SMDegn),
            DOB: objdata.dob,
            Address1: objdata.address1,
            Address2: objdata.address2,
            Address3: objdata.address3,
            Town: Number(Town),
            Mandal: objdata.mandal,
            DistID: objdata.distId,
            StateID: objdata.stateId,
            Country: objdata.country,
            Zipcode: objdata.zipcode,
            PhoneNo: objdata.phoneNo,
            MobileNo: objdata.mobileNo,
            EmailId: objdata.emailId,
            Fax: objdata.fax,
            ActId: Number(objdata.actId),
            PayScale: objdata.payScale,
            BranchIds: objdata.branchIds,
            SMImg: objdata.smimg,
            CommType: (objdata.commType == "P" ? 0 : 1),
            RptSel: objdata.rptSel,
            AprT: objdata.aprT,
            MayT: objdata.mayT,
            JunT: objdata.junT,
            JulT: objdata.julT,
            AugT: objdata.augT,
            SepT: objdata.sepT,
            OctT: objdata.octT,
            NovT: objdata.novT,
            DecT: objdata.decT,
            JanT: objdata.janT,
            FebT: objdata.febT,
            MarT: objdata.marT,
            AprI: objdata.aprI,
            MayI: objdata.mayI,
            JunI: objdata.junI,
            JulI: objdata.julI,
            AugI: objdata.augI,
            SepI: objdata.sepI,
            OctI: objdata.octI,
            NovI: objdata.novI,
            DecI: objdata.decI,
            JanI: objdata.janI,
            FebI: objdata.febI,
            MarI: objdata.marI,
          });
        }
        //
      },
      error => {
        console.log(error);
      });
    //End    
  }
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    //Focus Start
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    //End
  }
  onStartDateTimeChange(dt: any) {
    this.AttendMonth = dt;
    let dtS = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');    
    this.form1?.patchValue({
      DOB: dtS
    });
  }  
  setLoginDate() {
    let todaydtStr = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      DOB: todaydtStr
    });
    this.AttendMonth = new Date();
  }
  SaveOrSubmitForm() {
    //
    this.submitted = true;
    if (this.form1?.invalid) {
      return;
    }
    //
    let SMDegn: string = "";
    let SMDegnf: any = this.form1?.get('SMDegn')?.value;
    if (this.SmDegnList.find((item: { smdesnid: any; }) => item.smdesnid == Number(SMDegnf)) != undefined) {
      try {
        SMDegn = (this.SmDegnList.find((item: { smdesnid: any; }) => item.smdesnid == Number(SMDegnf)).smdesn);
      } catch { SMDegn = ""; }
    }
    let Town: string = "";
    let Townf: any = this.form1?.get('Town')?.value;
    if (this.AreaList.find((item: { townId: any; }) => item.townId == Number(Townf)) != undefined) {
      try {
        Town = (this.AreaList.find((item: { townId: any; }) => item.townId == Number(Townf)).townName);
      } catch { Town = ""; }
    }
    var ActId = this.form1?.get('ActId')?.value;
    var CommType = this.form1?.get('CommType')?.value;
    var PayScale = this.form1?.get('PayScale')?.value;
    var SMType = this.form1?.get('SMType')?.value;
    let myDateTime: any = new Date();
    let sqlTimeAsString = formatDate(new Date(), "yyyy-MM-dd", "en_US");
    var DOB = this.form1?.get('DOB')?.value;
    let DOBf: any = DOB.slice(0, 10);
    let dtS = formatDate(new Date(DOBf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    let dobdt = new Date(dtS);
    let dateto = new Date(dobdt.getFullYear(), dobdt.getMonth(), dobdt.getDate());
    //
    this.AttendMonth = dateto;
    this.form1?.patchValue({
      CreatedBy: Number(localStorage.getItem("Uid")),
      ModifiedBy: Number(localStorage.getItem("Uid")),
      ActId: Number(ActId),
      CommType: (Number(CommType) == 0 ? "P" : "A"),
      SMDegn: SMDegn,
      PayScale: Number(PayScale),
      CreatedDate: sqlTimeAsString,
      ModifiedDate: sqlTimeAsString,
      Town: Town,
      SMType: Number(SMType),
      SmUnder: Number(this.form1?.get('SmUnder')?.value),
      AprT: Number(this.form1?.get('AprT')?.value),
      MayT: Number(this.form1?.get('MayT')?.value),
      JunT: Number(this.form1?.get('JunT')?.value),
      JulT: Number(this.form1?.get('JulT')?.value),
      AugT: Number(this.form1?.get('AugT')?.value),
      SepT: Number(this.form1?.get('SepT')?.value),
      OctT: Number(this.form1?.get('OctT')?.value),
      NovT: Number(this.form1?.get('NovT')?.value),
      DecT: Number(this.form1?.get('DecT')?.value),
      JanT: Number(this.form1?.get('JanT')?.value),
      FebT: Number(this.form1?.get('FebT')?.value),
      MarT: Number(this.form1?.get('MarT')?.value),
      AprI: Number(this.form1?.get('AprI')?.value),
      MayI: Number(this.form1?.get('MayI')?.value),
      JunI: Number(this.form1?.get('JunI')?.value),
      JulI: Number(this.form1?.get('JulI')?.value),
      AugI: Number(this.form1?.get('AugI')?.value),
      SepI: Number(this.form1?.get('SepI')?.value),
      OctI: Number(this.form1?.get('OctI')?.value),
      NovI: Number(this.form1?.get('NovI')?.value),
      DecI: Number(this.form1?.get('DecI')?.value),
      JanI: Number(this.form1?.get('JanI')?.value),
      FebI: Number(this.form1?.get('FebI')?.value),
      MarI: Number(this.form1?.get('MarI')?.value),
      DOB: dtS
      //AccessBranchIdsArray: [''],
    });
    //console.log(this.form1?.value);
    if (this.form1?.invalid) {
      return;
    }
    this.httpClient.post(this._services.APIUrl + '/InsertSalesman', this.form1?.value).subscribe(res => {
      var result: any = res;
      if (result.msg.toString().includes('Successfull')) {
        Swal.fire('Salesman Creation', result.msg.toString(), 'success');
        this.clearform();
      }
    },
      error => {
        console.log('error c');
        console.log(error);
        this.clearform();
      });
  }
  clearform() {
    this.form1?.patchValue({
      SMId: 0,
      SMName: '',
      SMCode: '',
      SMType: 1,
      SmUnder: 0,
      SMDegn: 0,
      DOB: '12-Jan-2022',
      Address1: '',
      Address2: '',
      Address3: '',
      Town: '0',
      Mandal: '',
      DistID: 0,
      StateID: 0,
      Country: 'India',
      Zipcode: '',
      PhoneNo: '',
      MobileNo: '',
      EmailId: '',
      Fax: '',
      ActId: 0,
      PayScale: 0,
      BranchIds: '',
      CreatedBy: 1,
      CreatedDate: '',
      ModifiedBy: 1,
      ModifiedDate: '',
      Status: true,
      SMImg: null,
      CommType: '0',
      RptSel: 0,
      AprT: 0,
      MayT: 0,
      JunT: 0,
      JulT: 0,
      AugT: 0,
      SepT: 0,
      OctT: 0,
      NovT: 0,
      DecT: 0,
      JanT: 0,
      FebT: 0,
      MarT: 0,
      AprI: 0,
      MayI: 0,
      JunI: 0,
      JulI: 0,
      AugI: 0,
      SepI: 0,
      OctI: 0,
      NovI: 0,
      DecI: 0,
      JanI: 0,
      FebI: 0,
      MarI: 0,
    });
    this.setLoginDate();
    this.titletextneworedit = "New";
    this.submitted = false;
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    this.getBranchList();
    this.BranchList.forEach((x: { state: number; }) => x.state = 0);
  }
  onFocusOutEvent(event: any) {
    var short = this.form1?.get('SMCode')?.value;
    if (short.length == 0) {
      var txtname = event.target.value;
      let strname: string = "";
      if (txtname != null) {
        if (txtname.length == 0) {
          this.form1?.patchValue({
            SMCode: ''
          });
          return;
        }
        if (txtname.length <= 5) {
          strname = txtname;
        }
        else {
          strname = txtname.slice(0, 5);
        }
        this.form1?.patchValue({
          SMCode: strname
        });
      }
    }
  }
  changedistState(event: any) {
    let stateId: any = 0; let distId: any = 0;
    if (this.AreaList.find((item: { townId: any; }) => item.townId == Number(event.target.value)) != undefined) {
      try {
        distId = Number(this.AreaList.find((item: { townId: any; }) => item.townId == Number(event.target.value)).distId);
      } catch { distId = 0; }
      try {
        stateId = Number(this.AreaList.find((item: { townId: any; }) => item.townId == Number(event.target.value)).stateId);
      } catch { stateId = 0; }
    }
    this.form1?.patchValue({
      DistID: distId,
      StateID: stateId
    });
  }
  onCheckboxChange(e: any, data: any) {
    //
    const AccessBranchIdsArray: FormArray = this.form1?.get('AccessBranchIdsArray') as FormArray;
    let AccessBranchIds: string = "";
    if (e.target.checked) {
      AccessBranchIdsArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      AccessBranchIdsArray.controls.forEach((item: AbstractControl) => {
        if (item.value == e.target.value) {
          AccessBranchIdsArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    this.BranchList.find((item: { branchId: any; }) => item.branchId == e.target.value).state = data.state;
    //
    var array = AccessBranchIdsArray.value;
    if (array != null) {
      if (array.length > 0) {
        for (var i = 0; i < array.length; i++) {
          AccessBranchIds += array[i] + ",";
        }
      }
      if (AccessBranchIds.trim().length > 0) {
        let str = AccessBranchIds.trim();
        AccessBranchIds = str;
      }
    }
    this.form1?.patchValue({
      BranchIds: AccessBranchIds
    });
    var BranchIds = this.form1?.get('BranchIds')?.value;
  }
  getBranchList() {
    const request = {
      method: 'GET',
      action_url: 'GetBranch'
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.BranchList = res;
      },
      error => {
        console.log(error);
      });
  }
}
