<div class="contentMain">
  <main class="row">
    <div class="card">
      <div class="card-header mainheader header-container-btn">
        <h5>Payment Entry</h5>
        <button class="btn redbtn" [routerLink]="['/newpayment']" [state]=btntextNew type="button">
          Create New
        </button>
      </div>
      <form class="form-inline custom-form-inline" [formGroup]="form1" (ngSubmit)="SearchOrSubmitForm()">
        <label>Branch :</label>
        <select class="form-control" formControlName="BranchID">
          <option [value]="0">All Branches</option>
          <option *ngFor="let Brlist of BranchList;" [value]="Brlist.branchId">{{Brlist.branchName }}</option>
        </select>
        <label>Date Range:</label>
        <input type="text" class="form-control" placeholder="From Date" style="width:150px;" id="Fromdate" [(ngModel)]="Fromdate" name="Fromdate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange($event)" [bsValue]="Fromdate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
        <input type="text" class="form-control" placeholder="To Date" style="width:150px;" id="Todate" [(ngModel)]="Todate" name="Todate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange2($event)" [bsValue]="Todate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
        <button class="btn redbtn" type="submit">Search</button> &nbsp;
        <button class="btn redbtn" type="button" (click)="reloadComponent()">Refresh Page</button>
      </form>
      <br />
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Date</th>
              <!--<th>Trail Date</th>
              <th>Delv. Date</th>-->
              <th>Bill No</th>
              <th>Account Name</th>
              <th>Amount</th>
              <th>Edit | Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let list of itemlist;let i=index;">
              <td>{{i+1}}</td>
              <td> {{list.sodate }}</td>
              <!--<td> {{list.traildate }}</td>
              <td> {{list.delvdate }}</td>-->
              <td> {{list.series }}</td>
              <td> {{list.actName }}</td>
              <td class="text-right"> {{list.sovalue | currency:'INR':'' }}</td>
              <td align="center">
                <a [routerLink]="['/neworder']" (click)="editformbyid(list)">
                  <img src="../../../../../assets/images/edit-small.png" />
                </a>
                <a (click)="deleteformbyid(list.docId)">
                  <img src="../../../../../assets/images/delete.png" />
                </a>
              </td>
            </tr>
            <tr *ngIf="itemlist.length===0">
              <td colspan="8" class="text-center text-danger">No Data Found for Selection Criteria</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination-container">
          <div class="show ml-8">
            <span>Show</span>
            <select class="ms form-control">
              <option selected value="10">10</option>
              <option value="25">25</option>
            </select>
          </div>
          <div>
            <nav aria-label="Page navigation example" class="float-right mr-8">
              <ul class="pagination pagination-primary">
                <li class="page-item">
                  <a class="page-link" href="">Previous</a>
                </li>
                <li class="page-item"><a class="page-link" href="">1</a></li>
                <li class="page-item"><a class="page-link" href="">2</a></li>
                <li class="page-item"><a class="page-link" href="">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
