<div class="contentMain">
  <!-- breadcrumb -->
  <div class="row">
    <div class="col-md-12 card-header mainheader header-container">
      <h5>Charges - {{titletextneworedit}} Mode</h5>
    </div>
  </div>
  <main class="row">
    <div class="card">
      <form class="form" [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm()">
        <div class="card-body">
          <div class="kliqtab">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs p-0 mb-3">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#General">General</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#StockPoint">GST</a>
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content">
              <div role="tabpanel" class="tab-pane in active p-3" id="General">
                <!-- general -->

                <div class="checkbox text-right">
                  <input id="Status" type="checkbox" name="Status" formControlName="Status" />
                  <label for="Status">Status </label>
                </div>
                <div class="card-body">
                  <!-- row -->

                  <div class="row">
                    <!--<div class="col-lg-12 col-md-12">
                      <div class="d-flex">
                        <p class="pb-0 mb-0">Status</p>
                        <div class="checkbox ml-3 text-right">
                          <input id="activestatus" type="checkbox" />
                          <label for="activestatus"> Active </label>
                        </div>
                      </div>
                    </div>-->

                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <div class="form-group">
                          <label>Charge Name</label>
                          <input type="text" class="form-control" placeholder="charge Name" [ngClass]="{ 'is-invalid': submitted && fc.ChrgName.errors }" formControlName="ChrgName" #myname />
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <div class="form-group">
                          <label>Charge Higher</label>
                          <select class="form-control" formControlName="ChrgHigher">
                            <option [value]="0">Select Charge Higher</option>
                            <option *ngFor="let item of ChrgHigherList"
                                    [value]="item.chrgId">
                              {{item.chrgName}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <div class="form-group">
                          <label>Charge Type</label>
                          <select class="form-control" [ngClass]="{ 'is-invalid': ((submitted && fc.ChrgType.errors)||(submitted && fc.ChrgType.value==0)) }" formControlName="ChrgType">
                            <option [value]="0">Select Charge Type</option>
                            <option *ngFor="let item of ChrgTypesList"
                                    [value]="item.chtpId">
                              {{item.chtpName}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <label> Charge Style</label>
                        <select class="form-control" formControlName="ChrgStyle">
                          <option [value]="0">Select Charge Style</option>
                          <option [value]="1">Percentage</option>
                          <option [value]="2">Amount</option>
                          <option [value]="3">Quantity</option>
                          <option [value]="4">Pcs</option>
                        </select>
                      </div>
                    </div>


                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>Charge Rate</label>
                        <input type="text" class="form-control text-right" placeholder="Charge Rate" formControlName="ChrgRate" />
                      </div>
                    </div>


                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>Charge Calc on</label>
                        <select class="form-control" [ngClass]="{ 'is-invalid': ((submitted && fc.ChrgGross.errors)||(submitted && fc.ChrgGross.value==0)) }" formControlName="ChrgGross">
                          <option [value]="1">Net</option>
                          <option [value]="2">Gross</option>
                        </select>
                      </div>
                    </div>


                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>Account Name</label>
                        <select class="form-control" [ngClass]="{ 'is-invalid': ((submitted && fc.ActId.errors)||(submitted && fc.ActId.value==0)) }" formControlName="ActId">
                          <option [value]="0">Select Acc. Name</option>
                          <option *ngFor="let item of AccountsList"
                                  [value]="item.actId">
                            {{item.actName}}
                          </option>
                        </select>
                      </div>
                    </div>



                  </div>
                  <!-- /row -->
                </div>

                <!--/ general -->
              </div>
              <div role="tabpanel" class="tab-pane p-3" id="StockPoint">
                <!-- Stock point -->
                <div class="row">

                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <h4>GST Tax Rates</h4>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="GSTP">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of TaxRateList"
                                [value]="item.taxType">
                          {{item.taxName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6 mt-4">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <input type="button" value="GST" class="btn btn-primary" (click)="btnbindgstaccounts()" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>CGST %</label>
                      <input type="text" class="form-control text-right" formControlName="CGSTRate" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="CGSTInputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of InputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="CGSTOutputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of OutputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>SGST %</label>
                      <input type="text" class="form-control text-right" formControlName="SGSTRate" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="SGSTInputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of InputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="SGSTOutputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of OutputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>IGST %</label>
                      <input type="text" class="form-control text-right" formControlName="IGSTRate" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="IGSTInputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of InputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="IGSTOutputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of OutputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <div class="form-group">
                        <label>CESS %</label>
                        <input type="text" class="form-control text-right" placeholder="" formControlName="CessRate" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="CessInputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of InputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="CessOutputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of OutputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!--/ Stock Points -->
              </div>
            </div>
          </div>
          <!--/ bootstrap tabs -->
        </div>
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="button" (click)="clearform()">New</button>
          <button class="btn redbtn" type="submit">Save</button>
          <button class="btn redbtn" type="button" (click)="clearform()">Clear</button>
          <button class="btn redbtn" type="button">Help</button>
        </div>
      </form>
    </div>
    <!--/ card -->
  </main>
  <!-- main body -->
</div>
