<div class="contentMain">
  <main class="row">
    <div class="card">

      <div class="card-header mainheader header-container-btn">
        <h5>Delivery Entry</h5>
        <fieldset>
          <div>
            <label>
              <input class="clsradio" type="radio" id="option1"
                     name="group1" [value]="0" [(ngModel)]="isreadytodelv" [ngModelOptions]="{standalone: true}" checked>
              <label class="lbloptions" for="option1">Ready To Delivery</label>
            </label>
            <label>
              <input class="clsradio" type="radio" id="option2"
                     name="group1" [value]="1" [(ngModel)]="isreadytodelv" [ngModelOptions]="{standalone: true}">
              <label class="lbloptions" for="option2">Delivered</label>
            </label>
          </div>
        </fieldset>
        <button class="btn redbtn" [routerLink]="['/new-delivery']" type="button">
          Create New
        </button>
      </div>
      <form class="form-inline custom-form-inline" [formGroup]="form1" (ngSubmit)="SearchOrSubmitForm()">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class=" row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <label for="actname">Delivery Name</label>
                  <input type="text" class="form-control" placeholder="Delivery Name" id="actname" name="actname" style="width:100%;" formControlName="actname" />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <label for="actcode">Delivery Code</label>
                  <input type="text" class="form-control" placeholder="Delivery Code" id="actcode" name="actcode" style="width:100%;" formControlName="actcode" />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <label for="mobileno">Mobile No</label>
                  <input type="text" class="form-control" placeholder="Mobile No" id="mobileno" name="mobileno" style="width:100%;" formControlName="mobileno" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class=" row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <label for="Fromdate">From Date </label>
                  <input type="text" class="form-control" placeholder="From Date" style="width:100%;" id="Fromdate" [(ngModel)]="Fromdate" name="Fromdate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange($event)" [bsValue]="Fromdate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <label for="Todate">To Date</label>
                  <input type="text" class="form-control" placeholder="To Date" style="width: 100%;" id="Todate" [(ngModel)]="Todate" name="Todate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange2($event)" [bsValue]="Todate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
                </div>
                <!--<div class="col-lg-2 col-md-2 col-sm-5">
              <label for="status">Status</label>
              <select class="form-control" id="status" name="status" style="width:100%;" formControlName="status">
                <option [value]="0">All</option>
                <option [value]="1">Pending</option>
                <option [value]="2">Ready to Delivery</option>
                <option [value]="3">Delivered</option>
              </select>
            </div>-->
                <div class="col-lg-2 col-md-2 col-sm-5">
                  <button class="btn redbtn" type="submit">Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <br />
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Delivery Date</th>
              <th>Delivery No</th>
              <th>Customer Name</th>
              <th>Mobile No</th>
              <th>Amount</th>
              <th>Edit | Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let list of itemlist;let i=index;">
              <td>{{i+1}}</td>
              <td> {{list.docDate }}</td>
              <td> {{list.series }}</td>
              <td> {{list.actName }}</td>
              <td>{{list.actMob1}}</td>
              <td class="text-right"> {{list.sovalue | currency:'INR':'' }}</td>
              <td align="center">
                <a [routerLink]="['/new-delivery']" (click)="editformbyid(list)">
                  <img src="../../../../../assets/images/edit-small.png" />
                </a>
                <a (click)="deleteformbyid(list.docId)">
                  <img src="../../../../../assets/images/delete.png" />
                </a>
              </td>
            </tr>
            <tr *ngIf="itemlist.length===0">
              <td colspan="7" class="text-center text-danger">No Data Found for Selection Criteria</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination-container">
          <div class="show ml-8">
            <span>Show</span>
            <select class="ms form-control">
              <option selected value="10">10</option>
              <option value="25">25</option>
            </select>
          </div>
          <div>
            <nav aria-label="Page navigation example" class="float-right mr-8">
              <ul class="pagination pagination-primary">
                <li class="page-item">
                  <a class="page-link" href="">Previous</a>
                </li>
                <li class="page-item"><a class="page-link" href="">1</a></li>
                <li class="page-item"><a class="page-link" href="">2</a></li>
                <li class="page-item"><a class="page-link" href="">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
