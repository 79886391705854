<div class="contentMain">
  <main class="row">
    <!-- card -->
    <div class="card">
      <div class="card-header mainheader header-container-btn">
        <h5>Account Creation</h5>
        <button class="btn redbtn"  [routerLink]="['/newAccount']"  [state]=btntextNew type="button">
          Create New
        </button>
      </div>

      <!-- <button class="btn redbtn" [routerLink]="/newAccount" type="button">New</button> -->

      <form class="form-inline custom-form-inline" [formGroup]="form1" (ngSubmit)="SearchOrSubmitForm()">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class=" row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <label for="actgroup">Account Group</label>
                  <select class="form-control" id="actgroup" name="actgroup" style="width:100%;" formControlName="accountgroup" #myname>
                    <option [value]="0">Select Account Group</option>
                    <option *ngFor="let item of Actgrouplist"
                            [value]="item.groupId">
                      {{item.groupName}}
                    </option>
                  </select>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <label for="acttype">Account Type </label>
                  <select class="form-control" id="acttype" name="acttype" style="width:100%;" formControlName="accounttype">
                    <option [value]="0">Select Account Type</option>
                    <option *ngFor="let item of Acttypeslist"
                            [value]="item.actTypeId">
                      {{item.actType}}
                    </option>
                  </select>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <label for="branchid">Branch</label>
                  <select class="form-control" id="branchid" name="branchid" style="width:100%;" formControlName="branchid">
                    <option [value]="0">Select Branch</option>
                    <option *ngFor="let item of Branchlist" [value]="item.branchId">
                      {{item.branchName}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class=" row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <label for="actcode">Account Code </label>
                  <input type="text" class="form-control" placeholder="Account Code" id="actcode" name="actcode" style="width:100%;" formControlName="actcode"/>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <label for="actname">Account Name </label>
                  <input type="text" class="form-control" placeholder="Account Name" id="actname" name="actname" style="width:100%;" formControlName="actname" />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-5">
                  <label for="status">Status</label>
                  <select class="form-control" id="status" name="status" style="width:100%;" formControlName="status">
                    <option [value]="0">All</option>
                    <option [value]="1">active</option>
                    <option [value]="2">inactive</option>
                  </select>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-5">
                  <button class="btn redbtn" type="submit">Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
</form>
      <br />
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
          <thead>
            <tr>
              <!--<th><input id="remember_me" type="checkbox" /> Select</th>-->
              <th>S.No</th>
              <th>Account Code</th>
              <th>Account Name</th>
              <th>Account Type</th>
              <th>Town Name</th>
              <th>Mobile No</th>
              <th>Status</th>
              <th>Edit | Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of itemlist;let i=index;">
              <!--<td>
          <input id="activestatus" type="checkbox" />
        </td>-->
              <td>{{i+1}}</td>
              <td>{{ item.actCode }}</td>
              <td>{{ item.actName }}</td>
              <td>{{ item.actType }}</td>
              <td>{{ item.townName }}</td>
              <td>{{ item.actMob1 }}</td>
              <td>{{ item.status }}</td>
              <td>
                <a [routerLink]="['/newAccount']" (click)="editformbyid(item.obj,item.obj2)">
                  <img src="../../../../../assets/images/edit-small.png" />
                </a>
                <a (click)="deleteformbyid(item.obj.actId)"> <img src="../../../../../assets/images/delete.png" /></a>
                <!--<a>
            <img src="../../../../../assets/images/visibility.png" />
          </a>-->
              </td>
            </tr>
            <tr *ngIf="itemlist.length===0">
              <td colspan="8" class="text-center text-danger">No Data Found for Selection Criteria</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination-container">
          <div class="show ml-8">
            <span>Show</span>
            <select class="ms form-control">
              <option selected value="10">10</option>
              <option value="25">25</option>
            </select>
          </div>
          <div>
            <nav aria-label="Page navigation example" class="float-right mr-8">
              <ul class="pagination pagination-primary">
                <li class="page-item">
                  <a class="page-link" href="">Previous</a>
                </li>
                <li class="page-item"><a class="page-link" href="">1</a></li>
                <li class="page-item"><a class="page-link" href="">2</a></li>
                <li class="page-item"><a class="page-link" href="">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--<div class="card-footer text-left w-100">
        <button class="btn redbtn" type="button">Clear</button>
        <button class="btn redbtn"
                [routerLink]="['/createAccount']"
                type="button">
          Cancel
        </button>

        <button class="btn redbtn" type="button">Help</button>
      </div>-->
    </div>
    <!--/ card -->
  </main>
  <!-- main body -->
</div>
