import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../../../http.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-area-creation',
  templateUrl: './area-creation.component.html',
  styleUrls: ['./area-creation.component.scss']
})
export class AreaCreationComponent implements OnInit {
  form1: FormGroup | undefined;
  @ViewChild('myname') input;
  public itemlist: any=[];
  public btntextNew = { state: { id: 0, text: 'New', obj: null } };
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private elRef: ElementRef, private router: Router) {
     //form Defining Start
    this.form1 = this._fb.group({
      TownName: [''],
      TownCode: ['']     
    });
    //End form Defining
    this.bindlist();
  }
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    //Focus Start
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    //End
  }
  bindlist() {
    const request = {
      method: 'GET',
      action_url: 'GetTowns'
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.itemlist = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  editformbyid(item: any) {
    //console.log(item);
    this.router.navigateByUrl('/newArea', { state: { id: item.townId, text: 'Edit', obj: item } });
  }
  deleteformbyid(item: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        //
        //console.log(item);
        var jsondata = { id: Number(item) };
        this.httpClient.post(this._services.APIUrl + '/DeleteTowns', Number(item)).subscribe(res => {
          var result: any = res;
          //console.log(result.msg);
          if (result.msg != null) {
            if (result.msg.toString().includes('Successfull')) {
              Swal.fire('Deleted!', result.msg.toString(), 'success');
              this.bindlist();
            }
          }          
        },
          error => {
            console.log(error);
          });
        //
      }
    });
  }
  SearchOrSubmitForm() {
    var name = this.form1?.get('TownName')?.value;
    var short = this.form1?.get('TownCode')?.value;
    const request = {
      method: 'GET',
      action_url: 'GetSeletedTowns?Code=' + short + '&Name=' + name + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.itemlist = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
}




