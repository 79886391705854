<div class="contentMain">
  <!-- breadcrumb -->
  <div class="row">
    <div class="col-md-12 mainheader header-container">
      <h5>Bank Receipt</h5>
      <button class="btn redbtn" [routerLink]="['/new bank_receipt']" type="button">
        Create New
      </button>
    </div>
  </div>
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <!-- card -->
    <div class="card">
      <!-- card body -->
      <div class="card-body">
        <!-- top row -->
        <div class="row">
          <!-- col -->
          <div class="col-md-6">
            <div class="border p-3 h-100">
              <div class="row">
                <!-- col -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Division</label>
                    <select class="form-control show-tick ms select2"
                            data-placeholder="Select">
                      <option>Select</option>
                      <option>All Branches</option>
                      <option>Select Branches</option>
                      <option>Select Item3</option>
                      <option>Select Item4</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Transaction</label>
                    <select class="form-control show-tick ms select2"
                            data-placeholder="Select">
                      <option>Select</option>
                      <option>All</option>
                      <option>Week</option>
                      <option>Select Item3</option>
                      <option>Select Item4</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Document Series</label>
                    <select class="form-control show-tick ms select2"
                            data-placeholder="Select">
                      <option>Select</option>
                      <option>Select Item1</option>
                      <option>Select Item2</option>
                      <option>Select Item3</option>
                      <option>Select Item4</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Date Range</label>
                    <select class="form-control show-tick ms select2"
                            data-placeholder="Select">
                      <option>Select</option>
                      <option>Select Item1</option>
                      <option>Select Item2</option>
                      <option>Select Item3</option>
                      <option>Select Item4</option>
                    </select>
                  </div>
                </div>


                <!--/ col -->
              </div>
            </div>
          </div>
          <!--/ col -->
          <!-- right col -->
          <div class="col-md-6">
            <div class="border p-3 h-100">
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Branch</label>
                    <select class="form-control show-tick ms select2"
                            data-placeholder="Select">
                      <option>Select</option>
                      <option>Select Item1</option>
                      <option>Select Item2</option>
                      <option>Select Item3</option>
                      <option>Select Item4</option>
                    </select>
                  </div>
                </div>
                <!-- col -->

                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>A/C Name</label>
                    <select class="form-control show-tick ms select2"
                            data-placeholder="Select">
                      <option>Select</option>
                      <option>Select Item1</option>
                      <option>Select Item2</option>
                      <option>Select Item3</option>
                      <option>Select Item4</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Document no</label>
                    <select class="form-control show-tick ms select2"
                            data-placeholder="Select">
                      <option>Select</option>
                      <option>Select Item1</option>
                      <option>Select Item2</option>
                      <option>Select Item3</option>
                      <option>Select Item4</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Upto</label>
                    <select class="form-control show-tick ms select2"
                            data-placeholder="Select">
                      <option>Select</option>
                      <option>Select Item1</option>
                      <option>Select Item2</option>
                      <option>Select Item3</option>
                      <option>Select Item4</option>
                    </select>
                  </div>
                </div>

                <!--/ col -->
                <!-- col -->
                <!--/ col -->
                <!-- col -->
                <!-- <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Account Name, Address-1, Address-2, Town, Phone, Mobile, Email</label>
                                            <textarea class="form-control" style="height:100px;"></textarea>
                                        </div>
                                    </div> -->
                <!--/ col -->
              </div>
            </div>
          </div>
          <!--/right  col -->
        </div>
        <br />
        <!--/ top row -->
        <!-- buttons row -->
        <!--/ buttons row -->
        <!-- right table -->
        <!-- <div class="border p-3 h-100" >

                        <h4>Shirt Size &amp; Value</h4>
                        <div class="row" *ngIf="isShown" >
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Length</label>
                                    <input type="text" class="form-control" placeholder="Length">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Chest</label>
                                    <input type="text" class="form-control" placeholder="Chest">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Body</label>
                                    <input type="text" class="form-control" placeholder="Body">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Hip</label>
                                    <input type="text" class="form-control" placeholder="Hip">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Shoulder</label>
                                    <input type="text" class="form-control" placeholder="Shoulder">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Calour</label>
                                    <input type="text" class="form-control" placeholder="Calour">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Full hand</label>
                                    <input type="text" class="form-control" placeholder="Full hand">
                                </div>
                            </div> -->
        <!-- <div class="col-md-3">
                                <div class="form-group">
                                    <label>Half hand</label>
                                    <input type="text" class="form-control" placeholder="Half hand">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Model</label>
                                    <input type="text" class="form-control" placeholder="Half hand"
                                        value="11/4B 8LSP 1HP">
                                </div>
                            </div>
                            <div class="col-md-3">

                                    <button class=" btn redbtn float-right" (click)="sizeShow()" type="button">Save Item</button>

                            </div>

                        </div> -->
        <!--/ new item row-->
        <div class="row">
          <div class="col-md-12 table-responsive">
            <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
              <thead>
                <tr>
                  <th><input id="remember_me" type="checkbox" /></th>
                  <th>S No</th>
                  <th>Item Name</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <!-- <th>Others</th> -->
                  <th>Branch</th>
                  <th>Stock Point</th>
                  <th>Master</th>

                  <!-- <th>Add Size</th> -->
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input id="remember_me" type="checkbox" />
                  </td>
                  <td>1</td>
                  <td>Shirts</td>
                  <td>9/3/2021</td>
                  <td>SHIRT</td>
                  <td>3.00</td>
                  <td>1.00</td>

                  <!-- <td> <button class="btn redbtn" type="button" data-toggle="modal"
                                            data-target="#createNewItem"> Size</button>
                                    </td> -->
                </tr>
                <tr>
                  <td>
                    <input id="remember_me" type="checkbox" />
                  </td>
                  <td>2</td>
                  <td>Trousers</td>
                  <td>9/3/2021</td>
                  <td>SHIRT</td>
                  <td>3.00</td>
                  <td>1.00</td>

                  <!-- <td> <button class="btn redbtn" type="button" data-toggle="modal"
                                            data-target="#createNewItem">
                                            Size</button>
                                        </td> -->
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--/ right table -->
      <!-- final row -->
      <!--/final row -->
    </div>

    <!--/ card body -->
    <div class="card-footer text-left w-100">
      <button class="btn redbtn" type="button">New</button>
      <button class="btn redbtn" type="button">Edit</button>
      <button class="btn redbtn" type="button">Delete</button>
      <button class="btn redbtn" type="button">Cancel</button>
      <button class="btn redbtn" type="button">Preview</button>
      <button class="btn redbtn" type="button">Print</button>
      <button class="btn redbtn" type="button">close</button>
    </div>
    <!-- </div> -->
    <!--/ card -->
  </main>
  <!-- main body -->
</div>
