import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA,NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MastersModule } from './components/masters/masters.module';
import { CommonModule, DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { TransactionModule } from './components/transactions/transaction.module';
import { EnquiryreportsModule } from './components/enquiryreports/enquiryreports.module';
import { HttpService } from './http.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent    
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MastersModule,
    CommonModule,
    RouterModule,
    TransactionModule,
    EnquiryreportsModule,
    BsDatepickerModule.forRoot(),
  ],
  providers: [HttpService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
