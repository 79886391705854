import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '../masters/main/main.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPrintModule } from 'ngx-print';
import { OrderreportComponent } from './orderreport/orderreport.component';
import { DaywisereportComponent } from './daywisereport/daywisereport.component';
import { PendingdetailreportComponent } from './pendingdetailreport/pendingdetailreport.component';

const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      { path: 'orderreport', component: OrderreportComponent },
      { path: 'daywisereport', component: DaywisereportComponent },
      { path: 'pendingdetailreport', component: PendingdetailreportComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  declarations: [OrderreportComponent, DaywisereportComponent, PendingdetailreportComponent],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    BsDatepickerModule.forRoot(),
  ],
   exports: [RouterModule]
})
export class EnquiryreportsModule { }
