<div class="contentMain">
  <!-- breadcrumb -->
  <div class="row">
    <div class="col-md-12 card-header mainheader header-container-btn">
      <h5>Account Creation - {{titletextneworedit}} Mode</h5>
    </div>
    <!-- <div class="col-md-6 ">
            <ol class="breadcrum d-flex justify-content-end">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Account Creation</li>
            </ol>
        </div> -->
  </div>
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <div class="card">
      <form class="form" [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm()">
        <!-- card body -->
        <div class="card-body">
          <!-- bootstrap tabs -->
          <div class="kliqtab">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs p-0 mb-3">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#General">General</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#Address">Address</a>
              </li>
              <!--<li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#Specialoption">Special Option</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#Bank">Bank</a>
                          </li>
                          <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#customFields">Custom Fields</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#gst">GST</a>
              </li>-->
            </ul>
            <!-- Tab panes -->
            <div class="tab-content">
              <div role="tabpanel" class="tab-pane in active p-3" id="General">
                <!-- general -->
                <div class="row">
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-6">
                        <div class="form-group">
                          <label>Account Type<span class="text-danger font-weight-bold">*</span></label>
                          <select class="form-control" formControlName="ActTypef" (change)="changeActType()">
                            <option [value]="0">Select Account Type</option>
                            <option *ngFor="let item of Acttypeslist"
                                    [value]="item.actTypeId">
                              {{item.actType}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-6">
                        <div class="form-group">
                          <label>Account Name<span class="text-danger font-weight-bold">*</span></label>
                          <span>
                            <input type="text" class="form-control" placeholder="Account Name" [ngClass]="{ 'is-invalid': submitted && fc.ActName.errors }" (focusout)="onFocusOutEvent($event)" formControlName="ActName" #myname />
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Account Code<span class="text-danger font-weight-bold">*</span></label>
                          <input type="text" class="form-control" placeholder="Account Code" [ngClass]="{ 'is-invalid': submitted && fc.ActCode.errors }" formControlName="ActCode" />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Alias Name</label>
                          <input type="text" class="form-control" placeholder="Alias Name" formControlName="ActAlias" />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Bill Mode</label>
                          <select class="form-control" formControlName="Billmodef">
                            <option [value]="0">Cash</option>
                            <option [value]="1">Credit</option>
                            <option [value]="2">Card</option>
                            <option [value]="3">Others</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Sub Account</label>
                          <select class="form-control" formControlName="GroupID">
                            <option [value]="0">Select Sub Account</option>
                            <option *ngFor="let item of Actgrouplist"
                                    [value]="item.groupId">
                              <!--[selected]="item.groupOrder==1"-->
                              {{item.groupName}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Transaction Type</label>
                          <select class="form-control" formControlName="TransTypef">
                            <option *ngFor="let item of ActcustTtypes"
                                    [value]="item.accTypeId">
                              {{item.accCustTypeName}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Tax Type</label>
                          <select class="form-control" formControlName="TaxType">
                            <option [value]="0">Select Tax Type</option>
                            <option *ngFor="let item of TaxTypeslist"
                                    [value]="item.taxType">
                              {{item.taxName}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Salesman</label>
                          <select class="form-control" formControlName="SmId">
                            <option [value]="0">Select Salesman</option>
                            <option *ngFor="let item of SalesmanList"
                                    [value]="item.smid">
                              {{item.smname}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Registration Type</label>
                          <select class="form-control" formControlName="Regid">
                            <option [value]="1">Composition</option>
                            <option [value]="2">Consumer</option>
                            <option [value]="3">Regular</option>
                            <option [value]="4">Unregistered</option>
                          </select>
                        </div>
                        <!--<div class="checkbox col-4 pt-4">
                          <input id="remember_me" type="checkbox" />
                          <label for="remember_me"> TCS </label>
                        </div>-->
                      </div>

                    </div>
                    <!--/ row -->
                  </div>

                  <!--<div class="col-md-3">

                    <div class="d-grid gap-2 d-md-block text-center">
                      <button class="btn btn-secondary disabled rounded-pill"
                              type="button">
                        Active
                      </button>
                      <button class="btn btn-primary redbtn rounded-pill"
                              type="button">
                        Block
                      </button>
                    </div>

                    <figure class="avatarImg text-center">
                      <img src="./assets/images/avatar1.jpg"
                           class="rounded-circle"
                           alt="Profile Image" />
                    </figure>

                    <div class="d-grid gap-2 d-md-block text-center">
                      <button class="btn btn-outline-primary brdbtn rounded-pill"
                              type="button">
                        Web Cam
                      </button>
                      <button class="btn btn-outline-primary brdbtn rounded-pill"
                              type="button"
                              data-target="#uploadImage"
                              data-toggle="modal">
                        Select Image
                      </button>
                    </div>

                  </div>-->
                  <div class="col-md-3">
                    <button #fileInput style="visibility:hidden" class="btn" type="button" (click)="cancelImageasDefaultedit()">Cancel</button>

                    <figure class="avatarImg text-center">
                      <img [src]="imgUrl" class="rounded-circle" alt="Profile Image" style="width: 150px; height: 150px;" onerror="this.src='./assets/images/itemcreation/defaultimage.png'">
                    </figure>
                    <div class="d-grid gap-2 d-md-block text-center">
                      <label for="img" class="btn btn-outline-primary redbtn rounded-pill">Select Image</label>
                      <input type="file" style="visibility:hidden" id="img" #Image accept="image/*" formControlName="Image" (change)="onSelectFile($event)" /> <!--(change)="handleFileInput($event.target.files)"-->
                    </div>
                    <div class="d-grid gap-2 d-md-block text-center">
                      <button class="btn btn-outline-primary redbtn rounded-pill" type="button" (click)="cancelImageasDefault()">Cancel</button>
                    </div>
                  </div>
                </div>
                <!--/ general -->
                <!-- Branch Permissions -->
                <div class="row border-top mt-3">
                  <div class="col-12">
                    <h5 class="py-3">Branch Permissions<span class="text-danger font-weight-bold">*</span></h5>
                    <!-- table -->
                    <div class="table-responsive">
                      <div class="checkbox">
                        <input id="selectall" type="checkbox" [checked]="selectall" (change)="checkallbranchpermissions($event.target.checked)" />
                        <label for="selectall"> Select All </label>
                      </div>
                      <table class="table m-b-0 kliqtable">
                        <thead>
                          <tr>
                            <th scope="col">Check</th>
                            <th scope="col">Branch Name</th>
                            <th scope="col" class="text-right">
                              Current Year OP Bal
                            </th>
                            <th scope="col">Sign</th>
                            <th scope="col" class="text-right">
                              Previous Year OP Bal
                            </th>
                            <th scope="col">Sign</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of BranchList;let i=index;">
                            <td scope="row">
                              <div class="checkbox">
                                <input id="check{{i}}" type="checkbox" [checked]="item.state==true" [value]="item.state" (change)="checkbranchpermissions(item,$event.target.checked)" />
                                <label for="check{{i}}"> &nbsp; </label>
                              </div>
                            </td>
                            <td>{{item.branchName}}</td>
                            <td align="right">
                              <input class="form-control text-right" [value]="item.opBal" (change)="changebranchopbal(item,$event.target.value)" type="text" placeholder="0.00" />
                            </td>
                            <td>
                              <select (change)="changebranchopsign(item,$event.target.value)">
                                <option [value]="0" [selected]="item.sign == 'Dr'">Dr</option>
                                <option [value]="1" [selected]="item.sign == 'Cr'">Cr</option>
                              </select>
                            </td>
                            <td align="right">
                              <input disabled class="form-control text-right" [value]="item.opBalprev" type="text" placeholder="0.00" />
                            </td>
                            <td>
                              <select>
                                <option disabled [value]="0" [selected]="item.signprev == 'Dr'">Dr</option>
                                <option disabled [value]="1" [selected]="item.signprev == 'Cr'">Cr</option>
                              </select>
                            </td>
                          </tr>
                          <tr *ngIf="submittedBranchPermissions">
                            <td colspan="6"><label style=" color: red;">* Plz Select Atleast One Branch</label></td>
                          </tr>
                        </tbody>
                      </table>
                      <!--<div *ngIf="submittedBranchPermissions" class="invalid-feedback">
                        * Plz Select Atleast One Branch
                      </div>-->
                    </div>
                    <!--<div class="border-top pt-4">
                      <div class="checkbox">
                        <input id="active1" type="checkbox" />
                        <label for="active1">
                          Active Bill to Bill Adjustment to this A ccount
                        </label>
                      </div>
                      <div class="checkbox">
                        <input id="active2" type="checkbox" />
                        <label for="active2">
                          Display Opening Balance as Seperate
                        </label>
                      </div>
                    </div>-->

                  </div>
                </div>
              </div>
              <div role="tabpanel" class="tab-pane" id="Address">
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Contact Person</label>
                      <input type="text" class="form-control" placeholder="Contact Name" formControlName="Person" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Gender</label>
                      <select class="form-control" formControlName="ActGenderf">
                        <option [value]="0">Select Gender</option>
                        <option *ngFor="let item of GenderList"
                                [value]="item.genderId">
                          {{item.genderName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Profession</label>
                      <select class="form-control" formControlName="ActProfessionf">
                        <option [value]="0">Select Profession</option>
                        <option [value]="1">Manager</option>
                        <option [value]="2">Ass.Manager</option>
                        <option [value]="3">Salesman</option>
                      </select>
                    </div>
                  </div>

                </div>
                <!-- row -->
                <div class="row">
                  <div class="col-12">
                    <h5 class="py-3">Address</h5>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Address Line 1<span class="text-danger font-weight-bold">*</span></label>
                      <input type="text" class="form-control" placeholder="Address Line 1" formControlName="AddLine1" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Address Line 2</label>
                      <input type="text" class="form-control" placeholder="Address Line 2" formControlName="AddLine2" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Town</label>
                      <input type="text" class="form-control" placeholder="Town Name" formControlName="ActTownf" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group row">
                      <div class="col-10">
                        <label>Area<span class="text-danger font-weight-bold">*</span></label>
                        <select class="form-control" [ngClass]="{ 'is-invalid': ((submitted && fc.AreaId.errors)||(submitted && fc.AreaId.value==0)) }" formControlName="AreaId" (change)="changedistState($event)">
                          <option [value]="0">Select Area</option>
                          <option *ngFor="let item of TownsList"
                                  [value]="item.townId">
                            {{item.townName}}
                          </option>
                        </select>
                      </div>
                      <div class="col-2 pl-0">
                        <a href="javascript:void(0)"
                           class="formAddicon"
                           data-toggle="modal"
                           data-target="#addItem">
                          <span class="icon-plus icomoon"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Pincode</label>
                      <input type="text" class="form-control" placeholder='Pincode' formControlName="ActPinNo" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>District</label>
                      <select class="form-control" formControlName="ActDistID">
                        <option [value]="0">Select District</option>
                        <option *ngFor="let item of DistrictsList"
                                [value]="item.distId">
                          {{item.distName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>State</label>
                      <select class="form-control" formControlName="ActStateid">
                        <option [value]="0">Select State</option>
                        <option *ngFor="let item of StatesList"
                                [value]="item.stateId">
                          {{item.stateName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Country</label>
                      <input type="text" value="India" class="form-control" placeholder="" formControlName="ActCountry" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Email ID</label>
                      <input type="text" class="form-control" placeholder="Email ID" formControlName="ActEmail" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Mobile Number<span class="text-danger font-weight-bold">*</span></label>
                      <input type="text" class="form-control" placeholder="Mobile Number" [ngClass]="{ 'is-invalid': submitted && fc.ActMob1.errors  }" formControlName="ActMob1" />
                    </div>
                    <div *ngIf="fc.ActMob1.touched && fc.ActMob1.invalid" class="alert alert-danger">
                      <div *ngIf="fc.ActMob1.errors?.required">Number is required.</div>
                      <div *ngIf="fc.ActMob1.errors?.pattern">Enter only number.</div>
                      <div *ngIf="fc.ActMob1.errors?.minlength">Must be 10 characters</div>
                      <div *ngIf="fc.ActMob1.errors?.maxlength">Must be 10 characters</div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Alternative Mobile Number</label>
                      <input type="text" class="form-control" placeholder="Alternative Mobile Number" formControlName="ActMob2" />
                    </div>
                    <div *ngIf="fc.ActMob2.touched && fc.ActMob2.invalid" class="alert alert-danger">
                      <div *ngIf="fc.ActMob2.errors?.pattern">Enter only number.</div>
                      <div *ngIf="fc.ActMob2.errors?.minlength">Must be 10 characters</div>
                      <div *ngIf="fc.ActMob2.errors?.maxlength">Must be 10 characters</div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Phone Number</label>
                      <input type="text" class="form-control" placeholder="Phone Number" formControlName="ActPh1" />
                    </div>
                    <div *ngIf="fc.ActPh1.touched && fc.ActPh1.invalid" class="alert alert-danger">
                      <div *ngIf="fc.ActPh1.errors?.pattern">Enter only number.</div>
                      <div *ngIf="fc.ActPh1.errors?.minlength">Must be 10 characters</div>
                      <div *ngIf="fc.ActPh1.errors?.maxlength">Must be 10 characters</div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Alternative Phone Number</label>
                      <input type="text" class="form-control" placeholder="Alternative Phone Number" formControlName="ActPh2" />
                    </div>
                    <div *ngIf="fc.ActPh2.touched && fc.ActPh2.invalid" class="alert alert-danger">
                      <div *ngIf="fc.ActPh2.errors?.pattern">Enter only number.</div>
                      <div *ngIf="fc.ActPh2.errors?.minlength">Must be 10 characters</div>
                      <div *ngIf="fc.ActPh2.errors?.maxlength">Must be 10 characters</div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>STD Code</label>
                      <input type="text" class="form-control" placeholder="EX:040" formControlName="ActSTD" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Blood Group</label>
                      <input type="text" class="form-control" placeholder="EX:B+" formControlName="ActBlood" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Fax Number</label>
                      <input type="text" class="form-control" placeholder="Fax Number" formControlName="ActFax" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Aadhar Number</label>
                      <input type="text" class="form-control" placeholder="Aadhar Number" formControlName="ActAadharNo" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Transport Name</label>
                      <input type="text"
                             class="form-control"
                             placeholder="Transport Name" formControlName="ActTransport" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>PAN Number</label>
                      <input type="text"
                             class="form-control"
                             placeholder="PAN Number" formControlName="ActPAN" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Credit Limit</label>
                      <input type="text"
                             class="form-control text-right"
                             placeholder="Credit Limit" formControlName="CreditAmt" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>GSTIN Number</label>
                      <input type="text"
                             class="form-control"
                             placeholder="GSTIN Number" formControlName="ActGSTIn" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Credit Days</label>
                      <input type="text"
                             class="form-control text-right"
                             placeholder="Credit Days" formControlName="Creditdays" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Due Days</label>
                      <input type="text"
                             class="form-control text-right"
                             placeholder="Due Days" formControlName="DueDays" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Loyality Card Number</label>
                      <input type="text"
                             class="form-control"
                             placeholder="Loyality Card Number" formControlName="ActLoyalityNo" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Sales Tax Number</label>
                      <input type="text"
                             class="form-control"
                             placeholder="Sales Tax Number" formControlName="ActServiceTaxNo" />
                    </div>
                  </div>

                </div>

              </div>
              <!-- <div role="tabpanel" class="tab-pane" id="Specialoption">
                <div class="row">
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Default Rate by Type</label>
                      <div class="
                            select2-container
                            form-control
                            show-tick
                            ms
                            select2
                          "
                           id="s2id_autogen39">
                        <a href="javascript:void(0)"
                           class="select2-choice"
                           tabindex="-1">
                          <span class="select2-chosen" id="select2-chosen-40">Select Default Rate by Type</span><abbr class="select2-search-choice-close"></abbr>
                          <span class="select2-arrow" role="presentation"><b role="presentation"></b></span>
                        </a><label for="s2id_autogen40"
                                   class="select2-offscreen"></label><input class="select2-focusser select2-offscreen"
                                                                            type="text"
                                                                            aria-haspopup="true"
                                                                            role="button"
                                                                            aria-labelledby="select2-chosen-40"
                                                                            id="s2id_autogen40" />
                        <div class="
                              select2-drop
                              select2-display-none
                              select2-with-searchbox
                            ">
                          <div class="select2-search">
                            <label for="s2id_autogen40_search"
                                   class="select2-offscreen"></label>
                            <input type="text"
                                   autocomplete="off"
                                   autocorrect="off"
                                   autocapitalize="off"
                                   spellcheck="false"
                                   class="select2-input"
                                   role="combobox"
                                   aria-expanded="true"
                                   aria-autocomplete="list"
                                   aria-owns="select2-results-40"
                                   id="s2id_autogen40_search"
                                   placeholder="" />
                          </div>
                          <ul class="select2-results"
                              role="listbox"
                              id="select2-results-40"></ul>
                        </div>
                      </div>
                      <select class="form-control show-tick ms select2"
                              data-placeholder="Select"
                              tabindex="-1"
                              title=""
                              style="display: none">
                        <option>Select Default Rate by Type</option>
                        <option>Select Item1</option>
                        <option>Select Item2</option>
                        <option>Select Item3</option>
                        <option>Select Item4</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Control Days</label>
                      <input type="text"
                             class="form-control"
                             placeholder="Control Days" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <label>CAN Send SMS, Email</label>
                    <div class="form-group row">
                      <div class="checkbox col-6">
                        <input id="sms" type="checkbox" />
                        <label for="sms"> SMS </label>
                      </div>
                      <div class="checkbox col-6">
                        <input id="email" type="checkbox" />
                        <label for="email"> Emaail </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Date of Birth</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="zmdi zmdi-calendar"></i>
                          </span>
                        </div>
                        <input type="text"
                               class="form-control datepicker"
                               placeholder="Please choose date ..."
                               data-dtp="dtp_JT9Aq" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>D.License Exp Date</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="zmdi zmdi-calendar"></i>
                          </span>
                        </div>
                        <input type="text"
                               class="form-control datepicker"
                               placeholder="D.License Exp Date..."
                               data-dtp="dtp_uTFwW" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Anniversary Date</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="zmdi zmdi-calendar"></i>
                          </span>
                        </div>
                        <input type="text"
                               class="form-control datepicker"
                               placeholder="Anniversary Date..."
                               data-dtp="dtp_KOHYc" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Contact Person Shipment</label>
                      <input type="text"
                             class="form-control"
                             placeholder="Contact Person Shipment" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Spouse Name</label>
                      <input type="text"
                             class="form-control"
                             placeholder="Spouse Name" />
                    </div>
                  </div>

                </div>
                <div class="row pt-5">

                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>TAN Number</label>
                      <input type="text"
                             class="form-control"
                             placeholder="TAN Number" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>DL 20B</label>
                      <input type="text"
                             class="form-control"
                             placeholder="DL 20B" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>DL 21B</label>
                      <input type="text"
                             class="form-control"
                             placeholder="DL 21B" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>MC Number</label>
                      <input type="text"
                             class="form-control"
                             placeholder="MC Number" />
                    </div>
                  </div>

                </div>

              </div>
                <div role="tabpanel" class="tab-pane" id="Bank">

                  <div class="row">

                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>Bank Name</label>
                        <input type="text"
                               class="form-control"
                               placeholder="Bank Name" />
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>Account Number</label>
                        <input type="text"
                               class="form-control"
                               placeholder="Account Number" />
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>Account Holder Name</label>
                        <input type="text"
                               class="form-control"
                               placeholder="Account Holder Name" />
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>Branch Name</label>
                        <input type="text"
                               class="form-control"
                               placeholder="Branch Name" />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Address</label>
                        <input type="text"
                               class="form-control"
                               placeholder="Address" />
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>BSR Code</label>
                        <input type="text"
                               class="form-control"
                               placeholder="BSR Code" />
                      </div>
                    </div>


                    <div class="col-lg-3 col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>IFSC Code</label>
                        <input type="text"
                               class="form-control"
                               placeholder="IFSC Code" />
                      </div>
                    </div>
                  </div>
                </div>
              <div role="tabpanel" class="tab-pane" id="customFields">
                  <table class="table m-b-0 kliqtable">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Gold Customers</th>
                        <td>Gold</td>
                      </tr>
                      <tr>
                        <th scope="row">Royal Customers</th>
                        <td>Royal</td>
                      </tr>
                      <tr>
                        <th scope="row">Silver Customers</th>
                        <td>Silver</td>
                      </tr>
                      <tr>
                        <th scope="row">VIP Customers</th>
                        <td>VIP</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                          <div role="tabpanel" class="tab-pane" id="gst">
                            <div classs="row">
                              <div class="col-12">
                                <h5 class="py-3">GST Account Tagging</h5>
                              </div>

                              <div class="form-group row">
                                <div class="col-lg-2 text-right">
                                  <label>GST Tax Rates</label>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                  <div class="
                                      select2-container
                                      form-control
                                      show-tick
                                      ms
                                      select2
                                    "
                                       id="s2id_autogen41">
                                    <a href="javascript:void(0)"
                                       class="select2-choice"
                                       tabindex="-1">
                                      <span class="select2-chosen" id="select2-chosen-42">Select GST Tax Rates</span><abbr class="select2-search-choice-close"></abbr>
                                      <span class="select2-arrow" role="presentation"><b role="presentation"></b></span>
                                    </a><label for="s2id_autogen42"
                                               class="select2-offscreen"></label><input class="select2-focusser select2-offscreen"
                                                                                        type="text"
                                                                                        aria-haspopup="true"
                                                                                        role="button"
                                                                                        aria-labelledby="select2-chosen-42"
                                                                                        id="s2id_autogen42" />
                                    <div class="
                                        select2-drop
                                        select2-display-none
                                        select2-with-searchbox
                                      ">
                                      <div class="select2-search">
                                        <label for="s2id_autogen42_search"
                                               class="select2-offscreen"></label>
                                        <input type="text"
                                               autocomplete="off"
                                               autocorrect="off"
                                               autocapitalize="off"
                                               spellcheck="false"
                                               class="select2-input"
                                               role="combobox"
                                               aria-expanded="true"
                                               aria-autocomplete="list"
                                               aria-owns="select2-results-42"
                                               id="s2id_autogen42_search"
                                               placeholder="" />
                                      </div>
                                      <ul class="select2-results"
                                          role="listbox"
                                          id="select2-results-42"></ul>
                                    </div>
                                  </div>
                                  <select class="form-control show-tick ms select2"
                                          data-placeholder="Select"
                                          tabindex="-1"
                                          title=""
                                          style="display: none">
                                    <option>Select GST Tax Rates</option>
                                    <option>Select Item1</option>
                                    <option>Select Item2</option>
                                    <option>Select Item3</option>
                                    <option>Select Item4</option>
                                  </select>
                                </div>
                              </div>

                            </div>

                            <div class="row">
                              <div class="col-lg-2 col-md-2 text-right col-sm-6">
                                <label class="text-right">CGST:</label>
                              </div>
                              <div class="col-lg-2 col-md-2">
                                <div class="form-group">
                                  <input type="text"
                                         class="form-control text-right"
                                         value="0.00" />
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-4 col-sm-6">
                                <div class="form-group">
                                  <div class="
                                      select2-container
                                      form-control
                                      show-tick
                                      ms
                                      select2
                                    "
                                       id="s2id_autogen43">
                                    <a href="javascript:void(0)"
                                       class="select2-choice"
                                       tabindex="-1">
                                      <span class="select2-chosen" id="select2-chosen-44">Select </span><abbr class="select2-search-choice-close"></abbr>
                                      <span class="select2-arrow" role="presentation"><b role="presentation"></b></span>
                                    </a><label for="s2id_autogen44"
                                               class="select2-offscreen"></label><input class="select2-focusser select2-offscreen"
                                                                                        type="text"
                                                                                        aria-haspopup="true"
                                                                                        role="button"
                                                                                        aria-labelledby="select2-chosen-44"
                                                                                        id="s2id_autogen44" />
                                    <div class="
                                        select2-drop
                                        select2-display-none
                                        select2-with-searchbox
                                      ">
                                      <div class="select2-search">
                                        <label for="s2id_autogen44_search"
                                               class="select2-offscreen"></label>
                                        <input type="text"
                                               autocomplete="off"
                                               autocorrect="off"
                                               autocapitalize="off"
                                               spellcheck="false"
                                               class="select2-input"
                                               role="combobox"
                                               aria-expanded="true"
                                               aria-autocomplete="list"
                                               aria-owns="select2-results-44"
                                               id="s2id_autogen44_search"
                                               placeholder="" />
                                      </div>
                                      <ul class="select2-results"
                                          role="listbox"
                                          id="select2-results-44"></ul>
                                    </div>
                                  </div>
                                  <select class="form-control show-tick ms select2"
                                          data-placeholder="Select"
                                          tabindex="-1"
                                          title=""
                                          style="display: none">
                                    <option>Select</option>
                                    <option>Select Item1</option>
                                    <option>Select Item2</option>
                                    <option>Select Item3</option>
                                    <option>Select Item4</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-4 col-sm-6">
                                <div class="form-group">
                                  <div class="
                                      select2-container
                                      form-control
                                      show-tick
                                      ms
                                      select2
                                    "
                                       id="s2id_autogen45">
                                    <a href="javascript:void(0)"
                                       class="select2-choice"
                                       tabindex="-1">
                                      <span class="select2-chosen" id="select2-chosen-46">Select </span><abbr class="select2-search-choice-close"></abbr>
                                      <span class="select2-arrow" role="presentation"><b role="presentation"></b></span>
                                    </a><label for="s2id_autogen46"
                                               class="select2-offscreen"></label><input class="select2-focusser select2-offscreen"
                                                                                        type="text"
                                                                                        aria-haspopup="true"
                                                                                        role="button"
                                                                                        aria-labelledby="select2-chosen-46"
                                                                                        id="s2id_autogen46" />
                                    <div class="
                                        select2-drop
                                        select2-display-none
                                        select2-with-searchbox
                                      ">
                                      <div class="select2-search">
                                        <label for="s2id_autogen46_search"
                                               class="select2-offscreen"></label>
                                        <input type="text"
                                               autocomplete="off"
                                               autocorrect="off"
                                               autocapitalize="off"
                                               spellcheck="false"
                                               class="select2-input"
                                               role="combobox"
                                               aria-expanded="true"
                                               aria-autocomplete="list"
                                               aria-owns="select2-results-46"
                                               id="s2id_autogen46_search"
                                               placeholder="" />
                                      </div>
                                      <ul class="select2-results"
                                          role="listbox"
                                          id="select2-results-46"></ul>
                                    </div>
                                  </div>
                                  <select class="form-control show-tick ms select2"
                                          data-placeholder="Select"
                                          tabindex="-1"
                                          title=""
                                          style="display: none">
                                    <option>Select</option>
                                    <option>Select Item1</option>
                                    <option>Select Item2</option>
                                    <option>Select Item3</option>
                                    <option>Select Item4</option>
                                  </select>
                                </div>
                              </div>

                            </div>

                            <div class="row">
                              <div class="col-lg-2 col-md-2 text-right col-sm-6">
                                <label class="text-right">SGST:</label>
                              </div>
                              <div class="col-lg-2 col-md-2 col-sm-6">
                                <div class="form-group">
                                  <input type="text"
                                         class="form-control text-right"
                                         value="0.00" />
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-4 col-sm-6">
                                <div class="form-group">
                                  <div class="
                                      select2-container
                                      form-control
                                      show-tick
                                      ms
                                      select2
                                    "
                                       id="s2id_autogen47">
                                    <a href="javascript:void(0)"
                                       class="select2-choice"
                                       tabindex="-1">
                                      <span class="select2-chosen" id="select2-chosen-48">Select </span><abbr class="select2-search-choice-close"></abbr>
                                      <span class="select2-arrow" role="presentation"><b role="presentation"></b></span>
                                    </a><label for="s2id_autogen48"
                                               class="select2-offscreen"></label><input class="select2-focusser select2-offscreen"
                                                                                        type="text"
                                                                                        aria-haspopup="true"
                                                                                        role="button"
                                                                                        aria-labelledby="select2-chosen-48"
                                                                                        id="s2id_autogen48" />
                                    <div class="
                                        select2-drop
                                        select2-display-none
                                        select2-with-searchbox
                                      ">
                                      <div class="select2-search">
                                        <label for="s2id_autogen48_search"
                                               class="select2-offscreen"></label>
                                        <input type="text"
                                               autocomplete="off"
                                               autocorrect="off"
                                               autocapitalize="off"
                                               spellcheck="false"
                                               class="select2-input"
                                               role="combobox"
                                               aria-expanded="true"
                                               aria-autocomplete="list"
                                               aria-owns="select2-results-48"
                                               id="s2id_autogen48_search"
                                               placeholder="" />
                                      </div>
                                      <ul class="select2-results"
                                          role="listbox"
                                          id="select2-results-48"></ul>
                                    </div>
                                  </div>
                                  <select class="form-control show-tick ms select2"
                                          data-placeholder="Select"
                                          tabindex="-1"
                                          title=""
                                          style="display: none">
                                    <option>Select</option>
                                    <option>Select Item1</option>
                                    <option>Select Item2</option>
                                    <option>Select Item3</option>
                                    <option>Select Item4</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-4 col-sm-6">
                                <div class="form-group">
                                  <div class="
                                      select2-container
                                      form-control
                                      show-tick
                                      ms
                                      select2
                                    "
                                       id="s2id_autogen49">
                                    <a href="javascript:void(0)"
                                       class="select2-choice"
                                       tabindex="-1">
                                      <span class="select2-chosen" id="select2-chosen-50">Select </span><abbr class="select2-search-choice-close"></abbr>
                                      <span class="select2-arrow" role="presentation"><b role="presentation"></b></span>
                                    </a><label for="s2id_autogen50"
                                               class="select2-offscreen"></label><input class="select2-focusser select2-offscreen"
                                                                                        type="text"
                                                                                        aria-haspopup="true"
                                                                                        role="button"
                                                                                        aria-labelledby="select2-chosen-50"
                                                                                        id="s2id_autogen50" />
                                    <div class="
                                        select2-drop
                                        select2-display-none
                                        select2-with-searchbox
                                      ">
                                      <div class="select2-search">
                                        <label for="s2id_autogen50_search"
                                               class="select2-offscreen"></label>
                                        <input type="text"
                                               autocomplete="off"
                                               autocorrect="off"
                                               autocapitalize="off"
                                               spellcheck="false"
                                               class="select2-input"
                                               role="combobox"
                                               aria-expanded="true"
                                               aria-autocomplete="list"
                                               aria-owns="select2-results-50"
                                               id="s2id_autogen50_search"
                                               placeholder="" />
                                      </div>
                                      <ul class="select2-results"
                                          role="listbox"
                                          id="select2-results-50"></ul>
                                    </div>
                                  </div>
                                  <select class="form-control show-tick ms select2"
                                          data-placeholder="Select"
                                          tabindex="-1"
                                          title=""
                                          style="display: none">
                                    <option>Select</option>
                                    <option>Select Item1</option>
                                    <option>Select Item2</option>
                                    <option>Select Item3</option>
                                    <option>Select Item4</option>
                                  </select>
                                </div>
                              </div>

                            </div>

                            <div class="row">
                              <div class="col-lg-2 col-md-2 text-right col-sm-6">
                                <label class="text-right">IGST:</label>
                              </div>
                              <div class="col-lg-2 col-md-2 col-sm-6">
                                <div class="form-group">
                                  <input type="text"
                                         class="form-control text-right"
                                         value="0.00" />
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-4 col-sm-6">
                                <div class="form-group">
                                  <div class="
                                      select2-container
                                      form-control
                                      show-tick
                                      ms
                                      select2
                                    "
                                       id="s2id_autogen51">
                                    <a href="javascript:void(0)"
                                       class="select2-choice"
                                       tabindex="-1">
                                      <span class="select2-chosen" id="select2-chosen-52">Select </span><abbr class="select2-search-choice-close"></abbr>
                                      <span class="select2-arrow" role="presentation"><b role="presentation"></b></span>
                                    </a><label for="s2id_autogen52"
                                               class="select2-offscreen"></label><input class="select2-focusser select2-offscreen"
                                                                                        type="text"
                                                                                        aria-haspopup="true"
                                                                                        role="button"
                                                                                        aria-labelledby="select2-chosen-52"
                                                                                        id="s2id_autogen52" />
                                    <div class="
                                        select2-drop
                                        select2-display-none
                                        select2-with-searchbox
                                      ">
                                      <div class="select2-search">
                                        <label for="s2id_autogen52_search"
                                               class="select2-offscreen"></label>
                                        <input type="text"
                                               autocomplete="off"
                                               autocorrect="off"
                                               autocapitalize="off"
                                               spellcheck="false"
                                               class="select2-input"
                                               role="combobox"
                                               aria-expanded="true"
                                               aria-autocomplete="list"
                                               aria-owns="select2-results-52"
                                               id="s2id_autogen52_search"
                                               placeholder="" />
                                      </div>
                                      <ul class="select2-results"
                                          role="listbox"
                                          id="select2-results-52"></ul>
                                    </div>
                                  </div>
                                  <select class="form-control show-tick ms select2"
                                          data-placeholder="Select"
                                          tabindex="-1"
                                          title=""
                                          style="display: none">
                                    <option>Select</option>
                                    <option>Select Item1</option>
                                    <option>Select Item2</option>
                                    <option>Select Item3</option>
                                    <option>Select Item4</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-4 col-sm-6">
                                <div class="form-group">
                                  <div class="
                                      select2-container
                                      form-control
                                      show-tick
                                      ms
                                      select2
                                    "
                                       id="s2id_autogen53">
                                    <a href="javascript:void(0)"
                                       class="select2-choice"
                                       tabindex="-1">
                                      <span class="select2-chosen" id="select2-chosen-54">Select </span><abbr class="select2-search-choice-close"></abbr>
                                      <span class="select2-arrow" role="presentation"><b role="presentation"></b></span>
                                    </a><label for="s2id_autogen54"
                                               class="select2-offscreen"></label><input class="select2-focusser select2-offscreen"
                                                                                        type="text"
                                                                                        aria-haspopup="true"
                                                                                        role="button"
                                                                                        aria-labelledby="select2-chosen-54"
                                                                                        id="s2id_autogen54" />
                                    <div class="
                                        select2-drop
                                        select2-display-none
                                        select2-with-searchbox
                                      ">
                                      <div class="select2-search">
                                        <label for="s2id_autogen54_search"
                                               class="select2-offscreen"></label>
                                        <input type="text"
                                               autocomplete="off"
                                               autocorrect="off"
                                               autocapitalize="off"
                                               spellcheck="false"
                                               class="select2-input"
                                               role="combobox"
                                               aria-expanded="true"
                                               aria-autocomplete="list"
                                               aria-owns="select2-results-54"
                                               id="s2id_autogen54_search"
                                               placeholder="" />
                                      </div>
                                      <ul class="select2-results"
                                          role="listbox"
                                          id="select2-results-54"></ul>
                                    </div>
                                  </div>
                                  <select class="form-control show-tick ms select2"
                                          data-placeholder="Select"
                                          tabindex="-1"
                                          title=""
                                          style="display: none">
                                    <option>Select</option>
                                    <option>Select Item1</option>
                                    <option>Select Item2</option>
                                    <option>Select Item3</option>
                                    <option>Select Item4</option>
                                  </select>
                                </div>
                              </div>

                            </div>

                          </div>-->

            </div>
          </div>
        </div>
        <!--/ card body -->
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="button">Another</button>
          <button class="btn redbtn" type="submit">Save</button>
          <button class="btn redbtn" type="button">Find</button>
          <!--<button class="btn redbtn" type="button">Close</button>-->
          <button class="btn redbtn" type="button" (click)="clearform()">Cancel</button>
          <button class="btn redbtn" type="button">Help</button>
        </div>
      </form>
    </div>
  </main>

  <div class="modal fade" id="addItem" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <!-- body -->
      <!-- row -->

      <app-addnew-area></app-addnew-area>

      <!--/ row -->
      <!-- body -->
    </div>
  </div>

  <div class="modal fade" id="sman" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="mainheader">
          <h4 class="title" id="largeModalLabel">sman</h4>
        </div>
        <!-- body -->
        <div class="modal-body">
          <!-- row -->
          <div class="row">
            <div class="col-md-3 modal-sidebar">
              <h5>Select Location</h5>
              <div class="pt-3">
                <div class="checkbox">
                  <input id="locguntur" type="checkbox" />
                  <label for="locguntur"> Guntur </label>
                </div>
                <div class="checkbox">
                  <input id="locNellore" type="checkbox" />
                  <label for="locNellore"> Nellore </label>
                </div>
                <div class="checkbox">
                  <input id="locOngole" type="checkbox" />
                  <label for="locOngole"> Ongole </label>
                </div>
                <div class="checkbox">
                  <input id="locSuryapet" type="checkbox" />
                  <label for="locSuryapet"> Suryapet </label>
                </div>
                <div class="checkbox">
                  <input id="locVijayawada" type="checkbox" />
                  <label for="locVijayawada"> Vijayawada </label>
                </div>
                <div class="checkbox">
                  <input id="locVijayanagaram" type="checkbox" />
                  <label for="locVijayanagaram"> Vijayanagaram </label>
                </div>
                <div class="checkbox">
                  <input id="locguntur" type="checkbox" />
                  <label for="locguntur"> Guntur </label>
                </div>
                <div class="checkbox">
                  <input id="locNellore" type="checkbox" />
                  <label for="locNellore"> Nellore </label>
                </div>
                <div class="checkbox">
                  <input id="locOngole" type="checkbox" />
                  <label for="locOngole"> Ongole </label>
                </div>
                <div class="checkbox">
                  <input id="locSuryapet" type="checkbox" />
                  <label for="locSuryapet"> Suryapet </label>
                </div>
                <div class="checkbox">
                  <input id="locVijayawada" type="checkbox" />
                  <label for="locVijayawada"> Vijayawada </label>
                </div>
                <div class="checkbox">
                  <input id="locVijayanagaram" type="checkbox" />
                  <label for="locVijayanagaram"> Vijayanagaram </label>
                </div>
                <div class="checkbox">
                  <input id="locguntur" type="checkbox" />
                  <label for="locguntur"> Guntur </label>
                </div>
                <div class="checkbox">
                  <input id="locNellore" type="checkbox" />
                  <label for="locNellore"> Nellore </label>
                </div>
                <div class="checkbox">
                  <input id="locOngole" type="checkbox" />
                  <label for="locOngole"> Ongole </label>
                </div>
                <div class="checkbox">
                  <input id="locSuryapet" type="checkbox" />
                  <label for="locSuryapet"> Suryapet </label>
                </div>
                <div class="checkbox">
                  <input id="locVijayawada" type="checkbox" />
                  <label for="locVijayawada"> Vijayawada </label>
                </div>
                <div class="checkbox">
                  <input id="locVijayanagaram" type="checkbox" />
                  <label for="locVijayanagaram"> Vijayanagaram </label>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <div class="d-flex justify-content-between">
                <h5>Area</h5>
                <div class="checkbox">
                  <span class="d-inline-block pr-2">Status</span>
                  <input id="act01" type="checkbox" />
                  <label for="act01"> Active </label>
                </div>
              </div>
              <!-- <app-sman-creation></app-sman-creation> -->
            </div>
          </div>
          <!--/ row -->
        </div>
        <!-- body -->
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="button">New</button>

          <button class="btn redbtn" type="button">Save</button>
          <button class="btn redbtn" type="button">Edit</button>

          <button class="btn redbtn" type="button">Delete</button>

          <button class="btn redbtn" data-dismiss="modal" type="button">
            Close
          </button>
          <button class="btn redbtn" type="button">Cancel</button>
          <button class="btn redbtn" type="button">Help</button>
        </div>
      </div>
    </div>
  </div>
  <!-- main body -->
</div>
