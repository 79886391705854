<div class="contentMain">
  <!-- breadcrumb -->
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <!-- card -->
    <div class="card">
      <div class="card-header mainheader header-container-btn">
        <h5>Transaction Type</h5>
        <button class="btn redbtn" [routerLink]="['/newTrType']" type="button">
          Create New
        </button>
      </div>

      <!-- <button class="btn redbtn" [routerLink]="/newTransaction" type="button">New</button> -->

      <form class="form-inline custom-form-inline">
        <label>Voucher Type :</label>
        <select class="form-control show-tick ms select2"
                data-placeholder="Select">
          <option>Select Voucher Type</option>
          <option>Customer</option>
          <option>Type Customer</option>
          <option>Vendor</option>
          <option>Relish</option>
        </select>
        <label>Transaction Name:</label>
        <input type="text" class="form-control" placeholder="Transaction Name" />
        <label>Transaction code:</label>
        <input type="text" class="form-control" placeholder="Transaction code" />

        <label> Voucher Mode :</label>
        <select class="form-control show-tick ms select2"
                data-placeholder="Select">
          <option>Select Voucher Mode</option>
          <option>Customer</option>
          <option>Type Customer</option>
          <option>Vendor</option>
          <option>Relish</option>
        </select>

        <button class="btn redbtn" type="button">Search</button>
      </form>
      <br />
      <div class="table-responsive">
        <table class="
              table table-bordered table-striped table-hover
              js-basic-example
              dataTable
              kliqtable
            ">
          <thead>
            <tr>
              <th><input id="remember_me" type="checkbox" /> Select</th>
              <th>Transaction Name</th>
              <th>Transaction Code</th>
              <th>Voucher Type</th>
              <th>Voucher Mode</th>
              <th>Edit Delete View</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input id="activestatus" type="checkbox" />
              </td>
              <td>IGST Purchase</td>
              <td>IGP</td>
              <td>Purchase</td>
              <td>Credit</td>
              <td>
                <a [routerLink]="['/newTransaction']">
                  <img src="../../../../../assets/images/edit-small.png" />
                </a>
                <a> <img src="../../../../../assets/images/delete.png" /></a>
                <a>
                  <img src="../../../../../assets/images/visibility.png" />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox" />
              </td>
              <td>GST Purchase</td>
              <td>GStP</td>
              <td>Purchase</td>
              <td>Credit</td>
              <td>
                <a [routerLink]="['/newTransaction']">
                  <img src="../../../../../assets/images/edit-small.png" />
                </a>
                <a> <img src="../../../../../assets/images/delete.png" /></a>
                <a>
                  <img src="../../../../../assets/images/visibility.png" />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox" />
              </td>
              <td>Debit Note</td>
              <td>DB</td>
              <td>Purchase Return</td>
              <td>Credit</td>
              <td>
                <a [routerLink]="['/newTransaction']">
                  <img src="../../../../../assets/images/edit-small.png" />
                </a>
                <a> <img src="../../../../../assets/images/delete.png" /></a>
                <a>
                  <img src="../../../../../assets/images/visibility.png" />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox" />
              </td>
              <td>GST Sales</td>
              <td>GST</td>
              <td>Sales</td>
              <td>Credit</td>
              <td>
                <a [routerLink]="['/newTransaction']">
                  <img src="../../../../../assets/images/edit-small.png" />
                </a>
                <a> <img src="../../../../../assets/images/delete.png" /></a>
                <a>
                  <img src="../../../../../assets/images/visibility.png" />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox" />
              </td>
              <td>GST SALES RETURN</td>
              <td>SR</td>
              <td>sales Return</td>
              <td>Credit</td>
              <td>
                <a [routerLink]="['/newTransaction']">
                  <img src="../../../../../assets/images/edit-small.png" />
                </a>
                <a> <img src="../../../../../assets/images/delete.png" /></a>
                <a>
                  <img src="../../../../../assets/images/visibility.png" />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox" />
              </td>
              <td>BULK BREAK UP</td>
              <td>Bulk</td>
              <td>Transfer In</td>
              <td>Credit</td>
              <td>
                <a [routerLink]="['/newTransaction']">
                  <img src="../../../../../assets/images/edit-small.png" />
                </a>
                <a> <img src="../../../../../assets/images/delete.png" /></a>
                <a>
                  <img src="../../../../../assets/images/visibility.png" />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox" />
              </td>
              <td>SERVICE BILL</td>
              <td>serbill</td>
              <td>service bill</td>
              <td>Credit</td>
              <td>
                <a [routerLink]="['/newTransaction']">
                  <img src="../../../../../assets/images/edit-small.png" />
                </a>
                <a> <img src="../../../../../assets/images/delete.png" /></a>
                <a>
                  <img src="../../../../../assets/images/visibility.png" />
                </a>
              </td>
            </tr>

          </tbody>
        </table>
        <div class="pagination-container">
          <div class="show ml-8">
            <span>Show</span>
            <select class="ms form-control">
              <option selected value="10">10</option>
              <option value="25">25</option>
            </select>
          </div>
          <div>
            <nav aria-label="Page navigation example" class="float-right mr-8">
              <ul class="pagination pagination-primary">
                <li class="page-item">
                  <a class="page-link" href="#">Previous</a>
                </li>
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="#">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="card-footer text-left w-100">
        <button class="btn redbtn" type="button">Clear</button>
        <button class="btn redbtn"
                [routerLink]="['/createTransaction']"
                type="button">
          Cancel
        </button>

        <button class="btn redbtn" type="button">Help</button>
      </div>
    </div>
    <!--/ card -->
  </main>
  <!-- main body -->
</div>
