<div class="contentMain">
  <!-- breadcrumb -->
  <!-- <div class="row">
      <div class="col-md-6">
          <h5>HSN Code Creation</h5>
      </div>
      <div class="col-md-6 ">
          <ol class="breadcrum d-flex justify-content-end">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
              <li class="breadcrumb-item active" aria-current="page">HSN Code Creation</li>
          </ol>
      </div>
  </div> -->
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <!-- card -->
    <div class="card">
      <div class="card-header mainheader">
        <h5>HSN Code Creation - New Mode</h5>
      </div>
      <div class="card-body">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group">
              <div class="form-group">
                <label>Commodity Name</label>
                <input type="text" class="form-control" placeholder="Commodity Name">
              </div>
            </div>
          </div>
          <!--/ col -->
          <!-- col -->
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group">
              <div class="form-group">
                <label>HSN Code</label>
                <input type="text" class="form-control" placeholder="HSN Code">
              </div>
            </div>
          </div>
          <!--/ col -->
          <!-- col -->
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <label>Description</label>
              <div class="form-line">
                <textarea rows="4" class="form-control no-resize"
                          placeholder="Please type Description..."></textarea>
              </div>
            </div>
          </div>
          <!--/ col -->
        </div>
        <!-- /row -->
      </div>
      <div class="card-footer text-left w-100">
        <button class="btn redbtn" type="button">New</button>
        <button class="btn redbtn" type="button">Save</button>
        <button class="btn redbtn" type="button">Edit</button>
        <button class="btn redbtn" type="button">Delete</button>
        <button class="btn redbtn" type="button">Clear</button>
        <button class="btn redbtn" type="button">Cancel</button>
        <button class="btn redbtn" type="button">Save</button>
        <button class="btn redbtn" type="button">Help</button>
      </div>
    </div>
    <!--/ card -->
  </main>
  <!-- main body -->
</div>
