<html>
<head>
  <title></title>
</head>
<body>
  <div class="contentMain">
    <div class="row">
      <div class="col-md-12 mainheader header-container">
        <h5>Deliveries Cutting / Stitching</h5>
      </div>
    </div>
    <main class="row">
      <div class="card">
        <form autocomplete="off" class="form" [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm($event)">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <div class="border p-3 h-100">
                  <div class="row">
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>From Branch</label>
                        <select class="form-control" [attr.disabled]="!editable ? '' : null" (change)="changeBranch($event)" formControlName="BranchID" #myname>
                          <option *ngFor="let Brlist of BranchList" [value]="Brlist.branchId">{{Brlist.branchName }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label> From Stock Point</label>
                        <select class="form-control" [attr.disabled]="!editable ? '' : null" (change)="changeSp($event,1)" formControlName="SpId">
                          <option *ngFor="let item of SPListFrom" [value]="item.spid">{{item.spname }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12">
                      <div class="form-group">
                        <label>Transaction Type</label>
                        <select class="form-control" [attr.disabled]="!editable ? '' : null" formControlName="TtId">
                          <option *ngFor="let item of TTypesList" [value]="item.ttid">{{item.ttName }}</option>
                        </select>
                      </div>
                    </div>
                    <!--<div class="col-md-4 col-sm-4">
                      <div class="form-group">
                        <label title="{{actidtitle}}">A/C Code</label>
                        <input type="text" class="form-control" formControlName="AccountCode" [attr.readonly]="!editdisabled ? '' : null" placeholder="Code" />
                      </div>
                    </div>-->
                    <div class="col-md-4 col-sm-4">
                      <div class="form-group">
                        <label title="{{actidtitle}}">Code</label>
                        <input style="width:100%;" type="text" class="form-control" (input)="keypressAccountCodeName($event,0)" (change)="changeAccountCodeName($event,0)" (click)="clickAccountCodeName($event,0)" formControlName="AccountCode" [attr.readonly]="!editdisabled ? '' : null" placeholder="Code" />
                        <div class="combobox-options" *ngIf="filteredList.length>0 && flag===true">
                          <ul>
                            <li *ngFor="let item of filteredList" (click)="onselectAccountCodeName(item,0)">
                              <a>{{item.actCode}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7 col-sm-7">
                      <div class="form-group">
                        <label>Cutter / Stitcher Name</label>
                        <input style="width:100%;" type="text" class="form-control" (input)="keypressAccountCodeName($event,1)" (change)="changeAccountCodeName($event,1)" (click)="clickAccountCodeName($event,1)" formControlName="AccountName" [attr.readonly]="!editable ? '' : null" placeholder="Name" />
                        <div class="combobox-options" *ngIf="filteredList.length>0 && flag2===true">
                          <ul>
                            <li *ngFor="let item of filteredList" (click)="onselectAccountCodeName(item,1)">
                              <a>{{item.actName}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 col-sm-1">
                      <div class="form-group">
                        <label style="margin-top:5px">&nbsp;</label>
                        <button type="button" class="redbtn btn-sm" (click)="btnHeaderActSeries()">S</button>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12">
                      <div class="form-group">
                        <label>Address</label>
                        <input type="text" class="form-control" formControlName="ActAddr" [attr.readonly]="''" placeholder="Address" />
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Town</label>
                        <input type="text" class="form-control" formControlName="ActTown" [attr.readonly]="''" placeholder="Town" />
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Mobile No</label>
                        <input type="text" class="form-control" formControlName="ActPhone" [attr.readonly]="''" placeholder="Mobile No" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="border p-3 h-100">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>To Branch</label>
                        <select class="form-control" [attr.disabled]="!editable ? '' : null" (change)="changeBranch($event)" formControlName="ToBranchId" #myname>
                          <option *ngFor="let Brlist of BranchList" [value]="Brlist.branchId">{{Brlist.branchName }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>To Stock Point</label>
                        <select class="form-control" [attr.disabled]="!editable ? '' : null" formControlName="ToSPId" (change)="changeSp($event,0)">
                          <option *ngFor="let item of SPListTo" [value]="item.spid">{{item.spname }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Salesman</label>
                        <select class="form-control" [attr.disabled]="!editable ? '' : null" formControlName="SMId">
                          <option [value]="0">Select Salesman</option>
                          <option *ngFor="let item of SMList" [value]="item.smid">{{item.smname }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-3">
                      <div class="form-group">
                        <label>Bill No</label>
                        <input type="text" class="form-control" placeholder="Bill Series" formControlName="TSeries" />
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-3">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <input type="text" class="form-control" placeholder="Bill No" formControlName="TNo" />
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Bill Date</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                          </div>
                          <input type="text" class="form-control" [attr.readonly]="!editdisabled ? '' : null" style="width: 60%;" id="Fromdate" [(ngModel)]="DocDate" name="DocDate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChangeDocDate($event)" [bsValue]="DocDate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Narration</label>
                        <textarea class="form-control" [attr.readonly]="!editdisabled ? '' : null" placeholder="Narration" formControlName="Narration"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row" *ngIf="!CutterStitcheditable">
              <div class="col-md-2 col-sm-2 text-right">
                <b><label>Customer Id</label></b>
              </div>
              <div class="col-md-4 col-sm-4">
                <input type="text" formControlName="CustId" class="form-control" (input)="keypressCustId($event,0)" (change)="changeCustId($event,0)" (click)="clickCustId($event,0)" style="width: 100%;" />                
                <div class="combobox-options" *ngIf="filteredaccountList.length>0 && flag3===true">
                  <ul style=" width:100%;">
                    <li *ngFor="let item of filteredaccountList" (click)="onselectCustId(item,0)">
                      <a>{{item.actCode}}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-2 col-sm-2 text-left">
                <button type="button" class="btn redbtn" (click)="openPopup()">Search </button>
              </div>
            </div>
            <br />
            <div class="row" style="width: 100%; height: 250px; margin-top: -20px; overflow-y: scroll; overflow-x: auto;">
              <div class="col-md-12 col-sm-12">
                <table class="table table-bordered   dataTable kliqtable" style="width: 100%; overflow: auto;">
                  <thead>
                    <tr>
                      <th style="width: 4%;">S.No</th>
                      <th style="width: 12%;">Customer Id</th>
                      <th style="width: 11%;">Ord.Date</th>
                      <th style="width: 11%;">Ord.No</th>
                      <th style="width: 18%;">Item Name</th>
                      <th style="width: 8%;">Qty</th>
                      <th style="width: 13%;">Rate</th>
                      <th style="width: 13%;">Amount</th>
                      <th style="width: 10%;">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of dynamicArray; let i = index;">
                      <td>
                        {{i+1}}
                      </td>
                      <td>
                        {{dynamicArray[i].actCode}}
                      </td>
                      <td>
                        {{dynamicArray[i].docDate}}
                      </td>
                      <td>
                        {{dynamicArray[i].billNo}}
                      </td>
                      <td>
                        {{dynamicArray[i].itemname}}
                      </td>
                      <td class="text-right">
                        {{dynamicArray[i].qty | currency:'INR':''}}
                      </td>
                      <td class="text-right">
                        <input type="text" id="txtrate" [attr.readonly]="!editdisabled ? '' : null" [value]="dynamicArray[i].rate" (focusout)="changeitemrate($event.target.value,item)" style="text-align: right; width: 100%" />
                        <!--{{dynamicArray[i].rate | currency:'INR':''}}-->
                      </td>
                      <td class="text-right">
                        {{dynamicArray[i].amt | currency:'INR':''}}
                      </td>
                      <td align="center">
                        <a>
                          <img (click)="edititems(i,item)" src="../../../../../assets/images/edit-small.png" />
                        </a>
                        <a>
                          <img (click)="removeRowItem(i)" src="../../../../../assets/images/delete.png" />
                        </a>
                      </td>
                    </tr>
                    <tr *ngIf="dynamicArray.length===0">
                      <td colspan="9" class="text-center text-danger">No Data Found for Selection Criteria</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row" style="width: 100%; height: 150px;overflow:auto;">
              <div class="col-md-12">
                <div class="p-3 border mt-3">
                  <div class="row">
                    <div class="col-md-3 col-sm-3 text-right"></div>
                      <div class="col-md-3 col-sm-3 text-right">
                        <table style="width:100%;">
                          <tr>
                            <td><label>Tot. Items </label></td>
                          </tr>
                          <tr>
                            <td> <p><b><label class="form-check-label">{{totalitemscount | currency:'INR':''}}</label>  </b></p></td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-md-3 col-sm-3 text-right">
                        <table style="width:100%;">
                          <tr>
                            <td><label>Total Qty </label></td>
                          </tr>
                          <tr>
                            <td> <p><b><label class="form-check-label">{{totalitemsqty | currency:'INR':''}}</label>  </b></p></td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-md-3 col-sm-3 text-right">
                        <table style="width:100%;">
                          <tr>
                            <td><label>Total Amount </label></td>
                          </tr>
                          <tr>
                            <td> <p><b><label class="form-check-label">{{totalitemsamount | currency:'INR':''}}</label>  </b></p></td>
                          </tr>
                        </table>
                      </div>
                      <!--<div class="col-md-2 col-sm-4 text-right">
      <table style="width:100%;">
        <tr>
          <td><label>Add Charge </label></td>
        </tr>
        <tr>
          <td> <p><b><label class="form-check-label">{{totalitemsadvamount | currency:'INR':''}}</label>  </b></p></td>
        </tr>
      </table>
    </div>
    <div class="col-md-2 col-sm-4 text-right">
      <table style="width:100%;">
        <tr>
          <td><label>Round Off </label></td>
        </tr>
        <tr>
          <td> <p><b><label class="form-check-label">{{totalroundoffamount | currency:'INR':''}}</label>  </b></p></td>
        </tr>
      </table>
    </div>
    <div class="col-md-2 col-sm-4 text-right">
      <table style="width:100%;">
        <tr>
          <td><label>Net Amount </label></td>
        </tr>
        <tr>
          <td> <p><b><label class="form-check-label">{{totalitemsbillamount | currency:'INR':''}}</label>  </b></p></td>
        </tr>
      </table>
    </div>-->
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-left w-100">
            <button class="btn redbtn" type="button" (click)="clearform()">New</button>
            <button class="btn redbtn" type="submit">Save</button>
            <button class="btn redbtn" type="submit">Save & Print</button>
            <button class="btn redbtn" type="button" (click)="clearform()">Refresh</button>
            <button class="btn redbtn" type="button">Close</button>
          </div>
        </form>
      </div>
    </main>
    <!-- Bootstrap Modal for Upload Multiple images -->
    <div class="modal"
         tabindex="-1"
         role="dialog"
         [ngStyle]="{'display':displayStyle}">
      <div class="modal-dialog modal-lg" role="document">
        <h4 class="modal-title" style="background-color:cornflowerblue;color:white;">Delivery to Cutter &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{CustId}}</h4>
        <div class="modal-content">
          <div class="modal-body">
            <div style="width: 100%;height:100%;margin-top:-20px;">
              <div style="width: 100%; height: 250px; overflow-y: scroll; overflow-x: auto;">
                <table style="width: 100%; overflow:auto;" class="tableclass table table-bordered  ">
                  <thead>
                    <tr>
                      <th scope="row">
                        <input id="hcheck" type="checkbox" [checked]="selectall" (change)="checkallItems($event.target.checked)" />
                      </th>
                      <th>Ord.Date</th>
                      <th>Ord.No</th>
                      <th *ngIf="isvisbleCustIdinList">Cust Id</th>
                      <th>ItemName</th>
                      <th>Qty</th>
                      <th>Req.Qty</th>                     
                      <th>Rate</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of itemlist; let i = index;" [class.active]="i == HighlightRow">
                      <td scope="row">
                        <input id="check{{i}}" type="checkbox" [checked]="item.state==true" [value]="item.state" (change)="checkItem(item,$event.target.checked)" />
                      </td>
                      <td>{{item.docDate}}</td>
                      <td>{{item.billNo}}</td>
                      <td *ngIf="isvisbleCustIdinList">{{item.actCode}}</td>
                      <td>{{item.itemname}}</td>
                      <td class="text-right">{{item.balQty | currency:'INR':''}}</td>
                      <td class="text-right">
                        <input type="text" class="form-control" style="text-align: right;width:70px;" [value]="item.reqQty" [title]="item.reqQtye" (focusout)="changeitemqty($event.target.value,item)" />
                      </td>
                      <!--<td class="text-right">{{item.balQty | currency:'INR':''}}</td>-->
                      <td class="text-right">{{item.rate | currency:'INR':''}}</td>
                      <td class="text-right">{{item.amt | currency:'INR':''}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn redbtn" (click)="addRowItem()">Add </button>
            <button type="button" class="btn btn-danger" (click)="closePopup()">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Bootstrap Modal for Upload Multiple images -->
  </div>
</body>
</html>
