<div class="contentMain">
  <!-- breadcrumb -->
  <div class="row">
    <div class="col-md-12 mainheader mainheader header-container">
      <h5>Salesman Creation - {{titletextneworedit}} Mode</h5>
    </div>
  </div>
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <!-- card -->
    <div class="card">
      <form class="form" [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm()">
        <div class="card-body">
          <!-- bootstrap tabs -->
          <div class="kliqtab">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs p-0 mb-3">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab"
                   href="#salesmanCreation">Salesman Creation</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#targets">
                  Targets &amp;
                  Incentives
                </a>
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content">
              <div role="tabpanel" class="tab-pane in active p-3" id="salesmanCreation">
                <!-- salesmanCreation -->
                <div class="row">
                  <!-- col -->
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-9">
                    <!--/ row -->
                    <div class="row">
                      <!-- col -->
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Salesman Type</label>
                          <select class="form-control" [ngClass]="{ 'is-invalid': ((submitted && fc.SMType.errors)||(submitted && fc.SMType.value==0)) }" formControlName="SMType">
                            <option [value]="0">Select Salesman Type</option>
                            <option [value]="1">Salesman</option>
                            <option [value]="2">Agent</option>
                            <option [value]="3">Others</option>
                            <option [value]="4">Helper</option>
                          </select>
                        </div>
                      </div>
                      <!--/ col -->
                      <!-- col -->
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Salesman Name</label>
                          <input type="text" class="form-control" placeholder="Salesman Name" [ngClass]="{ 'is-invalid': submitted && fc.SMName.errors }" (focusout)="onFocusOutEvent($event)" formControlName="SMName" #myname>
                        </div>
                      </div>
                      <!--/ col -->
                      <!-- col -->
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Salesman Code</label>
                          <input type="text" class="form-control" placeholder="Salesman Code" [ngClass]="{ 'is-invalid': submitted && fc.SMCode.errors }" formControlName="SMCode">
                        </div>
                      </div>
                      <!--/ col -->
                      <!-- col -->
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Designation</label>
                          <select class="form-control" formControlName="SMDegn">
                            <option [value]="0">Select Designation</option>
                            <option *ngFor="let item of SmDegnList"
                                    [value]="item.smdesnid">
                              {{item.smdesn}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!--/ col -->
                      <!-- col -->
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Date of Birth</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                            </div>
                            <input type="text" style="width: 60%;" id="AttendMonth" [(ngModel)]="AttendMonth" name="AttendMonth" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange($event)" [bsValue]="AttendMonth" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
                          </div>
                        </div>
                      </div>
                      <input type="text" class="form-control" formControlName="DOB" style="display:none;" />
                      <!--/ col -->
                      <!-- col -->
                      <div class="col-lg-6 col-md-8 col-sm-6">
                        <div class="form-group">
                          <label>Salesman Under</label>
                          <select class="form-control" formControlName="SmUnder">
                            <option [value]="0">Select Salesman Under</option>
                            <option *ngFor="let item of SmUnderList"
                                    [value]="item.smid">
                              {{item.smname}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!--/ col -->
                      <!-- col -->
                      <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Address 1</label>
                          <input type="text" class="form-control" placeholder="Address 1" formControlName="Address1">
                        </div>
                      </div>
                      <!--/ col -->
                      <!-- col -->
                      <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Address 2</label>
                          <input type="text" class="form-control" placeholder="Address 2" formControlName="Address2">
                        </div>
                      </div>
                    </div>
                    <!--/ row -->
                  </div>
                  <!--/ col -->
                  <div class="col-md-3">
                    <!-- buttons -->
                    <!--<div class="d-grid gap-2 d-md-block text-center">
                      <button class="btn btn-secondary disabled rounded-pill"
                              type="button">
                        Active
                      </button>
                      <button class="btn btn-primary redbtn rounded-pill" type="button">Block</button>
                    </div>-->
                    <!--/ buttons -->
                    <figure class="avatarImg text-center">
                      <img src="./assets/images/avatar1.jpg" class="rounded-circle"
                           alt="Profile Image">
                    </figure>
                    <!-- buttons -->
                    <div class="d-grid gap-2 d-md-block text-center">
                      <button class="btn btn-outline-primary redbtn rounded-pill" type="button">
                        Web
                        Cam
                      </button>
                      <button class="btn btn-outline-primary redbtn rounded-pill" type="button">
                        Select
                        Image
                      </button>
                    </div>
                    <!--/ buttons -->
                  </div>
                </div>
                <div class="row">
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Area / Town</label>
                      <select class="form-control" (change)="changedistState($event)" formControlName="Town">
                        <option  [value]="0">Select Area / Town</option>
                        <option *ngFor="let item of AreaList"
                                [value]="item.townId">
                          {{item.townName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>District</label>
                      <select class="form-control" formControlName="DistID">
                        <option [value]="0">Select District</option>
                        <option *ngFor="let item of DistList"
                                [value]="item.distId">
                          {{item.distName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>State</label>
                      <select class="form-control" formControlName="StateID">
                        <option [value]="0">Select State</option>
                        <option *ngFor="let item of StateList"
                                [value]="item.stateId">
                          {{item.stateName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>PIN Code</label>
                      <input type="text" class="form-control" placeholder="PIN Code" formControlName="Zipcode">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Country</label>
                      <input type="text" class="form-control" placeholder="Country" formControlName="Country">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Mobile</label>
                      <input type="text" class="form-control" placeholder="Mobile" formControlName="MobileNo">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Phone</label>
                      <input type="text" class="form-control" placeholder="Phone" formControlName="PhoneNo">
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Email</label>
                      <input type="text" class="form-control" placeholder="Email" formControlName="EmailId">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-lg-6 col-md-8 col-sm-6">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Pay Scale type</label>
                          <select class="form-control" formControlName="CommType">
                            <option [value]="0">P</option>
                            <option [value]="1">A</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <div class="form-group">
                          <label>Pay Scale</label>
                          <input type="text" class="form-control text-right" placeholder="Pay Scale" formControlName="PayScale">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-lg-6 col-md-8 col-sm-6">
                    <div class="form-group">
                      <label>Accountant Name</label>
                      <select class="form-control" formControlName="ActId">
                        <option [value]="0">Select Acc. Name</option>
                        <option *ngFor="let item of AccountsList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <br>
                  <div class="col-lg-12 col-md-12 ">
                    <div class="row">
                      <div class="col-md-12">
                        <h5>Select Branch</h5>
                      </div>
                      <div *ngFor="let data of BranchList; let i=index;">
                        <div class=" col-lg-12 col-md-12 col-sm-12 checkbox ">
                          <div class="form-group">
                            <input id="active{{i}}" type="checkbox" [checked]="data.state==1" [value]="data.branchId" (change)="onCheckboxChange($event,data)">
                            <label for="active{{i}}">{{data.branchName}}</label>
                          </div>
                        </div>
                      </div>
                      <input type="text" value="" class="form-control" [ngClass]="{ 'is-invalid': submitted && fc.BranchIds.errors }" formControlName="BranchIds" style="display: none;" />
                      <div *ngIf="submitted && fc.BranchIds.errors" class="invalid-feedback">
                        <div *ngIf="fc.BranchIds.errors.required">* Plz Select Atleast One Branch</div>
                      </div>
                      <!--<div class=" col-lg-3 col-md-3 col-sm-4 checkbox ">
    <div class="form-group">
      <input id="active1" type="checkbox">
      <label for="active1"> ALL</label>
    </div>
  </div>
  <div class=" col-lg-3 col-md-3 col-sm-4 checkbox ">
    <div class="form-group">
      <input id="active1" type="checkbox">
      <label for="active1"> Main Office </label>
    </div>
  </div>
  <div class=" col-lg-3 col-md-3 col-sm-4 checkbox ">
    <div class="form-group">
      <input id="active1" type="checkbox">
      <label for="active1"> Head Office</label>
    </div>
  </div>
  <div class=" col-lg-3 col-md-3 col-sm-4 checkbox ">
    <div class="form-group">
      <input id="active1" type="checkbox">
      <label for="active1"> Road Branch </label>
    </div>
  </div>-->
                    </div>
                    </div>
                  <!--/ col -->
                </div>
                <!--/ salesmanCreation -->
              </div>
              <div role="tabpanel" class="tab-pane p-3" id="targets">
                <!-- Incentives -->
                <!-- row -->
                <div class="row">
                  <!-- col -->
                  <div class="col-md-2 text-right pt-4">April</div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Targets (INR)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="AprT">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Incentives (%)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="AprI">
                    </div>
                  </div>
                  <!--/ col -->
                </div>
                <!--/ row -->
                <!-- row -->
                <div class="row">
                  <!-- col -->
                  <div class="col-md-2 text-right pt-4">May</div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Targets (INR)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="MayT">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Incentives (%)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="MayI">
                    </div>
                  </div>
                  <!--/ col -->
                </div>
                <!--/ row -->
                <!-- row -->
                <div class="row">
                  <!-- col -->
                  <div class="col-md-2 text-right pt-4">June</div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Targets (INR)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="JunT">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Incentives (%)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="JunI">
                    </div>
                  </div>
                  <!--/ col -->
                </div>
                <!--/ row -->
                <!-- row -->
                <div class="row">
                  <!-- col -->
                  <div class="col-md-2 text-right pt-4">July</div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Targets (INR)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="JulT">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Incentives (%)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="JulI">
                    </div>
                  </div>
                  <!--/ col -->
                </div>
                <!--/ row -->
                <!-- row -->
                <div class="row">
                  <!-- col -->
                  <div class="col-md-2 text-right pt-4">August</div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Targets (INR)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="AugT">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Incentives (%)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="AugI">
                    </div>
                  </div>
                  <!--/ col -->
                </div>
                <!--/ row -->
                <!-- row -->
                <div class="row">
                  <!-- col -->
                  <div class="col-md-2 text-right pt-4">September</div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Targets (INR)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="SepT">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Incentives (%)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="SepI">
                    </div>
                  </div>
                  <!--/ col -->
                </div>
                <div class="row">
                  <!-- col -->
                  <div class="col-md-2 text-right pt-4">Octomber</div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Targets (INR)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="OctT">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Incentives (%)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="OctI">
                    </div>
                  </div>
                  <!--/ col -->
                </div>
                <!--/ row -->
                <!-- row -->
                <div class="row">
                  <!-- col -->
                  <div class="col-md-2 text-right pt-4">November</div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Targets (INR)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="NovT">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Incentives (%)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="NovI">
                    </div>
                  </div>
                  <!--/ col -->
                </div>
                <!--/ row -->
                <!-- row -->
                <div class="row">
                  <!-- col -->
                  <div class="col-md-2 text-right pt-4">December</div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Targets (INR)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="DecT">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Incentives (%)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="DecI">
                    </div>
                  </div>
                  <!--/ col -->
                </div>
                <!--/ row -->
                <!-- row -->
                <div class="row">
                  <!-- col -->
                  <div class="col-md-2 text-right pt-4">january</div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Targets (INR)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="JanT">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Incentives (%)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="JanI">
                    </div>
                  </div>
                  <!--/ col -->
                </div>
                <!--/ row -->
                <!-- row -->
                <div class="row">
                  <!-- col -->
                  <div class="col-md-2 text-right pt-4">february</div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Targets (INR)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="FebT">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Incentives (%)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="FebI">
                    </div>
                  </div>
                  <!--/ col -->
                </div>
                <!--/ row -->
                <!-- row -->
                <div class="row">
                  <!-- col -->
                  <div class="col-md-2 text-right pt-4">March</div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Targets (INR)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="MarT">
                    </div>
                  </div>
                  <!--/ col -->
                  <!-- col -->
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>Incentives (%)</label>
                      <input type="text" class="form-control text-right" placeholder="" formControlName="MarI">
                    </div>
                  </div>
                  <!--/ col -->
                </div>
                <!--/ row -->
                <!--/ Incentives -->
              </div>
            </div>
          </div>
          <!--/ bootstrap tabs -->
        </div>
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="button" (click)="clearform()">New</button>
          <button class="btn redbtn" type="submit">Save</button>
          <button class="btn redbtn" type="button" (click)="clearform()">Clear</button>
          <button class="btn redbtn" type="button">Help</button>
        </div>
      </form>
    </div>
    <!--/ card -->
  </main>
  <!-- main body -->
</div>
