import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpService } from '../../../../http.service';

@Component({
  selector: 'app-new-charge',
  templateUrl: './new-charge.component.html',
  styleUrls: ['./new-charge.component.scss']
})
export class NewChargeComponent implements OnInit {
  TaxRateList: any[];
  InputActList: any[];
  OutputActList: any[];
  AccountsList: any = [];
  ChrgHigherList: any = [];
  ChrgTypesList: any = [];
  form1: FormGroup | undefined;
  @ViewChild('myname') input;
  get fc(): any | undefined { return this.form1?.controls; }
  titletextneworedit: string = "New";
  submitted = false;
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    //form Defining Start
    this.form1 = this._fb.group({
      ChrgId: [0],
      ChrgName: ['', [Validators.required]],
      ChrgType: [0, [Validators.required]],
      ChrgStyle: [0],
      ChrgRate: [0],
      ChrgGross: [1],
      ActId: [0, [Validators.required]],
      ChrgHigher: [0],
      Status: [true],
      HSNCode: [''],
      GSTP: [0],
      SGSTRate: [0],
      CGSTRate: [0],
      IGSTRate: [0],
      CessRate: [0],
      SGSTInputAccID: [0],
      CGSTInputAccID: [0],
      IGSTInputAccID: [0],
      CessInputAccID: [0],
      SGSTOutputAccID: [0],
      CGSTOutputAccID: [0],
      IGSTOutputAccID: [0],
      CessOutputAccID: [0],
      CreatedBy: [0],
      CreatedDate: [''],
      ModifiedBy: [0],
      ModifiedDate: [''],
      Order: [0]
    });
    //End form Defining
    let objdata: any = null;
    let GSTP: any = "0";
    let GSTPf: any;
    if (this.router.getCurrentNavigation() === null) {
    }
    else {
      if (this.router.getCurrentNavigation().extras.state.id != undefined) {
        this.titletextneworedit = this.router.getCurrentNavigation().extras.state.text;
        objdata = this.router.getCurrentNavigation().extras.state.obj;
      }
    }
    if (objdata != null) {
      GSTPf = Number(objdata.vatper);
    }
    //when page Load function
    const request = {
      method: 'GET',
      action_url: 'GetIqchargesLoad'
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.ChrgHigherList = resobj.listchrgh;
        this.ChrgTypesList = resobj.listchrgt;
        this.AccountsList = resobj.listaccounts;
        this.TaxRateList = resobj.listtaxtypes;
        this.InputActList = resobj.listgsti;
        this.OutputActList = resobj.listgsto;
        //       
        try {
          if (this.TaxRateList.find((item: { taxName: any; }) => item.taxName == GSTPf) != undefined) {
            GSTP = (this.TaxRateList.find((item: { taxName: any; }) => item.taxName == GSTPf).taxType);
          }
        } catch { GSTP = "0"; }
        //
        if (objdata != null) {
          this.form1?.patchValue({
            ChrgId: objdata.chrgId,
            ChrgName: objdata.chrgName,
            ChrgType: objdata.chrgType,
            ChrgStyle: objdata.chrgStyle,
            ChrgRate: objdata.chrgRate,
            ChrgGross: objdata.chrgGross,
            ActId: Number(objdata.actId),
            ChrgHigher: objdata.chrgHigher,
            Status: objdata.status,
            HSNCode: objdata.hsncode,
            GSTP: GSTP,
            SGSTRate: objdata.sgstrate,
            CGSTRate: objdata.cgstrate,
            IGSTRate: objdata.igstrate,
            CessRate: objdata.cessRate,
            SGSTInputAccID: objdata.sgstinputAccId,
            CGSTInputAccID: objdata.cgstinputAccId,
            IGSTInputAccID: objdata.igstinputAccId,
            CessInputAccID: objdata.cessInputAccId,
            SGSTOutputAccID: objdata.sgstoutputAccId,
            CGSTOutputAccID: objdata.cgstoutputAccId,
            IGSTOutputAccID: objdata.igstoutputAccId,
            CessOutputAccID: objdata.cessOutputAccId,
            Order: objdata.order,
          });
        }
        //
      },
      error => {
        console.log(error);
      });
    //End
  }
  ngOnInit(): void { }
  ngAfterViewInit() {
    //Focus Start
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    //End
  }
  SaveOrSubmitForm() {
    //
    this.submitted = true;
    if (this.form1?.invalid) {
      return;
    }
    //
    let GSTP: any = "0";
    var ActId = this.form1?.get('ActId')?.value;
    var GSTPf = Number(this.form1?.get('GSTP')?.value);
    try {
      if (this.TaxRateList.find((item: { taxType: any; }) => item.taxType == GSTPf) != undefined) {
        GSTP = (this.TaxRateList.find((item: { taxType: any; }) => item.taxType == GSTPf).taxName);
      }
    } catch { GSTP = "0"; }
    let myDateTime: any = new Date();
    let sqlTimeAsString = formatDate(new Date(), "yyyy-MM-dd", "en_US");
    this.form1?.patchValue({
      CreatedBy: Number(localStorage.getItem("Uid")),
      ModifiedBy: Number(localStorage.getItem("Uid")),
      ActId: Number(ActId),
      ChrgType: Number(this.form1?.get('ChrgType')?.value),
      ChrgStyle: Number(this.form1?.get('ChrgStyle')?.value),
      ChrgRate: Number(this.form1?.get('ChrgRate')?.value),
      ChrgGross: Number(this.form1?.get('ChrgGross')?.value),
      ChrgHigher: Number(this.form1?.get('ChrgHigher')?.value),
      GSTP: Number(GSTP),
      SGSTRate: Number(this.form1?.get('SGSTRate')?.value),
      CGSTRate: Number(this.form1?.get('CGSTRate')?.value),
      IGSTRate: Number(this.form1?.get('IGSTRate')?.value),
      CessRate: Number(this.form1?.get('CessRate')?.value),
      SGSTInputAccID: Number(this.form1?.get('SGSTInputAccID')?.value),
      CGSTInputAccID: Number(this.form1?.get('CGSTInputAccID')?.value),
      IGSTInputAccID: Number(this.form1?.get('IGSTInputAccID')?.value),
      CessInputAccID: Number(this.form1?.get('CessInputAccID')?.value),
      SGSTOutputAccID: Number(this.form1?.get('SGSTOutputAccID')?.value),
      CGSTOutputAccID: Number(this.form1?.get('CGSTOutputAccID')?.value),
      IGSTOutputAccID: Number(this.form1?.get('IGSTOutputAccID')?.value),
      CessOutputAccID: Number(this.form1?.get('CessOutputAccID')?.value),
      Order: Number(this.form1?.get('Order')?.value),
      CreatedDate: sqlTimeAsString,
      ModifiedDate: sqlTimeAsString
    });
    //console.log(this.form1?.value);
    if (this.form1?.invalid) {
      return;
    }
    this.httpClient.post(this._services.APIUrl + '/InsertIqcharges', this.form1?.value).subscribe(res => {
      var result: any = res;
      if (result.msg.toString().includes('Successfull')) {
        Swal.fire('Charge Creation', result.msg.toString(), 'success');
        this.clearform();
      }
    },
      error => {
        console.log('error c');
        console.log(error);
        this.clearform();
      });
  }
  clearform() {
    let myDateTime: any = new Date();
    let sqlTimeAsString = formatDate(new Date(), "yyyy-MM-dd", "en_US");
    this.form1?.patchValue({
      CreatedBy: Number(localStorage.getItem("Uid")),
      ModifiedBy: Number(localStorage.getItem("Uid")),
      ActId: 0,
      ChrgType: 0,
      ChrgStyle: 0,
      ChrgRate: 0,
      ChrgGross: 1,
      ChrgHigher: 0,
      GSTP: 0,
      SGSTRate: 0,
      CGSTRate: 0,
      IGSTRate: 0,
      CessRate: 0,
      SGSTInputAccID: 0,
      CGSTInputAccID: 0,
      IGSTInputAccID: 0,
      CessInputAccID: 0,
      SGSTOutputAccID: 0,
      CGSTOutputAccID: 0,
      IGSTOutputAccID: 0,
      CessOutputAccID: 0,
      Order: 0,
      CreatedDate: sqlTimeAsString,
      ModifiedDate: sqlTimeAsString,
      ChrgId: 0,
      ChrgName: '',
      Status: true,
      HSNCode: '',
    });
    this.titletextneworedit = "New";
    this.submitted = false;
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
  }
  btnbindgstaccounts() {
    this.form1?.patchValue({
      SGSTRate: 0,
      CGSTRate: 0,
      IGSTRate: 0,
      CessRate: 0,
      SGSTInputAccID: 0,
      CGSTInputAccID: 0,
      IGSTInputAccID: 0,
      CessInputAccID: 0,
      SGSTOutputAccID: 0,
      CGSTOutputAccID: 0,
      IGSTOutputAccID: 0,
      CessOutputAccID: 0,
    });
    //console.log(this.TaxRateList);
    var GSTPf = Number(this.form1?.get('GSTP')?.value);
    if (GSTPf == 0 || GSTPf == 1 || GSTPf == 2) { }
    else {
      let GSTP: any = "";
      try {
        if (this.TaxRateList.find((item: { taxType: any; }) => item.taxType == GSTPf) != undefined) {
          GSTP = (this.TaxRateList.find((item: { taxType: any; }) => item.taxType == GSTPf).taxName);
        }
      } catch { GSTP = "0"; }
      //console.log(Number(GSTP));
      this.form1?.patchValue({
        SGSTRate: (Number(GSTP) / 2),
        CGSTRate: (Number(GSTP) / 2),
        IGSTRate: Number(GSTP),
        CessRate: 0,
      });
      const request = {
        method: 'GET',
        action_url: 'BindVipOut?TaxPer=' + Number(GSTP)
      };
      this._services.doHttp(request).subscribe(
        res => {
          let resobj: any = res;

          this.form1?.patchValue({
            SGSTInputAccID: Number(resobj.sgstacts.actidip),
            CGSTInputAccID: Number(resobj.cgstacts.actidip),
            IGSTInputAccID: Number(resobj.igstacts.actidip),
            CessInputAccID: Number(resobj.cessacts.actidip),
            SGSTOutputAccID: Number(resobj.sgstacts.actidop),
            CGSTOutputAccID: Number(resobj.cgstacts.actidop),
            IGSTOutputAccID: Number(resobj.igstacts.actidop),
            CessOutputAccID: Number(resobj.cessacts.actidop),
          });
        },
        error => {
          console.log(error);
        });

    }

  }
}
