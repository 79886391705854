import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountCreationComponent } from './account/account-creation/account-creation.component';
import { AreaCreationComponent } from './Area/area-creation/area-creation.component';
import { BranchCreationComponent } from './branch/branch-creation/branch-creation.component';
import { ChargesComponent } from './charge/charges/charges.component';
import { HsnCreationComponent } from './HSN//hsn-creation/hsn-creation.component';
import { ItemCategoryComponent } from './Item Category/item-category/item-category.component';
import { ItemCreationComponent } from './Item/item-creation/item-creation.component';
import { ManufactureComponent } from './Manufacture/manufacture/manufacture.component';
import { SalesmanCreationComponent } from './salesman/salesman-creation/salesman-creation.component';
import { SizeCreationComponent } from './size//size-creation/size-creation.component';
import { TransactionTypesComponent } from './transaction-types/types/transaction-types.component';
import { UomCreationComponent } from './uom-creation/uom-creation.component';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { SharedModule } from '../../shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddnewAreaComponent } from './Area/addnew-area/addnew-area.component';
import { AddnewBranchComponent } from './branch/addnew-branch/addnew-branch.component';
import { NewItemComponent } from './Item/new-item/new-item.component';
import { NewSalesmanComponent } from './salesman/new-salesman/new-salesman.component';
import { NewChargeComponent } from './charge/new-charge/new-charge.component';
import { NewManufactureComponent } from './Manufacture/new-manufacture/new-manufacture.component';
import { NewItemCategoryComponent } from './Item Category/new-item-category/new-item-category.component';
import { NewAccountComponent } from './account/new-account/new-account.component';
import { NewSizeComponent } from './size/new-size/new-size.component';
import { NewHsnComponent } from './HSN/new-hsn/new-hsn.component';
import { NewTranTypeComponent } from './transaction-types/new-tran-type/new-tran-type.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },

      { path: 'createAccount', component: AccountCreationComponent },
      { path: 'newAccount', component: NewAccountComponent },

      { path: 'createBranch', component: BranchCreationComponent },
      { path: 'newBranch', component: AddnewBranchComponent },

      { path: 'createArea', component: AreaCreationComponent },
      { path: 'newArea', component: AddnewAreaComponent },

      { path: 'createHSN', component: HsnCreationComponent },
      { path: 'newHSN', component: NewHsnComponent },

      { path: 'createItem', component: ItemCreationComponent },
      { path: 'newItem', component: NewItemComponent },

      { path: 'createSalesman', component: SalesmanCreationComponent },
      { path: 'newSalesman', component: NewSalesmanComponent },

      { path: 'manufacture', component: ManufactureComponent },
      { path: 'newManufacture', component: NewManufactureComponent },

      { path: 'charges', component: ChargesComponent },
      { path: 'newcharges', component: NewChargeComponent },

      { path: 'itemsCategory', component: ItemCategoryComponent },
      { path: 'newItemCategory', component: NewItemCategoryComponent },



      { path: 'createSize', component: SizeCreationComponent },
      { path: 'newSize', component: NewSizeComponent },

      { path: 'createUOM', component: UomCreationComponent },
      { path: 'transactionTypes', component: TransactionTypesComponent },
      { path: 'newTrType', component: NewTranTypeComponent },


      { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  declarations:
    [
      DashboardComponent,
      SalesmanCreationComponent,
      AreaCreationComponent,
      HsnCreationComponent,
      ItemCreationComponent,
      NewItemComponent,
      ItemCategoryComponent,
      ManufactureComponent,
      ChargesComponent,
      TransactionTypesComponent,
      AccountCreationComponent,
      BranchCreationComponent,
      SizeCreationComponent,
      UomCreationComponent,
      MainComponent,
      AddnewAreaComponent,
      AddnewBranchComponent,
      NewManufactureComponent,
      NewAccountComponent,
      NewSizeComponent,
      NewHsnComponent,
      NewTranTypeComponent,
      NewChargeComponent,
      NewSalesmanComponent,      
    ],
  imports:
    [
    RouterModule.forChild(routes),
    SharedModule,
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      BsDatepickerModule.forRoot(),
    ],
  exports: [RouterModule]
})
export class MastersModule { }
