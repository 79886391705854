<div class="block-header">
  <div class="row">
    <div class="col-lg-5 col-md-6 col-sm-12 align-self-center">
      <div class="searchBlockheader d-flex">
        <div>
          <span class=""></span>
        </div>
        <!-- <input type="text" placeholder="Search here..."> -->
      </div>
    </div>
    <div class="col-lg-7 col-md-6 col-sm-12 text-right">
      <div class="rightHeaderNav d-none">
        <a href="javascript:void(0)" class="d-none">
          <span class="icon-comment-o icomoon"></span>
        </a>
        <a href="javascript:void(0)" class="position-relative countLink">
          <span class="icon-bell-o icomoon"></span>
          <span class="position-absolute translate-middle bg-danger border border-light rounded-circle numb">12</span>
        </a>
        <a [routerLink]="['/login']" class="logoutNav">
          <span class="icon-power-off icomoon"></span> Logout
        </a>
      </div>
      <button class="btn btn-primary btn-icon mobile_menu"
              type="button"
              (click)="mobileViewSideBar($event)">
        <i class="zmdi zmdi-sort-amount-desc"></i>
      </button>
    </div>
  </div>
</div>
