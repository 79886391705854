import { Component, ElementRef, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from '../../../../http.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-order-entry',
  templateUrl: './order-entry.component.html',
  styleUrls: ['./order-entry.component.scss']
})
export class OrderEntryComponent implements OnInit {
  form1: FormGroup | undefined;
  isShown: boolean = false;
  itemlist: any=[];
  BranchList: any = [];
  Fromdate: any = "12-Jan-2021";
  Todate: any = "12-Jan-2021";
  filteredAccountCodeList: any = [];
  flagAccountCode: any = false;
  filteredAccountNameList: any = [];
  flagAccountName: any = false;
  actidtitle: any = "0"; accountList: any = [];
  public btntextNew = { state: { id: 0, text: 'New', obj: null } };
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private datePipe: DatePipe, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    let previousmonth = new Date();
    this.Fromdate = previousmonth;
    this.Todate = previousmonth;
    let todaydatestr: any = ""; todaydatestr= formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.form1 = this._fb.group({
      BranchID: [0],
      Fromdt: [todaydatestr],
      Todt: [todaydatestr],
      ActID: [0],
      AccountCode: '',
      AccountName: '',
    });
    this.bindBranchs();    
    this.SearchOrSubmitForm();

    const request = {
      method: 'GET',
      action_url: 'GetCustomerEnquiryload?compid=0&fyid=0'
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.accountList = resobj.listaccounts;
      },
      error => {
        console.log(error);
      });

  }  
  ngOnInit(): void {
   
  }
  onStartDateTimeChange(dt: any) {
    this.Fromdate = dt;
    let dtS = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      Fromdt: dtS
    });
  }
  onStartDateTimeChange2(dt: any) {
    this.Todate = dt;
    let dtS = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      Todt: dtS
    });
  }
  bindlist() {
    const request = {
      method: 'GET',
      action_url: 'GetOrderDetails'
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.itemlist = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  bindBranchs() {
    const request = {
      method: 'GET',
      action_url: 'GetBranch'
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.BranchList = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  editformbyid(item: any) {
    this.router.navigateByUrl('/neworder', { state: { id: item.docId, text: 'Edit', obj: item } });
  }
  deleteformbyid(item: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        //      
        var jsondata = { id: Number(item) };
        this.httpClient.post(this._services.APIUrl + '/DeleteOrderEntry', Number(item)).subscribe(res => {
          var result: any = res;

          if (result.msg != null) {
            if (result.msg.toString().includes('Successfull')) {
              Swal.fire('Deleted!', result.msg.toString(), 'success');
              this.bindlist();
            }
            else {
              Swal.fire('Failed to Delete!', result.msg.toString(), 'error');
            }
          }
        },
          error => {
            console.log(error);
          });
        //
      }
    });
  }


  onselectAccountName(item: any, isname: any) {
    this.actidtitle = item.actId.toString();
    this.form1?.patchValue({
      AccountCode: item.actCode,
      AccountName: item.actName,
      ActID: item.actId.toString(),
    });
    this.flagAccountName = false;
  }
  changeAccountName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredAccountNameList = [];
      this.flagAccountName = false;
    }
  }
  clickAccountName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredAccountNameList = [];
      this.flagAccountName = false;
    }
  }
  keypressAccountName(event: any, isname: any) {
    this.form1?.patchValue({
      ActID: "0",
    });
    if (event.target.value == "") {
      this.filteredAccountNameList = [];
      if (isname == 0) {
        this.flagAccountName = false;
      }
      else {
        this.flagAccountName = false;
      }
    }
    else {
      this.flagAccountName = false;
      this.filteredAccountNameList = this.accountList.filter((x: { actType: any; actName: any; }) => x.actName.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredAccountNameList != null) {
        if (this.filteredAccountNameList.length > 0) {
          this.flagAccountName = true;
        }
      }
    }
  }
  onselectAccountCode(item: any, isname: any) {
    this.actidtitle = item.actId.toString();
    console.log(this.actidtitle);
    this.form1?.patchValue({
      AccountCode: item.actCode,
      AccountName: item.actName,
      ActID: item.actId.toString(),
    });
    this.flagAccountCode = false;
  }
  changeAccountCode(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredAccountCodeList = [];
      this.flagAccountCode = false;
    }
  }
  clickAccountCode(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredAccountCodeList = [];
      this.flagAccountCode = false;
    }
  }
  keypressAccountCode(event: any, isname: any) {
    this.form1?.patchValue({
      ActID: "0",
    });
    console.log(event.target.value);
    if (event.target.value == "") {
      this.filteredAccountCodeList = [];
      if (isname == 0) {
        this.flagAccountCode = false;
      }
      else {
        this.flagAccountCode = false;
      }
    }
    else {
      this.flagAccountCode = false;
      this.filteredAccountCodeList = this.accountList.filter((x: { actType: any; actCode: any; }) => x.actCode.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredAccountCodeList != null) {
        if (this.filteredAccountCodeList.length > 0) {
          this.flagAccountCode = true;
        }
      }
    }
  }


  SearchOrSubmitForm() {
    let brid = 0; brid = Number(this.form1?.get('BranchID').value);
    if (isNaN(brid)) {
      brid = 0;
    }   
    //Fromdt
    let Fromdate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');    
    var Fromdt = this.form1?.get('Fromdt').value;
    if (Fromdt != "") {
      let Fromdtf: any = Fromdt.slice(0, 10);
      Fromdate =formatDate(new Date(Fromdtf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    }
    //end Fromdt
    let todate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    let ActID: any = Number(this.form1?.get('ActID').value);
    var Todt = this.form1?.get('Todt').value;
    if (Todt != "") {
      let Todtf: any = Todt.slice(0, 10);
      todate = formatDate(new Date(Todtf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    }
    //end todate

    const request = {
      method: 'GET',
      action_url: 'GetOrderDetails?brid=' + brid + '&Fdate=' + Fromdate + '&Tdate=' + todate + '&ActID=' + ActID + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.itemlist = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
    
  }
  reloadComponent() {
    localStorage.setItem("isrefresh", "1");
    this.router.navigateByUrl('/login');
  }
}
