import { Component, ElementRef, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from 'src/app/http.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
declare var require: any;
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  orderscount: any = 0;
  trailscount: any = 0;
  readytodelvcount: any = 0;
  devlcount: any = 0;
  ordercount: any = 0;
  receiptcount: any = 0;
  paymentcount: any = 0;
  cuttstockcount: any = 0;
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private datePipe: DatePipe, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    //when page Load function
    const request = {
      method: 'GET',
      action_url: 'Getdashboardload'
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        if (resobj != null) {
          this.orderscount = resobj.orderscount;
          this.trailscount = resobj.trailscount;
          this.readytodelvcount = resobj.readytodelvcount;
          this.devlcount = resobj.devlcount;
          this.ordercount = resobj.ordercount;
          this.receiptcount = resobj.receiptcount;
          this.paymentcount = resobj.paymentcount;
          this.cuttstockcount = resobj.cuttstockcount;
        }        
      },
      error => {
        console.log(error);
      });
  }
  ngOnInit(): void {

  }
  clearall() {
    this.orderscount = 0;
    this.trailscount = 0;
    this.readytodelvcount =0;
    this.devlcount = 0;
    this.ordercount = 0;
    this.receiptcount = 0;
    this.paymentcount =0;
    this.cuttstockcount = 0;
  }

}
