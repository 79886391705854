<div class="contentMain" style="margin-top:-18px;">
  <div class="row">
    <div class="col-md-12 col-lg-12 col-sm-12 mainheader header-container">
      <h5>Cash Book</h5>
    </div>
  </div>
  <main class="row">
    <div class="card">
      <form class="form" [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm($event)">
        <div class="card-body">
          <div class="row" style="margin-top:-20px;">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="border p-3 h-100">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Branches</label>
                      <select class="form-control" (change)="changeBranch($event)" formControlName="BranchID" #myname>
                        <option [value]="0">Select ALL</option>
                        <option *ngFor="let Brlist of BranchList" [value]="Brlist.branchId">{{Brlist.branchName }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Stock Point</label>
                      <select class="form-control" formControlName="SpId">
                        <option [value]="0">Select ALL</option>
                        <option *ngFor="let item of SPList" [value]="item.spid">{{item.spname }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Date Range</label>
                      <div class="input-group">
                        <input type="text" name="daterange" autocomplete="off" bsDaterangepicker [datesEnabled]="enabledDates"
                               [(ngModel)]="bsRangeValue" [ngModelOptions]="{standalone: true}" [bsConfig]="{ rangeInputFormat: 'DD-MMM-YYYY', showWeekNumbers: false,isAnimated: true }"
                               id="DateOfBirth" placeholder="Date Range" (bsValueChange)="dateRangeCreated($event)" class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Report Style</label>
                      <select class="form-control">
                        <option>Detailed</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="border p-3 h-100">
                <div class="row">
                  <!--<div class="col-lg-5 col-md-5 col-sm-5">
                    <div class="form-group">
                      <label title="{{actidtitle}}">A/C Code</label>
                      <div class="form-group">
                        <input type="text" formControlName="AccountCode" placeholder="A/C Code" class="form-control" (input)="keypressAccountCode($event,0)" (change)="changeAccountCode($event,0)" (click)="clickAccountCode($event,0)" style="width: 100%; font-size: 11px;" />
                        <div class="combobox-options" *ngIf="filteredAccountCodeList.length>0 && flagAccountCode===true">
                          <ul style=" width:100%;">
                            <li *ngFor="let item of filteredAccountCodeList" (click)="onselectAccountCode(item,0)">
                              <a>{{item.actCode}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>-->
                  <!--div class="col-lg-7 col-md-7 col-sm-7">
                    <div class="form-group">
                      <label>A/C Name</label>
                      <div class="form-group">
                        <select class="form-control" formControlName="AccountName">
                          <option [value]="0">Select ALL</option>
                          <option *ngFor="let item of filteredAccountNameList" [value]="item.actId">{{item.AccountName }}</option>
                        </select>-->
                        <!--<input type="text" formControlName="AccountName" placeholder="A/C Name" class="form-control" (input)="keypressAccountName($event,0)" (change)="changeAccountName($event,0)" (click)="clickAccountName($event,0)" style="width: 100%; font-size: 11px;" />
  <div class="combobox-options" *ngIf="filteredAccountNameList.length>0 && flagAccountName===true">
    <ul style=" width:100%;">
      <li *ngFor="let item of filteredAccountNameList" (click)="onselectAccountName(item,'Cash on Hand')">
        <a>{{item.actName}}</a>
      </li>
    </ul>
  </div>-->
                      <!--</div>
                    </div>
                  </div>-->
                  <!--<div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group">
                      <label>Format</label>
                      <select class="form-control">
                        <option [value]="0">Format 1</option>
                        <option [value]="1">Format 2</option>
                      </select>
                    </div>
                  </div>-->
                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="row">
                      <div class="col-lg-2 col-md-2 col-sm-2"></div>
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="form-group">
                          <label style="margin-top:50px"></label>
                          <button class="btn redbtn" type="submit">Show</button>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                          <label style="margin-top:50px"></label>
                          <!--<button class="btn redbtn" type="submit">Show & Print</button>-->
                          <button class="btn redbtn" printTitle="Print Title" styleSheetFile="{{cssPrintingPath}}"
                                  [printStyle]="{h4 : {'text-align': 'center'}, span : {'font-size': 'small'}}"
                                  printSectionId="print-section" ngxPrint>
                            Print
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row" style="margin-top:-20px;" id="print-section">
            <div style="width: 100%; border: 0px solid black;margin-top:-5px;">
              <table class="daywiseheadtable" style="width: 98%; border: 0px solid black;">
                <thead>
                  <tr>
                    <th style="text-align: center;width:100%;">{{CompName}}</th>
                  </tr>
                  <tr>
                    <th style="text-align: center;width:100%;">{{Address1}}</th>
                  </tr>
                  <tr>
                    <th style="text-align: center;width:100%;">{{title}} </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div class="col-md-12 table-responsive">
              <table class="daywisetable" style="width: 98%; border: 1px solid black;">
                <thead>
                  <tr>
                    <th style="border: 1px solid black;">Date</th>
                    <th style="border: 1px solid black;">Account Name</th>
                    <th style="border: 1px solid black;text-align:center">Receipt</th>
                    <th style="border: 1px solid black;text-align:center">Payment</th>
                    <th style="border: 1px solid black; text-align: center">R.Balance</th>
                 
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of itemlist;let i=index;">
                    <td style="border-left: 1px solid black;">
                      <span *ngIf="item.rbal === ''" style="font-weight: bold; font-size: medium; ">{{item.docDate }}</span>
                      <span *ngIf="item.narration === '1'" style=" font-size: medium; ">{{item.docDate }}</span>

                     
                    </td>
                   <td style="border-left: 1px solid black;">
                     <span *ngIf="item.accountname === 'Opening Balance'" style="font-weight: bold; font-size: small;">{{item.accountname }}</span>
                     <span *ngIf="item.accountname === 'Closing Balance'" style="font-weight: bold; font-size: small;">{{item.accountname }}</span>
                     <span *ngIf="item.accountname === 'Total Balance'" style="font-weight: bold; font-size: small;">{{item.accountname }}</span>
                     <span *ngIf="item.narration === '1'" style="font-size: medium;">{{item.accountname }}</span>
                     <span *ngIf="item.rbal === ''" style="font-style:italic;font-size:small;">{{item.accountname }}</span>
                   </td>
                   <td style="border-left: 1px solid black;text-align:right">
                     <span *ngIf="item.accountname === 'Opening Balance'" style="font-weight: bold; font-size: small;">{{item.receipt }}</span>
                     <span *ngIf="item.accountname === 'Closing Balance'" style="font-weight: bold; font-size: small;">{{item.receipt }}</span>
                     <span *ngIf="item.accountname === 'Total Balance'" style="font-weight: bold; font-size: small;">{{item.receipt }}</span>
                     <span *ngIf="item.narration === '1'" style="font-size: medium;">{{item.receipt }}</span>
                   </td>
                   <td style="border-left: 1px solid black; text-align: right">
                     <span *ngIf="item.accountname === 'Opening Balance'" style="font-weight: bold; font-size: small;">{{item.payment }}</span>
                     <span *ngIf="item.accountname === 'Closing Balance'" style="font-weight: bold; font-size: small;">{{item.payment }}</span>
                     <span *ngIf="item.accountname === 'Total Balance'" style="font-weight: bold; font-size: small;">{{item.payment }}</span>
                     
                     <span *ngIf="item.narration === '1'" style="font-size: medium;">{{item.payment }}</span>

                   </td>
                   <td style="border-left: 1px solid black; text-align: right">
                     <span *ngIf="item.accountname === 'Opening Balance'" style="font-weight: bold; font-size: small;">{{item.rbal }}</span>
                     <span *ngIf="item.accountname === 'Closing Balance'" style="font-weight: bold; font-size: small;">{{item.rbal }}</span>
                     <span *ngIf="item.accountname === 'Total Balance'" style="font-weight: bold; font-size: small;">{{item.rbal }}</span>
                     <span *ngIf="item.narration === '1'" style="font-size: medium;">{{item.rbal }}</span>
                   </td>
                  </tr>
                  <tr *ngIf="itemlist.length===0">
                    <td colspan="11" style=" border-top: 1px solid black;" class="text-center text-danger">No Data Found for Selection Criteria</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
</div>
