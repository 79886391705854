import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.checkEvents();
  }

  checkEvents() {
    this.router.events.subscribe(() => {
      this.removeSidebar();
    });
  }

  removeSidebar() {
    document.getElementById('leftsidebar')?.classList.remove('open');
  }

  mobileViewSideBar(event: any) {
    event.stopImmediatePropagation();
    document.getElementById('leftsidebar')?.classList.toggle('open');
  }

}
