import { Component, ElementRef, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from '../../../../http.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cut-master',
  templateUrl: './cut-master.component.html',
  styleUrls: ['./cut-master.component.scss']
})
export class CutMasterComponent implements OnInit {

  form1: FormGroup | undefined;
  isShown: boolean = false;
  cutterorstitcheName: any = "";
  itemlist: any = []; itemlist2: any = []; actidtitle = "0";
  BranchList: any = []; SPListFrom: any = []; SPListTo: any = [];
  cuttstichList: any = []; filteredList: any = [];
  flag: any = false;
  flag2: any = false;
  Fromdate: any = "12-Jan-2021";
  Todate: any = "12-Jan-2021";
  public btntextNew = { state: { id: 0, text: 'New', obj: null } };
  editid = 0;
  isstitching: any = 0; HeadingText: any = "";
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private datePipe: DatePipe, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    let objdata: any = null;
 
    if (this.router.getCurrentNavigation() == null) {
    }
    else {
      if (this.router.getCurrentNavigation().extras != undefined) {
        if (this.router.getCurrentNavigation().extras.state != undefined) {
          if (this.router.getCurrentNavigation().extras.state.id != undefined) {
            this.isstitching = (Number(this.router.getCurrentNavigation().extras.state.id));
            if (this.isstitching == 1) {
              this.HeadingText = " - STITCHING";
            }
            else {
              this.HeadingText = " - CUTTING";
            }
          }
        }
      }
    }
    let previousmonth = new Date();
    this.Fromdate = previousmonth;
    this.Todate = previousmonth;
    let todaydatestr: any = ""; todaydatestr = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.form1 = this._fb.group({
      BranchID: [0],
      SpId: [0],
      ToSPId: [0],
      fromspname: [''],
      tospname: [''],
      AccountName: [''],
      AccountCode:[''],
      actname: [''],
      actcode: [''],
      mobileno: [''],
      status: [0],
      Fromdt: [todaydatestr],
      Todt: [todaydatestr],    
    });
    this.SearchOrSubmitForm(0);
  }
  ngOnInit(): void {
  }
  navigatepage() {
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/New delivery-to-cut'], { state: { id: this.isstitching, text: 'Edit', obj: null } });
    });
  }
  SearchOrSubmitForm(isnotload: any) {

    let fromspid = Number(this.form1?.get('SpId').value);
    let tospid = Number(this.form1?.get('ToSPId').value);
    let fromspname: any = ""; let tospname: any = "";
    try {
      fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
    } catch { fromspname = ""; }
    try {
      tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
    } catch { tospname = ""; }
    this.form1?.patchValue({
      fromspname: fromspname,
      tospname: tospname,
    });
    if (fromspid != 0 || tospid != 0) {
      if (Number(this.form1?.get('SpId').value) == Number(this.form1?.get('ToSPId').value)) {
        this.itemlist = [];
        return;
      }
    }
    if ((fromspname.includes('CUTTING') || fromspname.includes('STITCHING')) && (tospname.includes('CUTTING') || tospname.includes('STITCHING'))) {
      this.itemlist = [];
      return;
    }
    let actcode = (this.form1?.get('AccountCode').value);
    let actname = (this.form1?.get('AccountName').value);
    //Fromdt
    let Fromdate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    var Fromdt = this.form1?.get('Fromdt').value;
    if (Fromdt != "") {
      let Fromdtf: any = Fromdt.slice(0, 10);
      Fromdate = formatDate(new Date(Fromdtf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    }
    //end Fromdt
    let todate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    var Todt = this.form1?.get('Todt').value;
    if (Todt != "") {
      let Todtf: any = Todt.slice(0, 10);
      todate = formatDate(new Date(Todtf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    }
    //end todate
    const request = {
      method: 'GET',
      action_url: 'Getp2ptransfer?isnotload=' + Number(isnotload) + ' &fromspid=' + fromspid + '&tospid=' + tospid + '&Fdate=' + Fromdate + '&Tdate=' + todate + '&actcode=' + actcode + '&actname=' + actname + '&fromspname=' + fromspname + '&tospname=' + tospname + '&isstitching=' + this.isstitching + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res == null) {
          this.itemlist = [];
        }
        else {
          let resobj: any = res;
          if (resobj.list == null) {
            this.itemlist = [];
          }
          else {
            this.itemlist = resobj.list;
          }

          if (Number(isnotload) == 0) {
            let spid: any = Number(resobj.spId);
            let ToSPId: any = Number(resobj.toSpid);
            this.form1?.patchValue({
              SpId: spid,
              ToSPId: ToSPId,
            });
            this.cuttstichList = resobj.listaccounts;
            this.SPListFrom = resobj.liststockpointsfrom;
            this.SPListTo = resobj.liststockpointsto;
            if (this.cuttstichList != null) {
              if (this.cuttstichList.length > 0) {
                this.filteredList = this.cuttstichList.filter((x: { actType: any; }) => (x.actType == "Cutting" || x.actType == "Stitching"));
              }
            }
          }

        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  displayStyle = "none";
  openPopup(id: any, actCode: any, actName: any) {
    this.itemlist2 = [];
    if (Number(id) == 0) {
      this.itemlist2 = [];
      return;
    }
    const request = {
      method: 'GET',
      action_url: 'Getp2ptransferViewItems?id=' + id
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        if (resobj != null) {
          this.itemlist2 = resobj;          
        }
        else {
          this.itemlist2 = [];
        }
        this.cutterorstitcheName = actCode + ' - ' + actName;
        this.displayStyle = "block";
      },
      error => {
        console.log(error);
      });  
  }
  closePopup() {
    this.displayStyle = "none";
  }
  bindlist() {
    const request = {
      method: 'GET',
      action_url: 'Getp2ptransfer'
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res == null) {
          this.itemlist = [];
        }
        else {
          let resobj: any = res;
          if (resobj.list == null) {
            this.itemlist = [];
          }
          else {
            this.itemlist = resobj.list;
          }
          let spid: any = 0;// Number(resobj.spId);
          let ToSPId: any = 0;// Number(resobj.toSpid);
          this.form1?.patchValue({
            SpId: spid,
            ToSPId: ToSPId,
          });
          this.cuttstichList = resobj.listaccounts;
          this.SPListFrom = resobj.liststockpointsfrom;
          this.SPListTo = resobj.liststockpointsto;
          if (this.cuttstichList != null) {
            if (this.cuttstichList.length > 0) {
              this.filteredList = this.cuttstichList.filter((x: { actType: any; }) => (x.actType == "Cutting" || x.actType == "Stitching"));
            }
          }
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  //Filter Header Account Code/Name
  onselectAccountCodeName(item: any, isname: any) {
    let iscutting: any = 0;
    this.clearaccountdetails();
    this.form1?.patchValue({
      AccountCode: item.actCode,
      AccountName: item.actName,    
    });
    this.actidtitle = item.actId.toString();
    this.flag = false; this.flag2 = false;
    let AccountCode = this.form1?.get('AccountCode').value;
    let AccountName = this.form1?.get('AccountName').value;
    if ((AccountCode.length == 0) && (AccountName.length == 0)) {
      return;
    }
    //Start
    let fromspname: any = ""; let tospname: any = "";
    try {
      fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
    } catch { fromspname = ""; }
    try {
      tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
    } catch { tospname = ""; }
    //
    if ((fromspname.includes('CUTTING')) && tospname.includes('HO')) {
      iscutting = 1;
    }
    if ((fromspname.includes('STITCHING')) && tospname.includes('HO')) {
      iscutting = 2;
    }
    //End
      const request = {
        method: 'GET',
        action_url: 'GetActUsingCodeOrName?Code=' + AccountCode + '&Name=' + AccountName + '&iscutting=' + iscutting + ''
      };
      this._services.doHttp(request).subscribe(
        res => {
          let resobj: any = res;
          if (resobj != null) {
            if (resobj.length > 0) {
              this.form1?.patchValue({
                AccountCode: resobj[0].actCode,
                AccountName: resobj[0].actName,               
              });
              this.actidtitle = Number(resobj[0].actId).toString();
            }
          }
        },
        error => {
          console.log(error);
        });
  }
  changeAccountCodeName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredList = [];
      this.flag = false;
    }
  }
  focusoutAccountCodeName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredList = [];
      this.flag = false;
    }
  }
  clickAccountCodeName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredList = [];
      this.flag = false;
    }
  }
  keypressAccountCodeName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredList = [];
      if (isname == 0) {
        this.flag = false;
      }
      else {
        this.flag2 = false;
      }
    }
    else {
      let iscutting: any = 0;
      //Start
      let fromspname: any = ""; let tospname: any = "";
      try {
        fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
      } catch { fromspname = ""; }
      try {
        tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
      } catch { tospname = ""; }
      //
      if (((fromspname.includes('CUTTING')) && tospname.includes('HO')) || ((fromspname.includes('HO')) && tospname.includes('CUTTING'))) {
        iscutting = 1;
      }
      if (((fromspname.includes('STITCHING')) && tospname.includes('HO')) || ((fromspname.includes('HO')) && tospname.includes('STITCHING'))) {
        iscutting = 2;
      }
      //End
      this.actidtitle = "0";
      this.flag = false; this.flag2 = false;
      if (isname == 0) {
        //this.form1?.patchValue({
        //  AccountName: "",     
        //});
        if (this.cuttstichList.length > 0) {
          if (iscutting == 0) {
            this.filteredList = this.cuttstichList.filter((x: { actType: any; actCode: any; }) => (x.actType == "Cutting" || x.actType == "Stitching") && x.actCode.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
          }
          else {
            this.filteredList = this.cuttstichList.filter((x: { actType: any; actCode: any; }) => x.actType == (iscutting == 2 ? "Stitching" : "Cutting") && x.actCode.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));            
          }
          if (this.filteredList != null) {
            if (this.filteredList.length > 0) {
              this.flag = true;
            }
          }
        }
        else {
          this.filteredList = [];
        }
      }
      else {
        //this.form1?.patchValue({
        //  AccountCode: "",        
        //});
        if (this.cuttstichList.length > 0) {
          if (iscutting == 0) {
            this.filteredList = this.cuttstichList.filter((x: { actType: any; actName: any; }) => (x.actType == "Cutting" || x.actType == "Stitching") && x.actName.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
          }
          else {
            this.filteredList = this.cuttstichList.filter((x: { actType: any; actName: any; }) => x.actType == (iscutting == 2 ? "Stitching" : "Cutting") && x.actName.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));            
          }
          if (this.filteredList != null) {
            if (this.filteredList.length > 0) {
              this.flag2 = true;
            }
          }

        }
      }
    }

  }
  //Filter Header Account Code/Name
  clearaccountdetails() {
    this.form1?.patchValue({
      AccountName: "",
      AccountCode: "",      
    });
    this.actidtitle = "0";
  }
  changeSp(event: any, isfrombr: any) {
    this.clearaccountdetails();
    //Start
    let fromspname: any = ""; let tospname: any = "";
    try {
      fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
    } catch { fromspname = ""; }
    try {
      tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
    } catch { tospname = ""; }
    //
    let actType: any = "";
    if ((fromspname.includes('CUTTING') && tospname.includes('HO')) || (fromspname.includes('HO') && tospname.includes('CUTTING'))) {
      actType = "Cutting";
    }
    else if ((fromspname.includes('STITCHING') && tospname.includes('HO')) || (fromspname.includes('HO') && tospname.includes('STITCHING'))) {
      actType = "Stitching";
    }
    if (this.cuttstichList != null) {
      if (this.cuttstichList.length > 0) {
        if (actType == "") {
          this.filteredList = this.cuttstichList.filter((x: { actType: any; actCode: any; }) => (x.actType == "Cutting" || x.actType == "Stitching"));
        }
        else {
          this.filteredList = this.cuttstichList.filter((x: { actType: any; }) => x.actType == actType);
        }
      }
    }
    //End
  }
  onStartDateTimeChange(dt: any) {
    this.Fromdate = dt;
    let dtS = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      Fromdt: dtS
    });
  }
  onStartDateTimeChange2(dt: any) {
    this.Todate = dt;
    let dtS = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      Todt: dtS
    });
  }
  editformbyid(item: any) {
    this.router.navigateByUrl('/new-delivery', { state: { id: item.docId, text: 'Edit', obj: item } });
  }
  deleteformbyid(item: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        var jsondata = { tno: item.tno, tseries: item.tseries };
        this.httpClient.post(this._services.APIUrl + '/DeleteP2PTransferEntry', jsondata).subscribe(res => {
          var result: any = res;

          if (result.msg != null) {
            if (result.msg.toString().includes('Successfull')) {
              Swal.fire('Deleted!', result.msg.toString(), 'success');
              this.SearchOrSubmitForm(1);
            }
            else {
              Swal.fire('Failed to Delete!', result.msg.toString(), 'error');
            }
          }
        },
          error => {
            console.log(error);
          });
        //
      }
    });

  }
}
