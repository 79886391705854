<div class="contentMain">
  <!-- main body -->
  <main class="row">
    <!-- card -->
    <div class="card">
      <div class="card-header mainheader header-container-btn">
        <h5>Bank Receipt NEW</h5>
        <button class="btn redbtn"
                [routerLink]="['/new-delivery']"
                type="button">
          Account Creation
        </button>
      </div>
      <!-- card body -->
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="border border-primary w-100 p-1">
              <h6 class="notice notice-success ml-3">
                Voucher Details
                <button class="btn redbtn" (click)="linkHide()" type="button">
                  Link Account
                </button>
              </h6>
              <div class="row">
                <div class="col-md-6 col-lg-6">
                  <!-- <div class="container "> -->
                  <!-- </div> -->
                  <form>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Division</label>
                      <div class="col-sm-8">
                        <select class="form-control form-control-sm">
                          <option selected disabled>Select Branch</option>
                          <option>Head Office</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Transaction</label>
                      <div class="col-sm-8">
                        <select class="form-control form-control-sm">
                          <option selected disabled>Select Stack</option>
                          <option>Head Office</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Salesman</label>
                      <div class="col-sm-8">
                        <select class="form-control form-control-sm">
                          <option selected disabled>Select Type</option>
                          <option>Head Office</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-2 col-form-label ml-4">Voucher</label>
                      <div class="col-sm-2" style="margin-left: -13px">
                        <input type="text"
                               class="form-control form-control-sm"
                               id="inputtext"
                               placeholder="series" />
                      </div>
                      <div class="col-sm-8" style="margin-left: -29px">
                        <select class="form-control form-control-sm">
                          <option selected disabled>Select Account</option>
                          <option>Head Office</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Narration</label>
                      <div class="col-sm-8">
                        <!-- <label>Customer Note</label> -->
                        <input class="form-control"
                               placeholder="Customer Note" />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-6 col-lg-6">
                  <!-- <div class="container pt-1"> -->
                  <!-- <h6 class="notice notice-success ml-3">Details</h6> -->
                  <!-- </div> -->
                  <form>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Branch</label>
                      <div class="col-sm-8">
                        <select class="form-control form-control-sm">
                          <option selected disabled>Select salesman</option>
                          <option>Head Office</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Trans Date</label>
                      <div class="col-sm-8">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="zmdi zmdi-calendar"></i>
                            </span>
                          </div>
                          <input type="text"
                                 class="form-control form-control-sm datepicker"
                                 placeholder="Bill Date" />
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Account</label>
                      <div class="col-sm-8">
                        <select class="form-control form-control-sm">
                          <option selected disabled>Select salesman</option>
                          <option>Head Office</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                  </form>
                  <div class="row pt-2">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <h6>Curent Balance</h6>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <h6>23,0004,777</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="container "> -->
              <!-- </div> -->

              <div class="row pt-1" *ngIf="linkAccount">
                <div class="col-lg-6 col-md-6">
                  <h6 class="notice notice-success ml-3">Link Accounts</h6>
                  <form>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Account</label>
                      <div class="col-sm-8">
                        <select class="form-control form-control-sm">
                          <option selected disabled>Select</option>
                          <option>Head Office</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Net Amount</label>

                      <div class="col-sm-8">
                        <input type="text"
                               class="form-control form-control-sm"
                               id="inputtext"
                               placeholder="series" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Town</label>
                      <div class="col-sm-8">
                        <input type="text"
                               class="form-control form-control-sm"
                               id="inputtext"
                               placeholder="PAN" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Narration</label>
                      <div class="col-sm-8">
                        <input type="text"
                               class="form-control form-control-sm"
                               id="inputtext"
                               placeholder="" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Pay Balance:</label>
                      <div class="col-sm-5">
                        <input type="text"
                               class="form-control form-control-sm"
                               id="inputtext"
                               placeholder="" />
                      </div>
                      <span class="col-sm-3">
                        <button class="btn redbtn"
                                (click)="linkHide()"
                                type="button">
                          Add
                        </button>
                      </span>
                    </div>
                  </form>
                </div>

                <div class="col-lg-6 col-md-6">
                  <h6 class="notice notice-success ml-3">Cheque /DD Details</h6>
                  <form>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Type</label>
                      <div class="col-sm-8">
                        <input type="text"
                               class="form-control form-control-sm"
                               id="inputtext"
                               placeholder="" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Cheque No</label>
                      <div class="col-sm-8">
                        <input type="text"
                               class="form-control form-control-sm"
                               id="inputtext"
                               placeholder="" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Cheque Date</label>
                      <div class="col-sm-8">
                        <input type="text"
                               class="form-control form-control-sm"
                               id="inputtext"
                               placeholder="" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Bank</label>
                      <div class="col-sm-8">
                        <input type="text"
                               readonly
                               class="form-control form-control-sm"
                               id="inputtext"
                               placeholder="" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="inputtext"
                             class="col-sm-3 col-form-label ml-4">Place</label>
                      <div class="col-sm-8">
                        <input type="text"
                               readonly
                               class="form-control form-control-sm"
                               id="inputtext"
                               placeholder="" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <!-- <div class="container pt-1"> -->
                  <h6 class="notice notice-success ml-3">
                    Add Voucher Details
                  </h6>
                  <!-- </div> -->
                  <div class="table-responsive">
                    <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
                      <thead>
                        <tr>
                          <th>
                            <input id="remember_me" type="checkbox" /> Code
                          </th>
                          <th>Account</th>
                          <th>Town</th>
                          <th>Net Amount</th>
                          <th>Edit Delete View</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input id="activestatus" type="checkbox" />
                          </td>
                          <td>Vijayawada</td>
                          <td>VJ2445</td>
                          <td>5465667</td>
                          <td>
                            <a [routerLink]="['/new-delivery']">
                              <img src="../../../../../assets/images/edit-small.png" />
                            </a>
                            <a>
                              <img src="../../../../../assets/images/delete.png" />
                            </a>
                            <a>
                              <img src="../../../../../assets/images/visibility.png" />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input id="activestatus" type="checkbox" />
                          </td>
                          <td>Vijayawada</td>
                          <td>VJ2445</td>
                          <td>5465667</td>
                          <td>
                            <a [routerLink]="['/new-delivery']">
                              <img src="../../../../../assets/images/edit-small.png" />
                            </a>
                            <a>
                              <img src="../../../../../assets/images/delete.png" />
                            </a>
                            <a>
                              <img src="../../../../../assets/images/visibility.png" />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input id="activestatus" type="checkbox" />
                          </td>
                          <td>Vijayawada</td>
                          <td>VJ2445</td>
                          <td>5465667</td>
                          <td>
                            <a [routerLink]="['/new-delivery']">
                              <img src="../../../../../assets/images/edit-small.png" />
                            </a>
                            <a>
                              <img src="../../../../../assets/images/delete.png" />
                            </a>
                            <a>
                              <img src="../../../../../assets/images/visibility.png" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="pagination-container">
                      <div class="show ml-8">
                        <span>Show</span>
                        <select class="ms form-control">
                          <option selected value="10">10</option>
                          <option value="25">25</option>
                        </select>
                      </div>
                      <div>
                        <nav aria-label="Page navigation example"
                             class="float-right mr-8">
                          <h5>Total Amount---0.00</h5>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- table -->
        </div>
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="button">F3-Save</button>
          <button class="btn redbtn" type="button">F3-Save&&Print</button>
          <button class="btn redbtn" type="button">Cancel</button>
        </div>
      </div>

      <!--/ card -->
      <!-- </main> -->
      <!-- main body -->
    </div>
  </main>
</div>
