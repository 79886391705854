import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-manufacture',
  templateUrl: './new-manufacture.component.html',
  styleUrls: ['./new-manufacture.component.scss']
})
export class NewManufactureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
