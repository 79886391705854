<button printTitle="Print Title" styleSheetFile="{{cssPrintingPath}}"
        [printStyle]="{h4 : {'text-align': 'center','font-weight': 'bold','font-size': 'large'}, span : {'font-size': 'small'}}"
        printSectionId="print-section" ngxPrint>
  Print
</button>
<div *ngIf="editid > 0 && itemwiseonly==0">
  <div id="print-section" style="height:90%;">
    <div style="width:100%;height:90%;">
      <h4>KIRAN TAILORS</h4>
      <table class="table" style="width:100%;">
        <thead>
          <tr>
            <th><label>Cust Id :</label></th>
            <td><label>{{actCode}}</label></td>
            <th><label>Order Date :</label></th>
            <td><label>{{docDate}}</label></td>
          </tr>
          <tr>
            <th><label></label></th>
            <td><label></label></td>
            <th><label>Trail Date :</label></th>
            <td><label>{{suppBilldate}}</label></td>
          </tr>
          <tr>
            <th><label>Order No :</label></th>
            <td><label>{{billNo}}</label></td>
            <th><label>Delivery Date :</label></th>
            <td><label>{{docDuedate}}</label></td>
          </tr>
        </thead>
      </table>
      <div *ngFor="let list of itemlist; let i = index">
        <div class="row">
          <table class="table" style="width:100%;">
            <thead>
              <tr>
                <th><label style="text-align:center;">{{list.itemname}}</label></th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="row">
          <table class="table" style="width:100%;">
            <tr *ngFor="let item of list.listsizes; let j = index;">
              <td *ngFor="let subitem of item.listssizes; let k = index;">
                <table style="width:100%;">
                  <tr>
                    <td *ngIf="subitem.sizeName.length==0; else elsecond1">
                      <span>
                        &nbsp;
                      </span>
                    </td>
                    <ng-template #elsecond1>
                      <td>
                        <span>
                          {{subitem.translateText}}
                        </span>
                      </td>
                    </ng-template>
                  </tr>
                  <tr>
                    <td *ngIf="(subitem.sizeValue.length==0 && subitem.sizeName.length==0); else elsecond2">
                      <span>
                        &nbsp;
                      </span>
                    </td>
                    <ng-template #elsecond2>
                      <td style="border-bottom:1px solid black;">
                        <span>
                          {{subitem.sizeValue}}
                        </span>
                      </td>
                    </ng-template>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="divfooter" style="width:100%;height:2%;">
      <table style="width:100%;">
        <tr>
          <td style="text-align:left;">{{todayWithPipe}}</td>
          <td style="text-align: right; padding-right: 10px">Signature</td>
        </tr>
      </table>
    </div>
  </div>
</div>

<!---------------------------------------------------------------------------------------------------------------------------------------------k-->
<div *ngIf="editid > 0 && itemwiseonly > 0">
  <div id="print-section">
    <div style="width: 100%;">
      <div style="width:100%;">
        <table class="" style="width: 98%;">
          <thead>
            <tr>
              <th style="text-align: center;">{{CompName}}</th>
            </tr>
            <tr>
              <th style="text-align: center;"><span>{{title}}</span></th>
            </tr>
          </thead>
        </table>
        <table class="" style="width: 98%;">
          <thead>
            <tr>
              <th style="text-align: center;font-weight:bold;font-size:medium;"><label style="text-decoration:underline;"> {{this.headingTitle}}</label></th>
            </tr>
          </thead>
        </table>
      </div>
      <table class="" style="width:100%;border:none;">
        <thead>
          <tr>
            <th style="text-align: left; font-size: small"><label>Cust Id & Name:</label></th>
            <td style="font-size:small"><label>{{actCode}}    &nbsp;&ndash;&nbsp;{{actName}}</label></td>
            <th style="text-align: left; font-size: small"><label>{{BillOrOrder}} No:</label></th>
            <td style="font-size:small"><label>{{billNo}}</label></td>
          </tr>
          <tr>
            <th style="text-align: left; font-size: small"><label>Town :</label></th>
            <td style="font-size:small"><label>{{acttownname}}</label></td>
            <th style="text-align: left; font-size: small"><label>{{BillOrOrder}} Date :</label></th>
            <td style="font-size:small"><label>{{docDate}}</label></td>
          </tr>
          <tr>
            <th style="text-align: left; font-size: small"><label></label></th>
            <td style="font-size:small"><label></label></td>
            <th style="text-align: left; font-size: small"><label>Trail Date :</label></th>
            <td style="font-size:small"><label>{{suppBilldate}}</label></td>
          </tr>
          <tr>
            <th style="text-align: left; font-size: small"><label>Ph :</label></th>
            <td style="font-size:small"><label>{{actmob1}}</label></td>
            <th style="text-align: left; font-size: small"><label>{{docDuedateCaption}}</label></th>
            <td style="font-size:small"><label>{{docDuedate}}</label></td>
          </tr>
        </thead>
      </table>
      <table style="width: 98%; border: 1px solid black;">
        <thead>
          <tr>
            <th style="text-align: left; width: 10%; border: 1px solid black;">S.No</th>
            <th style="text-align: left; width: 60%; border: 1px solid black;">Item Name</th>
            <th style="text-align: right; width: 10%; border: 1px solid black;">Qty</th>
            <th style="text-align: right; width: 10%; border: 1px solid black;">Rate</th>
            <th style="text-align: right; width: 10%; border: 1px solid black;">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let list of itemlist; let i = index">
            <td style="text-align: left; width: 10%;border: 1px solid black;font-size:small"><label>{{i+1}}</label></td>
            <td style="text-align: left; width: 60%;border: 1px solid black;font-size:small"><label>{{list.itemname}}</label></td>
            <td style="text-align: right; width: 10%; border: 1px solid black; font-size: small"><label>{{list.qty | currency:'INR':''}}</label></td>
            <td style="text-align: right; width: 10%; border: 1px solid black; font-size: small"><label>{{list.rate | currency:'INR':''}}</label></td>
            <td style="text-align: right; width: 10%; border: 1px solid black; font-size: small"><label>{{list.netValue | currency:'INR':''}}</label></td>
          </tr>
        </tbody>
      </table>
      <div style="width: 100%;">
        <table style="width:98%;">
          <tr>
            <th style="text-align: right; width: 10%;">&nbsp;</th>
            <th style="text-align: left; width: 60%;">&nbsp;</th>
            <th style="text-align: right; width: 10%;">Total </th>
            <th style="text-align: right; width: 10%;">Amt:</th>
            <th style="text-align: right; width: 10%;">{{totnetvalue | currency:'INR':''}}</th>
          </tr>
        </table>
      </div>
      <div style="width:100%;">
        <table class="" style="width: 98%; border: 1px solid black;">
          <thead>
            <tr>
              <th style="text-align: right;"><label>NOS :</label></th>
              <td style="text-align: center;"><label>{{totitemscount | currency:'INR':''}}</label></td>
              <th style="text-align: right; "><label>Total Qty :</label></th>
              <td style="text-align: center; "><label>{{totqty | currency:'INR':''}}</label></td>
              <th style="text-align: right; "><label>Adv.Amt :</label></th>
              <td style="text-align: right; "><label>{{advanceamt | currency:'INR':''}}</label></td>
            </tr>
            <tr *ngIf="discValue > 0">
              <th>&nbsp;</th>
              <td>&nbsp;</td>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
              <th style="text-align: right; "><label>Disc.Amt :</label></th>
              <td style="text-align: right; "><label>{{discValue | currency:'INR':''}}</label></td>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
              <th style="text-align: right; "><label>Amt Recd :</label></th>
              <td style="text-align: right; "><label>{{recdamt | currency:'INR':''}}</label></td>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
              <th style="text-align: right; "><label>Due Amt :</label></th>
              <td style="text-align: right; "><label>{{billamt | currency:'INR':''}}</label></td>
            </tr>
          </thead>
        </table>
      </div>
      <br />
      <div style="width: 98%;">
        <table style="width:100%;">
          <tr>
            <td style="text-align:left;">{{todayWithPipe}}</td>
            <td style="text-align: right; padding-right: 10px">Signature</td>
          </tr>
        </table>
      </div>
    </div>
    <!--<div class="divfooter" style="width: 98%;">
      <table style="width:100%;">
        <tr>
          <td style="text-align:left;">{{todayWithPipe}}</td>
          <td style="text-align: right; padding-right: 10px">Signature</td>
        </tr>
      </table>
    </div>-->
  </div>
</div>

