<div class="contentMain">
  <!-- breadcrumb -->
  <div class="row">
    <div class="col-md-12 mainheader header-container">
      <h5>Transaction Types</h5>
    </div>
    <!-- <div class="col-md-6 ">
        <ol class="breadcrum d-flex justify-content-end">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">Transaction Types</li>
        </ol>
    </div> -->
  </div>
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <!-- card -->
    <div class="card">
      <div class="card-body">
        <!-- bootstrap tabs -->
        <div class="kliqtab">
          <!-- row -->
          <div class="row">
            <div class="col-md-12">
              <div class="checkbox">
                <input id="Batch" type="checkbox">
                <label for="Batch"> Activate On </label>
              </div>
            </div>
          </div>
          <!--/ row -->
          <!-- Nav tabs -->
          <ul class="nav nav-tabs p-0 mb-3">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#general">General</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#Parameters">Parameters</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#FootNotes">Foot Notes</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#BillFormats">Bill Formats</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#ItemGroups">Item Groups</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#Charges">Charges</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#Columns">Columns</a>
            </li>
          </ul>
          <!-- Tab panes -->
          <div class="tab-content">
            <div role="tabpanel" class="tab-pane in active p-3" id="general">
              <!-- general -->
              <!-- row -->
              <div class="row">
                <!-- col -->
                <div class="col-lg-10 col-md-12">
                  <div class="form-group">
                    <label>Transaction Name</label>
                    <input type="text" class="form-control" placeholder="Transaction Name">
                  </div>

                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-2 col-md-4 pt-4">
                  <div class="checkbox">
                    <input id="istouch" type="checkbox">
                    <label for="istouch">is Touch? </label>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Transaction Code</label>
                    <input type="text" class="form-control" placeholder="Transaction Code">
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Voucher Due</label>
                    <input type="text" class="form-control" placeholder="Voucher Due">
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 pt-4">
                  <div class="checkbox">
                    <input id="isposs" type="checkbox">
                    <label for="isposs">is Pos? </label>
                  </div>

                </div>

                <!--/ col -->
                <!-- col -->
                <div class="col-lg-10 col-md-12">
                  <div class="form-group">
                    <label>Voucher Type</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Voucher Type</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <br>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Voucher Series Type</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Series Type</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Series</label>
                    <input type="text" class="form-control" placeholder="series">
                  </div>

                </div>
                <div class="col-lg-4 col-md-6  pt-4">
                  <div class="checkbox">
                    <input id="bnorestarteveryday" type="checkbox">
                    <label for="bnorestarteveryday">B.No Restart Everyday </label>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Voucher Mode</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Voucher Mode</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 pt-4">
                  <div class="checkbox">
                    <input id="inclusivetax" type="checkbox">
                    <label for="inclusivetax">Inclusive Tax </label>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 pt-4">
                  <div class="checkbox">
                    <input id="setasdefault" type="checkbox">
                    <label for="setasdefault">Set as Default Transaction </label>
                  </div>
                </div>


                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Select Round of Type</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Round of Type</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-6 col-md-8">
                  <div class="form-group">
                    <label>Round</label>
                    <input type="text" class="form-control" placeholder="Round">
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Select Trade Type</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Trade Type</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-6 col-md-8">
                  <div class="form-group">
                    <label>Select Sale</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Sale</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-10 col-md-12">
                  <div class="form-group">
                    <label>Default Sale Returns Adjustment</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Default Sale Returns Adjustment</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Default Cash Account</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Default Cash Account</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-6 col-md-8">
                  <div class="form-group">
                    <label>Bank Account</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Bank Account</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Default Stock Point</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Default Stock Point</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Qty Decimals</label>
                    <input type="number" class="form-control" placeholder="Round">
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-2 col-md-3">
                  <div class="form-group">
                    <label>Rate Decimals</label>
                    <input type="number" class="form-control" placeholder="Round">
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Default Account Type</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Default Account Type</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Rate Type</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Rate Type</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <div>
                      <p>Pack </p>
                    </div>
                    <div class="radio inlineblock m-r-20">
                      <input type="radio" name="pack" id="purchaseback" class="with-gap"
                             value="option1">
                      <label for="purchaseback" class="pt-1">Purchase Pack</label>
                    </div>
                    <div class="radio inlineblock">
                      <input type="radio" name="pack" id="SalePack" class="with-gap"
                             value="option2" checked="">
                      <label for="SalePack" class="pt-1">Sale Pack</label>
                    </div>
                  </div>
                </div>
                <!--/ col -->
              </div>
              <!--/ row -->
              <!--/ general -->
            </div>
            <div role="tabpanel" class="tab-pane p-3" id="Parameters">
              <!-- Parameters -->
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
                  <thead>
                    <tr>
                      <th>S.No:</th>
                      <th>Parameter Name</th>
                      <th>Parameter Value</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>1</th>
                      <th>Position</th>
                      <th>Office</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    <tr>
                      <td>2</td>
                      <td>System Architect</td>
                      <td>Edinburgh</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Accountant</td>
                      <td>Tokyo</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Junior Technical Author</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Senior Javascript Developer</td>
                      <td>Edinburgh</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Accountant</td>
                      <td>Tokyo</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Integration Specialist</td>
                      <td>New York</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Sales Assistant</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Integration Specialist</td>
                      <td>Tokyo</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Javascript Developer</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Software Engineer</td>
                      <td>Edinburgh</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Office Manager</td>
                      <td>London</td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>Support Lead</td>
                      <td>Edinburgh</td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>Regional Director</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Senior Marketing Designer</td>
                      <td>London</td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>Regional Director</td>
                      <td>London</td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>Marketing Designer</td>
                      <td>London</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>Chief Financial Officer (CFO)</td>
                      <td>New York</td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>Systems Administrator</td>
                      <td>New York</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>Software Engineer</td>
                      <td>London</td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>Personnel Lead</td>
                      <td>Edinburgh</td>
                    </tr>
                    <tr>
                      <td>22</td>
                      <td>Development Lead</td>
                      <td>New York</td>
                    </tr>
                    <tr>
                      <td>23</td>
                      <td>Chief Marketing Officer (CMO)</td>
                      <td>New York</td>
                    </tr>
                    <tr>
                      <td>24</td>
                      <td>Pre-Sales Support</td>
                      <td>New York</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>Sales Assistant</td>
                      <td>Sidney</td>
                    </tr>
                    <tr>
                      <td>26</td>
                      <td>Chief Executive Officer (CEO)</td>
                      <td>London</td>
                    </tr>
                    <tr>
                      <td>27</td>
                      <td>Developer</td>
                      <td>Edinburgh</td>
                    </tr>
                    <tr>
                      <td>28</td>
                      <td>Regional Director</td>
                      <td>Singapore</td>
                    </tr>
                    <tr>
                      <td>29</td>
                      <td>Software Engineer</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>30</td>
                      <td>Chief Operating Officer (COO)</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>31</td>
                      <td>Regional Marketing</td>
                      <td>Tokyo</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>Integration Specialist</td>
                      <td>Sidney</td>
                    </tr>
                    <tr>
                      <td>33</td>
                      <td>Developer</td>
                      <td>London</td>
                    </tr>
                    <tr>
                      <td>34</td>
                      <td>Technical Author</td>
                      <td>London</td>
                    </tr>
                    <tr>
                      <td>35</td>
                      <td>Team Leader</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>36</td>
                      <td>Post-Sales support</td>
                      <td>Edinburgh</td>
                    </tr>
                    <tr>
                      <td>37</td>
                      <td>Marketing Designer</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>38</td>
                      <td>Office Manager</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>39</td>
                      <td>Secretary</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>Financial Controller</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>41</td>
                      <td>Office Manager</td>
                      <td>London</td>
                    </tr>
                    <tr>
                      <td>42</td>
                      <td>Director</td>
                      <td>New York</td>
                    </tr>
                    <tr>
                      <td>43</td>
                      <td>Support Engineer</td>
                      <td>Singapore</td>
                    </tr>
                    <tr>
                      <td>44</td>
                      <td>Software Engineer</td>
                      <td>London</td>
                    </tr>
                    <tr>
                      <td>45</td>
                      <td>Support Engineer</td>
                      <td>Tokyo</td>
                    </tr>
                    <tr>
                      <td>46</td>
                      <td>Developer</td>
                      <td>New York</td>
                    </tr>
                    <tr>
                      <td>47</td>
                      <td>Support Engineer</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>48</td>
                      <td>Data Coordinator</td>
                      <td>Singapore</td>
                    </tr>
                    <tr>
                      <td>49</td>
                      <td>Software Engineer</td>
                      <td>New York</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>Software Engineer</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>51</td>
                      <td>Junior Javascript Developer</td>
                      <td>Edinburgh</td>
                    </tr>
                    <tr>
                      <td>52</td>
                      <td>Sales Assistant</td>
                      <td>New York</td>
                    </tr>
                    <tr>
                      <td>53</td>
                      <td>Regional Director</td>
                      <td>London</td>
                    </tr>
                    <tr>
                      <td>54</td>
                      <td>Systems Administrator</td>
                      <td>London</td>
                    </tr>
                    <tr>
                      <td>55</td>
                      <td>Developer</td>
                      <td>San Francisco</td>
                    </tr>
                    <tr>
                      <td>56</td>
                      <td>Regional Director</td>
                      <td>Edinburgh</td>
                    </tr>
                    <tr>
                      <td>57</td>
                      <td>Javascript Developer</td>
                      <td>Singapore</td>
                    </tr>
                    <tr>
                      <td>58</td>
                      <td>Customer Support</td>
                      <td>New York</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--/ Parameters -->
            </div>
            <div role="tabpanel" class="tab-pane p-3" id="FootNotes">
              <!-- FootNotes -->
              <!-- row -->
              <div class="row">
                <!-- col -->
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Footer Note 1</label>
                    <input type="text" class="form-control" placeholder="Footer Note 1">
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Footer Note 2</label>
                    <input type="text" class="form-control" placeholder="Footer Note 2">
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Footer Note 3</label>
                    <input type="text" class="form-control" placeholder="Footer Note 3">
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Footer Note 4</label>
                    <input type="text" class="form-control" placeholder="Footer Note 4">
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Message</label>
                    <div class="form-line">
                      <textarea rows="4" class="form-control no-resize"
                                placeholder="Please type Description..."></textarea>
                    </div>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="form-group">
                    <label>Post Type</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>0-Bill wise</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
              </div>
              <!--/ row -->
              <!--/ FootNotes -->
            </div>
            <div role="tabpanel" class="tab-pane p-3" id="BillFormats">
              <!-- BillFormats -->
              <!--/ row -->
              <div class="row">
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Printer Name</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Printer Name</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Paper Size</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Paper Size</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Page Orientation</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Page Orientation</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Document Title</label>
                    <input type="text" class="form-control" placeholder="Document Title">
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Window Bill formate</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Window Bill formate</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Dos Bill Small formate</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Dos Bill Small formate</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Dos Bill Large formate</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Dos Bill Large formate</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                  <div class="checkbox">
                    <input id="askbillformats" type="checkbox">
                    <label for="askbillformats">Ask Bill Formats </label>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Use Print</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Dos Bill Small formate</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Print Selection</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>One Preview</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>No. of Copies</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select No. of Copies</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Barcode Bill Formate</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Barcode Bill Formate</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>User Barcode Print</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Barcode Bill Formate</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Barcode Print Selection</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>0-Print</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Barcode No. of Copies</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select No. of Copies</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Select Barcode Printer</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Barcode Printer</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Select Bar Code Paper size Printer</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Bar Code Paper size Printer</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label>Page Orientation</label>
                    <select class="form-control show-tick ms select2" data-placeholder="Select">
                      <option>Select Page Orientation</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                      <option>Select Item</option>
                    </select>
                  </div>
                </div>
                <!--/ col -->
              </div>
              <!--/ row -->
              <!--/ BillFormats -->
            </div>
            <div role="tabpanel" class="tab-pane p-3" id="ItemGroups">
              <!-- ItemGroups -->
              <!-- row -->
              <div class="row">
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="checkbox">
                    <input id="itemgroupname" type="checkbox">
                    <label for="itemgroupname">Item Group name </label>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="checkbox">
                    <input id="itemcategory" type="checkbox">
                    <label for="itemcategory">Item Category </label>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="checkbox">
                    <input id="ladieswere" type="checkbox">
                    <label for="ladieswere">Ladies were </label>
                  </div>
                </div>
                <!--/ col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6">
                  <div class="checkbox">
                    <input id="readymade" type="checkbox">
                    <label for="readymade">Ready made </label>
                  </div>
                </div>
                <!--/ col -->
              </div>
              <!--/ row-->
              <!--/ ItemGroups -->
            </div>
            <div role="tabpanel" class="tab-pane p-3" id="Charges">
              <!-- Charges -->
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
                  <thead>
                    <tr>
                      <th>Charge Name</th>
                      <th>Adjusement Account</th>
                      <th>Charge Type</th>
                      <th>Adj. Style</th>
                      <th>Cost Type</th>
                      <th>Default Value</th>
                      <th>Order</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="checkbox">
                          <input id="pnf" type="checkbox">
                          <label for="pnf">P &amp; F </label>
                        </div>
                      </td>
                      <td>Freight A/C</td>
                      <td>Add</td>
                      <td>Net Amount</td>
                      <td>Amount</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="checkbox">
                          <input id="roundoff" type="checkbox">
                          <label for="roundoff"> Round Off </label>
                        </div>
                      </td>
                      <td>Round Off</td>
                      <td>Round Off</td>
                      <td>Net Amount</td>
                      <td>Amount</td>
                      <td>1</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--/ Charges -->
            </div>
            <div role="tabpanel" class="tab-pane p-3" id="Columns">
              <!-- Columns -->
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
                  <thead>
                    <tr>
                      <th>Column Caption</th>
                      <th>Column Original Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="checkbox">
                          <input id="sno" type="checkbox">
                          <label for="sno">S.No: </label>
                        </div>
                      </td>
                      <td>SNo</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="checkbox">
                          <input id="itemcode" type="checkbox">
                          <label for="itemcode"> Item Code </label>
                        </div>
                      </td>
                      <td>Item Code</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--/ Columns -->
            </div>
          </div>
        </div>
        <!--/ bootstrap tabs -->
      </div>
      <div class="card-footer text-left w-100">
        <button class="btn redbtn" type="button">Update</button>
        <button class="btn redbtn" type="button">Another</button>
        <button class="btn redbtn" type="button">Save</button>


        <button class="btn redbtn" type="button">Close</button>
        <button class="btn redbtn" type="button">Help</button>
      </div>
    </div>
    <!--/ card -->
  </main>
  <!-- main body -->
</div>
