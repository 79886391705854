<div class="contentMain">
  <!-- breadcrumb -->
  <div class="row">
    <div class="col-md-12 mainheader  mainheader header-container">
      <h5>Item Creation - {{titletextneworedit}} Mode</h5>
    </div>
    <!-- <div class="col-md-6 ">
        <ol class="breadcrum d-flex justify-content-end">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">Item Creation</li>
        </ol>
    </div> -->
  </div>
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <div class="card">
      <form class="form" [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm()">
        <!-- card body -->
        <div class="card-body">
          <!-- bootstrap tabs -->
          <div class="kliqtab">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs p-0 mb-3">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#General">General</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#ItemDetails">Item Details</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#AccountDetails">
                  Account
                  Details
                </a>
              </li>
              <!--<li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#CustomFields">
                  Custom
                  Fields
                </a>
              </li>-->
            </ul>
            <!-- Tab panes -->
            <div class="tab-content">
              <div role="tabpanel" class="tab-pane in active p-3" id="General">
                <!-- general continue-->
                <div class="p-3 border mb-3">
                  <!-- general -->
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-6">
                          <div class="form-group">
                            <label>Item Name</label>
                            <input type="text" class="form-control" placeholder="Item Name" [ngClass]="{ 'is-invalid': submitted && fc.ItemName.errors }" formControlName="ItemName" #myname>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-6">
                          <div class="form-group">
                            <label>Item Code</label>
                            <input type="text" class="form-control" placeholder="Item Code" [ngClass]="{ 'is-invalid': submitted && fc.ItemCode.errors }" formControlName="ItemCode">
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-6">
                          <div class="form-group">
                            <label>EAN Code</label>
                            <input type="text" class="form-control" placeholder="EAN Code" formControlName="ItemEANCode">
                          </div>
                        </div>
                        <!--<div class="col-lg-12 col-md-12 col-sm-6">
                          <div class="form-group">
                            <label>Item Alias</label>
                            <input type="text" class="form-control" placeholder="Item Alias" formControlName="Alias">
                          </div>
                        </div>-->
                      </div>
                    </div>
                    <!--<div class="col-md-3">
                      <div class="options p-3 mt-3">
                        <div class="checkbox">
                          <input id="BarCode" type="checkbox" formControlName="BarCode">
                          <label for="BarCode"> Batch </label>
                        </div>
                        <div class="checkbox">
                          <input id="IMEINO" type="checkbox" formControlName="IMEINO">
                          <label for="IMEINO"> IMEI No </label>
                        </div>
                        <div class="checkbox">
                          <input id="Taka" type="checkbox" formControlName="Taka">
                          <label for="Taka"> Taka </label>
                        </div>
                        <div class="checkbox">
                          <input id="MultiRates" type="checkbox" formControlName="MultiRates">
                          <label for="MultiRates"> Multiple Rates </label>
                        </div>
                        <div class="checkbox">
                          <input id="MultiRatesWithMMS" type="checkbox" formControlName="MultiRatesWithMMS">
                          <label for="MultiRatesWithMMS"> MRP - SRate with Multiple Rates </label>
                        </div>
                        <button #fileInput style="visibility:hidden" class="btn" type="button" (click)="cancelImageasDefaultedit()">Cancel</button>
                      </div>
                    </div>-->
                    <div class="col-md-6 ">
                      <button #fileInput style="visibility:hidden" class="btn" type="button" (click)="cancelImageasDefaultedit()">Cancel</button>
                      <input type="file" style="visibility: hidden; border: 1px solid red;" id="img" #Image accept="image/*" formControlName="Image" (change)="onSelectFile($event)" />
                      <figure class="avatarImg text-center" style="margin-top: -55px;">
                        <img [src]="imgUrl" class="rounded-circle" alt="Profile Image" style="width: 150px; height: 150px;" onerror="this.src='./assets/images/itemcreation/defaultimage.png'">
                      </figure>
                      <table style="width:100%;">
                        <tr>
                          <td style="text-align:right;">
                            <label for="img" class="btn btn-outline-primary redbtn rounded-pill">Select Image</label>
                            <!--<input type="file" style="visibility: hidden; border: 1px solid red;" id="img" #Image accept="image/*" formControlName="Image" (change)="onSelectFile($event)" />-->
                          </td>
                          <td><button class="btn btn-outline-primary redbtn rounded-pill" type="button" (click)="cancelImageasDefault()">Cancel</button></td>
                        </tr>
                      </table>
                      <!--<div class="d-grid gap-2 d-md-block text-center">
                      <label for="img" class="btn btn-outline-primary redbtn rounded-pill">Select Image</label>
                      <input type="file" style="visibility:hidden" id="img" #Image accept="image/*" formControlName="Image" (change)="onSelectFile($event)" />-->
                      <!--(change)="handleFileInput($event.target.files)"-->
                      <!--</div>
                      <div class="d-grid gap-2 d-md-block text-center">
                        <button class="btn btn-outline-primary redbtn rounded-pill" type="button" (click)="cancelImageasDefault()">Cancel</button>
                      </div>-->

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-8 col-sm-6">
                      <div class="form-group row">
                        <div class="col-12  ">
                          <div class="form-group">
                            <label>Item Alias</label>
                            <input type="text" class="form-control" placeholder="Item Alias" formControlName="Alias">
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-12  ">
                          <div class="form-group">
                            <label>Description</label>
                            <input type="text" class="form-control" placeholder="Please type Description..." formControlName="ItemDescription" />
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-11">
                          <label>Category</label>
                          <select class="form-control" formControlName="CategoryId" (change)="changesubcategory()">
                            <option [value]="0">Select Category</option>
                            <option *ngFor="let item of CatgList"
                                    [value]="item.catId">
                              {{item.catName}}
                            </option>
                          </select>
                        </div>
                        <div class="col-1 pl-0 ">
                          <a href="javascript:void(0)" class="formAddicon"
                             data-toggle="modal" data-target="#category">
                            <span class="icon-plus icomoon"></span>
                          </a>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-11  ">
                          <div class="form-group">
                            <label>Sub Category</label>
                            <select class="form-control" formControlName="SubCategoryId">
                              <option [value]="0">Select Sub Category</option>
                              <option *ngFor="let item of SubCatgList"
                                      [value]="item.catId">
                                {{item.catName}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-1">&nbsp; </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6" style="height:350px;overflow:auto;">
                      <input type="text" class="form-control" formControlName="Size" style="display: none;">
                      <div class="form-group" style="overflow:auto;">
                        <div style="font-weight: bold;width:100%; background-color: #18558A;color:white;">
                          <label>Size Selection</label>
                        </div>
                        <div style="overflow: auto; border: 2px dotted silver;border-top:none; width: 100%;">
                          <table style="overflow: auto;">
                            <tr>
                              <td colspan="3">
                                <div class="checkbox">
                                  <input id="selectall" type="checkbox" [checked]="selectall" (change)="checkallsizes($event.target.checked)" />
                                  <label style="font-weight:bold;" for="selectall"> Select All </label>
                                </div>
                              </td>
                            </tr>
                            <tr *ngFor="let item of SizesList;let i=index;">
                              <td scope="row">
                                <div class="checkbox">
                                  <input id="checksize{{i}}" type="checkbox" [checked]="item.state==true" [value]="item.state" (change)="checksizes(item,$event.target.checked)" />
                                  <label for="checksize{{i}}"> &nbsp; </label>
                                </div>
                              </td>
                              <td><span style="font-weight:bold;">{{item.sizeName}}</span></td>
                              <td><input style="width:50px;" class="text-right" type="text" [value]="item.sizeOrder" (change)="changesizeorder(item,$event.target.value)" /></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <!--<div class="form-group">
                        <label>Description</label>
                        <div class="form-line">
                          <input type="text" class="form-control" placeholder="Please type Description..." formControlName="ItemDescription"/>
                        </div>
                      </div>-->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Manufacture</label>
                        <select class="form-control" formControlName="MnfId">
                          <option [value]="0">Select Manufacture</option>
                          <option *ngFor="let item of MnfsList"
                                  [value]="item.mnfId">
                            {{item.mnfName}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Brand</label>
                        <select class="form-control" formControlName="CompID">
                          <option [value]="0">Select Brand</option>
                          <option *ngFor="let item of BrandsList"
                                  [value]="item.icid">
                            {{item.itemCompName}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>GST%</label>
                            <select class="form-control" formControlName="VATPer" (change)="btnbindgstaccounts()">
                              <option [value]="0">&nbsp;</option>
                              <option *ngFor="let item of TaxRateList"
                                      [value]="item.taxType">
                                {{item.taxName}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Item Type</label>
                        <select class="form-control" formControlName="ItemType">
                          <option [value]="0">Select Item Type</option>
                          <option *ngFor="let item of ItemsTypeList"
                                  [value]="item.itemsTypeId">
                            {{item.itemsTypeName}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <div class="form-group row">
                        <div class="col-10">
                          <label>HSN Code</label>
                          <select class="form-control" formControlName="CommodityID">
                            <option [value]="0">Select HSN Code</option>
                            <option *ngFor="let item of HSNList"
                                    [value]="item.commodityId">
                              {{item.commodityName}}
                            </option>
                          </select>
                        </div>
                        <div class="col-2 pl-0 ">
                          <a href="javascript:void(0)" class="formAddicon"
                             data-toggle="modal" data-target="#hsn">
                            <span class="icon-plus icomoon"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Store Number</label>
                        <input type="number" class="form-control text-right" placeholder="0.00" formControlName="StoreNo">
                      </div>
                    </div>
                  </div>
                </div>
                <!--/ general continue-->
                <div class="p-3 border">
                  <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Purchase Pack</label>
                        <select class="form-control" (change)="changeUomPurchasePack()" formControlName="UOMId">
                          <option [value]="0">Select Purchase Pack</option>
                          <option *ngFor="let item of PurPackUOMList"
                                  [value]="item.uomid">
                            {{item.uomname}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Purchase Rate</label>
                        <input type="number" class="form-control text-right" placeholder="0.00" (focusout)="PurSalepackCalcPMargin()" formControlName="PRate">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 ">
                      <div class="form-group">
                        <label>Margin (%)</label>
                        <input type="number" class="form-control text-right" placeholder="0.00" (focusout)="Marginfocusout()" formControlName="Margin">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 ">
                      <div class="form-group">
                        <label>Sale Rate</label>
                        <input type="number" class="form-control text-right" placeholder="0.00" (change)="PurSalepackCalcSMargin()" (focusout)="SaleRatefocusout()" formControlName="SRate">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 align-self-center text-end">
                      <label class="text-primary">Issue Conversion Purchase Pack = Sale Pack * </label>&nbsp;<input type="text" class="text-right form-control-sm" placeholder="1" formControlName="ItemQty">
                    </div>
                    <!--<table style="width:100%;">
                      <tr>
                        <td style="width: 36%;"><label class="text-primary">Issue Conversion Purchase Pack = Sale Pack * </label></td>
                        <td ><input type="text" class="text-right form-control form-control-sm" placeholder="1" formControlName="ItemQty"></td>
                      </tr>
                    </table>-->
                    <!--<div class="col-lg-12 col-md-12 align-self-center text-end" style="border:1px solid green;">
                                      <p class="text-end">Issue Conversion Purchase Pack = Sale Pack * </p>
                                      </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                                     </div>
                                    </div>
                                    <input type="text" class="text-right form-control-sm" placeholder="1" formControlName="ItemQty">-->
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-6">
                      <div class="form-group">
                        <label>Sale Pack</label>
                        <select class="form-control" (change)="changeUomSalePack()" formControlName="RptUOMId">
                          <option *ngFor="let item of SalePackUOMList"
                                  [value]="item.uomid">
                            {{item.uomname}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <input type="number" class="form-control text-right" placeholder="0.00" formControlName="PurCostCalculated">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <input type="number" class="form-control text-right" placeholder="0.00" formControlName="SaleCostCalculated">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                      <div class="form-group">
                        <label>MRP</label>
                        <input type="number" class="form-control text-end text-right" placeholder="0.00" formControlName="MRP">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label>Weight</label>
                        <input type="number" class="form-control text-end text-right" placeholder="0.00" formControlName="Weight">
                      </div>
                    </div>
                    <div class="col-lg-1 col-md-2">
                      <div class="form-group">
                        <label>Discount</label>
                        <select class="form-control" formControlName="DiscType">
                          <option [value]="0">P</option>
                          <option [value]="1">A</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-2">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <input type="number" class="form-control text-end text-right" placeholder="0.00" formControlName="Disc">
                      </div>
                    </div>
                    <div class="col-lg-1 col-md-2">
                      <div class="form-group">
                        <label>Comc</label>
                        <select class="form-control" formControlName="CommissionType">
                          <option [value]="0">P</option>
                          <option [value]="1">A</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-2">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <input type="number" class="form-control text-end text-right" placeholder="0.00" formControlName="CommissionAmt">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div role="tabpanel" class="tab-pane" id="ItemDetails">
                <!-- card -->
                <div class="card">
                  <div class="card-header">
                    <h6>Rates</h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-3 col-md-3 col-sm-6">
                        <div class="form-group">
                          <label>WS Rate</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="WsRate">
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6">
                        <div class="form-group">
                          <label>Dealer Rate</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="DealarRate">
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6">
                        <div class="form-group">
                          <label>SPL Rate 1</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="RetailRate">
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6">
                        <div class="form-group">
                          <label>SPL Rate 2</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="SPLRate">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /card -->
                <div class="form-group">
                  <div>
                    <p>Report based on</p>
                  </div>
                  <div class="radio inlineblock m-r-20">
                    <input type="radio" id="purchaseback" class="with-gap" [value]="0" formControlName="RptSel">
                    <label for="purchaseback" class="pt-1">Purchase Pack</label>
                  </div>
                  <div class="radio inlineblock">
                    <input type="radio" id="SalePack" class="with-gap" [value]="1" checked="" formControlName="RptSel">
                    <label for="SalePack" class="pt-1">Sale Pack</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Min Stock</label>
                      <input type="number" class="form-control text-right" placeholder="0.00" formControlName="MinStk">
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Max Stock</label>
                      <input type="number" class="form-control text-right" placeholder="0.00" formControlName="MaxStk">
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>Re Order Level</label>
                      <input type="number" class="form-control text-right" placeholder="0.00" formControlName="Cases">
                    </div>
                  </div>
                </div>
                <div class="row" style="border:1px solid silver;">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <div>
                        <p>Charge Type</p>
                      </div>
                      <div class="radio inlineblock m-r-20">
                        <input type="radio" id="chargerates" class="with-gap" [value]="0" formControlName="GenID" (click)="changeChargeType()">
                        <label for="chargerates" class="pt-1">Cutting / Stitching Charge</label>
                      </div>
                      <!--<div class="radio inlineblock">
      <input type="radio" id="chargerates2" class="with-gap" [value]="1" checked="" formControlName="GenID">
      <label for="chargerates2" class="pt-1">Stitching Charge</label>
    </div>-->
                      <div class="radio inlineblock">
                        <input type="radio" id="chargerates3" class="with-gap" [value]="1" checked="" formControlName="GenID" (click)="changeChargeType()">
                        <label for="chargerates3" class="pt-1">Both Charges</label>
                      </div>
                    </div>
                    <div *ngIf="isbothCS">
                      <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-5 text-right">
                          <label style="font-weight:bold;font-size:12px;text-align:right;">Both Charge Amount</label>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="CgcessAmt">
                        </div>
                      </div><br />
                    </div>
                    <div *ngIf="!isbothCS">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="checkbox">
                              <input id="MultiRates" type="checkbox" formControlName="MultiRates">
                              <label style="font-weight:bold;" for="MultiRates"> Cutting Charge Amount</label>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-sm-4">
                            <input type="number" class="form-control text-right" placeholder="0.00" formControlName="MRPdisc">
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="checkbox">
                              <input id="MultiRatesWithMMS" type="checkbox" formControlName="MultiRatesWithMMS">
                              <label style="font-weight:bold;" for="MultiRatesWithMMS"> Stitching Charge Amount</label>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-sm-4">
                            <input type="number" class="form-control text-right" placeholder="0.00" formControlName="TotSalRate">
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  </div>
                <!-- card -->
                <div class="card">
                  <div class="card-header">
                    <h6>MRP - SRate with Multiple Rates</h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>PRate 1</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="PRate1">
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>SRate 1</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="SRate1">
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>MRP 1</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="MRP1">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>PRate 2</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="PRate2">
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>SRate 2</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="SRate2">
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>MRP 2</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="MRP2">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>PRate 3</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="PRate3">
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>SRate 3</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="SRate3">
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>MRP 3</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="MRP3">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>PRate 4</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="PRate4">
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>SRate 4</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="SRate4">
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>MRP 4</label>
                          <input type="number" class="form-control text-right" placeholder="0.00" formControlName="MRP4">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--/ address -->
              </div>
              <div role="tabpanel" class="tab-pane" id="AccountDetails">
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>GST Tax Rates</label>
                      <select class="form-control" formControlName="VATPer2" (change)="btnbindgstaccounts2()">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of TaxRateList"
                                [value]="item.taxType">
                          {{item.taxName}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>CGST %</label>
                      <input type="number" class="form-control text-right" placeholder="0.00" formControlName="CGSTRate" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="CGSTInputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of InputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="CGSTOutputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of OutputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>SGST %</label>
                      <input type="number" class="form-control text-right" placeholder="0.00" formControlName="SGSTRate" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="SGSTInputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of InputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="SGSTOutputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of OutputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>IGST %</label>
                      <input type="number" class="form-control text-right" placeholder="0.00" formControlName="IGSTRate" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="IGSTInputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of InputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="IGSTOutputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of OutputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <div class="form-group">
                        <label>CESS %</label>
                        <input type="number" class="form-control text-right" placeholder="0.00" formControlName="CessRate" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="CessInputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of InputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <select class="form-control" formControlName="CessOutputAccID">
                        <option [value]="0">&nbsp;</option>
                        <option *ngFor="let item of OutputActList"
                                [value]="item.actId">
                          {{item.actName}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h6>Local</h6>
                  </div>
                  <div class="card-body">

                    <div class="row">

                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>GST Purchase A/c</label>
                          <select class="form-control" formControlName="PurActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>GST Sales A/c</label>
                          <select class="form-control" formControlName="SalesActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>GST Purchase Return A/c</label>
                          <select class="form-control" formControlName="PurRetActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>GST Sale Return A/c</label>
                          <select class="form-control" formControlName="SalesRetActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>GST Credit Note A/c</label>
                          <select class="form-control" formControlName="CNActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>GST Debit Note A/c</label>
                          <select class="form-control" formControlName="DNActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>GST Service Bill A/c</label>
                          <select class="form-control" formControlName="ServiceBillActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h6>Non Local</h6>
                  </div>
                  <div class="card-body">

                    <div class="row">

                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>IGST Purchase A/c</label>
                          <select class="form-control" formControlName="IGSTPurActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>IGST Sales A/c</label>
                          <select class="form-control" formControlName="IGSTSalActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>IGST Purchase Return A/c</label>
                          <select class="form-control" formControlName="IGSTPurRetActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>IGST Sale Return A/c</label>
                          <select class="form-control" formControlName="IGSTSalesRetActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>IGST Credit Note A/c</label>
                          <select class="form-control" formControlName="IGSTCNActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>IGST Debit Note A/c</label>
                          <select class="form-control" formControlName="IGSTDNActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="form-group">
                          <label>IGST Service Bill A/c</label>
                          <select class="form-control" formControlName="IGSTServiceBillActId">
                            <option [value]="0">&nbsp;</option>
                            <option *ngFor="let item of PurchaseGSTActsList"
                                    [value]="item.actId">
                              {{item.actName}}
                            </option>
                          </select>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
              <!--<div role="tabpanel" class="tab-pane" id="CustomFields">
                <div class="table-responsive">
                  <table class="table m-b-0 kliqtable">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of CustFieldsList;let i=index;">
                        <td>{{item.cfname}}</td>
                        <td *ngIf="item.cftype==='Number';else second">
                          <input type="text" [value]="item.cfvalue" (change)="changeCustfieldval(item,$event)" class="form-control input-sm" />
                        </td>
                        <ng-template #second>
                          <td *ngIf="item.cftype==='Date';else third">
                            <input type="text" style="width: 60%;" id="AttendMonth" [(ngModel)]="AttendMonth" name="AttendMonth" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange(item,$event,i)" [bsValue]="AttendMonth" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD/MMM/YYYY' }">
                          </td>
                        </ng-template>
                        <ng-template #third>
                          <td *ngIf="item.cftype==='List';else fourth">
                            <select class="form-control" (change)="changeCustfieldval(item,$event)">
                              <option [value]="0">&nbsp;</option>
                              <option *ngFor="let list of splitDescription(item.cfvalue)">
                                {{list}}
                              </option>
                            </select>
                          </td>
                        </ng-template>
                        <ng-template #fourth>
                          <td>
                            <input type="text" [value]="item.cfvalue" (change)="changeCustfieldval(item,$event)" class="form-control input-sm" />
                          </td>
                        </ng-template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>-->

            </div>
          </div>
          <!--/ bootstrap tabs -->
        </div>
        <!--/ card body -->
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="button" (click)="clearform()">New</button>
          <button class="btn redbtn" type="button" (click)="clearform()">Another</button>
          <button class="btn redbtn" type="submit">Save</button>
          <button class="btn redbtn" type="button">Find</button>
          <button class="btn redbtn" type="button" (click)="clearform()">Clear</button>
          <button class="btn redbtn" type="button">Help</button>
        </div>
      </form>
    </div>
  </main>
  <div class="modal fade" id="addItem" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="mainheader">

          <h4 class="title" id="largeModalLabel">Create Item</h4>

        </div>
        <!-- body -->
        <div class="modal-body">

          <div class="row">

            <div class="col-md-3 modal-sidebar">
              <h5>Select Location</h5>
              <div class="pt-3">

                <div class="checkbox">
                  <input id="locNellore" type="checkbox">
                  <label for="locNellore"> Accounts </label>
                </div>
                <div class="checkbox">
                  <input id="locOngole" type="checkbox">
                  <label for="locOngole"> Adminstration </label>
                </div>
                <div class="checkbox">
                  <input id="locSuryapet" type="checkbox">
                  <label for="locSuryapet"> New Division1 </label>
                </div>
                <div class="checkbox">
                  <input id="locVijayawada" type="checkbox">
                  <label for="locVijayawada"> New Division 2 </label>
                </div>

              </div>
            </div>
            <div class="col-md-9">
              <div class="d-flex justify-content-between">
                <h5>Area</h5>
                <div class="checkbox">
                  <span class="d-inline-block pr-2">Status</span>
                  <input id="act01" type="checkbox">
                  <label for="act01"> Active </label>
                </div>
              </div>
              <div class="border p-3">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Division Name</label>
                      <input type="text" class="form-control" placeholder="Write Area Name">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Division code</label>
                      <input type="text" class="form-control" placeholder="Write Area code">
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Select Branch</label>
                      <select class="form-control">
                        <option>Select Area Under</option>
                        <option>Select Item1</option>
                        <option>Select Item2</option>
                        <option>Select Item3</option>
                        <option>Select Item4</option>
                      </select>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- body -->
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="button">New</button>

          <button class="btn redbtn" type="button">Save</button>
          <button class="btn redbtn" type="button">Edit</button>

          <button class="btn redbtn" type="button">Delete</button>

          <button class="btn redbtn" data-dismiss="modal" type="button">Close</button>
          <button class="btn redbtn" type="button">Cancel</button>
          <button class="btn redbtn" type="button">Help</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="hsn" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="mainheader">

          <h4 class="title" id="largeModalLabel">Create Item</h4>

        </div>
        <!-- body -->
        <!-- <app-hsn-creation></app-hsn-creation>w -->
        <!-- body -->
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="button">New</button>

          <button class="btn redbtn" type="button">Save</button>
          <button class="btn redbtn" type="button">Edit</button>

          <button class="btn redbtn" type="button">Delete</button>

          <button class="btn redbtn" data-dismiss="modal" type="button">Close</button>
          <button class="btn redbtn" type="button">Cancel</button>
          <button class="btn redbtn" type="button">Help</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="category" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="mainheader">

          <h4 class="title" id="largeModalLabel">Create Item</h4>

        </div>
        <!-- body -->
        <div class="modal-body">

          <div class="row">

            <div class="col-md-3 modal-sidebar">
              <h5>Select Location</h5>
              <div class="pt-3">

                <div class="checkbox">
                  <input id="locNellore" type="checkbox">
                  <label for="locNellore"> Accounts </label>
                </div>
                <div class="checkbox">
                  <input id="locOngole" type="checkbox">
                  <label for="locOngole"> Adminstration </label>
                </div>
                <div class="checkbox">
                  <input id="locSuryapet" type="checkbox">
                  <label for="locSuryapet"> New Division1 </label>
                </div>
                <div class="checkbox">
                  <input id="locVijayawada" type="checkbox">
                  <label for="locVijayawada"> New Division 2 </label>
                </div>

              </div>
            </div>
            <div class="col-md-9">
              <div class="d-flex justify-content-between">
                <h5>Category</h5>
                <div class="checkbox">
                  <span class="d-inline-block pr-2">Status</span>
                  <input id="act01" type="checkbox">
                  <label for="act01"> Active </label>
                </div>
              </div>
              <div class="border p-3">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Category Name</label>
                      <input type="text" class="form-control" placeholder="Write Area Name">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Category code</label>
                      <input type="text" class="form-control" placeholder="Write Area code">
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Discription</label>
                      <select class="form-control">
                        <option>Select Area Under</option>
                        <option>Select Item1</option>
                        <option>Select Item2</option>
                        <option>Select Item3</option>
                        <option>Select Item4</option>
                      </select>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- body -->
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="button">New</button>

          <button class="btn redbtn" type="button">Save</button>
          <button class="btn redbtn" type="button">Edit</button>

          <button class="btn redbtn" type="button">Delete</button>

          <button class="btn redbtn" data-dismiss="modal" type="button">Close</button>
          <button class="btn redbtn" type="button">Cancel</button>
          <button class="btn redbtn" type="button">Help</button>
        </div>
      </div>
    </div>
  </div>
  <!-- main body -->
</div>
