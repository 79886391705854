import { Component, ElementRef, OnInit, AfterContentInit, ViewChild, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from '../../../../http.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
declare var require: any;
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-new-cut-master',
  templateUrl: './new-cut-master.component.html',
  styleUrls: ['./new-cut-master.component.scss']
})
export class NewCutMasterComponent implements OnInit {

  dynamicArray: Array<DynamicGrid> = [];
  newDynamic: any = {};
  CustId: any = "";
  selectall: any = false;
  cssPrintingPath: any = "./assets/fonts/Printing.css";
  form1: FormGroup | undefined;
  isShown: boolean = false;
  itemlist: any = [];
  BranchList: any = []; SPListFrom: any = []; SPListTo: any = [];
  SMList: any = [];
  SizeList: any = []; SizeList2: any = []; TTypesList: any = [];
  GetItems: any = [];
  SelectedItem: any;
  SelectedAccount: any;
  DocDate: any = "12-Jan-2021";
  SuppBilldate: any = "12-Jan-2021";
  DocDuedate: any = "12-Jan-2021";
  totalitemscount: any = 0;
  totalitemsqty: any = 0;
  totalitemsamount: any = 0;
  totalitemsadvamount: any = 0;
  totalroundoffamount: any = 0;
  totalitemsbillamount: any = 0;
  actidtitle: any = "0";
  CutterStitcheditable: any = false;
  isvisbleCustIdinList: any = false;
  @ViewChild('myname') input;
  get fc(): any | undefined { return this.form1?.controls; }
  titletextneworedit: string = "New";
  submitted = false;
  editable: any = true;
  editdisabled: any = true;
  editid: any = 0;
  cuttstichList: any = []; 
  filteredList: any = [];
  accountList: any = [];
  filteredaccountList: any = [];
  flag: any = false;
  flag2: any = false;
  flag3: any = false;
  //
  isstitching: any = 0;
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private datePipe: DatePipe, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    
    let todaydate = new Date();
    this.DocDate = todaydate;
    this.SuppBilldate = todaydate;
    this.DocDuedate = todaydate;
    //form Defining Start
    this.form1 = this._fb.group({
      //IQDoc_Header Table
      DocId: [0],
      CompID: [0],
      DivId: [0],
      BranchID: [0],
      SpId: [0],
      CCID: [0],
      SMId: [0],
      TtId: [0],
      DocDate: [''],
      DocDuedate: [''],
      TSeries: ['', [Validators.required]],
      TNo: [0, [Validators.required]],
      TExtn: [''],
      ActID: [0],
      Dcno: [''],
      DcAmt: [0],
      BillAmt: [0],
      Mode: [1],
      SuppBillno: [''],
      SuppBilldate: [''],
      SuppBillAmt: [0],
      DbSave: [false],
      TcBook: [false],
      DocCancel: [0],
      CashDiscount: [0],
      DocType: [0],
      ZDocId: [0],
      BillTime: [''],
      Narration: [''],
      BillQty: [0],
      VisitDate: [''],
      BillCost: [0],
      Etseries: [''],
      ETno: [0],
      Exclusive: [0],
      EtExtn: [''],
      EstAmt: [0],
      RecdAmt: [0],
      RendedAmt: [0],
      Pack: [0],
      AmcSeries: [''],
      AmcNo: [0],
      WayBillSeries: [''],
      WayBillNo: [0],
      WayBillDate: [''],
      DiscValue: [0],
      AdjValue: [0],
      DoctorID: [0],
      Comments: [''],
      BillPrinted: [0],
      FromSPID: [0],
      HeadActID: [0],
      ActControlID: [0],
      ProductValue: [0],
      ChrgValue: [0],
      SalRetAdjIDs: [''],
      Message: [''],
      MemoID: [0],
      BillVerified: [0],
      CreatedBy: [0],
      CreatedDate: [''],
      ModifiedBy: [0],
      ModifiedDate: [''],
      RptSel: [0],
      TransCommitted: [false],
      CashAdvance: [0],
      CformNo: [''],
      CformDate: [''],
      JobId: [0],
      InvoiceCost: [0],
      ToSPId: [0],
      TransferStatus: [false],
      DispatchStatus: [0],
      Remarks: [''],
      BillCancel: [false],
      CalculatedAmt: [0],
      ConfirmBy: [0],
      ConfirmDate: [''],
      AuditBy: [0],
      AuditDate: [''],
      TotalAdjValue: [0],
      Status: [1],
      ActName: [''],
      ActAddr: [''],
      ActTown: [''],
      ActPhone: [''],
      ActMailId: [''],
      ActCSTNo: [''],
      ActGRNNo: [''],
      ActTINNo: [''],
      ActPAN: [''],
      TradeType: [0],
      PointType: [0],
      ToDocId: [0],
      ToBranchId: [0],
      Note1: [''],
      Note2: [''],
      EntryMode: [0],
      DocActID: [0],
      VANID: [0],
      IsApproved: [0],
      ShipID: [0],
      ActStateID: [0],
      RefDocID: [0],
      WorkstationID: [''],
      ServiceOrGoods: [false],
      isPosted: [false],
      SM2: [0],
      //end IQDoc_Header Table
      AccountCode: [''],
      AccountName: [''],
      AccountFullAddress: [''],
      CustId: [''],
      mobile: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
      frombrid: [0],
      fromspid: [0],
      fromspname: [''],
      frombractid: [0],
      tobrid: [0],
      tospid: [0],
      tospname: [''],
      tobractid: [0],
    });
    let todaydtStr = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      DocDate: todaydtStr,
      DocDuedate: todaydtStr,
      SuppBilldate: todaydtStr,
    });
    let objdata: any = null;
    this.editid = 0;
    if (this.router.getCurrentNavigation() == null) {
    }
    else {
      if (this.router.getCurrentNavigation().extras != undefined) {
        if (this.router.getCurrentNavigation().extras.state != undefined) {
          if (this.router.getCurrentNavigation().extras.state.id != undefined) {
            this.isstitching = (Number(this.router.getCurrentNavigation().extras.state.id));            
          }
        }
      }
    }
    //when page Load function
    const request = {
      method: 'GET',
      action_url: 'GetDelvtoCuttingload?id=' + this.editid + '&iscutting=1&isstitching=' + this.isstitching + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.BranchList = resobj.listbranches;
        this.SPListFrom = resobj.liststockpointsfrom;
        this.SPListTo = resobj.liststockpointsto;
        let brid: any = Number(resobj.objdh.branchId);
        let ToBranchId: any = Number(resobj.objdh.toBranchId);
        let spid: any = Number(resobj.objdh.spId);
        let ToSPId: any = Number(resobj.objdh.toSpid);
        let actId: any = Number(resobj.objdh.actId);
        this.TTypesList = resobj.listtt;
        this.SMList = resobj.listsm;
        this.cuttstichList = resobj.listaccounts;
        if (this.cuttstichList != null) {
          if (this.cuttstichList.length > 0) {
            this.filteredList = this.cuttstichList.filter((x: { actType: any; }) => x.actType == "Cutting");
            this.accountList = this.cuttstichList.filter((x: { actType: any; }) => x.actType == "Customer" || x.actType == "Customer-Supplier" || x.actType == "Supplier");
          }
        }
        //Edit       
        this.form1?.patchValue({
          TSeries: resobj.objdh.tseries,
          TNo: resobj.objdh.tno,
          BranchID: brid,
          SpId: spid,
          ToBranchId: brid,
          ToSPId: ToSPId,
          actId: actId,
          TtId: Number(resobj.objdh.ttId),
          Narration: resobj.objdh.narration,
          Message: resobj.objdh.message,
          BillAmt: Number(resobj.objdh.billAmt),
        });
        if (Number(this.editid) > 0) {
          this.editdisabled = Number(resobj.editdisabled) == 0 ? false : true;
        }
        //       
      },
      error => {
        console.log(error);
      });
  }
  ngOnInit(): void {

  }
  defaultclear() {
    this.totalitemscount = 0;
    this.totalitemsqty = 0;
    this.totalitemsamount = 0;
    this.totalitemsbillamount = 0;
  }

  changeitemrate(eventvalue: any, obj: any) {
    //
    let qtyitm: any = Number(obj.qty);
    obj.qty = (qtyitm);
    obj.rate = Number(eventvalue);
    obj.amt = (Number(qtyitm) * Number(eventvalue));
    obj.netValue = Number((obj.qty) * Number(eventvalue));
//    this.dynamicArray.filter((x: { itemId: any, rate: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { rate: any; }) => x.rate = Number(eventvalue));
    this.defaultclear();
    this.totalitemscount = this.dynamicArray.length;
    this.dynamicArray.forEach((x: { qty: number; }) => this.totalitemsqty += x.qty);
    this.dynamicArray.forEach((x: { amt: number; }) => this.totalitemsamount += x.amt);
    this.totalitemsbillamount = (Number(this.totalitemsamount));
    //end
  }

  //Filter Header Account Code/Name
  onselectAccountCodeName(item: any, isname: any) {
    this.isvisbleCustIdinList = true;
    let iscutting: any = 0;
    this.clearaccountdetails();
    this.form1?.patchValue({
      AccountCode: item.actCode,
      AccountName: item.actName,
      ActAddr: item.actAddr,
      ActTown: item.townName,
      ActPhone: item.actMob1,
    });
    this.actidtitle = item.actId.toString();
    this.flag = false; this.flag2 = false;
    let AccountCode = this.form1?.get('AccountCode').value;
    let AccountName = this.form1?.get('AccountName').value;
    if ((AccountCode.length == 0) && (AccountName.length == 0)) {
      return;
    }
    //Start
    let fromspname: any = ""; let tospname: any = "";
    try {
      fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
    } catch { fromspname = ""; }
    try {
      tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
    } catch { tospname = ""; }
    //
    if ((fromspname.includes('CUTTING')) && tospname.includes('HO')) {
      iscutting = 1;
    }
    if ((fromspname.includes('STITCHING')) && tospname.includes('HO')) {
      iscutting = 2;
    }
    let iscuttorstit: any = 0;
    if ((fromspname.includes('HO') && tospname.includes('CUTTING')) || (fromspname.includes('CUTTING') && tospname.includes('HO'))) {
      iscuttorstit = 0;
    }
    if ((fromspname.includes('HO') && tospname.includes('STITCHING')) || (fromspname.includes('STITCHING') && tospname.includes('HO'))) {
      iscuttorstit = 1;
    }
    //End   
    if (iscutting == 0) {
      const request = {
        method: 'GET',
        action_url: 'GetActUsingCodeOrName?Code=' + AccountCode + '&Name=' + AccountName + '&iscutting=' + iscutting + ''
      };
      this._services.doHttp(request).subscribe(
        res => {
          let resobj: any = res;
          if (resobj != null) {
            if (resobj.length > 0) {
              this.form1?.patchValue({
                AccountCode: resobj[0].actCode,
                AccountName: resobj[0].actName,
                ActAddr: resobj[0].actAddr,
                ActTown: resobj[0].townName,
                ActPhone: resobj[0].actMob1,
              });
              this.actidtitle = Number(resobj[0].actId).toString();
            }
          }
        },
        error => {
          console.log(error);
        });
    }
    else {
      const request = {
        method: 'GET',
        action_url: 'GetItemsByActCode?actcode=' + AccountCode + '&actname=' + AccountName + '&iscutting=' + iscutting + '&spid=' + Number(this.form1?.get('SpId').value) + '&iscuttorstit=' + iscuttorstit+''
      };
      this._services.doHttp(request).subscribe(
        res => {
          let resobj: any = res;
          if (resobj != null) {
            if (resobj.account != null) {
              this.form1?.patchValue({
                AccountCode: resobj.account.actCode,
                AccountName: resobj.account.actName,
                ActAddr: resobj.account.actAddr,
                ActTown: resobj.account.townName,
                ActPhone: resobj.account.actMob1,
              });
              this.actidtitle = Number(resobj.account.actId).toString();
            }
            this.itemlist = resobj.items;
            this.isvisbleCustIdinList = true;
            if (resobj.items != null) {
              this.itemlist.filter((x: { state: any; reqQty: any; reqQtye: any; }) => x.state == false).forEach((x: { reqQty: any; reqQtye: any; }) => x.reqQty = x.reqQtye);
              this.itemlist.filter((x: { state: any; amt: any; reqQtye: any; rate: any; }) => x.state == false).forEach((x: { amt: any; reqQtye: any; rate: any; }) => x.amt = Number(((x.reqQtye) * x.rate)));
              for (var aa = 0; aa < this.itemlist.length; aa++) {
                if (this.dynamicArray != null) {
                  if (this.dynamicArray.length > 0) {
                    var list: any = this.dynamicArray.filter((obj: { state: any; docItemId: any; idid: any; }) => obj.state == true && obj.docItemId == this.itemlist[aa].docItemId && obj.idid == this.itemlist[aa].idid);
                    if (list != null) {
                      if (list.length > 0) {
                        this.itemlist[aa].state = true;
                        this.itemlist[aa].qty = list[0].reqQty;
                        this.itemlist[aa].reqQty = list[0].reqQty;
                        this.itemlist[aa].reqQtye = list[0].reqQty;
                        this.itemlist[aa].amt = Number(((list[0].reqQty) * list[0].rate));
                        //
                        let fromspname: any = ""; let tospname: any = "";
                        try {
                          fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
                        } catch { fromspname = ""; }
                        try {
                          tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
                        } catch { tospname = ""; }
                        //
                        if ((fromspname.includes('CUTTING')) || (fromspname.includes('STITCHING'))) {
                          if ((fromspname.includes('CUTTING')) && tospname.includes('HO')) {
                            this.itemlist[aa].totcgcessAmt = Number((((list[0].genId == 0 && list[0].multiRates == 1) ? (list[0].mrpdisc * list[0].qty) : (list[0].genId == 1 ? (list[0].cgcessAmt * list[0].qty) : 0))));
                          }
                          if ((fromspname.includes('STITCHING')) && tospname.includes('HO')) {
                            this.itemlist[aa].totcgcessAmt = Number((((list[0].genId == 0 && list[0].multiRatesWithMms == 1) ? (list[0].totSalRate * list[0].qty) : (list[0].genId == 1 ? (list[0].cgcessAmt * list[0].qty) : 0))));
                          }
                        }

                      }
                    }
                  }
                }
              }
              let isselectall: any = true;
              var list = this.itemlist.filter((obj: { state: any; }) => obj.state == false);
              if (list != null) {
                if (list.length > 0) {
                  isselectall = false;
                }
              }
              this.selectall = isselectall;
            }
            if (resobj.account != null) {
              this.CustId = 'CustId : ' + resobj.account.actCode;
              this.displayStyle = "block";
            }
          }
        },
        error => {
          console.log(error);
        });
    }
  }
  changeAccountCodeName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredList = [];
      this.flag = false;
    }
  }
  focusoutAccountCodeName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredList = [];
      this.flag = false;
    }
  }
  clickAccountCodeName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredList = [];
      this.flag = false;
    }
  }
  keypressAccountCodeName(event: any, isname: any) {

    if (event.target.value == "") {
      this.filteredList = [];
      if (isname == 0) {
        this.flag = false;
      }
      else {
        this.flag2 = false;
      }
    }
    else {
      let iscutting: any = 1;
      //Start
      let fromspname: any = ""; let tospname: any = "";
      try {
        fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
      } catch { fromspname = ""; }
      try {
        tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
      } catch { tospname = ""; }
      //
      if (((fromspname.includes('CUTTING')) && tospname.includes('HO')) || ((fromspname.includes('HO')) && tospname.includes('CUTTING'))) {
        iscutting = 1;
      }
      if (((fromspname.includes('STITCHING')) && tospname.includes('HO')) || ((fromspname.includes('HO')) && tospname.includes('STITCHING'))) {
        iscutting = 2;
      }
    //End
      this.actidtitle = "0";
      this.flag = false; this.flag2 = false;
      if (isname == 0) {
        this.form1?.patchValue({
          AccountName: "",
          ActAddr: "",
          ActTown: "",
          ActPhone: "",
        });
        this.filteredList = this.cuttstichList.filter((x: { actType: any; actCode: any; }) => x.actType == (iscutting == 2 ?"Stitching":"Cutting") && x.actCode.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
        if (this.filteredList != null) {
          if (this.filteredList.length > 0) {
            this.flag = true;
          }
        }
      }
      else {
        this.form1?.patchValue({
          AccountCode: "",
          ActAddr: "",
          ActTown: "",
          ActPhone: "",
        });
        this.filteredList = this.cuttstichList.filter((x: { actType: any; actName: any; }) => x.actType == (iscutting == 2 ? "Stitching" : "Cutting") && x.actName.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
        if (this.filteredList != null) {
          if (this.filteredList.length > 0) {
            this.flag2 = true;
          }
        }
      }      
    }
  }
  //Filter Header Account Code/Name
  //Filter CustId
  onselectCustId(item: any, isname: any) {
    this.form1?.patchValue({
      CustId: item.actCode,
    });
    this.flag3 = false;
    this.isvisbleCustIdinList = true;
  }
  changeCustId(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag3 = false;
    }
  }  
  clickCustId(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag3 = false;
    }
  }
  keypressCustId(event: any, isname: any) {

    if (event.target.value == "") {
      this.filteredaccountList = [];
      if (isname == 0) {
        this.flag3 = false;
      }
      else {
        this.flag3 = false;
      }
    }
    else {     
      this.flag3 = false;
      this.filteredaccountList = this.accountList.filter((x: { actType: any; actCode: any; }) => x.actCode.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredaccountList != null) {
        if (this.filteredaccountList.length > 0) {
          this.flag3 = true;
        }
      }
    }
  }
  //Filter CustId
  addRow(index) {
    this.newDynamic = { CustId: "", Act_Id: 0, Description: "", ItemName: "", ItemId: 0, Qty: 0, Amt: 0 };
    this.dynamicArray.push(this.newDynamic);
    return true;
  }
  deleteIDIDs: any = "";
  deleteRow(index) {
    if (this.dynamicArray.length == 1) {
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      return true;
    }
  }
  displayStyle = "none";
  openPopup() {
    this.isvisbleCustIdinList = true;
    let iscutting: any = 0;
    //Start
    let fromspname: any = ""; let tospname: any = "";
    try {
      fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
    } catch { fromspname = ""; }
    try {
      tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
    } catch { tospname = ""; }
    if (((fromspname.includes('CUTTING')) || (fromspname.includes('STITCHING'))) && tospname.includes('HO')) {
      iscutting = 1;
    }
    let isHOtoCutt: any = 0;
    if ((fromspname.includes('HO')) && tospname.includes('CUTTING')) {
      isHOtoCutt = 1;
    }
    let iscuttorstit: any = 0;
    if (((fromspname.includes('HO')) && tospname.includes('CUTTING')) || ((fromspname.includes('CUTTING')) && tospname.includes('HO'))) {
      iscuttorstit = 0;
    }
    if (((fromspname.includes('HO')) && tospname.includes('STITCHING')) || ((fromspname.includes('STITCHING')) && tospname.includes('HO'))) {
      iscuttorstit = 1;
    }
    //
    this.CustId = "";
    let custid = this.form1?.get('CustId').value;
    let spid = Number(this.form1?.get('SpId').value);
    if (custid.length == 0) {
      return;
    }
    const request = {
      method: 'GET',
      action_url: 'GetItemsByActCode?actcode=' + custid + '&iscutting=' + iscutting + '&spid=' + spid + '&isHOtoCutt=' + isHOtoCutt + '&iscuttorstit=' + iscuttorstit+''
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        if (resobj != null) {
          this.itemlist = resobj.items;
          if (resobj.items != null) {
            this.itemlist.filter((x: { state: any; reqQty: any; reqQtye: any; }) => x.state == false).forEach((x: { reqQty: any; reqQtye: any; }) => x.reqQty = x.reqQtye);
            this.itemlist.filter((x: { state: any; amt: any; reqQtye: any; rate: any; }) => x.state == false).forEach((x: { amt: any; reqQtye: any; rate: any; }) => x.amt = Number(((x.reqQtye) * x.rate)));
            for (var aa = 0; aa < this.itemlist.length; aa++) {
              if (this.dynamicArray != null) {
                if (this.dynamicArray.length > 0) {
                  var list: any = this.dynamicArray.filter((obj: { state: any; docItemId: any; idid: any; }) => obj.state == true && obj.docItemId == this.itemlist[aa].docItemId && obj.idid == this.itemlist[aa].idid);
                  if (list != null) {
                    if (list.length > 0) {
                      this.itemlist[aa].state = true;
                      this.itemlist[aa].qty = list[0].reqQty;
                      this.itemlist[aa].reqQty = list[0].reqQty;
                      this.itemlist[aa].reqQtye = list[0].reqQty;
                      this.itemlist[aa].amt = Number(((list[0].reqQty) * list[0].rate));
                      this.itemlist[aa].totcgcessAmt = Number(((list[0].reqQty) * list[0].cgcessAmt));
                    }
                  }
                }
              }
            }
            let isselectall: any = true;
            var list = this.itemlist.filter((obj: { state: any; }) => obj.state == false);
            if (list != null) {
              if (list.length > 0) {
                isselectall = false;
              }
            }
            this.selectall = isselectall;
          }
          if (resobj.account != null) {
            this.CustId = 'CustId : ' + resobj.account.actCode;
            this.displayStyle = "block";
          }
        }
      },
      error => {
        console.log(error);
      });
  }
  ClickedRow: any;
  HighlightRow: Number | undefined = -1;
  closePopup() {
    this.displayStyle = "none";
  }
  checkallItems(val: any) {
    this.itemlist.forEach((x: { state: any; }) => x.state = val);
    let isselectall: any = true;
    var list = this.itemlist.filter((obj: { state: any; }) => obj.state == false);
    if (list != null) {
      if (list.length > 0) {
        isselectall = false;
      }
    }
    this.selectall = isselectall;
  }
  checkItem(item: any, val: any) {
    this.itemlist.filter((obj: { billNo: any; itemname: any; docItemId: any; idid: any; }) => obj.docItemId == item.docItemId && obj.idid == item.idid).forEach((x: { state: any; }) => x.state = val);
    let isselectall: any = true;
    var list = this.itemlist.filter((obj: { state: any; }) => obj.state == false);
    if (list != null) {
      if (list.length > 0) {
        isselectall = false;
      }
    }
    this.selectall = isselectall;
  }
  cleartotalamounts() {
    this.totalitemscount = 0;
    this.totalitemsqty = 0;
    this.totalitemsamount = 0;
    this.totalitemsadvamount = 0;
    this.totalroundoffamount = 0;
    this.totalitemsbillamount = 0;
  }
  clearaccountdetails() {
    this.form1?.patchValue({
      AccountName: "",
      AccountCode: "",
      ActAddr: "",
      ActTown: "",
      ActPhone: "",
    });
    this.actidtitle = "0";
  }
  onStartDateTimeChangeDocDate(dt: any) {
    this.DocDate = dt;
    let dtDocDate = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      DocDate: dtDocDate
    });
  }
  changeSp(event: any, isfrombr: any) {
    this.clearaccountdetails();
    this.dynamicArray = [];
    this.cleartotalamounts();
    this.form1?.patchValue({
      CustId: '',
    });    
    //Start
    let fromspname: any = ""; let tospname: any = "";
    try {
      fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
    } catch { fromspname = ""; }
    try {
      tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
    } catch { tospname = ""; }
    //
    this.CutterStitcheditable = false;
    if (((fromspname.includes('CUTTING')) || (fromspname.includes('STITCHING'))) && tospname.includes('HO')) {
      this.CutterStitcheditable = true;
    }
    let actType: any = "Cutting";
    if ((fromspname.includes('CUTTING') && tospname.includes('HO')) || (fromspname.includes('HO') && tospname.includes('CUTTING'))) {
      actType = "Cutting";
    }
    else if ((fromspname.includes('STITCHING') && tospname.includes('HO')) || (fromspname.includes('HO') && tospname.includes('STITCHING'))) {
      actType = "Stitching";
    }
    if (this.cuttstichList != null) {
      if (this.cuttstichList.length > 0) {
        this.filteredList = this.cuttstichList.filter((x: { actType: any; }) => x.actType == actType);
      }
    }
    //End
  }
  changeBranch(event: any) {
    let id: any = 0; id = Number(event.target.value);
    let ttid: any = 0;
    const request = {
      method: 'GET',
      action_url: 'GetStockPointsByBranchByCS?id=' + id + '&vtype=Point-To-Point Transfer&ttid=' + ttid + '&iscutting=1'
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.SPListFrom = resobj.listsplist;
        this.form1?.patchValue({
          TSeries: resobj.billseries,
          TNo: resobj.maxbillno,
        });
      },
      error => {
        console.log(error);
      });
  }
  SaveOrSubmitForm(evn: any) {
    if (Number(this.actidtitle) == 0) {
      Swal.fire('Warning', 'Please Select Account ..', 'warning');
      return;
    }
    let fromspname: any = ""; let tospname: any = "";
    try {
      fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
    } catch { fromspname = ""; }
    try {
      tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
    } catch { tospname = ""; }
    this.form1?.patchValue({
      fromspname: fromspname,
      tospname: tospname,
    });
    if (Number(this.form1?.get('SpId').value) == Number(this.form1?.get('ToSPId').value)) {
      Swal.fire('Warning', 'Same StockPoints in the Selection Criteria ..', 'warning');
      return;
    }
    if ((fromspname.includes('CUTTING') || fromspname.includes('STITCHING')) && (tospname.includes('CUTTING') || tospname.includes('STITCHING'))) {
      Swal.fire('Warning', 'Transaction is not Possible.. Please Select Different StockPoints', 'warning');
      return;
    }
    this.submitted = true;
    let sqlTimeAsString = formatDate(new Date(), "yyyy-MM-dd", "en_US");
    //
    let fyid: any = 0; let compid: any = 0;
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }

    let editid: any = Number(this.form1?.get('DocId').value);
    if (editid == 0) {
      this.form1?.patchValue({
        BranchID: Number(this.form1?.get('BranchID').value),
        SpId: Number(this.form1?.get('SpId').value),
        fromspid: Number(this.form1?.get('SpId').value),
        tospid: Number(this.form1?.get('ToSPId').value),
        TNo: Number(this.form1?.get('TNo').value),
      });
    }

    this.form1?.patchValue({
      CalculatedAmt: Number(this.form1?.get('CalculatedAmt').value),
      VisitDate: sqlTimeAsString,
      WayBillDate: sqlTimeAsString,
      CformDate: sqlTimeAsString,
      ConfirmDate: sqlTimeAsString,
      AuditDate: sqlTimeAsString,
      CreatedBy: Number(localStorage.getItem("Uid")),
      ModifiedBy: Number(localStorage.getItem("Uid")),
      CompID: compid,
      CreatedDate: sqlTimeAsString,
      ModifiedDate: sqlTimeAsString,
      BillAmt: Number(this.totalitemsbillamount),
      ActID: Number(this.actidtitle),
      fromspid: Number(this.form1?.get('SpId').value),
      tospid: Number(this.form1?.get('ToSPId').value),
      frombrid: Number(this.form1?.get('BranchID').value),
      tobrid: Number(this.form1?.get('ToBranchId').value),
      SpId: Number(this.form1?.get('SpId').value),
      ToSPId: Number(this.form1?.get('ToSPId').value),
    });
    let fromspid: any = Number(this.form1?.get('SpId').value);
    let tospid: any = Number(this.form1?.get('ToSPId').value);
    let frombrid: any = Number(this.form1?.get('frombrid').value);
    let tobrid: any = Number(this.form1?.get('tobrid').value);
    if (this.form1?.invalid) {
      return;
    }
    if (this.dynamicArray == null) {
      Swal.fire('Warning', 'Atleast One Valid Item should be Enter for Saving Invoice', 'warning');
      return;
    }
    if (this.dynamicArray.length == 0) {
      Swal.fire('Warning', 'Atleast One Valid Item should be Enter for Saving Invoice', 'warning');
      return;
    }
    var obj = { objheader: this.form1?.value, listitem: this.dynamicArray, deleteidids: this.deleteIDIDs, fromspname: fromspname, tospname: tospname, fromspid: fromspid, tospid: tospid, frombrid: frombrid, tobrid: tobrid, totalchargeamount: this.totalitemsadvamount };

    this.httpClient.post(this._services.APIUrl + '/InsertP2PTransfer', obj).subscribe(res => {
      var result: any = res;
      if (result.msg.toString().includes('Successfull')) {
        Swal.fire('Bill Saved', result.msg.toString(), 'success');
        //
        let id: any = 0;
        try {
          id = Number(result.status);
        } catch { }
        this.clearform();
      }
    },
      error => {
        console.log(error);
      });
  }
  reload() {   
    //this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
    //  this.router.navigate(['/New delivery-to-cut']);
    //});
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/New delivery-to-cut'], { state: { id: this.isstitching, text: 'Edit', obj: null } });
    });
  }
  clearform() {
    this.reload();
  }
  changeitemqty(eventvalue: any, obj: any) {
    let qtyitm: any = Number(eventvalue);
    let balQty: any = Number(obj.balQty);
    if (qtyitm > balQty) {
      obj.reqQty = Number(obj.reqQtye);
      qtyitm = Number(obj.reqQtye);
      Swal.fire('Warning', 'Require Qty Should not be greater than balance Qty', 'warning');
      obj.reqQty = Number(obj.reqQtye);
      return;
    }
    //   
    obj.qty = (qtyitm);
    obj.rate = Number(obj.rate);
    obj.amt = (Number(qtyitm) * Number(obj.rate));
    this.itemlist.filter((x: { docItemId: any, reqQty: any; idid: any; }) => x.docItemId == obj.docItemId && x.idid == obj.idid).forEach((x: { reqQty: any; }) => x.reqQty = Number((qtyitm)));
    this.itemlist.filter((x: { docItemId: any, rate: any; idid: any; }) => x.docItemId == obj.docItemId && x.idid == obj.idid).forEach((x: { rate: any; }) => x.rate = Number(obj.rate));
    this.itemlist.filter((x: { docItemId: any, amt: any; idid: any; }) => x.docItemId == obj.docItemId && x.idid == obj.idid).forEach((x: { amt: any; }) => x.amt = Number(((qtyitm) * obj.rate)));
    this.itemlist.filter((x: { docItemId: any; cgcessAmt: any; totcgcessAmt: any; idid: any; }) => x.docItemId == obj.docItemId && x.idid == obj.idid).forEach((x: { totcgcessAmt: any; cgcessAmt: any; }) => x.totcgcessAmt = Number(((qtyitm) * x.cgcessAmt)));
    //
    let fromspname: any = ""; let tospname: any = "";
    try {
      fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
    } catch { fromspname = ""; }
    try {
      tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
    } catch { tospname = ""; }
    //
    if ((fromspname.includes('CUTTING')) || (fromspname.includes('STITCHING'))) {
      if ((fromspname.includes('CUTTING')) && tospname.includes('HO')) {
        this.itemlist.filter((x: { state: any; reqQtye: any; docItemId: any; cgcessAmt: any; totcgcessAmt: any; idid: any; qty: number; genId: any; multiRates: any; mrpdisc: any; }) => x.docItemId == obj.docItemId && x.idid == obj.idid).forEach((x: { reqQtye: any; cgcessAmt: number; totcgcessAmt: number; qty: number; genId: any; multiRates: any; mrpdisc: any; }) => x.totcgcessAmt = (((x.genId == 0 && x.multiRates == 1) ? (x.mrpdisc * Number(qtyitm)) : (x.genId == 1 ? (x.cgcessAmt * Number(qtyitm)) : 0))));
      }
      if ((fromspname.includes('STITCHING')) && tospname.includes('HO')) {
        this.itemlist.filter((x: { state: any; reqQtye: any; docItemId: any; cgcessAmt: any; totcgcessAmt: any; idid: any; qty: number; genId: any; multiRatesWithMms: any; totSalRate: any; }) => x.docItemId == obj.docItemId && x.idid == obj.idid).forEach((x: { reqQtye: any; cgcessAmt: number; totcgcessAmt: number; qty: number; genId: any; multiRatesWithMms: any; totSalRate: any; }) => x.totcgcessAmt = (((x.genId == 0 && x.multiRatesWithMms == 1) ? (x.totSalRate * Number(qtyitm)) : (x.genId == 1 ? (x.cgcessAmt * Number(qtyitm)) : 0))));
      }
    }
  }
  addRowItem() {
    this.HighlightRow = -1;
    var list: any = this.itemlist;   
    if (list != null) {
      if (list.length > 0) {
        for (var xx = 0; xx < list.length; xx++) {
          if (list[xx].state == true) {
            if (this.dynamicArray != null) {
              if (this.dynamicArray.length == 0) {
                this.dynamicArray.push(list[xx]);
              }
              else {
                var iscontains: any = this.dynamicArray.filter((obj: { docItemId: any; idid: any; }) => obj.docItemId == list[xx].docItemId && obj.idid == list[xx].idid);               
                if (iscontains.length > 0) {
                  let totitemqty: any = 0;
                  this.itemlist.filter((obj: { docItemId: any; idid: any; }) => obj.docItemId == list[xx].docItemId && obj.idid == list[xx].idid).forEach((x: { qty: any; }) => totitemqty += x.qty);
                  this.dynamicArray.filter((obj: { docItemId: any; idid: any; }) => obj.docItemId == list[xx].docItemId && obj.idid == list[xx].idid).forEach((x: { reqQty: any; }) => x.reqQty = totitemqty);
                  this.dynamicArray.filter((obj: { docItemId: any; idid: any; }) => obj.docItemId == list[xx].docItemId && obj.idid == list[xx].idid).forEach((x: { qty: any; }) => x.qty = totitemqty);
                  this.dynamicArray.filter((obj: { docItemId: any; idid: any; }) => obj.docItemId == list[xx].docItemId && obj.idid == list[xx].idid).forEach((x: { amt: any; }) => x.amt = list[xx].amt);
                  this.dynamicArray.filter((obj: { docItemId: any; idid: any; }) => obj.docItemId == list[xx].docItemId && obj.idid == list[xx].idid).forEach((x: { netvalue: any; }) => x.netvalue = list[xx].netvalue);
                }
                else {
                  this.dynamicArray.push(list[xx]);
                }
              }
            }
            else {
              this.dynamicArray.push(list[xx]);
            }
          }
          else {
            this.dynamicArray = this.dynamicArray.filter((obj: { docItemId: any; state: any; idid: any;}) => obj.idid != list[xx].idid);
          }
        }
        this.displayStyle = "none";
        this.selectall = false;
        this.cleartotalamounts();
        if (this.dynamicArray != null) {
          this.totalitemscount = this.dynamicArray.length;
          this.dynamicArray.forEach((x: { qty: number; }) => this.totalitemsqty += x.qty);
          this.dynamicArray.forEach((x: { amt: number; }) => this.totalitemsamount += x.amt);
          this.dynamicArray.forEach((x: { amt: number; }) => this.totalitemsbillamount += x.amt);
          let fromspid: any = Number(this.form1?.get('SpId').value);
          var spfromlist: any = this.SPListFrom.filter((obj: { spid: any; spname: any; }) => obj.spid == fromspid);
          if (spfromlist != null) {
            if (spfromlist.length > 0) {
              for (var ss = 0; ss < spfromlist.length; ss++) {
                if ((spfromlist[ss].spname == "CUTTING-SP") || (spfromlist[ss].spname == "STITCHING-SP")) {
                  if (spfromlist[ss].spname == "CUTTING-SP") {
                    this.dynamicArray.forEach((x: { cgcessAmt: number; totcgcessAmt: number; qty: number; genId: any; multiRates: any; mrpdisc: any; }) => this.totalitemsadvamount += (((x.genId == 0 && x.multiRates == 1) ? (x.mrpdisc * x.qty) : (x.genId == 1 ? (x.cgcessAmt * x.qty) : 0))));
                    return;
                  }
                  if (spfromlist[ss].spname == "STITCHING-SP") {
                    this.dynamicArray.forEach((x: { cgcessAmt: number; totcgcessAmt: number; qty: number; genId: any; multiRatesWithMms: any; totSalRate: any; }) => this.totalitemsadvamount += (((x.genId == 0 && x.multiRatesWithMms == 1) ? (x.totSalRate * x.qty) : (x.genId == 1 ? (x.cgcessAmt * x.qty) : 0))));
                    return;
                  }
                }
              }
            }
          }
        }
      }
      console.log(this.dynamicArray);
    }
  }
  removeRowItem(index: any) {
    this.HighlightRow = -1;
    this.dynamicArray.splice(index, 1);
    this.cleartotalamounts();
    if (this.dynamicArray != null) {
      this.totalitemscount = this.dynamicArray.length;
      this.dynamicArray.forEach((x: { qty: number; }) => this.totalitemsqty += x.qty);
      this.dynamicArray.forEach((x: { amt: number; }) => this.totalitemsamount += x.amt);
      this.dynamicArray.forEach((x: { amt: number; }) => this.totalitemsbillamount += x.amt);
      //this.dynamicArray.forEach((x: { totcgcessAmt: number; }) => this.totalitemsadvamount += x.totcgcessAmt);
      let fromspid: any = Number(this.form1?.get('SpId').value);
      var spfromlist: any = this.SPListFrom.filter((obj: { spid: any; spname: any; }) => obj.spid == fromspid);
      if (spfromlist != null) {
        if (spfromlist.length > 0) {
          for (var ss = 0; ss < spfromlist.length; ss++) {
            if ((spfromlist[ss].spname == "CUTTING-SP") || (spfromlist[ss].spname == "STITCHING-SP")) {
              if (spfromlist[ss].spname == "CUTTING-SP") {
                this.dynamicArray.forEach((x: { cgcessAmt: number; totcgcessAmt: number; qty: number; genId: any; multiRates: any; mrpdisc: any; }) => this.totalitemsadvamount += (((x.genId == 0 && x.multiRates == 1) ? (x.mrpdisc * x.qty) : (x.genId == 1 ? (x.cgcessAmt * x.qty) : 0))));
                return;
              }
              if (spfromlist[ss].spname == "STITCHING-SP") {
                this.dynamicArray.forEach((x: { cgcessAmt: number; totcgcessAmt: number; qty: number; genId: any; multiRatesWithMms: any; totSalRate: any; }) => this.totalitemsadvamount += (((x.genId == 0 && x.multiRatesWithMms == 1) ? (x.totSalRate * x.qty) : (x.genId == 1 ? (x.cgcessAmt * x.qty) : 0))));
                return;
              }
            }
          }
        }
      }
    }
  }
  edititems(index: any, item: any) {
    //
    let fromspname: any = ""; let tospname: any = "";
    try {
      fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
    } catch { fromspname = ""; }
    try {
      tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
    } catch { tospname = ""; }
    // 
    this.HighlightRow = -1;
    let listAccounts: any; let iscuttorstit: any = 0;
    let isHOtoCutt: any = 0;
    if ((fromspname.includes('HO')) && tospname.includes('CUTTING')) {
      isHOtoCutt = 1;
    }
    if ((fromspname.includes('HO') && tospname.includes('CUTTING')) || (fromspname.includes('CUTTING') && tospname.includes('HO'))){
      iscuttorstit = 0;
    }
    if ((fromspname.includes('HO') && tospname.includes('STITCHING')) || (fromspname.includes('STITCHING') && tospname.includes('HO'))) {
      iscuttorstit = 1;
    }
    if (((fromspname.includes('CUTTING')) || (fromspname.includes('STITCHING'))) && tospname.includes('HO')) {
      listAccounts = this.itemlist.filter((obja: { cutterid: any; }) => obja.cutterid == item.cutterid);
      this.CustId = 'CustId : ' + item.cuttercode;
    }
    else {
      const request = {
        method: 'GET',
        action_url: 'GetItemsByActCode?actcode=' + item.actCode + '&iscutting=0&isHOtoCutt=' + isHOtoCutt+'&spid=' + Number(this.form1?.get('SpId').value) + '&iscuttorstit=' + iscuttorstit +''
      };
      this._services.doHttp(request).subscribe(
        res => {
          let resobj: any = res;
          if (resobj != null) {
            this.itemlist = resobj.items;
            this.displayStyle = "block";
            this.CustId = 'CustId : ' + item.actCode;
            if (this.itemlist != null) {
              if (this.itemlist.length > 0) {
                var indexOflist = this.itemlist.findIndex(i => i.docItemId === item.docItemId && i.idid === item.idid);
                this.HighlightRow = Number(indexOflist);
                this.itemlist.filter((x: { state: any; reqQty: any; reqQtye: any; }) => x.state == false).forEach((x: { reqQty: any; reqQtye: any; }) => x.reqQty = x.reqQtye);
                this.itemlist.filter((x: { state: any; amt: any; reqQtye: any; rate: any; }) => x.state == false).forEach((x: { amt: any; reqQtye: any; rate: any; }) => x.amt = Number(((x.reqQtye) * x.rate)));
                for (var aa = 0; aa < this.itemlist.length; aa++) {
                  if (this.dynamicArray != null) {
                    if (this.dynamicArray.length > 0) {
                      var list: any = this.dynamicArray.filter((obj: { state: any; docItemId: any; idid: any; }) => obj.state == true && obj.docItemId == this.itemlist[aa].docItemId && obj.idid == this.itemlist[aa].idid);
                      if (list != null) {
                        if (list.length > 0) {
                          this.itemlist[aa].state = true;
                          this.itemlist[aa].qty = list[0].reqQty;
                          this.itemlist[aa].reqQty = list[0].reqQty;
                          this.itemlist[aa].reqQtye = list[0].reqQty;
                          this.itemlist[aa].amt = Number(((list[0].reqQty) * list[0].rate));
                        }
                      }
                    }
                  }
                }
                let isselectall: any = true;
                var list = this.itemlist.filter((obj: { state: any; }) => obj.state == false);
                if (list != null) {
                  if (list.length > 0) {
                    isselectall = false;
                  }
                }
                this.selectall = isselectall;
              }

            }
          }
        },
        error => {
          console.log(error);
        });          
      return;
    }
    this.displayStyle = "block";
    this.itemlist = listAccounts;   
    if (this.itemlist != null) {
      if (this.itemlist.length > 0) {
        var indexOflist = this.itemlist.findIndex(i => i.docItemId === item.docItemId && i.idid === item.idid);
        this.HighlightRow = Number(indexOflist);
        let isselectall: any = true;
        var list = this.itemlist.filter((obj: { state: any; }) => obj.state == false);
        if (list != null) {
          if (list.length > 0) {
            this.itemlist.filter((x: { state: any; reqQty: any; reqQtye: any; }) => x.state == false).forEach((x: { reqQty: any; reqQtye: any; }) => x.reqQty = x.reqQtye);
            this.itemlist.filter((x: { state: any; amt: any; reqQtye: any; rate: any; }) => x.state == false).forEach((x: { amt: any; reqQtye: any; rate: any; }) => x.amt = Number(((x.reqQtye) * x.rate)));
            this.itemlist.filter((x: { state: any; amt: any; reqQtye: any; cgcessAmt: any; }) => x.state == false).forEach((x: { amt: any; reqQtye: any; cgcessAmt: any; }) => x.amt = Number(((x.reqQtye) * x.cgcessAmt)));

            if ((fromspname.includes('CUTTING')) || (fromspname.includes('STITCHING'))) {
              if ((fromspname.includes('CUTTING')) && tospname.includes('HO')) {
                this.itemlist.filter((x: { state: any; reqQtye: any; cgcessAmt: number; totcgcessAmt: number; qty: number; genId: any; multiRates: any; mrpdisc: any; }) => x.state == false).forEach((x: { reqQtye: any; cgcessAmt: number; totcgcessAmt: number; qty: number; genId: any; multiRates: any; mrpdisc: any; }) => x.totcgcessAmt = (((x.genId == 0 && x.multiRates == 1) ? (x.mrpdisc * x.reqQtye) : (x.genId == 1 ? (x.cgcessAmt * x.reqQtye) : 0))));
              }
              if ((fromspname.includes('STITCHING')) && tospname.includes('HO')) {
                this.itemlist.filter((x: { state: any; reqQtye: any; cgcessAmt: number; totcgcessAmt: number; qty: number; genId: any; multiRatesWithMms: any; totSalRate: any; }) => x.state == false).forEach((x: { reqQtye: any; cgcessAmt: number; totcgcessAmt: number; qty: number; genId: any; multiRatesWithMms: any; totSalRate: any; }) => x.totcgcessAmt = (((x.genId == 0 && x.multiRatesWithMms == 1) ? (x.totSalRate * x.reqQtye) : (x.genId == 1 ? (x.cgcessAmt * x.reqQtye) : 0))));
              }
            }
            isselectall = false;
          }
        }
        this.selectall = isselectall;

      }

    }

  }
  btnHeaderActSeries() {
    this.isvisbleCustIdinList = true;
    let iscutting: any = 0;
    this.form1?.patchValue({
      ActAddr: "",
      ActTown: "",
      ActPhone: "",
    });
    this.actidtitle = Number("0").toString();
    let AccountCode = this.form1?.get('AccountCode').value;
    let AccountName = this.form1?.get('AccountName').value;
    if ((AccountCode.length == 0) && (AccountName.length == 0)) {
      return;
    }
    //Start
    let fromspname: any = ""; let tospname: any = "";
    try {
      fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
    } catch { fromspname = ""; }
    try {
      tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
    } catch { tospname = ""; }
    //
    let iscuttorstit: any = 0;
    if ((fromspname.includes('CUTTING')) && tospname.includes('HO')) {
      iscutting = 1; iscuttorstit = 0;
    }
    if ((fromspname.includes('STITCHING')) && tospname.includes('HO')) {
      iscutting = 2; iscuttorstit = 1;
    }
    if (((fromspname.includes('HO')) && tospname.includes('CUTTING'))) {
      iscutting = 0; iscuttorstit = 0;
    }
    if (((fromspname.includes('HO')) && tospname.includes('STITCHING'))) {
      iscutting = 0; iscuttorstit = 1;
    }
    //End
    if (iscutting == 0) {
      const request = {
        method: 'GET',
        action_url: 'GetActUsingCodeOrName?Code=' + AccountCode + '&Name=' + AccountName + '&iscutting=' + iscutting + ''
      };
      this._services.doHttp(request).subscribe(
        res => {
          let resobj: any = res;
          if (resobj != null) {
            if (resobj.length > 0) {
              this.form1?.patchValue({
                AccountCode: resobj[0].actCode,
                AccountName: resobj[0].actName,
                ActAddr: resobj[0].actAddr,
                ActTown: resobj[0].townName,
                ActPhone: resobj[0].actMob1,
              });
              this.actidtitle = Number(resobj[0].actId).toString();
            }
          }
        },
        error => {
          console.log(error);
        });
    }
    else {
      const request = {
        method: 'GET',
        action_url: 'GetItemsByActCode?actcode=' + AccountCode + '&actname=' + AccountName + '&iscutting=' + iscutting + '&spid=' + Number(this.form1?.get('SpId').value) + '&iscuttorstit=' + iscuttorstit+''
      };
      this._services.doHttp(request).subscribe(
        res => {
          let resobj: any = res;
          if (resobj != null) {
            if (resobj.account != null) {
              this.form1?.patchValue({
                AccountCode: resobj.account.actCode,
                AccountName: resobj.account.actName,
                ActAddr: resobj.account.actAddr,
                ActTown: resobj.account.townName,
                ActPhone: resobj.account.actMob1,
              });
              this.actidtitle = Number(resobj.account.actId).toString();
            }
            this.itemlist = resobj.items;
            this.isvisbleCustIdinList = true;
            if (resobj.items != null) {
              this.itemlist.filter((x: { state: any; reqQty: any; reqQtye: any; }) => x.state == false).forEach((x: { reqQty: any; reqQtye: any; }) => x.reqQty = x.reqQtye);
              this.itemlist.filter((x: { state: any; amt: any; reqQtye: any; rate: any; }) => x.state == false).forEach((x: { amt: any; reqQtye: any; rate: any; }) => x.amt = Number(((x.reqQtye) * x.rate)));
              for (var aa = 0; aa < this.itemlist.length; aa++) {
                if (this.dynamicArray != null) {
                  if (this.dynamicArray.length > 0) {
                    var list: any = this.dynamicArray.filter((obj: { state: any; docItemId: any; idid: any; }) => obj.state == true && obj.docItemId == this.itemlist[aa].docItemId && obj.idid == this.itemlist[aa].idid);
                    if (list != null) {
                      if (list.length > 0) {
                        this.itemlist[aa].state = true;
                        this.itemlist[aa].qty = list[0].reqQty;
                        this.itemlist[aa].reqQty = list[0].reqQty;
                        this.itemlist[aa].reqQtye = list[0].reqQty;
                        this.itemlist[aa].amt = Number(((list[0].reqQty) * list[0].rate));
                        //
                        let fromspname: any = ""; let tospname: any = "";
                        try {
                          fromspname = (this.SPListFrom.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('SpId').value)).spname);
                        } catch { fromspname = ""; }
                        try {
                          tospname = (this.SPListTo.find((item: { spid: any; }) => item.spid == Number(this.form1?.get('ToSPId').value)).spname);
                        } catch { tospname = ""; }
                        //
                        if ((fromspname.includes('CUTTING')) || (fromspname.includes('STITCHING'))) {
                          if ((fromspname.includes('CUTTING')) && tospname.includes('HO')) {
                            this.itemlist[aa].totcgcessAmt = Number((((list[0].genId == 0 && list[0].multiRates == 1) ? (list[0].mrpdisc * list[0].qty) : (list[0].genId == 1 ? (list[0].cgcessAmt * list[0].qty) : 0))));
                          }
                          if ((fromspname.includes('STITCHING')) && tospname.includes('HO')) {
                            this.itemlist[aa].totcgcessAmt = Number((((list[0].genId == 0 && list[0].multiRatesWithMms == 1) ? (list[0].totSalRate * list[0].qty) : (list[0].genId == 1 ? (list[0].cgcessAmt * list[0].qty) : 0))));
                          }
                        }

                      }
                    }
                  }
                }
              }
              let isselectall: any = true;
              var list = this.itemlist.filter((obj: { state: any; }) => obj.state == false);
              if (list != null) {
                if (list.length > 0) {
                  isselectall = false;
                }
              }
              this.selectall = isselectall;
            }
            if (resobj.account != null) {
              this.CustId = 'CustId : ' + resobj.account.actCode;
              this.displayStyle = "block";
            }
          }
        },
        error => {
          console.log(error);
        });
    }
  }
}
export class DynamicGrid {
  actId: number = 0;
  ActName: string = "";
  ActCode: string = "";
  ActAddr: string = "";
  ActTown: string = "";
  ActPhone: string = "";
  itemname: string = "";
  TSeries: string = "";
  TNo: string = "";
  BillNo: string = "";
  DocDate: any;
  DocDueDate: any;
  SuppBilldate: any;
  SuppBillno: string = "";
  qty: number = 0;
  balQty: number = 0;
  reqQty: number = 0;
  reqQtye: number = 0;
  rate: number = 0;
  amt: number = 0;
  grossvalue: number = 0;
  netvalue: number = 0;
  desc: string = "";
  state: any;
  docItemId: any = "";
  DocId: number = 0;
  ITemId: number = 0;
  ishighlightrow: any;
  idid: number = 0;
  Odid: number = 0;
  CessAccId: number = 0;
  genId: number = 0;
  cgcessAmt: number = 0;
  totcgcessAmt: number = 0;
  multiRates: any = 0;
  multiRatesWithMms: any = 0;
  totSalRate: any = 0;
  mrpdisc: any = 0;
}
