<form class="form-inline custom-form-inline" [formGroup]="form1" (ngSubmit)="SearchOrSubmitForm()">
  <label>Branch :</label>
  <select class="form-control" formControlName="BranchID">
    <option [value]="0">All Branches</option>
    <option *ngFor="let Brlist of BranchList;" [value]="Brlist.branchId">{{Brlist.branchName }}</option>
  </select>
  <label>Date Range:</label>
  <input type="text" class="form-control" placeholder="From Date" style="width:150px;" id="Fromdate" [(ngModel)]="Fromdate" name="Fromdate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange($event)" [bsValue]="Fromdate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
  <input type="text" class="form-control" placeholder="To Date" style="width:150px;" id="Todate" [(ngModel)]="Todate" name="Todate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange2($event)" [bsValue]="Todate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
  <button class="btn redbtn" type="submit">Search</button> &nbsp;
  <button printTitle="Print Title" styleSheetFile="{{cssPrintingPath}}"
          [printStyle]="{h4 : {'text-align': 'center'}, span : {'font-size': 'small'}}"
          printSectionId="print-section" ngxPrint>
    <img src="../../../../../assets/images/printer.jpg" style="width:40px;height:30px;" />
  </button>
  <img (click)="generateExcel()" src="../../../../../assets/images/excel.jpg" style="width: 40px; height: 30px;" />
</form>
<br />
<div id="print-section" #TABLE #table>
  <div style="width: 100%; overflow: auto;">
    <table class="table" style="width: 98%; border: 0px solid black;">
      <thead>
        <tr>
          <th style="text-align: left; border: 1px solid black;width:12%;">Order No</th>
          <th style="text-align: center; border: 1px solid black; width: 13%; ">Cust Id</th>
          <th style="text-align: left; border: 1px solid black; width: 12%; ">Order Date</th>
          <th style="text-align: left; border: 1px solid black; width: 12%; ">Delv.Date</th>
          <th style="text-align: center; border: 1px solid black; width: 17%; ">Item Name</th>
          <th style="text-align: left; border: 1px solid black; width: 8%; ">Nos</th>
          <th style="text-align: left; border: 1px solid black; width: 12%; ">Rate</th>
          <th style="text-align: left; border: 1px solid black; width: 14%; ">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let list of itemlist; let i = index">
          <td colspan="8">
            <table style="width: 100%; overflow: auto;">
              <tr>
                <td style="text-align: left; border: 1px solid black; width: 12%;"><label style="font-weight:bold;">{{list.objh.billNo}}</label></td>
                <td colspan="7" style="text-align: left; border: 1px solid black; width: 88%;"><label style="font-weight:bold;">{{list.objh.actName}}</label></td>
              </tr>
              <tr>
                <td style="text-align: left; border: 1px solid black; width: 12%; vertical-align: top;">&nbsp;</td>
                <td style="text-align: left; border: 1px solid black; width: 13%; vertical-align: top; "><label>{{list.objh.actCode}}</label></td>
                <td style="text-align: left; border: 1px solid black; width: 12%; vertical-align: top; "><label>{{list.objh.docDate}}</label></td>
                <td style="text-align: left; border: 1px solid black; width: 12%; vertical-align: top; "><label>{{list.objh.suppBilldate}}</label></td>
                <td style="text-align: left; border: 1px solid black; width: 51%;" colspan="2">
                  <table style="width: 100%; overflow: auto;">
                    <tr *ngFor="let item of list.listi; let j = index">
                      <td style="text-align: left; border: 1px solid black; width: 33%;"><label>{{item.itemname}}</label></td>
                      <td style="text-align: right; border: 1px solid black; width: 16%;"><label>{{item.qty | currency:'INR':''}}</label></td>
                      <td style="text-align: right; border: 1px solid black; width: 23%;"><label>{{item.rate | currency:'INR':''}}</label></td>
                      <td style="text-align: right; border: 1px solid black; width: 28%;"><label>{{item.netValue | currency:'INR':''}}</label></td>
                    </tr>
                    <tr *ngIf="list.listi.length>0">
                      <td>&nbsp;</td>
                      <th style="text-align: right; "><label>{{list.totqty | currency:'INR':''}}</label></th>
                      <td>&nbsp;</td>
                      <th style="text-align: right; "><label>{{list.totnetvalue | currency:'INR':''}}</label></th>
                    </tr>
                    <tr *ngIf="list.listi.length>0">
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <th style="text-align: right;"><label>Adv. Amt</label></th>
                      <th style="text-align: right; "><label>{{list.advanceamt | currency:'INR':''}}</label></th>
                    </tr>
                    <tr *ngIf="list.listi.length>0">
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <th style="text-align: right;"><label>Due Amount</label></th>
                      <th style="text-align: right; "><label>{{list.billamt | currency:'INR':''}}</label></th>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr *ngIf="itemlist.length===0">
          <td style=" border: 0px solid black;" colspan="8" class="text-center text-danger">No Data Found for Selection Criteria</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
