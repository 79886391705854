import { Component, ElementRef, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from 'src/app/http.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
declare var require: any;

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-account-ledger',
  templateUrl: './account-ledger.component.html',
  styleUrls: ['./account-ledger.component.scss']
})
export class AccountLedgerComponent implements OnInit {
  enabledDates: any = true;
  form1: FormGroup | undefined;
  BranchList: any = []; SPList: any = []; accountList: any = []; itemlist:any= [];
  filteredAccountCodeList: any = [];
  CompName: any = ""; Address1: any = "";
  title: any = 'Account Ledger';
  flagAccountCode: any = false;
  cssPrintingPath: any = "./assets/fonts/Printing.css";
  filteredAccountNameList: any = [];
  flagAccountName: any = false;
  actidtitle: any = "0";
  //
  @ViewChild('myname') input;
  get fc(): any | undefined { return this.form1?.controls; }
  submitted = false;
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  //minDate = new Date();
  minDate = new Date(this.bsValue.getFullYear(), this.bsValue.getMonth(), 1);
  ranges: any = [{
    value: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date()],
    label: 'Yester Day'
  }, {
    value: [new Date(), new Date(new Date().setDate(new Date().getDate() + 1))],
    label: 'Next Day'
  }];
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private datePipe: DatePipe, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    this.maxDate.setDate(this.bsValue.getDate());
    this.bsRangeValue = [this.maxDate, this.maxDate];
    this.form1 = this._fb.group({
      BranchID: [0],
      SpId: [0],     
      ActID: [0],
      AccountCode: '',
      AccountName: '',
      dtfrom: [''],
      dtto: [''],
    });
    let fyid: any = 0; let compid: any = 0;
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }
    //when page Load function
    const request = {
      method: 'GET',
      action_url: 'GetAccountLedgerload?compid=' + compid + '&fyid=' + fyid + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        if (resobj != null) {
          this.CompName = resobj.objcomp.compName;
          this.Address1 = resobj.objcomp.address1;
        }
        let dtDocDate = formatDate(new Date(resobj.fromdate), 'yyyy-MM-dd', 'en_US');
        this.BranchList = resobj.listbranches;
        this.SPList = resobj.liststockpoints;
        this.accountList = resobj.listaccounts;
        this.bsRangeValue = [new Date(dtDocDate), this.maxDate];
      },
      error => {
        console.log(error);
      });
    //End    
  }
  ngOnInit(): void {
  }
  SaveOrSubmitForm(evn: any) {
    let AccountCode = this.form1?.get('AccountCode').value;
    let AccountName = this.form1?.get('AccountName').value;
    if ((AccountCode.length == 0) && (AccountName.length == 0)) {
      Swal.fire('Warning', 'Please Select Account Code & Name ..', 'warning');
      this.itemlist = [];
      return;
    }
    if (this.actidtitle == "0" || this.actidtitle == "") {
      Swal.fire('Warning', 'Please Select Account Code & Name ..', 'warning');
      return;
    }
    let ActID: any = Number(this.form1?.get('ActID').value);
    let BranchID: any = Number(this.form1?.get('BranchID').value);
    let SpId: any = Number(this.form1?.get('SpId').value);
    let dtfrom :any= this.form1?.get('dtfrom').value;
    let dtto: any = this.form1?.get('dtto').value;
    let fyid: any = 0; let compid: any = 0;
    this.title = AccountName + " Account Ledger From " + formatDate(new Date(dtfrom), 'dd-MM-yyyy', 'en_US') + " to " + formatDate(new Date(dtto), 'dd-MM-yyyy', 'en_US');
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }
    const request = {
      method: 'GET',
      action_url: 'GetledgerItemsbyaccountbyId?id=' + ActID + '&brid=' + BranchID + '&spid=' + SpId + '&dtfrom=' + dtfrom + '&dtto=' + dtto + '&compid=' + compid + '&fyid=' + fyid+''
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        if (resobj != null) {
          this.itemlist = resobj.listitems;
        }
        else {
          this.itemlist = [];
        }       
      },
      error => {
        console.log(error);
      });
  }
  changeBranch(event: any) {
    let id: any = 0; id = Number(event.target.value);
    if (id == 0) {
      return;
    }
    const request = {
      method: 'GET',
      action_url: 'GetStockPointsByBranch?id=' + id + '&vtype=Purchase'
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.SPList = resobj.listsplist;
        this.form1?.patchValue({
          TSeries: resobj.billseries,
          TNo: resobj.maxbillno,
        });
      },
      error => {
        console.log(error);
      });
  }
  //Filter AccountCode
  onselectAccountCode(item: any, isname: any) {
    this.actidtitle = item.actId.toString();
    this.form1?.patchValue({      
      AccountCode: item.actCode,
      AccountName: item.actName,
      ActID: item.actId.toString(),
    });
    this.flagAccountCode = false;
  }
  changeAccountCode(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredAccountCodeList = [];
      this.flagAccountCode = false;
    }
  }
  clickAccountCode(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredAccountCodeList = [];
      this.flagAccountCode = false;
    }
  }
  keypressAccountCode(event: any, isname: any) {

    if (event.target.value == "") {
      this.filteredAccountCodeList = [];
      if (isname == 0) {
        this.flagAccountCode = false;
      }
      else {
        this.flagAccountCode = false;
      }
    }
    else {
      this.flagAccountCode = false;
      this.filteredAccountCodeList = this.accountList.filter((x: { actType: any; actCode: any; }) => x.actCode.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredAccountCodeList != null) {
        if (this.filteredAccountCodeList.length > 0) {
          this.flagAccountCode = true;
        }
      }
    }
  }
  //End Filter AccountCode
  //Filter AccountName
  onselectAccountName(item: any, isname: any) {
    this.actidtitle = item.actId.toString();
    this.form1?.patchValue({
      AccountCode: item.actCode,
      AccountName: item.actName,
      ActID: item.actId.toString(),
    });
    this.flagAccountName = false;
  }
  changeAccountName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredAccountNameList = [];
      this.flagAccountName = false;
    }
  }
  clickAccountName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredAccountNameList = [];
      this.flagAccountName = false;
    }
  }
  keypressAccountName(event: any, isname: any) {

    if (event.target.value == "") {
      this.filteredAccountNameList = [];
      if (isname == 0) {
        this.flagAccountName = false;
      }
      else {
        this.flagAccountName = false;
      }
    }
    else {
      this.flagAccountName = false;
      this.filteredAccountNameList = this.accountList.filter((x: { actType: any; actName: any; }) => x.actName.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredAccountNameList != null) {
        if (this.filteredAccountNameList.length > 0) {
          this.flagAccountName = true;
        }
      }
    }
  }
  //Filter AccountName
  dateCreated($event) {

  }
  dateRangeCreated($event) {
    let startDate = $event[0].toJSON().split('T')[0];
    let endDate = $event[1].toJSON().split('T')[0];
    this.form1?.patchValue({
      dtfrom: startDate.toString(),
      dtto: endDate.toString(),
    });
    //console.log('startDate : ' + startDate);
    //console.log('endDate : ' + endDate);
  }

}
