<div class="contentMain">
  <!-- breadcrumb -->
  <div class="row">
    <div class="col-md-12 card-header mainheader header-container">
      <h5>Area Creation - {{titletextneworedit}} Mode</h5>
    </div>
  </div>
  <div class="row">
    <main class="row">
      <div class="card">
        <form [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm()" class="card auth_form">
          <div class="card-body">
            <div class="checkbox text-right">
              <input id="Status" type="checkbox" name="Status" formControlName="TownStatus" />
              <label for="Status">Status </label>
            </div>
            <!-- row -->
            <div class="row">
              <!--<div class="col-lg-12 col-md-12">
    <div class="text-right">-->
              <!--<p class="pb-0 mb-0">Status</p>-->
              <!--<div class="checkbox ml-3 ">
        <input id="activestatus" type="checkbox" checked formControlName="TownStatus"/>
        <label for="activestatus"> Status </label>
      </div>
    </div>
            </div>-->
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="form-group">
                  <div class="form-group">
                    <label>Area Name</label>
                    <input type="text" class="form-control" placeholder="Area Name" [ngClass]="{ 'is-invalid': submitted && fc.TownName.errors }" (focusout)="onFocusOutEvent($event)" formControlName="TownName" #myname />
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="form-group">
                  <div class="form-group">
                    <label>Area Code</label>
                    <input type="text" class="form-control" placeholder="Area Code" [ngClass]="{ 'is-invalid': submitted && fc.TownCode.errors }" formControlName="TownCode" />
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="form-group">
                  <div class="form-group">
                    <label>Pin Code</label>
                    <input type="text" class="form-control" placeholder="Pin Code" formControlName="PinCode" />
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="form-group">
                  <label> Under</label>
                  <select class="form-control" formControlName="TownUnder">
                    <option [value]="0">Select Area Under</option>
                    <option *ngFor="let item of AreaList"
                            [value]="item.townId">
                      {{item.townName}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="form-group">
                  <label>District</label>
                  <select class="form-control" (change)="changeState($event)" [ngClass]="{ 'is-invalid': ((submitted && fc.DistID.errors)||(submitted && fc.DistID.value==0)) }" formControlName="DistID">
                    <option [value]="0">Select District</option>
                    <option *ngFor="let item of DistList"
                            [value]="item.distId">
                      {{item.distName}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="form-group">
                  <label>State</label>
                  <select class="form-control" [ngClass]="{ 'is-invalid': ((submitted && fc.StateID.errors)||(submitted && fc.StateID.value==0)) }" formControlName="StateID">
                    <option [value]="0">Select State</option>
                    <option *ngFor="let item of StateList"
                            [value]="item.stateId">
                      {{item.stateName}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="form-group">
                  <label>Country</label>
                  <input type="text" class="form-control" placeholder="Country" value="India" formControlName="Country" />
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="form-group">
                  <label>Salesman</label>
                  <select class="form-control" formControlName="SmId">
                    <option [value]="0">Select Salesman</option>
                    <option *ngFor="let item of SalesmanList"
                            [value]="item.smid">
                      {{item.smname}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="button" (click)="clearform()">New</button>
          <button class="btn redbtn" type="submit">Save</button>
          <button class="btn redbtn" type="button" (click)="clearform()">Clear</button>
          <button class="btn redbtn" type="button">Help</button>
        </div>
        </form>
      </div>
    </main>
</div>
  </div>
