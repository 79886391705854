import { formatDate } from '@angular/common';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationStart, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpService } from '../../../../http.service';
import { Item } from 'src/app/shared/multi-dropdown/multi-dropdown.model';
@Component({
  selector: 'app-new-item',
  templateUrl: './new-item.component.html',
  styleUrls: ['./new-item.component.scss']
})
export class NewItemComponent implements OnInit {
  imgUrl: string = "./assets/images/itemcreation/defaultimage.png";
  fileToUpload: File = null;
  AttendMonth: any = "12-Jan-2021";
  AttendMonth1: any = "12-Jan-2021";
  AttendMonth2: any = "12-Jan-2021";
  TaxRateList: any[];
  InputActList: any[];
  OutputActList: any[];
  DivsList: any = [];
  CatgList: any = [];
  SubCatgList: any = [];
  MnfsList: any = [];
  BrandsList: any = [];
  SizesList: any = [];
  ItemsTypeList: any = [];  
  HSNList: any = [];
  PurPackUOMList: any = [];
  SalePackUOMList: any = [];
  PurchaseGSTActsList: any = [];
  CustFieldsList: any = [];
  ItemDiscMastList: any = [];
  form1: FormGroup | undefined;
  @ViewChild('myname') input;
  @ViewChild('labelImport', { static: true })
  labelImport: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  get fc(): any | undefined { return this.form1?.controls; }
  titletextneworedit: string = "New";
  submitted = false;
  selectedFile: File = null;
  reader: any = new FileReader();
  imgUrl2: string = "./assets/images/itemcreation/defaultimage.png"; 
  selectall = false;
  isbothCS: any = false;
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    let date = new Date();
    let previousmonth = new Date();
    this.AttendMonth = previousmonth;
    this.AttendMonth1 = previousmonth;
    this.AttendMonth2 = previousmonth;
    //form Defining Start
    this.form1 = this._fb.group({
      ItemId: [0],
      ItemName: ['', [Validators.required]],
      ItemCode: ['', [Validators.required]],
      ItemEANCode: [''],
      ItemDescription: [''],
      ItemType: [0],
      ItemPack: [''],
      CategoryId: [0],
      MnfId: [0],
      GenID: [0],
      UOMId: [0],
      CompID: [0],
      RptUOMId: [0],
      PurActId: [0],
      SalesActId: [0],
      PurRetActId: [0],
      SalesRetActId: [0],
      ServiceBillActId: [0],
      CNActId: [0],
      DNActId: [0],
      IGSTPurActId: [0],
      IGSTSalActId: [0],
      IGSTPurRetActId: [0],
      IGSTSalesRetActId: [0],
      IGSTServiceBillActId: [0],
      IGSTCNActId: [0],
      IGSTDNActId: [0],
      VATPer: [5],
      VATPer2: [5],
      MinStk: [0],
      MaxStk: [0],
      CreatedBy: [0],
      CreateDate: [''],
      ModifiedBy: [0],
      ModifiedDate: [''],
      Status: [true],
      PRate: [0],
      SRate: [0],
      MRP: [0],
      WsRate: [0],
      PurUOMID: [0],
      SalUOMID: [0],
      CostCalculated: [1],
      RptSel: [0],
      ItemImage: [null],
      UpdateICost: [true],
      ItemDivId: [0],
      SubCategoryId: [0],
      ProductBaseId: [0],
      ItemVariant: [0],
      ItemBarCodePrefix: [''],
      BarCodeType: [0],
      StoreNo: [0],
      Size: ['0'],
      SizeIds: [''],
      CommodityID: [0],
      Margin: [0],
      PurCostCalculated: [0],
      SaleCostCalculated: [0],
      ItemQty: [1],
      DealarRate: [0],
      SPLRate: [0],
      RetailRate: [0],
      Weight: [0],
      SaleDisc: [0],
      Cases: [0],
      WholeSaleType: [''],
      WholeSale: [0],
      RetailType: [''],
      RetailPrice: [0],
      WSPurchaseType: [''],
      WSPurchase: [0],
      DisRetReceivedType: [''],
      DisRetReceived: [0],
      MRPdisc: [0],
      TotSalRate: [0],
      BarCode: [false],
      SGSTRate: [0],
      CGSTRate: [0],
      IGSTRate: [0],
      CessRate: [0],
      SGSTInputAccID: [0],
      CGSTInputAccID: [0],
      IGSTInputAccID: [0],
      CessInputAccID: [0],
      SGSTOutputAccID: [0],
      CGSTOutputAccID: [0],
      CessOutputAccID: [0],
      IGSTOutputAccID: [0],
      IMEINO: [false],
      Taka: [false],
      Alias: [''],
      SuppActid: [0],
      MultiRates: [false],
      MultiRatesWithMMS: [false],
      PRate1: [0],
      SRate1: [0],
      MRP1: [0],
      PRate2: [0],
      SRate2: [0],
      MRP2: [0],
      PRate3: [0],
      SRate3: [0],
      MRP3: [0],
      PRate4: [0],
      SRate4: [0],
      MRP4: [0],
      CommissionType: [1],
      CommissionAmt: [0],
      //Disc_Mast table     
      DiscType: [0],
      Disc: [0],
      Image: [null],
      ImageURL: ['./assets/images/itemcreation/defaultimage.png'],
      CgcessAmt:[0],
      //End
    });
    //End form Defining
    let objdata: any = null;
    let GSTP: any = 5;
    let GSTPf: any = "5.00";
    let editid: any = 0;
    let objimageUrl: any = "./assets/images/itemcreation/defaultimage.png"; 
    if (this.router.getCurrentNavigation() == null) {
    }
    else {
      if (this.router.getCurrentNavigation().extras.state.id != undefined) {
        editid = Number(this.router.getCurrentNavigation().extras.state.id);
        this.titletextneworedit = this.router.getCurrentNavigation().extras.state.text;
        objdata = this.router.getCurrentNavigation().extras.state.obj;
      }
    }
    console.log(objdata);
    //when page Load function
    const request = {
      method: 'GET',
      action_url: 'GetItemsLoad?id=' + editid
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.DivsList = resobj.listdivs;
        this.CatgList = resobj.listcats;
        this.SubCatgList = resobj.listsubcats;
        this.MnfsList = resobj.listmnfs;
        this.SizesList = resobj.listsizes;
        this.TaxRateList = resobj.listtaxtypes;
        this.ItemsTypeList = resobj.listitemtype;
        this.HSNList = resobj.listcommodity;
        this.PurPackUOMList = resobj.listuom;
        this.SalePackUOMList = resobj.listuomsalepack;
        this.PurchaseGSTActsList = resobj.listpuracts;
        this.InputActList = resobj.listgsti;
        this.OutputActList = resobj.listgsto;
        this.CustFieldsList = resobj.listcustfields;
        this.BrandsList = resobj.listitemcomp;
        this.ItemDiscMastList = resobj.listitemdiscmast;
        if (this.HSNList != null) {
          if (this.HSNList.length > 0) {
            let commodityId: any = Number(this.HSNList[0]["commodityId"]);
            this.form1?.patchValue({
              CommodityID: commodityId
            });
          }
        }
        if (this.ItemsTypeList != null) {
          if (this.ItemsTypeList.length > 0) {
            let itemsTypeId: any = Number(this.ItemsTypeList[2]["itemsTypeId"]);
            this.form1?.patchValue({
              ItemType: itemsTypeId
            });
          }
        }
        if (this.SizesList != null) {
          if (this.SizesList.length > 0) {
            this.SizesList.forEach((x: { sizeOrder: any; }) => x.sizeOrder = Number(x.sizeOrder));
            let isselectall: any = false;
            var list = this.SizesList.filter((obj: { state: any; }) => obj.state == false);            
            if (list != null) {
              if (list.length > 0) {
                isselectall = false;
              } else { isselectall = true; }
            }
            this.selectall = isselectall;
          }
        }
        if (objdata == null) {
          if (this.CatgList != null) {
            if (this.CatgList.length > 0) {
              let catId: any = Number(this.CatgList[0]["catId"]);
              this.form1?.patchValue({
                CategoryId: catId
              });
              this.changesubcategory();
            }
          }
        }
        else {
          this.form1?.patchValue({
            CategoryId: Number(objdata.categoryId),
            SubCategoryId: Number(objdata.subCategoryId),
          });          
        }

        if (this.MnfsList != null) {
          if (this.MnfsList.length > 0) {
            let mnfId: any = Number(this.MnfsList[0]["mnfId"]);
            this.form1?.patchValue({
              MnfId: mnfId
            });
          }
        }
        if (objdata == null) {
          if (this.PurPackUOMList != null) {
            if (this.PurPackUOMList.length > 0) {
              let uomid: any = Number(this.PurPackUOMList.find((item: { uomname: any; }) => item.uomname == "NOS").uomid);
              this.form1?.patchValue({
                UOMId: uomid
              });

            }
          }
          let SalePackId: any = 0;
          if (this.SalePackUOMList != null) {
            if (this.SalePackUOMList.length > 0) {
              SalePackId = Number(this.SalePackUOMList[0]["uomid"]);
            }
          }
          this.form1?.patchValue({
            RptUOMId: SalePackId,
          });
        }
        else {
          this.form1?.patchValue({
            UOMId: Number(objdata.uomid),
            RptUOMId: Number(objdata.rptUomid),
          });
        }
        
        this.form1?.patchValue({
          ItemCode: resobj.itemmaxid,
          ItemEANCode: resobj.itemmaxid,
        });
        
        //edit 
        if (objdata != null) {

          if (objdata.imageUrl != null) {
            objimageUrl = (objdata.imageUrl == "" ? "./assets/images/itemcreation/defaultimage.png" : objdata.imageUrl);           
          }

          GSTPf = objdata.vatper == "-1" ? "Exempt" : (objdata.vatper == "0.1" ? objdata.vatper + "0" : objdata.vatper + ".00");
          try {
            if (this.TaxRateList.find((item: { taxName: any; }) => item.taxName == GSTPf) != undefined) {
              GSTP = (this.TaxRateList.find((item: { taxName: any; }) => item.taxName == GSTPf).taxType);
            }
          } catch { GSTP = 5; }
          //Size
          let Size: any = "";
          try {
            if (this.SizesList.find((item: { sizeName: any; }) => item.sizeName == objdata.size) != undefined) {
              Size = (this.SizesList.find((item: { sizeName: any; }) => item.sizeName == GSTPf).sizeId);
            }
          } catch { Size = ""; }
          //end
          //Disc
          let Disc: any = 0; let DiscType: any = 0;
          try {
            if (this.ItemDiscMastList.find((item: { itemId: any; }) => item.itemId == Number(objdata.itemId)) != undefined) {
              Disc = (this.ItemDiscMastList.find((item: { itemId: any; }) => item.itemId == Number(objdata.itemId)).disc);
              DiscType = (this.ItemDiscMastList.find((item: { itemId: any; }) => item.itemId == Number(objdata.itemId)).discType);
            }
          } catch { Disc = 0; }
          //end
          this.isbothCS = false;
          if (Number(objdata.genId) == 1) {
            this.isbothCS = true;
          }
          this.form1?.patchValue({
            ItemId: Number(objdata.itemId),
            ItemName: objdata.itemName,
            ItemCode: objdata.itemCode,
            ItemEANCode: objdata.itemEancode,
            ItemDescription: objdata.itemDescription,
            ItemType: Number(objdata.itemType),
            ItemPack: (objdata.itemPack),
            CategoryId: Number(objdata.categoryId),
            MnfId: Number(objdata.mnfId),
            GenID: Number(objdata.genId),
           /* UOMId: Number(objdata.uomid),*/
            CompID: Number(objdata.compId),
            RptUOMId: Number(objdata.rptUomid),
            PurActId: Number(objdata.purActId),
            SalesActId: Number(objdata.salesActId),
            PurRetActId: Number(objdata.purRetActId),
            SalesRetActId: Number(objdata.salesRetActId),
            ServiceBillActId: Number(objdata.serviceBillActId),
            CNActId: Number(objdata.cnactId),
            DNActId: Number(objdata.dnactId),
            IGSTPurActId: Number(objdata.igstpurActId),
            IGSTSalActId: Number(objdata.igstsalActId),
            IGSTPurRetActId: Number(objdata.igstpurRetActId),
            IGSTSalesRetActId: Number(objdata.igstsalesRetActId),
            IGSTServiceBillActId: Number(objdata.igstserviceBillActId),
            IGSTCNActId: Number(objdata.igstcnactId),
            IGSTDNActId: Number(objdata.igstdnactId),
            VATPer: Number(GSTP),
            VATPer2: Number(GSTP),
            MinStk: Number(objdata.minStk),
            MaxStk: Number(objdata.maxStk),            
            PRate: Number(objdata.prate),
            SRate: Number(objdata.srate),
            MRP: Number(objdata.mrp),
            WsRate: Number(objdata.wsRate),
            PurUOMID: Number(objdata.purUomid),
            SalUOMID: Number(objdata.salUomid),
            CostCalculated: Number(objdata.costCalculated),
            RptSel: Number(objdata.rptSel),
            ItemDivId: Number(objdata.itemDivId),
            /*SubCategoryId: Number(objdata.subCategoryId),*/
            StoreNo: Number(objdata.storeNo),
            Size: Size,
            SizeIds: objdata.size,
            CommodityID: Number(objdata.commodityId),
            Margin: Number(objdata.margin),
            PurCostCalculated: Number(objdata.purCostCalculated),
            SaleCostCalculated: Number(objdata.saleCostCalculated),
            ItemQty: Number(objdata.itemQty),
            DealarRate: Number(objdata.dealarRate),
            SPLRate: Number(objdata.splrate),
            RetailRate: Number(objdata.retailRate),
            Weight: Number(objdata.weight),
            Cases: Number(objdata.cases),      
            BarCode: (objdata.barCode),
            SGSTRate: Number(objdata.sgstrate),
            CGSTRate: Number(objdata.cgstrate),
            IGSTRate: Number(objdata.igstrate),
            CessRate: Number(objdata.cessrate),
            SGSTInputAccID: Number(objdata.sgstinputAccId),
            CGSTInputAccID: Number(objdata.cgstinputAccId),
            IGSTInputAccID: Number(objdata.igstinputAccId),
            CessInputAccID: Number(objdata.cessInputAccId),
            SGSTOutputAccID: Number(objdata.sgstoutputAccId),
            CGSTOutputAccID: Number(objdata.cgstoutputAccId),
            CessOutputAccID: Number(objdata.cessOutputAccId),
            IGSTOutputAccID: Number(objdata.igstoutputAccId),
            IMEINO: (objdata.imeino),
            Taka: (objdata.taka),
            Alias: (objdata.alias),
            MultiRates: (objdata.multiRates),
            MultiRatesWithMMS: (objdata.multiRatesWithMms),
            PRate1: Number(objdata.prate1),
            SRate1: Number(objdata.srate1),
            MRP1: Number(objdata.mrp1),
            PRate2: Number(objdata.prate2),
            SRate2: Number(objdata.srate2),
            MRP2: Number(objdata.mrp2),
            PRate3: Number(objdata.prate3),
            SRate3: Number(objdata.srate3),
            MRP3: Number(objdata.mrp3),
            PRate4: Number(objdata.prate4),
            SRate4: Number(objdata.srate4),
            MRP4: Number(objdata.mrp4),
            CommissionType: (objdata.commissionType == true?1:0),
            CommissionAmt: Number(objdata.commissionAmt),
            ImageURL: objimageUrl,
            //Disc_Mast table     
            DiscType: Number(DiscType),
            Disc: Number(Disc),
            CgcessAmt: Number(objdata.cgcessAmt),
            MRPdisc: Number(objdata.mrpdisc),
            TotSalRate: Number(objdata.totSalRate),
          //End
          });
          this.imgUrl2 = objimageUrl;          
          this.fileInput.nativeElement.click();
        }
        //end
        this.btnbindgstaccounts();
      },
      error => {
        console.log(error);
      });
    //End
  }

  ngOnInit(): void {
    
  }
  changeChargeType() {
    let iscutting: any = this.form1?.get('MultiRates')?.value;
    let isstitching: any = this.form1?.get('MultiRatesWithMMS')?.value;
    let GenID: any = Number(this.form1?.get('GenID')?.value);
    this.isbothCS = GenID == 0 ? true : false;
  }
  checksizes(item: any, val: any) {
    this.SizesList.filter((obj: { sizeId: any; }) => obj.sizeId == item.sizeId).forEach((x: { state: any; }) => x.state = val);
    this.SizesList.forEach((x: { sizeOrder: any; }) => x.sizeOrder = Number(x.sizeOrder));
    let isselectall: any = true;
    var list = this.SizesList.filter((obj: { state: any; }) => obj.state == false);
    if (list != null) {
      if (list.length > 0) {
        isselectall = false;
      }
    }
    this.selectall = isselectall;
  }
  checkallsizes(val: any) {
    this.SizesList.forEach((x: { state: any; }) => x.state = val);
    this.SizesList.forEach((x: { sizeOrder: any; }) => x.sizeOrder = Number(x.sizeOrder));
    let isselectall: any = true;
    var list = this.SizesList.filter((obj: { state: any; }) => obj.state == false);
    if (list != null) {
      if (list.length > 0) {
        isselectall = false;
      }
    }
    this.selectall = isselectall;
  }
  changesizeorder(item: any, val: any) {
    let val2: any = ""; val2 = val.toString();
    val = val2.replace(",", ""); val = val == "" ? 0 : val;
    this.SizesList.filter((obj: { sizeId: any; }) => obj.sizeId == item.sizeId).forEach((x: { sizeOrder: any; }) => x.sizeOrder = Number(val));
  }

  ngAfterViewInit() {
    //Focus Start
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    //End
  }
  
  cancelImageasDefault() {
    this.imgUrl = "./assets/images/itemcreation/defaultimage.png";
    //this.imgUrl2 = "./assets/images/itemcreation/defaultimage.png";
    this.form1?.patchValue({
      ImageURL: ('./assets/images/itemcreation/defaultimage.png'),
    });
  }
  cancelImageasDefaultedit() {
    this.imgUrl = "./assets/images/itemcreation/defaultimage.png";
    try {
      
      this.imgUrl = this.imgUrl2;
      this.form1?.patchValue({
        ImageURL: (this.imgUrl2),
      });
    }
    catch {
      this.imgUrl = "./assets/images/itemcreation/defaultimage.png";
      this.imgUrl2 = "./assets/images/itemcreation/defaultimage.png";
      this.imgUrl = this.imgUrl2;
      this.form1?.patchValue({
        ImageURL: (this.imgUrl2),
      });
    }
  }

  onSelectFile(fileInput: any) {

    this.selectedFile = <File>fileInput.target.files[0];
    this.fileToUpload = this.selectedFile;
    let _reader = new FileReader();
    _reader.onload = (event: any) => {
      this.imgUrl = event.target.result;
      this.form1?.patchValue({
        ImageURL: ('./assets/images/itemcreation/' + this.selectedFile.name),
      });
    }
    _reader.readAsDataURL(this.fileToUpload);

  }

  upload() {
    let ia: any = 0;
    const formData = new FormData();
    formData.append('Image', this.selectedFile);
    formData.append('isaccount', ia);
    const uploadReq = new HttpRequest('POST', `api/upload`, formData, {
      reportProgress: true,
    });
    this.httpClient.request(uploadReq).subscribe(event => {

    });

  }

  PurSalepackCalcPMargin() {
    var PRate = Number(this.form1?.get('PRate')?.value);
    var ItemQty = Number(this.form1?.get('ItemQty')?.value);
    var Margin = Number(this.form1?.get('Margin')?.value);
    //
    var totpr = (PRate / (ItemQty == 0 ? 1 : ItemQty));
    if (Margin == 0) {
      this.form1?.patchValue({
        SRate: 0,
      });
    }
    this.form1?.patchValue({
      PurCostCalculated: totpr,      
    });
    //
  }

  PurSalepackCalcSMargin() {
    var SRate = Number(this.form1?.get('SRate')?.value);
    var ItemQty = Number(this.form1?.get('ItemQty')?.value);
    //
    var totsr = (SRate / (ItemQty == 0 ? 1 : ItemQty));
    this.form1?.patchValue({
      SaleCostCalculated: totsr
    });
    //
    this.changeUomSalePack();
  }

  SaveOrSubmitForm() {
    let SizeIds: any = "";
    try {
      if (this.SizesList != null) {
        this.SizesList.forEach((x: { sizeOrder: any; }) => x.sizeOrder = Number(x.sizeOrder));
        var list = this.SizesList.filter((obj: { state: any; }) => obj.state == true);
        if (list != null) {
          if (list.length > 0) {
            var orderlist = list.sort((a, b) => (a.sizeOrder > b.sizeOrder) ? 1 : -1);
            if (orderlist != null) {
              for (var xx = 0; xx < orderlist.length; xx++) {
                SizeIds += orderlist[xx].sizeName + ',';
              }
            }
            //if (SizeIds.length > 0) {
            //  SizeIds = SizeIds.slice(0, -1);
            //}         
          }
        }
      }
    } catch { }
    this.submitted = true;
    if (this.form1?.invalid) {
      return;
    }
    //
    let myDateTime: any = new Date();
    let sqlTimeAsString = formatDate(new Date(), "yyyy-MM-dd", "en_US");
    let UOMId = Number(this.form1?.get('UOMId')?.value);
    //    
    let itempack: string = "NOS";
    if (this.PurPackUOMList.find((item: { uomid: any; }) => item.uomid == Number(UOMId)) != undefined) {
      try {
        itempack = (this.PurPackUOMList.find((item: { uomid: any; }) => item.uomid == Number(UOMId)).uomname);
      } catch { itempack = "NOS"; }
    }
    //
    let Disc: any = Number(this.form1?.get('Disc')?.value);
    let DiscType: any = Number(this.form1?.get('DiscType')?.value);
    //
    let CommissionTypeupdate: any = true;
    let CommissionType: any = (this.form1?.get('CommissionType')?.value).toString();
    if (CommissionType == "true") { CommissionTypeupdate = true; }
    if (CommissionType == "false") { CommissionTypeupdate = false; }
    if (CommissionType == "0") { CommissionTypeupdate = false; }
    if (CommissionType == "1") { CommissionTypeupdate = true; }
    //
    let GSTP: any = "5"; let GSTPf: any = 0;
    GSTPf = Number(this.form1?.get('VATPer')?.value);
    try {
      if (this.TaxRateList.find((item: { taxType: any; }) => item.taxType == GSTPf) != undefined) {
        GSTP = (this.TaxRateList.find((item: { taxType: any; }) => item.taxType == GSTPf).taxName);
      }
    } catch { GSTP = "5"; }
    //
    this.form1?.patchValue({
      //
      Size: SizeIds,
      //
      ItemPack: itempack,
      CreatedBy: Number(localStorage.getItem("Uid")),
      ModifiedBy: Number(localStorage.getItem("Uid")),
      CreateDate: sqlTimeAsString,
      ModifiedDate: sqlTimeAsString,
      Cases: Number(this.form1?.get('Cases')?.value),
      CategoryId: Number(this.form1?.get('CategoryId')?.value),
      CommissionAmt: Number(this.form1?.get('CommissionAmt')?.value),
      CommissionType: CommissionTypeupdate,
      CommodityID: Number(this.form1?.get('CommodityID')?.value),
      CompID: Number(this.form1?.get('CompID')?.value),
      CostCalculated: Number(this.form1?.get('CostCalculated')?.value),
      DealarRate: Number(this.form1?.get('DealarRate')?.value),
      DisRetReceived: Number(this.form1?.get('DisRetReceived')?.value),
      GenID: Number(this.form1?.get('GenID')?.value),
      ItemDivId: Number(this.form1?.get('ItemDivId')?.value),
      ItemQty: Number(this.form1?.get('ItemQty')?.value),
      ItemType: Number(this.form1?.get('ItemType')?.value),
      ItemVariant: Number(this.form1?.get('ItemVariant')?.value),
      MRP: Number(this.form1?.get('MRP')?.value),
      MRP1: Number(this.form1?.get('MRP1')?.value),
      MRP2: Number(this.form1?.get('MRP2')?.value),
      MRP3: Number(this.form1?.get('MRP3')?.value),
      MRP4: Number(this.form1?.get('MRP4')?.value),
     
      Margin: Number(this.form1?.get('Margin')?.value),
      MaxStk: Number(this.form1?.get('MaxStk')?.value),
      MinStk: Number(this.form1?.get('MinStk')?.value),
      MnfId: Number(this.form1?.get('MnfId')?.value),
      PRate: Number(this.form1?.get('PRate')?.value),
      PRate1: Number(this.form1?.get('PRate1')?.value),
      PRate2: Number(this.form1?.get('PRate2')?.value),
      PRate3: Number(this.form1?.get('PRate3')?.value),
      PRate4: Number(this.form1?.get('PRate4')?.value),
      ProductBaseId: Number(UOMId),
      PurCostCalculated: Number(this.form1?.get('PurCostCalculated')?.value),
      PurUOMID: UOMId,
      RetailPrice: Number(this.form1?.get('RetailPrice')?.value),
      RetailRate: Number(this.form1?.get('RetailRate')?.value),
      RptSel: Number(this.form1?.get('RptSel')?.value),
      RptUOMId: Number(this.form1?.get('RptUOMId')?.value),
      SPLRate: Number(this.form1?.get('SPLRate')?.value),
      SRate: Number(this.form1?.get('SRate')?.value),
      SRate1: Number(this.form1?.get('SRate1')?.value),
      SRate2: Number(this.form1?.get('SRate2')?.value),
      SRate3: Number(this.form1?.get('SRate3')?.value),
      SRate4: Number(this.form1?.get('SRate4')?.value),
      SalUOMID: Number(this.form1?.get('RptUOMId')?.value),
      SaleCostCalculated: Number(this.form1?.get('SaleCostCalculated')?.value),
      SaleDisc: Number(this.form1?.get('SaleDisc')?.value),
      StoreNo: Number(this.form1?.get('StoreNo')?.value),
      SubCategoryId: Number(this.form1?.get('SubCategoryId')?.value),
      SuppActid: Number(this.form1?.get('SuppActid')?.value),      
      UOMId: UOMId,
      VATPer: Number(GSTP),
      WSPurchase: Number(this.form1?.get('WSPurchase')?.value),
      Weight: Number(this.form1?.get('Weight')?.value),
      WholeSale: Number(this.form1?.get('WholeSale')?.value),
      WsRate: Number(this.form1?.get('WsRate')?.value),
      //Disc_master
      Disc: Number(this.form1?.get('Disc')?.value),
      DiscType: Number(this.form1?.get('DiscType')?.value),
      CessRate: Number(this.form1?.get('CessRate')?.value),
      CessInputAccID: Number(this.form1?.get('CessInputAccID')?.value),
      CessOutputAccID: Number(this.form1?.get('CessOutputAccID')?.value),
      CgcessAmt: Number(this.form1?.get('CgcessAmt')?.value),
      TotSalRate: Number(this.form1?.get('TotSalRate')?.value),
      MRPdisc: Number(this.form1?.get('MRPdisc')?.value),
      //
    });
    let iscutting: any = this.form1?.get('MultiRates')?.value;
    let isstitching: any = this.form1?.get('MultiRatesWithMMS')?.value;
    let GenID: any = Number(this.form1?.get('GenID')?.value);
    let fyid: any = 0; let compid: any = 0;
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }
        
    var obj = { objitems: this.form1?.value, Disc: Disc, DiscType: DiscType, compid: compid, fyid: fyid };
    if (this.form1?.invalid) {
      return;
    }   

    this.httpClient.post(this._services.APIUrl + '/InsertItems', obj).subscribe(res => {
      var result: any = res;
      if (result.msg.toString().includes('Successfull')) {

        Swal.fire('Item Creation', result.msg.toString(), 'success');
        //Image Upload
        this.upload();
        //end
        this.clearform();       
      }
      else {
        Swal.fire('Item Creation', result.msg.toString(), 'error');
      }
    },
      error => {
        //Swal.fire('Item Creation', error, 'error');
        console.log(error);
      });
  }

  changesubcategory() {
    var CategoryId = Number(this.form1?.get('CategoryId')?.value);
    let SubCategoryId: any = 0;
    var listcount = null;
    const request = {
      method: 'GET',
      action_url: 'GetSubCategories?id=' + CategoryId
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.SubCatgList = res;
      },
      error => {
        console.log(error);
      });
    //try {
    //  listcount = this.SubCatgList.filter((item: { higherId: any }) => item.higherId === CategoryId);
    //} catch { listcount = null; }
    if (this.SubCatgList != null) {
      if (this.SubCatgList.length > 0) {
        SubCategoryId = Number(this.SubCatgList[0]["catId"]);
      }
    }
    this.form1?.patchValue({
      SubCategoryId: SubCategoryId
    });

  }

  changeUomPurchasePack() {

    var UOMId = Number(this.form1?.get('UOMId')?.value);
    let SalePackId: any = 0;
    var listcount = null;
    const request = {
      method: 'GET',
      action_url: 'GetUomSalePack?id=' + UOMId
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.SalePackUOMList = res;
      },
      error => {
        console.log(error);
      });
    if (this.SalePackUOMList != null) {
      if (this.SalePackUOMList.length > 0) {
        SalePackId = Number(this.SalePackUOMList[0]["uomid"]);
      }
    }
    this.form1?.patchValue({
      RptUOMId: SalePackId
    });   
  }

  changeUomSalePack() {
    //
    let SalePackItemQty: any = 1;
    if (Number(this.form1?.get('UOMId')?.value) == Number(this.form1?.get('RptUOMId')?.value)) { }
    else {
      try {
        if (this.SalePackUOMList.find((item: { uomid: any; }) => item.uomid == Number(this.form1?.get('RptUOMId')?.value)) != undefined) {
          SalePackItemQty = (this.SalePackUOMList.find((item: { uomid: any; }) => item.uomid == Number(this.form1?.get('RptUOMId')?.value)).convQty);
        }
      } catch { SalePackItemQty = 1; }      
    }
    //
    var SRate = Number(this.form1?.get('SRate')?.value);
    var PRate = Number(this.form1?.get('PRate')?.value);
    var ItemQty = Number(this.form1?.get('ItemQty')?.value);

    this.form1?.patchValue({
      ItemQty: SalePackItemQty,
      SaleCostCalculated: (SRate / (ItemQty == 0 ? 1 : ItemQty)),
      PurCostCalculated: (PRate / (ItemQty == 0 ? 1 : ItemQty))
    });
  }

  Marginfocusout() {
    var PRate = Number(this.form1?.get('PRate')?.value);
    var SRate = Number(this.form1?.get('SRate')?.value);
    var Margin = Number(this.form1?.get('Margin')?.value);
    //
    if (PRate > 0 && Margin > 0) {
      SRate = ((Margin * PRate) / 100) + PRate;
    }
    if (Margin >= 0 && Margin <= 99) { }
    else {
      if (Margin > 99) {
        Margin = 0;
        Swal.fire('', 'Percentage must be in 0 to 99 only.', 'error');
      }
      else { Margin = 0; }
    }
    this.form1?.patchValue({
      SRate: SRate,
      Margin: Margin
    });
    //
  }

  SaleRatefocusout() {

    var SaleRate = Number(this.form1?.get('SRate')?.value);
    var PurRate = Number(this.form1?.get('PRate')?.value);
    var Margin = Number(this.form1?.get('Margin')?.value);

    var SRate = Number(this.form1?.get('SRate')?.value);
    var PRate = Number(this.form1?.get('PRate')?.value);
    var ItemQty = Number(this.form1?.get('ItemQty')?.value);
    //
    if (SRate > 0) {     
      if (SRate < PRate) {
        Swal.fire('','Sale Rate should be greater than Purchase Rate!',  'error');
        SRate = 0;
        this.form1?.patchValue({
          SRate: 0,
        });
      }
      if (SRate > 0) {
        this.form1?.patchValue({
          SRate: SRate,
          SaleCostCalculated: (SRate / (ItemQty == 0 ? 1 : ItemQty)),
        });
      }
    }
    //
    var SaleRates = Number(this.form1?.get('SRate')?.value);
    var PurRates = Number(this.form1?.get('PRate')?.value);   
    //
    if (PurRates > 0 && SaleRates > 0) {
      if (SaleRate > PurRate) {
        Margin = ((SaleRate - PurRate) / PurRate) * 100;
      }
      else if (PurRate > SaleRate) {
        Margin = ((SaleRate - PurRate) / PurRate) * 100;       
      }
      else if (PurRate == SaleRate) {
        Margin = 0;
      }
      this.form1?.patchValue({
        Margin: Margin,
      });
    }
    var SlRate = Number(this.form1?.get('SRate')?.value);
    var PuRate = Number(this.form1?.get('PRate')?.value);
    //
    this.form1?.patchValue({
      SaleCostCalculated: (SlRate / (ItemQty == 0 ? 1 : ItemQty)),
      PurCostCalculated: (PuRate / (ItemQty == 0 ? 1 : ItemQty))
    });
    //
  }

  clearform() {
    this.isbothCS = false;
    const request = {
      method: 'GET',
      action_url: 'GetItemMaxId'
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resmsg:any = res;
        this.form1?.patchValue({
          ItemId: 0,
          ItemName: '',
          ItemCode: resmsg.msg,
          ItemEANCode: resmsg.msg,
          ItemDescription: '',
          ItemType: 0,
          ItemPack: '',
          CategoryId: 0,
          MnfId: 0,
          GenID: 0,
          UOMId: 0,
          CompID: 0,
          RptUOMId: 0,
          PurActId: 0,
          SalesActId: 0,
          PurRetActId: 0,
          SalesRetActId: 0,
          ServiceBillActId: 0,
          CNActId: 0,
          DNActId: 0,
          IGSTPurActId: 0,
          IGSTSalActId: 0,
          IGSTPurRetActId: 0,
          IGSTSalesRetActId: 0,
          IGSTServiceBillActId: 0,
          IGSTCNActId: 0,
          IGSTDNActId: 0,
          VATPer: 5,
          VATPer2: 5,
          MinStk: 0,
          MaxStk: 0,
          CreatedBy: 0,
          CreateDate: '',
          ModifiedBy: 0,
          ModifiedDate: '',
          Status: true,
          PRate: 0,
          SRate: 0,
          MRP: 0,
          WsRate: 0,
          PurUOMID: 0,
          SalUOMID: 0,
          CostCalculated: 1,
          RptSel: 0,
          ItemImage: null,
          UpdateICost: true,
          ItemDivId: 0,
          SubCategoryId: 0,
          ProductBaseId: 0,
          ItemVariant: 0,
          ItemBarCodePrefix: '',
          BarCodeType: 0,
          StoreNo: 0,
          Size: '0',
          SizeIds: '',
          CommodityID: 0,
          Margin: 0,
          PurCostCalculated: 0,
          SaleCostCalculated: 0,
          ItemQty: 1,
          DealarRate: 0,
          SPLRate: 0,
          RetailRate: 0,
          Weight: 0,
          SaleDisc: 0,
          Cases: 0,
          WholeSaleType: '',
          WholeSale: 0,
          RetailType: '',
          RetailPrice: 0,
          WSPurchaseType: '',
          WSPurchase: 0,
          DisRetReceivedType: '',
          DisRetReceived: 0,
          MRPdisc: 0,
          TotSalRate: 0,
          BarCode: false,
          SGSTRate: 0,
          CGSTRate: 0,
          IGSTRate: 0,
          CessRate: 0,
          SGSTInputAccID: 0,
          CGSTInputAccID: 0,
          IGSTInputAccID: 0,
          CessInputAccID: 0,
          SGSTOutputAccID: 0,
          CGSTOutputAccID: 0,
          CessOutputAccID: 0,
          IGSTOutputAccID: 0,
          IMEINO: false,
          Taka: false,
          Alias: '',
          SuppActid: 0,
          MultiRates: false,
          MultiRatesWithMMS: false,
          PRate1: 0,
          SRate1: 0,
          MRP1: 0,
          PRate2: 0,
          SRate2: 0,
          MRP2: 0,
          PRate3: 0,
          SRate3: 0,
          MRP3: 0,
          PRate4: 0,
          SRate4: 0,
          MRP4: 0,
          CommissionType: 1,
          CommissionAmt: 0,
          //Disc_Mast table     
          DiscType: 0,
          Disc: 0,
          Image: [null],
          ImageURL: ['./assets/images/itemcreation/defaultimage.png'],
          CgcessAmt:0,
          //End
        });
        const invalidControl = this.input.nativeElement;
        invalidControl.focus();
        //
        if (this.HSNList != null) {
          if (this.HSNList.length > 0) {
            let commodityId: any = Number(this.HSNList[0]["commodityId"]);
            this.form1?.patchValue({
              CommodityID: commodityId
            });
          }
        }
        if (this.ItemsTypeList != null) {
          if (this.ItemsTypeList.length > 0) {
            let itemsTypeId: any = Number(this.ItemsTypeList[2]["itemsTypeId"]);
            this.form1?.patchValue({
              ItemType: itemsTypeId
            });
          }
        }
        if (this.CatgList != null) {
          if (this.CatgList.length > 0) {
            let catId: any = Number(this.CatgList[0]["catId"]);
            this.form1?.patchValue({
              CategoryId: catId
            });
            this.changesubcategory();
          }
        }
        if (this.MnfsList != null) {
          if (this.MnfsList.length > 0) {
            let mnfId: any = Number(this.MnfsList[0]["mnfId"]);
            this.form1?.patchValue({
              MnfId: mnfId
            });
          }
        }
        if (this.PurPackUOMList != null) {
          if (this.PurPackUOMList.length > 0) {
            let uomid: any = Number(this.PurPackUOMList.find((item: { uomname: any; }) => item.uomname == "NOS").uomid);
            this.form1?.patchValue({
              UOMId: uomid
            });

          }
        }
        let SalePackId: any = 0;
        if (this.SalePackUOMList != null) {
          if (this.SalePackUOMList.length > 0) {
            SalePackId = Number(this.SalePackUOMList[0]["uomid"]);
          }
        }
        this.form1?.patchValue({
          RptUOMId: SalePackId,
        });
        this.btnbindgstaccounts();
        if (this.SizesList != null) {
          if (this.SizesList.length > 0) {
            this.SizesList.forEach((x: { sizeOrder: any; }) => x.sizeOrder = 0);
            this.SizesList.forEach((x: { state: any; }) => x.state = false);    
          }
        }
        this.selectall = false;
       //end
      },
      error => {
        console.log(error);
      });    
    this.titletextneworedit = "New";
    this.submitted = false;
    this.imgUrl = "./assets/images/itemcreation/defaultimage.png";
   //end
  }

  btnbindgstaccounts() {
    var GSTPf = Number(this.form1?.get('VATPer')?.value);
    this.form1?.patchValue({
      SGSTRate: 0,
      CGSTRate: 0,
      IGSTRate: 0,
      CessRate: 0,
      SGSTInputAccID: 0,
      CGSTInputAccID: 0,
      IGSTInputAccID: 0,
      CessInputAccID: 0,
      SGSTOutputAccID: 0,
      CGSTOutputAccID: 0,
      IGSTOutputAccID: 0,
      CessOutputAccID: 0,
      //
      PurActId: 0,
      SalesActId: 0,
      PurRetActId: 0,
      SalesRetActId: 0,
      ServiceBillActId: 0,
      CNActId: 0,
      DNActId: 0,
      IGSTPurActId: 0,
      IGSTSalActId: 0,
      IGSTPurRetActId: 0,
      IGSTSalesRetActId: 0,
      IGSTServiceBillActId: 0,
      IGSTCNActId: 0,
      IGSTDNActId: 0,
      VATPer2: GSTPf,
      //
    });

    if (GSTPf == 0 || GSTPf == 1 || GSTPf == 2) { }
    else {
      let GSTP: any = "";
      try {
        if (this.TaxRateList.find((item: { taxType: any; }) => item.taxType == GSTPf) != undefined) {
          GSTP = (this.TaxRateList.find((item: { taxType: any; }) => item.taxType == GSTPf).taxName);
        }
      } catch { GSTP = "0"; }
      this.form1?.patchValue({
        SGSTRate: (Number(GSTP) / 2),
        CGSTRate: (Number(GSTP) / 2),
        IGSTRate: Number(GSTP),
        CessRate: 0,
      });
      const request = {
        method: 'GET',
        action_url: 'BindPurAccounts?TaxPer=' + Number(GSTP)
      };
      this._services.doHttp(request).subscribe(
        res => {
          let resobj: any = res;

          this.form1?.patchValue({
            SGSTInputAccID: Number(resobj.sgstacts.actidip),
            CGSTInputAccID: Number(resobj.cgstacts.actidip),
            IGSTInputAccID: Number(resobj.igstacts.actidip),
            CessInputAccID: Number(resobj.cessacts.actidip),
            SGSTOutputAccID: Number(resobj.sgstacts.actidop),
            CGSTOutputAccID: Number(resobj.cgstacts.actidop),
            IGSTOutputAccID: Number(resobj.igstacts.actidop),
            CessOutputAccID: Number(resobj.cessacts.actidop),
            //
            PurActId: Number(resobj.puraccts.gstpuractid),
            SalesActId: Number(resobj.puraccts.gstsaleactid),
            PurRetActId: Number(resobj.puraccts.gstpurretactid),
            SalesRetActId: Number(resobj.puraccts.gstsaleretactid),
            ServiceBillActId: Number(resobj.puraccts.gstsbactid),
            CNActId: Number(resobj.puraccts.gstcnactid),
            DNActId: Number(resobj.puraccts.gstdnactid),
            IGSTPurActId: Number(resobj.puraccts.igstpuractid),
            IGSTSalActId: Number(resobj.puraccts.igstsaleactid),
            IGSTPurRetActId: Number(resobj.puraccts.igstpurretactid),
            IGSTSalesRetActId: Number(resobj.puraccts.igstsaleretactid),
            IGSTServiceBillActId: Number(resobj.puraccts.igstsbactid),
            IGSTCNActId: Number(resobj.puraccts.igstcnactid),
            IGSTDNActId: Number(resobj.puraccts.igstdnactid),
            //
          });
        },
        error => {
          console.log(error);
        });

    }

  }
  btnbindgstaccounts2() {
    var GSTPf = Number(this.form1?.get('VATPer2')?.value);
    this.form1?.patchValue({
      VATPer: GSTPf
    });
    this.btnbindgstaccounts();
  }

  //Cust fields
  onStartDateTimeChange(item: any, dt: any, indx: any) {
    if (indx == 0) {
      this.AttendMonth = dt;
    }
    else if (indx == 1) {
      this.AttendMonth1 = dt;
    }
    else {
      this.AttendMonth2 = dt;
    }
    let dtS = formatDate(new Date(dt), 'dd/MMM/yyyy', 'en_US');
    this.CustFieldsList.find((x: { cfid: any; }) => x.cfid == Number(item.cfid)).cfvalue2 = dtS;
  }

  splitDescription(theString: string) {
    return theString.split(',');
  }

  changeCustfieldval(item: any, event: any) {
    this.CustFieldsList.find((x: { cfid: any; }) => x.cfid == Number(item.cfid)).cfvalue2 = event.target.value;
    
  }
  //end
}

