import { Component, ElementRef, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from 'src/app/http.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcelService } from '../../../excel.service';

@Component({
  selector: 'app-daywisereport',
  templateUrl: './daywisereport.component.html',
  styleUrls: ['./daywisereport.component.scss']
})
export class DaywisereportComponent implements OnInit {
  daywisebytext: any = "ord";
  daywisebytexttitle: any = "Order";
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title: any = 'Order Report Items Wise Summary';
  CompName: any = ""; Address1: any = "";
  totqty: any = 0; totbillscount: any = 0;
  totnetvalue: any = 0;
  public itemlist: any = [];
  public bottomitemlist: any = [];
  cssPrintingPath: any = "./assets/fonts/Printing.css";
  today: Date = new Date();
  pipe = new DatePipe('en-US');
  todayWithPipe = null;
  BranchList: any = [];
  Fromdate: any = "12-Jan-2021";
  Todate: any = "12-Jan-2021";
  form1: FormGroup | undefined;
  //
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private datePipe: DatePipe, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute, private excelService: ExcelService) {

    this.todayWithPipe = this.pipe.transform(Date.now(), 'h:mm:ss a');
    let previousmonth = new Date();
    this.Fromdate = previousmonth;
    this.Todate = previousmonth;
    let todaydatestr: any = ""; todaydatestr = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.form1 = this._fb.group({
      BranchID: [0],
      Fromdt: [todaydatestr],
      Todt: [todaydatestr]
    });
    this.bindBranchs();

    if (this.router.getCurrentNavigation() == null) {
    }
    else {

      if (this.router.getCurrentNavigation().extras != undefined) {
        if (this.router.getCurrentNavigation().extras.state != undefined) {
          if (this.router.getCurrentNavigation().extras.state.text != undefined) {
            this.daywisebytext = this.router.getCurrentNavigation().extras.state.text;
          }
        }
      }
    }
    //Fromdt
    let Fromdate: any = formatDate(new Date(), 'dd-MM-yyyy', 'en_US');
    var Fromdt = this.form1?.get('Fromdt').value;
    if (Fromdt != "") {
      let Fromdtf: any = Fromdt.slice(0, 10);
      Fromdate = formatDate(new Date(Fromdtf.toString().trim()), 'dd-MM-yyyy', 'en_US');
    }
    //end Fromdt
    let todate: any = formatDate(new Date(), 'dd-MM-yyyy', 'en_US');
    var Todt = this.form1?.get('Todt').value;
    if (Todt != "") {
      let Todtf: any = Todt.slice(0, 10);
      todate = formatDate(new Date(Todtf.toString().trim()), 'dd-MM-yyyy', 'en_US');
    }
    //end todate
    this.daywisebytexttitle = "Orders From " + Fromdate + " To " + todate + "";
    if (this.daywisebytext.length > 0) {
      if (this.daywisebytext == 'ord') {
        this.daywisebytexttitle = "Orders From " + Fromdate + " To " + todate + "";
      }
      else if (this.daywisebytext == 'ic') {
        this.daywisebytexttitle = "Issue To Cutting Master From " + Fromdate + " To " + todate + "";
      }
      else if (this.daywisebytext == 'rc') {
        this.daywisebytexttitle = "Receive From Cutting Master From " + Fromdate + " To " + todate + "";
      }
      else if (this.daywisebytext == 'is') {
        this.daywisebytexttitle = "Issue To Stitching Master From " + Fromdate + " To " + todate + "";
      }
      else if (this.daywisebytext == 'rs') {
        this.daywisebytexttitle = "Receive From Stitching Master From " + Fromdate + " To " + todate + "";
      }
      else if (this.daywisebytext == 'del') {
        this.daywisebytexttitle = "Delivery From " + Fromdate + " To " + todate + "";
      }

      this.SearchOrSubmitForm();
    }

  }
  ngOnInit(): void {

  }
  ExportTOExcel() {
  }
  generateExcel() {
    let brid = 0; brid = Number(this.form1?.get('BranchID').value);
    if (isNaN(brid)) {
      brid = 0;
    }
    //Fromdt
    let Fromdate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    var Fromdt = this.form1?.get('Fromdt').value;
    if (Fromdt != "") {
      let Fromdtf: any = Fromdt.slice(0, 10);
      Fromdate = formatDate(new Date(Fromdtf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    }
    //end Fromdt
    let todate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    var Todt = this.form1?.get('Todt').value;
    if (Todt != "") {
      let Todtf: any = Todt.slice(0, 10);
      todate = formatDate(new Date(Todtf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    }
    //end todate
    const request = {
      method: 'GET',
      action_url: 'GetOrdersItemwiseDetailedExcel?brid=' + brid + '&Fdate=' + Fromdate + '&Tdate=' + todate + '&daywiseby=' + this.daywisebytext + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          let resobj: any = res;
          if (resobj != null) {
            if (this.daywisebytext == "" || this.daywisebytext == "ord") {

              this.excelService.generateExcel(resobj, this.daywisebytext, this.daywisebytexttitle, this.CompName, this.Address1);
            }
            else if (this.daywisebytext == "ic" || this.daywisebytext == "rc" || this.daywisebytext == "is" || this.daywisebytext == "rs") {
              this.excelService.generateExcelWorkEntryTransactions(resobj, this.daywisebytext, this.daywisebytexttitle, this.CompName, this.Address1);
            }
            else if (this.daywisebytext == "del") {
              this.excelService.generateExcelDeliveryTransactions(resobj, this.daywisebytext, this.daywisebytexttitle, this.CompName, this.Address1);
            }
          }
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });

  }
  SearchOrSubmitForm() {
    this.totnetvalue = 0; this.totqty = 0; this.totbillscount = 0;
    let brid = 0; brid = Number(this.form1?.get('BranchID').value);
    if (isNaN(brid)) {
      brid = 0;
    }
    //Fromdt
    let Fromdate: any = formatDate(new Date(), 'dd-MM-yyyy', 'en_US');
    var Fromdt = this.form1?.get('Fromdt').value;
    if (Fromdt != "") {
      let Fromdtf: any = Fromdt.slice(0, 10);
      Fromdate = formatDate(new Date(Fromdtf.toString().trim()), 'dd-MM-yyyy', 'en_US');
    }
    //end Fromdt
    let todate: any = formatDate(new Date(), 'dd-MM-yyyy', 'en_US');
    var Todt = this.form1?.get('Todt').value;
    if (Todt != "") {
      let Todtf: any = Todt.slice(0, 10);
      todate = formatDate(new Date(Todtf.toString().trim()), 'dd-MM-yyyy', 'en_US');
    }
    //end todate
    this.daywisebytexttitle = "Orders From " + Fromdate + " To " + todate + "";
    if (this.daywisebytext.length > 0) {
      if (this.daywisebytext == 'ord') {
        this.daywisebytexttitle = "Orders From " + Fromdate + " To " + todate + "";
      }
      else if (this.daywisebytext == 'ic') {
        this.daywisebytexttitle = "Issue To Cutting Master From " + Fromdate + " To " + todate + "";
      }
      else if (this.daywisebytext == 'rc') {
        this.daywisebytexttitle = "Receive From Cutting Master From " + Fromdate + " To " + todate + "";
      }
      else if (this.daywisebytext == 'is') {
        this.daywisebytexttitle = "Issue To Stitching Master From " + Fromdate + " To " + todate + "";
      }
      else if (this.daywisebytext == 'rs') {
        this.daywisebytexttitle = "Receive From Stitching Master From " + Fromdate + " To " + todate + "";
      }
      else if (this.daywisebytext == 'del') {
        this.daywisebytexttitle = "Delivery From " + Fromdate + " To " + todate + "";
      }
    }
    //
    let fyid: any = 0; let compid: any = 0;
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }
    const request = {
      method: 'GET',
      action_url: 'GetOrdersItemwiseDetailed?brid=' + brid + '&Fdate=' + Fromdate + '&Tdate=' + todate + '&daywiseby=' + this.daywisebytext + '&compid=' + compid + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          let resobj: any = res;
          if (resobj != null) {
            this.CompName = resobj.objcomp.compName;
            this.Address1 = resobj.objcomp.address1;
          }
          if (resobj.list == null) {
            this.itemlist = [];
          }
          else {
            this.itemlist = resobj.list;
          }
          if (resobj.objbottom != null) {
            if (resobj.objbottom.listitems != null) {
              if (resobj.objbottom.listitems.length > 0) {
                this.totnetvalue = resobj.objbottom.totnetvalue;
                this.totbillscount = resobj.objbottom.totbillscount;
                this.totqty = resobj.objbottom.totitemscount;
                this.bottomitemlist = resobj.objbottom.listitems;
              }
            }
          }
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  bindBranchs() {
    const request = {
      method: 'GET',
      action_url: 'GetBranch'
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.BranchList = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  onStartDateTimeChange(dt: any) {
    this.Fromdate = dt;
    let dtS = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      Fromdt: dtS
    });
  }
  onStartDateTimeChange2(dt: any) {
    this.Todate = dt;
    let dtS = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      Todt: dtS
    });
  }
}
