import { Component, ElementRef, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from '../../../../http.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-paymentlist',
  templateUrl: './paymentlist.component.html',
  styleUrls: ['./paymentlist.component.css']
})
export class PaymentlistComponent implements OnInit {
  form1: FormGroup | undefined;
  isShown: boolean = false;
  itemlist: any = [];
  BranchList: any = [];
  Fromdate: any = "12-Jan-2021";
  Todate: any = "12-Jan-2021";
  public btntextNew = { state: { id: 0, text: 'New', obj: null } };
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private datePipe: DatePipe, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    let previousmonth = new Date();
    this.Fromdate = previousmonth;
    this.Todate = previousmonth;
    let todaydatestr: any = ""; todaydatestr = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.form1 = this._fb.group({
      BranchID: [0],
      Fromdt: [todaydatestr],
      Todt: [todaydatestr]
    });
    this.bindBranchs();
    this.SearchOrSubmitForm();
  }
  ngOnInit(): void {

  }
  onStartDateTimeChange(dt: any) {
    this.Fromdate = dt;
    let dtS = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      Fromdt: dtS
    });
  }
  onStartDateTimeChange2(dt: any) {
    this.Todate = dt;
    let dtS = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      Todt: dtS
    });
  }
  bindlist() {
    let brid = 0; brid = Number(this.form1?.get('BranchID').value);
    if (isNaN(brid)) {
      brid = 0;
    }
    //Fromdt
    let Fromdate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    var Fromdt = this.form1?.get('Fromdt').value;
    if (Fromdt != "") {
      let Fromdtf: any = Fromdt.slice(0, 10);
      Fromdate = formatDate(new Date(Fromdtf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    }
    //end Fromdt
    let todate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    var Todt = this.form1?.get('Todt').value;
    if (Todt != "") {
      let Todtf: any = Todt.slice(0, 10);
      todate = formatDate(new Date(Todtf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    }
    const request = {
      method: 'GET',
      action_url: 'GetPayments?brid=' + brid + '&Fdate=' + Fromdate + '&Tdate=' + todate + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.itemlist = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  bindBranchs() {
    const request = {
      method: 'GET',
      action_url: 'GetBranch'
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.BranchList = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  editformbyid(item: any) {
    this.router.navigateByUrl('/newpayment', { state: { id: item.docId, text: 'Edit', obj: item } });
  }
  deleteformbyid(item: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        //      
        var jsondata = { id: Number(item) };
        this.httpClient.post(this._services.APIUrl + '/DeletePaymentEntry', Number(item)).subscribe(res => {
          var result: any = res;

          if (result.msg != null) {
            if (result.msg.toString().includes('Successfull')) {
              Swal.fire('Deleted!', result.msg.toString(), 'success');
              this.bindlist();
            }
            else {
              Swal.fire('Failed to Delete!', result.msg.toString(), 'error');
            }
          }
        },
          error => {
            console.log(error);
          });
        //
      }
    });

  }
  SearchOrSubmitForm() {
    let brid = 0; brid = Number(this.form1?.get('BranchID').value);
    if (isNaN(brid)) {
      brid = 0;
    }
    //Fromdt
    let Fromdate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    var Fromdt = this.form1?.get('Fromdt').value;
    if (Fromdt != "") {
      let Fromdtf: any = Fromdt.slice(0, 10);
      Fromdate = formatDate(new Date(Fromdtf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    }
    //end Fromdt
    let todate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    var Todt = this.form1?.get('Todt').value;
    if (Todt != "") {
      let Todtf: any = Todt.slice(0, 10);
      todate = formatDate(new Date(Todtf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    }
    //end todate
    const request = {
      method: 'GET',
      action_url: 'GetPayments?brid=' + brid + '&Fdate=' + Fromdate + '&Tdate=' + todate + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.itemlist = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });

  }
  reloadComponent() {
    localStorage.setItem("isrefresh", "1");
    this.router.navigateByUrl('/login');
  }
}
