import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/index';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  currenturl: any = "";
  public token: any = false;
  public actId: any = false;
  public actName: any = false;
  public townName: any = false;
  public userType: any = false;
  public validations = {
    email: /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i,
    otp: /^\d{6}$/
  };
  public info = {
    service_url: 'http://52504/api/'
  };
  public APIUrl = "http://52504/api";
  url: any = "";
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    //this.info['service_url'] = baseUrl + 'api/';
    //this.APIUrl = baseUrl + 'api';
    if (baseUrl.includes("localhost")) {  }
    else {
      baseUrl = "http://kliqss.in/";
    }    
    this.info['service_url'] = baseUrl + 'api/';
    this.APIUrl = baseUrl + 'api';
  }

  public doHttp(request) {
    let options;
    //if (this.token) {
    //  options = new HttpHeaders()
    //    .set('Content-Type', 'application/json')
    //    .set('Access-Control-Allow-Origin', 'AllowOrigin')
    //    .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    //    .set('pragma', 'no-cache')
    //    .set('Authorization', `Bearer ${this.token}`);
    //} else {
      options = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', 'AllowOrigin')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0');
    //}

    if (request.method === 'GET') {
      const params = '';
      return this.http.get(this.info['service_url'] + request.action_url + params, {
        headers: options
      });
    } else if (request.method === 'POST') {
      return this.http.post(this.info['service_url'] + request.action_url, request.params, {
        headers: options
      });
    } else if (request.method === 'PUT') {
      return this.http.put(this.info['service_url'] + request.action_url, JSON.stringify(request.params), {
        headers: options
      });
    } else if (request.method === 'DELETE') {
      return this.http.delete(this.info['service_url'] + request.action_url, {
        headers: options
      });
    }
  }


  public doHttp1(request) {
    let options;
    if (this.token) {
      options = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('token', this.token);
    } else {
      options = new HttpHeaders()
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0');
    }

    if (request.method === 'GET') {
      const params = '';
      return this.http.get(request.action_url + params, {
        headers: options
      });
    } else if (request.method === 'POST') {
      return this.http.post(request.action_url, request.params, {
        headers: options
      });
    } else if (request.method === 'PUT') {
      return this.http.put(request.action_url, JSON.stringify(request.params), {
        headers: options
      });
    } else if (request.method === 'DELETE') {
      return this.http.delete(request.action_url, {
        headers: options
      });
    }
  }

  UsersInsert(val: any) {
    return this.http.post(this.APIUrl + '/UsersInsert', val);
  }
  /**
   * store user data
   * @param data
   */
  public storeUserData(data) {
    if (data['user_type'] = 'user') {
      this.token = data['access_token'];
      this.actId = data['act_id'];
      this.actName = data['act_name'];
      this.townName = data['townname'];
      this.userType = data['user_type'];
      this.setItem('access_token', data['access_token']);
      this.setItem('act_id', data['act_id']);
      this.setItem('act_name', data['act_name']);
      this.setItem('townname', data['townname']);
      this.setItem('user_type', data['user_type']);
    }

  }

  /**
   * stoe key and value in local storage
   * @param key
   * @param item
   */
  public setItem(key, item) {
    //console.log(key, item);
    window.localStorage.setItem(key, item);
  }

  /**
   * get value from local stoarge
   * @param key
   * @returns {string}
   */
  public getItem(key) {
    let temp = window.localStorage.getItem(key);
    return temp;
  }

  public removeItem(key) {
    window.localStorage.removeItem(key);
  }

  public errorMsg(error) {
    if (error.status === 401) {
      this.logout();
    }
  }


  public logout() {
    this.token = false;
    this.actId = false;
    this.actName = false;
    this.townName = false;
    this.removeItem('access_token');
    this.removeItem('act_id');
    this.removeItem('act_name');
    this.removeItem('townname');

  }

}
