import { Component, ElementRef, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from '../../../../http.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-delivery-entry',
  templateUrl: './delivery-entry.component.html',
  styleUrls: ['./delivery-entry.component.scss']
})
export class DeliveryEntryComponent implements OnInit {
  form1: FormGroup | undefined;
  isShown: boolean = false;
  itemlist: any = [];
  BranchList: any = [];
  Fromdate: any = "12-Jan-2021";
  Todate: any = "12-Jan-2021";
  isreadytodelv: any = 0;
  public btntextNew = { state: { id: 0, text: 'New', obj: null } };
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private datePipe: DatePipe, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    let previousmonth = new Date();
    this.Fromdate = previousmonth;
    this.Todate = previousmonth;
    let todaydatestr: any = ""; todaydatestr = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.form1 = this._fb.group({
      BranchID: [0],
      actname: [''],
      actcode: [''],
      mobileno: [''],
      status:[0],
      Fromdt: [todaydatestr],
      Todt: [todaydatestr],
      isreadytodelv:[0],
    });
    this.SearchOrSubmitForm();
  }
  ngOnInit(): void {
  }
  onStartDateTimeChange(dt: any) {
    this.Fromdate = dt;
    let dtS = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      Fromdt: dtS
    });
  }
  onStartDateTimeChange2(dt: any) {
    this.Todate = dt;
    let dtS = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      Todt: dtS
    });
  }
  bindlist() {
    const request = {
      method: 'GET',
      action_url: 'GetDeliveryDetails?isreadytodelv=1'
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.itemlist = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  editformbyid(item: any) {
    this.router.navigateByUrl('/new-delivery', { state: { id: item.docId, text: 'Edit', obj: item } });
  }
  deleteformbyid(item: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        //      
        var jsondata = { id: Number(item) };
        this.httpClient.post(this._services.APIUrl + '/DeleteDeliveryEntry', Number(item)).subscribe(res => {
          var result: any = res;

          if (result.msg != null) {
            if (result.msg.toString().includes('Successfull')) {
              Swal.fire('Deleted!', result.msg.toString(), 'success');
              this.SearchOrSubmitForm();
            }
            else {
              Swal.fire('Failed to Delete!', result.msg.toString(), 'error');
            }
          }
        },
          error => {
            console.log(error);
          });
        //
      }
    });

  }
  SearchOrSubmitForm() {
    //    
    let actname = this.form1?.get('actname').value;
    let actcode = this.form1?.get('actcode').value;
    let mobileno = this.form1?.get('mobileno').value;
    //let status = Number(this.form1?.get('status').value);
    //Fromdt
    let Fromdate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    var Fromdt = this.form1?.get('Fromdt').value;
    if (Fromdt != "") {
      let Fromdtf: any = Fromdt.slice(0, 10);
      Fromdate = formatDate(new Date(Fromdtf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    }
    //end Fromdt
    let todate: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    var Todt = this.form1?.get('Todt').value;
    if (Todt != "") {
      let Todtf: any = Todt.slice(0, 10);
      todate = formatDate(new Date(Todtf.toString().trim()), 'yyyy-MM-dd', 'en_US');
    }
    //end todate
    const request = {
      method: 'GET',
      action_url: 'GetDeliveryDetails?actname=' + actname + '&actcode=' + actcode + '&mobileno=' + mobileno + '&Fdate=' + Fromdate + '&Tdate=' + todate + '&isreadytodelv=0'
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.itemlist = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }

}
