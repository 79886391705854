import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../../http.service';

@Component({
  selector: 'app-order-entry-images',
  templateUrl: './order-entry-images.component.html',
  styleUrls: ['./order-entry-images.component.scss']
})
export class OrderEntryImagesComponent implements OnInit {
  imgUrl: string = "./assets/images/1.jpg";
  imgUrle: string = "./assets/images/1.jpg";
  @ViewChild('fileInput') fileInput: ElementRef;
  form1: FormGroup | undefined;  
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    this.form1 = this._fb.group({
      ImageURL: ['./assets/images/1.jpg']
    });
  }
  ngOnInit(): void {
  }
  cancelImageasDefault() {
    this.imgUrl = "./assets/images/1.jpg";
    this.form1?.patchValue({
      ImageURL: ('./assets/images/1.jpg'),
    });
  }
  cancelImageasDefaultedit() {
    this.imgUrl = "./assets/images/1.jpg";
    try {

      this.imgUrl = this.imgUrle;
      this.form1?.patchValue({
        ImageURL: (this.imgUrle),
      });
    }
    catch {
      this.imgUrl = "./assets/images/1.jpg";
      this.imgUrle = "./assets/images/1.jpg";
      this.imgUrl = this.imgUrle;
      this.form1?.patchValue({
        ImageURL: (this.imgUrle),
      });
    }
  }

  onSelectFile(fileInput: any) {
    let selectedFile: File = null;
    selectedFile = <File>fileInput.target.files[0];
    let _reader = new FileReader();
    _reader.onload = (event: any) => {
      this.imgUrl = event.target.result;
      this.form1?.patchValue({
        ImageURL: ('./assets/images/transactions/' + selectedFile.name),
      });
    }
    _reader.readAsDataURL(selectedFile);

  }



}
