<div class="contentMain">
  <main class="row">
    <div class="card">
      <div class="card-header mainheader header-container-btn">
        <h5>Work Entry {{HeadingText}}</h5>
        <button class="btn redbtn" [routerLink]="[]" (click)="navigatepage()" type="button">Create New</button>
      </div>
      <form class="form-inline custom-form-inline" [formGroup]="form1" (ngSubmit)="SearchOrSubmitForm(1)">
        <div class="container-fluid">
          <div class=" row">
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="form-group">
                <label for="SpId"> From Stock Point</label>
                <select style="width:100%" class="form-control" id="SpId" name="SpId" (change)="changeSp($event,1)" formControlName="SpId">
                  <!--<option [value]="0">Select ALL</option>-->
                  <option *ngFor="let item of SPListFrom" [value]="item.spid">{{item.spname }}</option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="form-group">
                <label for="ToSPId">To Stock Point</label>
                <select style="width: 100%" class="form-control" id="ToSPId" name="ToSPId" formControlName="ToSPId" (change)="changeSp($event,0)">
                  <!--<option [value]="0">Select ALL</option>-->
                  <option *ngFor="let item of SPListTo" [value]="item.spid">{{item.spname }}</option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="form-group">
                <label for="AccountCode" title="{{actidtitle}}">Cutter / Stitcher Code</label>
                <input style="width:100%;" id="AccountCode" name="AccountCode" type="text" class="form-control" (input)="keypressAccountCodeName($event,0)" (change)="changeAccountCodeName($event,0)" (click)="clickAccountCodeName($event,0)" formControlName="AccountCode" placeholder="Code" />
                <div class="combobox-options" *ngIf="filteredList.length>0 && flag===true">
                  <ul>
                    <li *ngFor="let item of filteredList" (click)="onselectAccountCodeName(item,0)">
                      <a>{{item.actCode}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="form-group">
                <label for="AccountName">Cutter / Stitcher Name</label>
                <input style="width:100%;" id="AccountName" name="AccountName" type="text" class="form-control" (input)="keypressAccountCodeName($event,1)" (change)="changeAccountCodeName($event,1)" (click)="clickAccountCodeName($event,1)" formControlName="AccountName" placeholder="Name" />
                <div class="combobox-options" *ngIf="filteredList.length>0 && flag2===true">
                  <ul>
                    <li *ngFor="let item of filteredList" (click)="onselectAccountCodeName(item,1)">
                      <a>{{item.actName}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="form-group">
                <label for="Fromdate">From Date </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                  </div>
                  <input type="text" class="form-control" style="width: 60%;" id="Fromdate" [(ngModel)]="Fromdate" name="Fromdate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange($event)" [bsValue]="Fromdate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="form-group">
                <label for="Todate">To Date</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                  </div>
                  <input type="text" class="form-control" style="width: 60%;" id="Todate" [(ngModel)]="Todate" name="Todate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChange2($event)" [bsValue]="Todate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
              <div class="form-group">
                <label style="margin-top:50px"></label>
                <button class="btn redbtn" type="submit" style="margin-top:26px;">Search</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <br />
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Bill.Date</th>
              <th>Bill.No</th>
              <th>Cutter / Stitcher Code</th>
              <th>Cutter / Stitcher Name</th>
              <th>Amount</th>
              <th class="text-center">View | Edit| Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of itemlist;let i=index;">
              <td>{{i+1}}</td>
              <td> {{item.docDate }}</td>
              <td> {{item.series }}</td>
              <td> {{item.actCode }}</td>
              <td> {{item.actName }}</td>
              <td class="text-right"> {{item.sovalue | currency:'INR':'' }}</td>
              <td align="center">
                <a (click)="openPopup(item.docId,item.actCode,item.actName)">
                  <img src="../../../../../assets/images/visibility.png" />
                </a>
                <a>
                  <img src="../../../../../assets/images/edit-small.png" />
                </a>
                <a (click)="deleteformbyid(item)">
                  <img src="../../../../../assets/images/delete.png" />
                </a>
              </td>
            </tr>
            <tr *ngIf="itemlist.length===0">
              <td colspan="7" class="text-center text-danger">No Data Found for Selection Criteria</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination-container">
          <div class="show ml-8">
            <span>Show</span>
            <select class="ms form-control">
              <option selected value="10">10</option>
              <option value="25">25</option>
            </select>
          </div>
          <div>
            <nav aria-label="Page navigation example" class="float-right mr-8">
              <ul class="pagination pagination-primary">
                <li class="page-item"><a class="page-link" href="">Previous</a></li>
                <li class="page-item"><a class="page-link" href="">1</a></li>
                <li class="page-item"><a class="page-link" href="">2</a></li>
                <li class="page-item"><a class="page-link" href="">3</a></li>
                <li class="page-item"><a class="page-link" href="">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- Bootstrap Modal for Upload Multiple images -->
  <div class="modal"
       tabindex="-1"
       role="dialog"
       [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog modal-lg" role="document">
      <h4 class="modal-title" style="background-color:cornflowerblue;color:white;">&nbsp;&nbsp;{{cutterorstitcheName}}</h4>
      <div class="modal-content">
        <div class="modal-body">
          <div style="width: 100%;height:100%;margin-top:-20px;">
            <div style="width: 100%; height: 250px; overflow-y: scroll; overflow-x: auto;">
              <table style="width: 100%; overflow:auto;" class="tableclass table table-bordered  ">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Bill.Date</th>
                    <th>Bill.No</th>
                    <th>Cust Id</th>
                    <!--<th>Cust Name</th>-->
                    <th>ItemName</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of itemlist2; let i = index;">
                    <td>{{i+1}}</td>
                    <td>{{item.docDate}}</td>
                    <td>{{item.tSeries}}</td>
                    <td>{{item.actCode}}</td>
                    <!--<td>{{item.actName}}</td>-->
                    <td>{{item.itemname}}</td>
                    <td class="text-right">{{item.qty | currency:'INR':''}}</td>
                    <td class="text-right">{{item.rate | currency:'INR':''}}</td>
                    <td class="text-right">{{item.netvalue | currency:'INR':''}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">          
          <button type="button" class="btn btn-danger" (click)="closePopup()">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Bootstrap Modal for Upload Multiple images -->
</div>
