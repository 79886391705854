import { Component, ElementRef, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/http.service';
import { HttpClient } from '@angular/common/http';
import * as sideMenu from './sidebar.config';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public isToggled: boolean = false;
  WorkEntryCUTTING: any = 0;
  WorkEntrySTITCHING: any = 0;
  public menuOptions = sideMenu.SIDEBAR_MENU;

  constructor(private httpClient: HttpClient, private _services: HttpService, private router: Router, private activatedRoute: ActivatedRoute) {
    const request = {
      method: 'GET',
      action_url: 'Getttypescuttandstitch'
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          let objres: any = res;
          this.WorkEntryCUTTING = objres.workentrycutting;
          this.WorkEntrySTITCHING = objres.workentrystitching;
        }       
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  ngOnInit(): void {

  }
  navigatepage(routeLink: any, isstitching: any) {
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
      this.router.navigate([routeLink], { state: { id: isstitching, text: 'Edit', obj: null } });
    });
  }
  navigatepagedaywise(routeLink: any, shortname: any) {
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
      this.router.navigate([routeLink], { state: { text: shortname } });
    });

  }
  //public toggleDropdown(e: any, menuObject: any = null) {
  //  e.stopPropagation();
  //  if (menuObject) menuObject.isClicked = !menuObject.isClicked;
  //  /* this.isToggled = !this.isToggled;
  //  const toggledEle = document.querySelector(
  //    `#leftsidebar .menu li:nth-child(${index}) a`
  //  );
  //  const blockedEle = document.querySelector(
  //    `#leftsidebar .menu li:nth-child(${index}) .ml-menu`
  //  );
  //  if (this.isToggled) {
  //    toggledEle?.classList.add('toggled');
  //    blockedEle?.classList.add('d-block');
  //  } else {
  //    toggledEle?.classList.remove('toggled');
  //    blockedEle?.classList.remove('d-block');
  //  } */
  //}
  //public toggleMenu() {
  //  document.querySelector('body')?.classList.toggle('ls-toggle-menu');
  //}
  public toggleDropdown(e: any, menuObject: any = null) {   
    e.stopPropagation();
    if (menuObject) menuObject.isClicked = !menuObject.isClicked;
  }
  public toggleMenu(e: any) {   
    e.preventDefault();
    e.stopPropagation();
    document.querySelector('body')?.classList.toggle('ls-toggle-menu');
  }

  sidemenu() {

  }

}
