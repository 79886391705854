import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '../masters/main/main.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SalesComponent } from './sales/sales.component';
import { StockOutwardComponent } from './stock-outward/stock-outward.component';
import { NewDeliveryComponent } from './delivery/new-delivery/new-delivery.component';
import { PrintorderComponent } from './order/printorder/printorder.component';
import { OrderEntryComponent } from './order/order-entry/order-entry.component';
import { DeliveryEntryComponent } from './delivery/delivery-entry/delivery-entry.component';
import { NewOrderComponent } from './order/new-order/new-order.component';
import { OrderEntryImagesComponent } from './order/order-entry-images/order-entry-images.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPrintModule } from 'ngx-print';
import { AccountLedgerComponent } from './account-ledger/account-ledger.component';
import { CustomerEnquiryComponent } from './customer-enquiry/customer-enquiry.component';
import { CutMasterComponent } from './delivertocutMaster/cut-master/cut-master.component';
import { NewCutMasterComponent } from './delivertocutMaster/new-cut-master/new-cut-master.component';
import { BankreceiptComponent } from './bankReceipt/bankreceipt/bankreceipt.component';
import { NewBankReceiptComponent } from './bankReceipt/new-bank-receipt/new-bank-receipt.component';
import { NewpaymentComponent } from './payments/newpayment/newpayment.component';
import { PaymentlistComponent } from './payments/paymentlist/paymentlist.component';
import { CashbookComponent } from './cashbook/cashbook.component';
const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      { path: 'orderEntry', component: OrderEntryComponent },
      { path: 'orderimages', component: OrderEntryImagesComponent },
      { path: 'neworder', component: NewOrderComponent },
      { path: 'printorder', component: PrintorderComponent },
      { path: 'newpayment', component: NewpaymentComponent },
      { path: 'paymentlist', component: PaymentlistComponent },

      { path: 'delivery-entry', component: DeliveryEntryComponent },
      { path: 'new-delivery', component: NewDeliveryComponent },

      { path: 'sales', component: SalesComponent },
      { path: 'stock-Outward', component: StockOutwardComponent },     
      { path: 'accLedger', component: AccountLedgerComponent },
      { path: 'cashbook', component: CashbookComponent },
      { path: 'custEnquiry', component: CustomerEnquiryComponent },
      { path: 'delivery-to-cut', component: CutMasterComponent },
      { path: 'New delivery-to-cut', component: NewCutMasterComponent },
      { path: 'bank_receipt', component: BankreceiptComponent },
      { path: 'new bank_receipt', component: NewBankReceiptComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

    ]
  }
];


@NgModule({
  declarations: [
    OrderEntryComponent,
    NewOrderComponent,
    DeliveryEntryComponent,
    OrderEntryImagesComponent,
    SalesComponent,
    StockOutwardComponent,
    NewDeliveryComponent,
    PrintorderComponent,
    AccountLedgerComponent,
    CustomerEnquiryComponent,
    CutMasterComponent,
    NewCutMasterComponent,
    BankreceiptComponent,
    NewBankReceiptComponent,
    NewpaymentComponent,
    PaymentlistComponent,
    CashbookComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [RouterModule]
})
export class TransactionModule { }
