import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import Swal from 'sweetalert2';
import { HttpService } from '../../../../http.service';

@Component({
  selector: 'app-addnew-area',
  templateUrl: './addnew-area.component.html',
  styleUrls: ['./addnew-area.component.scss']
})
export class AddnewAreaComponent implements OnInit {
  AreaList: any = [];
  DistList: any = [];
  StateList: any = [];
  SalesmanList: any = [];
  form1: FormGroup | undefined;
  @ViewChild('myname') input;
  get fc(): any | undefined { return this.form1?.controls; }
  titletextneworedit: string = "New";
  submitted = false;
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    //form Defining Start
    this.form1 = this._fb.group({
      TownName: ['', [Validators.required]],
      TownCode: ['', [Validators.required, Validators.maxLength(5)]],
      TownID: [0],
      PinCode: [''],
      TownUnder: [0],
      DistID: [0, [Validators.required]],
      StateID: [0, [Validators.required]],
      Country: ['India'],
      SmId: [0],
      Visitdate: [''],
      Entdate: [''],
      entUid: [0],
      TownStatus: [true],
      RptSel: [0],
    });
    this.getAreaList();
    this.getDistList();
    this.getStateList();
    if (this.router.getCurrentNavigation() === null) {
      this.getSalesmanList();
    }       
    if (this.router.getCurrentNavigation() != null) {
      if (this.router.getCurrentNavigation().extras.state === undefined) {
        this.getSalesmanList();
      }
      else {
        if (this.router.getCurrentNavigation().extras.state.id === undefined) {
          this.getSalesmanList();
        }
        //End form Defining
        //Edit Start
        if (this.router.getCurrentNavigation().extras.state.id != undefined) {
          this.titletextneworedit = this.router.getCurrentNavigation().extras.state.text;
          var objdata = this.router.getCurrentNavigation().extras.state.obj;
          if (objdata != null) {
            this.form1?.patchValue({
              TownName: objdata.townName,
              TownCode: objdata.townCode,
              TownID: Number(objdata.townId),
              PinCode: objdata.pinCode,
              TownUnder: Number(objdata.townUnder),
              DistID: Number(objdata.distId),
              StateID: Number(objdata.stateId),
              Country: objdata.country,
              SmId: Number(objdata.smId),
              Visitdate: objdata.visitDate,
              Entdate: objdata.entDate,
              entUid: objdata.entUid,
              TownStatus: (objdata.townStatus == 1 ? true : false),
              RptSel: objdata.rptSel,
            });
            const request = {
              method: 'GET',
              action_url: 'GetSalesman'
            };
            this._services.doHttp(request).subscribe(
              res => {
                this.SalesmanList = res;
              },
              error => {
                console.log(error);
              });
          }
        }
        //End Edit
      }
    }
  }
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    //Focus Start
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    //End
  }
  SaveOrSubmitForm() {
    this.submitted = true;
    let myDateTime: any = new Date();
    let sqlTimeAsString = formatDate(new Date(), "yyyy-MM-dd", "en_US");
    if (this.form1?.invalid) {
      return;
    }
    var TownID = this.form1?.get('TownID')?.value;
    var DistID = this.form1?.get('DistID')?.value;
    var StateID = this.form1?.get('StateID')?.value;
    var SmId = this.form1?.get('SmId')?.value;
    //
    let TownStatus: any = this.form1?.get('TownStatus')?.value;
    this.form1?.patchValue({
      Visitdate: sqlTimeAsString,
      Entdate: sqlTimeAsString,
      TownID: Number(TownID),
      TownUnder: Number(this.form1?.get('TownUnder')?.value),
      DistID: Number(DistID),
      StateID: Number(StateID),
      TownStatus: (TownStatus == true ? 1 : (TownStatus == false ? 0 : TownStatus)),
      SmId: Number(SmId)
    });
    //    
    var postvalue = this.form1?.value;
    //console.log(this.form1?.value);   
    let options;
    options = new HttpHeaders()
      .set('Content-Type', 'application/json;charset=utf-8')
      .set('pragma', 'no-cache')
      .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0');
    this.httpClient.post(this._services.APIUrl + '/InsertTowns', JSON.stringify(postvalue), {
      headers: options
    }).subscribe(res => {
      var result: any = res;
      //console.log(result.msg);
      if (result.msg != null) {
        if (result.msg.toString().includes('Successfull')) {
          Swal.fire('Area Creation', result.msg.toString(), 'success');
          this.clearform();
        }
      }
    },
      error => {
        console.log(error);
      });
  }
  clearform() {
    this.form1?.patchValue({
      //
      TownName: '',
      TownCode: '',
      TownID: 0,
      PinCode: '',
      TownUnder: 0,
      DistID: 0,
      StateID: 0,
      Country: 'India',
      SmId: 0,
      Visitdate: '',
      Entdate: '',
      entUid: 0,
      TownStatus: true,
      RptSel: 0,
      //     
    });
    this.titletextneworedit = "New";
    this.submitted = false;
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
  }
  onFocusOutEvent(event: any) {
    var short = this.form1?.get('TownCode')?.value;
    if (short.length == 0) {
      var txtname = event.target.value;
      let strname: string = "";
      if (txtname != null) {
        if (txtname.length == 0) {
          this.form1?.patchValue({
            TownCode: ''
          });
          return;
        }
        if (txtname.length <= 5) {
          strname = txtname;
        }
        else {
          strname = txtname.slice(0, 5);
        }
        this.form1?.patchValue({
          TownCode: strname
        });
      }
    }
  }
  changeState(event: any) {
    let stateId: any = 0;
    if (this.DistList.find((item: { distId: any; }) => item.distId == Number(event.target.value)) != undefined) {
      try {
        stateId = Number(this.DistList.find((item: { distId: any; }) => item.distId == Number(event.target.value)).stateId);
      } catch { stateId = 0; }
    }
    this.form1?.patchValue({
      StateID: stateId
    });
  }
  getAreaList() {
    const request = {
      method: 'GET',
      action_url: 'GetTownsHigher'
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.AreaList = res;
      },
      error => {
        console.log(error);
      });
  }
  getDistList() {
    const request = {
      method: 'GET',
      action_url: 'GetDistricts'
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.DistList = res;
      },
      error => {
        console.log(error);
      });
  }
  getStateList() {
    const request = {
      method: 'GET',
      action_url: 'GetStates'
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.StateList = res;
      },
      error => {
        console.log(error);
      });
  }
  getSalesmanList() {
    const requests = {
      method: 'GET',
      action_url: 'GetSalesman'
    };
    this._services.doHttp(requests).subscribe(
      res => {
        if (res != null) {
          this.SalesmanList = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
}
