import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-bank-receipt',
  templateUrl: './new-bank-receipt.component.html',
  styleUrls: ['./new-bank-receipt.component.scss']
})
export class NewBankReceiptComponent implements OnInit {
  linkAccount: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  linkHide() {
    this.linkAccount = true;
  }
}
