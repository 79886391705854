import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpService } from '../../../../http.service';

@Component({
  selector: 'app-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.scss']
})
export class NewAccountComponent implements OnInit {
  imgUrl: string = "./assets/images/accountcreation/defaultimage.png";
  fileToUpload: File = null;
  Acttypeslist: any[];
  Actgrouplist: any[];
  BranchList: any = [];
  ActcustTtypes: any = [];
  TaxTypeslist: any = [];
  SalesmanList: any = [];
  TownsList: any = [];
  DistrictsList: any = [];
  StatesList: any = [];
  GenderList: any = [];

  form1: FormGroup | undefined;
  @ViewChild('myname') input;
  @ViewChild('fileInput') fileInput: ElementRef;
  get fc(): any | undefined { return this.form1?.controls; }
  titletextneworedit: string = "New";
  submitted = false;
  submittedBranchPermissions = false;
  selectedFile: File = null;
  reader: any = new FileReader();
  imgUrl2: string = "./assets/images/accountcreation/defaultimage.png";
  selectall = true;
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    let myDateTime: any = new Date();
    let sqlTimeAsString = formatDate(new Date(), "yyyy-MM-dd", "en_US");
    //form Defining Start
    this.form1 = this._fb.group({
      ActId: [0],
      ActName: ['', [Validators.required]],
      ActCode: ['', [Validators.required]],
      ActMG: [''],
      ActAlias: [''],
      ActTypef: [0, [Validators.required]],
      ActType:[''],
      GroupID: [0],
      ActOrder: [1],
      ParentsTree: [''],
      Regid: [2],
      ActOpSplit: [0],
      ActOPbal: [0],
      ActOpSign: ['D'],
      TaxType: [0],
      CostAna: [0],
      ActMode: [0],
      Bill2Bill: [0],
      DisplayOpBal: [0],
      TDS: [0],
      CreditAmt: [0],
      Creditdays: [0],
      CreditWarnAmt: [0],
      Billmodef: [0],
      Billmode: [''],
      TransTypef: [0],
      TransType: [''],
      BranchID: [0],
      CreatedBy: [0],
      CreatedDate: [0],
      ModifiedBy: [0],
      ModifiedDate: [0],
      RptSel: [0],
      Status: [true],
      Asct: [false],
      Ecommerce: [false],
      cpdx: [false],
      partyid: [0],
      istransporter: [false],
      ActImage: [null],
      SGSTInputAccID: [0],
      CGSTInputAccID: [0],
      IGSTInputAccID: [0],
      SGSTOutputAccID: [0],
      CGSTOutputAccID: [0],
      IGSTOutputAccID: [0],
      GSTP: ['0'],
      CGSTRate: [0],
      SGSTRate: [0],
      IGSTRate: [0],
      Block: [false],
      ChkDuplicateSuppBNo: [false],
      TCS: [false],
      //Address     
      Person: [''],
      AddLine1: ['', [Validators.required]],
      AddLine2: [''],
      AddLine3: [''],
      ActTownf: [''],
      ActTown: [''],
      ActPinNo: [''],
      ActMDL: [''],
      ActDistID: [0],
      ActStateid: [0],
      ActCountry: ['India'],
      ActPh1: ['', [Validators.minLength(10),Validators.maxLength(10)]],
      ActPh2: ['', [Validators.minLength(10), Validators.maxLength(10)]],
      ActSTD: [''],
      ActMob1: ['', [Validators.required,Validators.minLength(10),Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
      ActMob2: ['', [Validators.minLength(10), Validators.maxLength(10)]],
      ActEmail: [''],
      ActFax: [''],
      ActCSTNo: [''],
      ActSSTNo: [''],
      ActTINNO: [''],
      ActMFLNo: [''],
      ActServiceTaxNo: [''],
      ActPAN: [''],
      ActTAN: [''],
      ActGSTIn: [''],
      AreaId: [0, [Validators.required]],
      ActTransport: [''],
      ActPhoto: [''],
      ActDL20B: [''],
      ActDL21B: [''],
      SmId: [0],
      ShpPerson: [''],
      ShpAddLine1: [''],
      ShpAddLine2: [''],
      ShpTown: [''],
      ShpDistrict: [''],
      ShpDistID: [0],
      ShpStateid: [0],
      ShpPinNo: [''],
      ShpPh1: [''],
      ShpPh2: [''],
      ShpSTD: [''],
      ShpMob1: [''],
      ShpEmail: [''],
      ShpSpouses: [''],
      ActGenderf: [0],
      ActGender: [''],
      ActProfessionf: [0],
      ActProfession: [''],
      ActAadharNo: [''],
      ActBlood: [''],
      ActLoyalityNo: [''],
      ShpDefaultrate: [''],
      ShpCrtlDays: [''],
      ShpSMSsts: [false],
      ShpEmailSts: [false],
      ShpDOB: [''],
      ShpDlicenceExtdt: [''],
      ShpAnniversary: [''],
      ShpOther1: [''],
      ShpOther2: [''],
      ShpRemark1: [''],
      ShpRemark2: [''],
      ShpImage: [null],
      RefPerson: [''],
      RefAddLine1: [''],
      RefAddLine2: [''],
      RefTown: [''],
      RefDistrict: [''],
      RefState: [''],
      RefDistID: [0],
      RefStateid: [0],
      RefPinNo: [''],
      RefPh1: [''],
      RefPh2: [''],
      RefMob: [''],
      RefEmail: [''],
      DueDays: [0],
      //End
      Image: [null],
      ImageURL: ['./assets/images/accountcreation/defaultimage.png'],
    });
    //End form Defining
    let objdata: any = null; let objdata2: any = null;
    let editid: any = 0;
    let objimageUrl: any = "./assets/images/accountcreation/defaultimage.png";
    if (this.router.getCurrentNavigation() == null) { }
    else
    {
      if (this.router.getCurrentNavigation().extras.state === undefined) { }
      else
      {
        if (this.router.getCurrentNavigation().extras.state.id != undefined) {
          editid = Number(this.router.getCurrentNavigation().extras.state.id);
          this.titletextneworedit = this.router.getCurrentNavigation().extras.state.text;
          objdata = this.router.getCurrentNavigation().extras.state.obj;
        }
      }
    }    
    //when page Load function
    const request = {
      method: 'GET',
      action_url: 'GetAccountLoad?id=' + editid
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.BranchList = resobj.listbranch;
        this.Acttypeslist = resobj.listacttypes;
        this.Actgrouplist = resobj.listactgroups;
        this.ActcustTtypes = resobj.listactcusttypes;
        this.TaxTypeslist = resobj.listtaxtypes;
        this.SalesmanList = resobj.listsalesman;
        this.TownsList = resobj.listtowns;
        this.DistrictsList = resobj.listdistricts;
        this.StatesList = resobj.liststates;
        this.GenderList = resobj.listgender;
        objdata2 = resobj.objadr;
        let subaccount: any = 0; let Acttypeid: any = 0;        
        if (objdata == null) {
          if (this.Actgrouplist != null) {
            if (this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Sundry Debtors") != undefined) {
              subaccount = Number(this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Sundry Debtors").groupId);
            }
          }
          if (this.Acttypeslist != null) {
            if (this.Acttypeslist.find((item: { actType: any; }) => item.actType == "Customer") != undefined) {
              Acttypeid = Number(this.Acttypeslist.find((item: { actType: any; }) => item.actType == "Customer").actTypeId);
            }
          }
        }
        else {
          if (this.Acttypeslist != null) {
            if (this.Acttypeslist.find((item: { actType: any; }) => item.actType == objdata.actType) != undefined) {
              Acttypeid = Number(this.Acttypeslist.find((item: { actType: any; }) => item.actType == objdata.actType).actTypeId);
            }
          }
          subaccount = Number(objdata.groupId);             
        }
        this.form1?.patchValue({
          GroupID: Number(subaccount),
          ActTypef: Number(Acttypeid),          
        });
        //edit 
        if (objdata != null) {             
  
          let TransType: any = 0;
          if (this.ActcustTtypes != null) {
            if (this.ActcustTtypes.find((item: { accCustTypeName: any; }) => item.accCustTypeName == objdata.transType) != undefined) {
              TransType = Number(this.ActcustTtypes.find((item: { accCustTypeName: any; }) => item.accCustTypeName == objdata.transType).accTypeId);
            }
          }      
          this.form1?.patchValue({

            ActId: editid,
            ActName: objdata.actName,
            ActCode: objdata.actCode,
            ActAlias: objdata.actAlias,
            Billmodef: (objdata.billmode == "Cash" ? 0 : (objdata.billmode == "Credit" ? 1 : (objdata.billmode == "Card" ? 2 : 3))),
            TransTypef: TransType,
            TaxType: Number(objdata.taxType),
            Regid: Number(objdata.regid),          
            CreditAmt: Number(objdata.creditAmt),
            Creditdays: Number(objdata.creditdays),

          });          
        }
        if (objdata2 != null) {
          if (objdata2.actPhoto != null) {
            objimageUrl = (objdata2.actPhoto == "" ? "./assets/images/accountcreation/defaultimage.png" : objdata2.actPhoto);
          }

          let GenderId: any = 0;
          if (this.GenderList != null) {
            if (this.GenderList.find((item: { genderName: any; }) => item.genderName == objdata2.actGender) != undefined) {
              GenderId = Number(this.GenderList.find((item: { genderName: any; }) => item.genderName == objdata2.actGender).genderId);
            }
          }
          let ActProfessionId: any = 0;
          if (objdata2.actProfession != null) {
            ActProfessionId = (objdata2.actProfession == "Manager" ? 1 : (objdata2.actProfession == "Ass.Manager" ? 2 : (objdata2.actProfession == "Salesman"?3:0)));
          }
          this.form1?.patchValue({           
            SmId: Number(objdata2.smId),
            Person: objdata2.person,
            ActGenderf: Number(GenderId),
            ActProfessionf: Number(ActProfessionId),
            AddLine1: objdata2.addLine1,
            AddLine2: objdata2.addLine2,
            AddLine3: objdata2.addLine3,
            ActTownf: objdata2.actTown,
            AreaId: Number(objdata2.areaId),
            ActPinNo: objdata2.actPinNo,
            ActDistID: Number(objdata2.actDistId),
            ActStateid: Number(objdata2.actStateid),
            ActCountry: objdata2.actCountry,
            ActEmail: objdata2.actEmail,
            ActPh1: objdata2.actPh1,
            ActPh2: objdata2.actPh2,
            ActMob1: objdata2.actMob1,
            ActMob2: objdata2.actMob2,
            ActSTD: objdata2.actStd,
            ActBlood: objdata2.actBlood,
            ActFax: objdata2.actFax,
            ActAadharNo: objdata2.actAadharNo,
            ActTransport: objdata2.actTransport,
            ActPAN: objdata2.actPan,
            ActGSTIn: objdata2.actGstin,
            DueDays: Number(objdata2.dueDays),
            ActLoyalityNo: objdata2.actLoyalityNo,
            ActServiceTaxNo: objdata2.actServiceTaxNo,
            ImageURL: objimageUrl,
            ActPhoto: objimageUrl,

          });
          this.imgUrl2 = objimageUrl;
          this.fileInput.nativeElement.click();
        }
        //end
      },
      error => {
        console.log(error);
      });
    //End
  }
  ngOnInit(): void {

  }
  ngAfterViewInit() {
    //Focus Start
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    //End
  }
  SaveOrSubmitForm() {
   
    this.submitted = true;
    //
    let fyid: any = 0; let compid: any = 0;
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }
    this.BranchList.forEach((x: { compid: any; }) => x.compid = compid);
    this.BranchList.forEach((x: { fyid: any; }) => x.fyid = fyid);
    this.submittedBranchPermissions = true;
    var list = this.BranchList.filter((obj: { state: any; }) => obj.state == true);
    if (list != null) {
      if (list.length > 0) {
        this.submittedBranchPermissions = false;
      }
    }
    if (this.submittedBranchPermissions) {
      return;
    }
    if (this.form1?.invalid) {
      return;
    }   
    let myDateTime: any = new Date();
    let sqlTimeAsString = formatDate(new Date(), "yyyy-MM-dd", "en_US");
    //
    let ActTypef: any = Number(this.form1?.get('ActTypef')?.value);
    let ActType: any = "Customer";
    if (this.Acttypeslist != null) {
      if (this.Acttypeslist.find((item: { actTypeId: any; }) => item.actTypeId == Number(ActTypef)) != undefined) {
        ActType = (this.Acttypeslist.find((item: { actTypeId: any; }) => item.actTypeId == Number(ActTypef)).actType);
      }
    }
    let ActMG: any = "A";
    if ((ActType == "Liability") || (ActType == "Supplier")) {
      ActMG = "L";
    }
    if (ActType == "Income") {
      ActMG = "I";
    }
    if (ActType == "Expense") {
      ActMG = "E";
    }
    //end ActType
    let Billmodef: any = this.form1?.get('Billmodef')?.value;
    let Billmode: any = "Cash";
    if (Number(Billmodef) == 1) {
      Billmode = "Credit";
    }
    if (Number(Billmodef) == 2) {
      Billmode = "Card";
    }
    if (Number(Billmodef) == 3) {
      Billmode = "Others";
    }
    //end Billmode
    let TransTypef: any = Number(this.form1?.get('TransTypef')?.value);
    let TransType: any = '';
    if (this.ActcustTtypes != null) {
      if (this.ActcustTtypes.find((item: { accTypeId: any; }) => item.accTypeId == TransTypef) != undefined) {
        TransType = (this.ActcustTtypes.find((item: { accTypeId: any; }) => item.accTypeId == TransTypef).accCustTypeName);
      }
    }
    //end TransType
    let ActGenderf: any = this.form1?.get('ActGenderf')?.value;
    let ActGender: any = "Male";
    if (this.GenderList != null) {
      if (this.GenderList.find((item: { genderId: any; }) => item.genderId == Number(ActGenderf)) != undefined) {
        ActGender = (this.GenderList.find((item: { genderId: any; }) => item.genderId == Number(ActGenderf)).genderName);
      }
    }
    //end ActGender
    let ActProfessionf: any = this.form1?.get('ActProfessionf')?.value;
    let ActProfession: any = "";
    if (Number(ActProfessionf) == 1) {
      ActProfession = "Manager";
    }
    if (Number(ActProfessionf) == 2) {
      ActProfession = "Ass.Manager";
    }
    if (Number(ActProfessionf) == 3) {
      ActProfession = "Salesman";
    }
    //end ActProfession
    let townName: any = "";
    try {
      if (this.TownsList != null) {
        if (this.TownsList.find((item: { townId: any; }) => item.townId == Number(this.form1?.get('AreaId')?.value)) != undefined) {
          townName = (this.TownsList.find((item: { townId: any; }) => item.townId == Number(this.form1?.get('AreaId')?.value)).townName);
        }
      }
    } catch { }
    //end TownName
    this.form1?.patchValue({
      CreatedBy: Number(localStorage.getItem("Uid")),
      ModifiedBy: Number(localStorage.getItem("Uid")),
      CreatedDate: sqlTimeAsString,
      ModifiedDate: sqlTimeAsString,
      ShpDOB: sqlTimeAsString,
      ShpDlicenceExtdt: sqlTimeAsString,
      ShpAnniversary: sqlTimeAsString,
      ActType: ActType,
      ActMG: ActMG,
      Billmode: Billmode,
      GroupID: Number(this.form1?.get('GroupID')?.value),
      TransType: TransType,
      TaxType: Number(this.form1?.get('TaxType')?.value),
      SmId: Number(this.form1?.get('SmId')?.value),
      Regid: Number(this.form1?.get('Regid')?.value),
      ActGender: ActGender,
      ActProfession: ActProfession,
      AreaId: Number(this.form1?.get('AreaId')?.value),
      ActDistID: Number(this.form1?.get('ActDistID')?.value),
      ActStateid: Number(this.form1?.get('ActStateid')?.value),
      CreditAmt: Number(this.form1?.get('CreditAmt').value),
      Creditdays: Number(this.form1?.get('Creditdays').value),
      DueDays: Number(this.form1?.get('DueDays').value),
      ActTINNO: this.form1?.get('ActGSTIn').value,
      AddLine3: this.form1?.get('ActTownf').value,
      ActTown: townName,
      ActPhoto: this.form1?.get('ImageURL').value,
    });
    var obj = { objactmst: this.form1?.value, objactadr: this.form1?.value, listbranch: this.BranchList };

    //console.log(obj);

    this.httpClient.post(this._services.APIUrl + '/InsertAccount', obj).subscribe(res => {
      var result: any = res;
      if (result.msg.toString().includes('Successfull')) {

        Swal.fire('Account Creation', result.msg.toString(), 'success');
        this.upload();
        this.clearform();
      }
      else {
        Swal.fire('Account Creation', result.msg.toString(), 'error');
      }
      },
      error => {
        console.log(error);
      });

  }
  clearform() {
    this.form1?.patchValue({
      ActId: 0,
      ActName: '',
      ActCode: '',
      ActMG: '',
      ActAlias: '',
      ActTypef: 0,
      ActType: '',
      GroupID: 0,
      ActOrder: 1,
      ParentsTree: '',
      Regid: 2,
      ActOpSplit: 0,
      ActOPbal: 0,
      ActOpSign: 'D',
      TaxType: 0,
      CostAna: 0,
      ActMode: 0,
      Bill2Bill: 0,
      DisplayOpBal: 0,
      TDS: 0,
      CreditAmt: 0,
      Creditdays: 0,
      CreditWarnAmt: 0,
      Billmodef: 0,
      Billmode: '',
      TransTypef: 0,
      TransType: '',
      BranchID: 0,
      CreatedBy: 0,
      CreatedDate: 0,
      ModifiedBy: 0,
      ModifiedDate: 0,
      RptSel: 0,
      Status: true,
      Asct: false,
      Ecommerce: false,
      cpdx: false,
      partyid: 0,
      istransporter: false,
      ActImage: null,
      SGSTInputAccID: 0,
      CGSTInputAccID: 0,
      IGSTInputAccID: 0,
      SGSTOutputAccID: 0,
      CGSTOutputAccID: 0,
      IGSTOutputAccID: 0,
      GSTP: '0',
      CGSTRate: 0,
      SGSTRate: 0,
      IGSTRate: 0,
      Block: false,
      ChkDuplicateSuppBNo: false,
      TCS: false,
      //Address     
      Person: '',
      AddLine1: '',
      AddLine2: '',
      AddLine3: '',
      ActTownf: '',
      ActTown: '',
      ActPinNo: '',
      ActMDL: '',
      ActDistID: 0,
      ActStateid: 0,
      ActCountry: 'India',
      ActPh1: '',
      ActPh2: '',
      ActSTD: '',
      ActMob1: '',
      ActMob2: '',
      ActEmail: '',
      ActFax: '',
      ActCSTNo: '',
      ActSSTNo: '',
      ActTINNO: '',
      ActMFLNo: '',
      ActServiceTaxNo: '',
      ActPAN: '',
      ActTAN: '',
      ActGSTIn: '',
      AreaId: 0,
      ActTransport: '',
      ActPhoto: '',
      ActDL20B: '',
      ActDL21B: '',
      SmId: 0,
      ShpPerson: '',
      ShpAddLine1: '',
      ShpAddLine2: '',
      ShpTown: '',
      ShpDistrict: '',
      ShpDistID: 0,
      ShpStateid: 0,
      ShpPinNo: '',
      ShpPh1: '',
      ShpPh2: '',
      ShpSTD: '',
      ShpMob1: '',
      ShpEmail: '',
      ShpSpouses: '',
      ActGenderf: 0,
      ActGender: '',
      ActProfessionf: 0,
      ActProfession: '',
      ActAadharNo: '',
      ActBlood: '',
      ActLoyalityNo: '',
      ShpDefaultrate: '',
      ShpCrtlDays: '',
      ShpSMSsts: false,
      ShpEmailSts: false,
      ShpDOB: '',
      ShpDlicenceExtdt: '',
      ShpAnniversary: '',
      ShpOther1: '',
      ShpOther2: '',
      ShpRemark1: '',
      ShpRemark2: '',
      ShpImage: null,
      RefPerson: '',
      RefAddLine1: '',
      RefAddLine2: '',
      RefTown: '',
      RefDistrict: '',
      RefState: '',
      RefDistID: 0,
      RefStateid: 0,
      RefPinNo: '',
      RefPh1: '',
      RefPh2: '',
      RefMob: '',
      RefEmail: '',
      DueDays: 0,
      //End
      Image: null,
      ImageURL: './assets/images/accountcreation/defaultimage.png',
    });
    this.titletextneworedit = "New";
    this.submitted = false;
    this.imgUrl = "./assets/images/itemcreation/defaultimage.png";
    let subaccount: any = 0; let Acttypeid: any = 0;
    if (this.Actgrouplist != null) {
      if (this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Sundry Debtors") != undefined) {
        subaccount = Number(this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Sundry Debtors").groupId);
      }
    }
    if (this.Acttypeslist != null) {
      if (this.Acttypeslist.find((item: { actType: any; }) => item.actType == "Customer") != undefined) {
        Acttypeid = Number(this.Acttypeslist.find((item: { actType: any; }) => item.actType == "Customer").actTypeId);
      }
    }
    this.form1?.patchValue({
      GroupID: Number(subaccount),
      ActTypef: Number(Acttypeid),
    });
    this.BranchList.forEach((x: { compid: any; }) => x.compid = 0);
    this.BranchList.forEach((x: { fyid: any; }) => x.fyid = 0);
    this.BranchList.forEach((x: { state: any; }) => x.state = true);
    this.BranchList.forEach((x: { opBal: any; }) => x.opBal = 0);
    this.BranchList.forEach((x: { sign: any; }) => x.sign = "Dr");
    this.selectall = true; this.submittedBranchPermissions = false;
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
  }
  onFocusOutEvent(event: any) {
    var ActName = this.form1?.get('ActName')?.value;
    if (ActName.length == 0) {
      this.form1?.patchValue({
        ActCode: "",
      });
      return;
    }
    var short = this.form1?.get('ActCode')?.value;
    if (short.length > 0) {
      return;
    }    
    //
    let ActTypef: any = Number(this.form1?.get('ActTypef')?.value);
    let ActType: any = "Customer";
    if (this.Acttypeslist != null) {
      if (this.Acttypeslist.find((item: { actTypeId: any; }) => item.actTypeId == Number(ActTypef)) != undefined) {
        ActType = (this.Acttypeslist.find((item: { actTypeId: any; }) => item.actTypeId == Number(ActTypef)).actType);
      }
    }    
    //end ActType    
    const request = {
      method: 'GET',
      action_url: 'GetActCodeByName?CmbAccType=' + ActType + '&txtAccName=' + ActName + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.form1?.patchValue({
          ActCode: resobj.msg,
        });
      },
      error => {
        console.log(error);
      });   
  }
  changedistState(event: any) {
    let stateId: any = 0; let distId: any = 0;
    if (this.TownsList.find((item: { townId: any; }) => item.townId == Number(event.target.value)) != undefined) {
      try {
        distId = Number(this.TownsList.find((item: { townId: any; }) => item.townId == Number(event.target.value)).distId);
      } catch { distId = 0; }
      try {
        stateId = Number(this.TownsList.find((item: { townId: any; }) => item.townId == Number(event.target.value)).stateId);
      } catch { stateId = 0; }
    }
    this.form1?.patchValue({
      ActDistID: distId,
      ActStateid: stateId
    });
  }
  cancelImageasDefault() {
    this.imgUrl = "./assets/images/accountcreation/defaultimage.png";
    //this.imgUrl2 = "./assets/images/accountcreation/defaultimage.png";
    this.form1?.patchValue({
      ImageURL: ('./assets/images/accountcreation/defaultimage.png'),
    });
  }
  cancelImageasDefaultedit() {
    this.imgUrl = "./assets/images/accountcreation/defaultimage.png";
    try {

      this.imgUrl = this.imgUrl2;
      this.form1?.patchValue({
        ImageURL: (this.imgUrl2),
      });
    }
    catch {
      this.imgUrl = "./assets/images/accountcreation/defaultimage.png";
      this.imgUrl2 = "./assets/images/accountcreation/defaultimage.png";
      this.imgUrl = this.imgUrl2;
      this.form1?.patchValue({
        ImageURL: (this.imgUrl2),
      });
    }
  }
  onSelectFile(fileInput: any) {

    this.selectedFile = <File>fileInput.target.files[0];
    this.fileToUpload = this.selectedFile;
    let _reader = new FileReader();
    _reader.onload = (event: any) => {
      this.imgUrl = event.target.result;
      this.form1?.patchValue({
        ImageURL: ('./assets/images/accountcreation/' + this.selectedFile.name),
      });
    }
    _reader.readAsDataURL(this.fileToUpload);
  }
  upload() {
    let ia: any = 1;
    const formData = new FormData();
    formData.append('Image', this.selectedFile);
    formData.append('isaccount', ia);
    
    const uploadReq = new HttpRequest('POST', `api/upload`, formData, {
      reportProgress: true,
    });
    this.httpClient.request(uploadReq).subscribe(event => {

    });

  }
  changeActType() {

    let acttypeval = Number(this.form1?.get('ActTypef')?.value);
    let acttype: any = "Customer";
    if (this.Acttypeslist.find((item: { actTypeId: any; }) => item.actTypeId == acttypeval) != undefined) {
      try {
        acttype = (this.Acttypeslist.find((item: { actTypeId: any; }) => item.actTypeId == acttypeval).actType);
      } catch { acttype = "Customer"; }
    }
    if (acttype == "Asset" || acttype == "Expense" || acttype == "Customer" || acttype == "Customer-Supplier" || acttype == "Cash" || acttype == "Bank") {
      if (this.BranchList != null) {
        this.BranchList.forEach((x: { sign: string; }) => x.sign = "Dr");
      }
    }
    if ((acttype == "Liability") || (acttype == "Income") || (acttype == "Supplier") || (acttype == "Cutting")) {
      if (this.BranchList != null) {
        this.BranchList.forEach((x: { sign: string; }) => x.sign = "Cr");
      }
    }
    let subaccount: any = 0;
    if (this.Actgrouplist != null) {
      //
      if ((acttype == "Customer") || (acttype == "Stitching")) {
        if (this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Sundry Debtors") != undefined) {
          subaccount = Number(this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Sundry Debtors").groupId);
          this.form1?.patchValue({
            GroupID: Number(subaccount),
          });
        }
      }
      else if ((acttype == "Supplier") || (acttype == "Cutting")) {
        if (this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Sundry Creditors") != undefined) {
          subaccount = Number(this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Sundry Creditors").groupId);
          this.form1?.patchValue({
            GroupID: Number(subaccount),
          });
        }
      }
      if (acttype == "Liability" || acttype == "Bank" || acttype == "Customer-Supplier") {
        if (this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Current Liabilities") != undefined) {
          subaccount = Number(this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Current Liabilities").groupId);
        }
      }
      else if (acttype == "Asset" || acttype == "Cash") {
        if (this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Current Assets") != undefined) {
          subaccount = Number(this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Current Assets").groupId);
        }
      }
      else if (acttype == "Income") {
        if (this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Direct Income") != undefined) {
          subaccount = Number(this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Direct Income").groupId);
        }
      }
      else if (acttype == "Expense") {
        if (this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Direct Expenses") != undefined) {
          subaccount = Number(this.Actgrouplist.find((item: { groupName: any; }) => item.groupName == "Direct Expenses").groupId);
        }
      }
    }
    this.form1?.patchValue({
      GroupID: Number(subaccount),
    });
  }
  checkbranchpermissions(item: any, val: any) {
    this.BranchList.filter((obj: { branchId: any; }) => obj.branchId == item.branchId).forEach((x: { state: any; }) => x.state = val);
    let isselectall: any = true;
    var list = this.BranchList.filter((obj: { state: any; }) => obj.state == false);
    if (list != null) {
      if (list.length > 0) {
        isselectall = false;
      }
    }
    this.selectall = isselectall;
    //
    this.submittedBranchPermissions = true;
    var listbr = this.BranchList.filter((obj: { state: any; }) => obj.state == true);
    if (listbr != null) {
      if (listbr.length > 0) {
        this.submittedBranchPermissions = false;
      }
    }
    //
  }
  checkallbranchpermissions(val: any) {
    this.BranchList.forEach((x: { state: any; }) => x.state = val);
    let isselectall: any = true;
    var list = this.BranchList.filter((obj: { state: any; }) => obj.state == false);
    if (list != null) {
      if (list.length > 0) {
        isselectall = false;
      }
    }
    this.selectall = isselectall;
    //
    this.submittedBranchPermissions = true;
    var listbr = this.BranchList.filter((obj: { state: any; }) => obj.state == true);
    if (listbr != null) {
      if (listbr.length > 0) {
        this.submittedBranchPermissions = false;
      }
    }
    //
  }
  changebranchopbal(item: any, val: any) {
    let val2: any = ""; val2 = val.toString();
    val= val2.replace(",", "");
    this.BranchList.filter((obj: { branchId: any; }) => obj.branchId == item.branchId).forEach((x: { opBal: any; }) => x.opBal = Number(val));
  }
  changebranchopsign(item: any, val: any) {
    let valsign: string = "Dr";
    if (val == 0) { valsign = "Dr"; } else { valsign = "Cr"; }
    this.BranchList.filter((obj: { branchId: any; }) => obj.branchId == item.branchId).forEach((x: { sign: any; }) => x.sign = valsign);
    
  }

}
