import { Component, ElementRef, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from '../../../../http.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-new-delivery',
  templateUrl: './new-delivery.component.html',
  styleUrls: ['./new-delivery.component.scss']
})
export class NewDeliveryComponent implements OnInit {

  form1: FormGroup | undefined;
  isShown: boolean = false;
  itemlist: any = [];
  BranchList: any = []; SPList: any = [];
  SizeList: any = []; SizeList2: any = [];
  GetItems: any = [];
  OrderBillsList: any = [];
  OrderBillsListfilter: any = [];
  SelectedItem: any;
  SelectedAccount: any;
  DocDate: any = "12-Jan-2021";
  SuppBilldate: any = "12-Jan-2021";
  DocDuedate: any = "12-Jan-2021";
  dynamicArray: any = [];
  totalitemscount: any = 0;
  totalitemsqty: any = 0;
  totalitemsamount: any = 0;
  totalitemsadvamount: any = 0;
  totalitemsbillamount: any = 0;
  @ViewChild('myname') input;
  get fc(): any | undefined { return this.form1?.controls; }
  titletextneworedit: string = "New";
  submitted = false;
  //
  cuttstichList: any = [];
  accountList: any = [];
  filteredaccountList: any = [];
  flag1: any = false;
  flag2: any = false;
  flag3: any = false;
  //
  editable: any = true;
  editid: any = 0;
  selectall = false;
  submittedorderItems = false;
  HighlightRow: Number | undefined = 0;
  deleteIDIDs: any = "";
  CashAccounts: any = [];
  BankAccounts: any = [];
  CreditAccounts: any = [];
  isWorkEntryProcessing: any = false;
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private datePipe: DatePipe, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    let todaydate = new Date();
    this.DocDate = todaydate;
    this.SuppBilldate = todaydate;
    this.DocDuedate = todaydate;
    //form Defining Start
    this.form1 = this._fb.group({
      //IQDoc_Header Table
      DocId: [0],
      CompID: [0],
      DivId: [0],
      BranchID: [0],
      SpId: [0],
      CCID: [0],
      SMId: [0],
      TtId: [0],
      DocDate: [''],
      DocDuedate: [''],
      TSeries: ['', [Validators.required]],
      TNo: [0, [Validators.required]],
      TExtn: [''],
      ActID: [0],
      Dcno: [''],
      DcAmt: [0],
      BillAmt: [0],
      Mode: [0],
      SuppBillno: [''],
      SuppBilldate: [''],
      SuppBillAmt: [0],
      DbSave: [false],
      TcBook: [false],
      DocCancel: [0],
      CashDiscount: [0],
      DocType: [0],
      ZDocId: [0],
      BillTime: [''],
      Narration: [''],
      BillQty: [0],
      VisitDate: [''],
      BillCost: [0],
      Etseries: [''],
      ETno: [0],
      Exclusive: [0],
      EtExtn: [''],
      EstAmt: [0],
      RecdAmt: [0],
      RendedAmt: [0],
      Pack: [0],
      AmcSeries: [''],
      AmcNo: [0],
      WayBillSeries: [''],
      WayBillNo: [0],
      WayBillDate: [''],
      DiscValue: [0],
      AdjValue: [0],
      DoctorID: [0],
      Comments: [''],
      BillPrinted: [0],
      FromSPID: [0],
      HeadActID: [0],
      ActControlID: [0],
      ProductValue: [0],
      ChrgValue: [0],
      SalRetAdjIDs: [''],
      Message: [''],
      MemoID: [0],
      BillVerified: [0],
      CreatedBy: [0],
      CreatedDate: [''],
      ModifiedBy: [0],
      ModifiedDate: [''],
      RptSel: [0],
      TransCommitted: [false],
      CashAdvance: [0],
      CformNo: [''],
      CformDate: [''],
      JobId: [0],
      InvoiceCost: [0],
      ToSPId: [0],
      TransferStatus: [false],
      DispatchStatus: [0],
      Remarks: [''],
      BillCancel: [false],
      CalculatedAmt: [0],
      ConfirmBy: [0],
      ConfirmDate: [''],
      AuditBy: [0],
      AuditDate: [''],
      TotalAdjValue: [0],
      Status: [1],
      ActName: [''],
      ActAddr: [''],
      ActTown: [''],
      ActPhone: [''],
      ActMailId: [''],
      ActCSTNo: [''],
      ActGRNNo: [''],
      ActTINNo: [''],
      ActPAN: [''],
      TradeType: [0],
      PointType: [0],
      ToDocId: [0],
      ToBranchId: [0],
      Note1: [''],
      Note2: [''],
      EntryMode: [0],
      DocActID: [0],
      VANID: [0],
      IsApproved: [0],
      ShipID: [0],
      ActStateID: [0],
      RefDocID: [0],
      WorkstationID: [''],
      ServiceOrGoods: [false],
      isPosted: [false],
      SM2: [0],
      //end IQDoc_Header Table
      AccountCode: [''],
      AccountName: [''],
      AccountFullAddress: [''],
      mobile: [''],
      orderdocid: [0],
      //
      cashmodeamount: [0],
      cashmodeactid: [0],
      upimodeamount: [0],
      upimodeactid: [0],
      cardmodeamount: [0],
      cardmodeactid: [0],
      creditmodeamount: [0],
      creditmodeactid: [0],
      //

    });
    let todaydtStr = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      DocDate: todaydtStr,
      DocDuedate: todaydtStr,
      SuppBilldate: todaydtStr,
    });
    let objdata: any = null;
    this.editid = 0;
    if (this.router.getCurrentNavigation() == null) {
    }
    else {
      if (this.router.getCurrentNavigation().extras.state != undefined) {
        if (this.router.getCurrentNavigation().extras.state.id != undefined) {
          this.editid = Number(this.router.getCurrentNavigation().extras.state.id);
          this.titletextneworedit = this.router.getCurrentNavigation().extras.state.text;
          objdata = this.router.getCurrentNavigation().extras.state.obj;
        }
      }
    }
    if (Number(this.editid) > 0) {
      this.editable = false;
    }
    else {
      this.editable = true;
    }
    //when page Load function
    let fyid: any = 0; let compid: any = 0;
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }
    const request = {
      method: 'GET',
      action_url: 'GetDeliveryEntryload?id=' + this.editid + '&compid=' + compid+''
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.isWorkEntryProcessing = resobj.isWorkEntryProcessing;
        this.clearSettlements();
        this.BranchList = resobj.listbranches;
        this.SPList = resobj.liststockpoints.filter((x: { spname: any; }) => !x.spname.includes('CUTTING') && !x.spname.includes('STITCHING'));
        this.GetItems = resobj.listitems;
        this.SizeList = resobj.listsizes;
        this.OrderBillsList = resobj.listobills;
        this.OrderBillsListfilter = resobj.listobills;
        let brid: any = Number(resobj.objdh.branchId);
        let spid: any = Number(resobj.objdh.spId);
        let actId: any = Number(resobj.objdh.actId);
        this.CashAccounts = resobj.listaccounts.filter((x: { actType: any; }) => x.actType == "Cash");
        this.CashAccounts = this.CashAccounts.sort((a, b) => (a.actName > b.actName) ? 1 : -1);
        this.BankAccounts = resobj.listaccounts.filter((x: { actType: any; }) => x.actType == "Bank");
        this.BankAccounts = this.BankAccounts.sort((a, b) => (a.actName > b.actName) ? 1 : -1);
        this.CreditAccounts = resobj.listaccounts.filter((x: { actType: any; }) => x.actType == "Customer" || x.actType == "Customer-Supplier" || x.actType == "Supplier" || x.actType == "Cutting" || x.actType == "Stitching");
        this.CreditAccounts = this.CreditAccounts.sort((a, b) => (a.actName > b.actName) ? 1 : -1);
        this.cuttstichList = resobj.listaccounts;
        if (this.cuttstichList != null) {
          if (this.cuttstichList.length > 0) {
            this.accountList = this.cuttstichList.filter((x: { actType: any; }) => x.actType == "Customer" || x.actType == "Customer-Supplier" || x.actType == "Supplier");
          }
        }
        this.form1?.patchValue({
          cashmodeactid: this.CashAccounts[0].actId,
          upimodeactid: this.BankAccounts[0].actId,
          cardmodeactid: this.BankAccounts[0].actId,
          creditmodeactid: this.CreditAccounts[0].actId,
          HeadActID: this.CashAccounts[0].actId,
        });
        //Edit
        if (Number(this.editid) > 0) {
          this.form1?.patchValue({
            DocId: Number(this.editid),
            CalculatedAmt: Number(resobj.objdh.calculatedAmt),
            CashDiscount: Number(resobj.objdh.cashDiscount),
            DiscValue: Number(resobj.objdh.discValue),
            orderdocid: Number(resobj.orderdocid),
            TtId: Number(resobj.objdh.ttId),
            ActControlID: Number(resobj.objdh.actControlId),
            HeadActID: Number(resobj.objdh.headActId) == 0 ? 6 : Number(resobj.objdh.headActId),
            Mode: Number(resobj.objdh.actControlId),
            creditmodeactid: Number(resobj.orderdocidactid),
          });
          let CashDiscount: any = Number(this.form1?.get('CashDiscount').value);
          let ActControlID: any = Number(this.form1?.get('ActControlID').value);
          let HeadActID: any = Number(this.form1?.get('HeadActID').value);
          if (Number(ActControlID) == 0) {
            this.form1?.patchValue({
              cashmodeactid: HeadActID,
              cashmodeamount: CashDiscount,
            });
          }
          else if (Number(ActControlID) == 1) {
            this.form1?.patchValue({
              creditmodeactid: HeadActID,
              creditmodeamount: CashDiscount,
            });
          }
          else if (Number(ActControlID) == 2) {
            this.form1?.patchValue({
              cardmodeactid: HeadActID,
              cardmodeamount: CashDiscount,
            });
          }
          else if (Number(ActControlID) == 4) {
            this.form1?.patchValue({
              upimodeactid: HeadActID,
              upimodeamount: CashDiscount,
            });
          }
          if (resobj.listitemsd != null) {
            this.dynamicArray = resobj.listitemsd;
            //this.dynamicArray = this.dynamicArray.sort((a, b) => (a.idid > b.idid) ? 1 : -1);
            var dynamicArray2 = this.dynamicArray.filter((obj: { state: any; }) => obj.state == true);
            if (dynamicArray2 != null) {
              if (dynamicArray2.length > 0) {
                this.selectall = true;
                this.totalitemscount = dynamicArray2.length;
                dynamicArray2.forEach((x: { reqQty: number; }) => this.totalitemsqty += x.reqQty);
                dynamicArray2.forEach((x: { itemValue: number; }) => this.totalitemsamount += x.itemValue);
                this.focusoutadvamt();
                this.SizeList2 = dynamicArray2[0].listsizes;
                if (this.SizeList2 != null) {
                  if (this.SizeList2.length>0) {
                    let itemId: any = Number(this.SizeList2[0].itemId);
                    for (var xx = 0; xx < this.dynamicArray.length; xx++) {
                      if (itemId == Number(this.dynamicArray[xx].itemId)) {
                        this.HighlightRow = xx;
                      }
                    }
                  }
                }

              }
            }
          }
          let docDatef: any = resobj.objdh.docDate.slice(0, 10);
          let docDateS = formatDate(new Date(docDatef.toString().trim()), 'yyyy-MM-dd', 'en_US');
          let docDatedt = new Date(docDateS);
          let docDate = new Date(docDatedt.getFullYear(), docDatedt.getMonth(), docDatedt.getDate());
          //
          let suppBilldatef: any = resobj.objdh.suppBilldate.slice(0, 10);
          let suppBilldateS = formatDate(new Date(suppBilldatef.toString().trim()), 'yyyy-MM-dd', 'en_US');
          let suppBilldatedt = new Date(suppBilldateS);
          let suppBilldate = new Date(suppBilldatedt.getFullYear(), suppBilldatedt.getMonth(), suppBilldatedt.getDate());
          //
          let docDuedatef: any = resobj.objdh.docDuedate.slice(0, 10);
          let docDuedateS = formatDate(new Date(docDuedatef.toString().trim()), 'yyyy-MM-dd', 'en_US');
          let docDuedatedt = new Date(docDuedateS);
          let docDuedate = new Date(docDuedatedt.getFullYear(), docDuedatedt.getMonth(), docDuedatedt.getDate());
          //
          this.form1?.patchValue({
            DocDate: docDateS,
            DocDuedate: docDuedateS,
            SuppBilldate: suppBilldateS,
            orderdocid: Number(resobj.objdh.zdocId),
          });
          this.DocDate = docDate;
          this.SuppBilldate = suppBilldate;
          this.DocDuedate = docDuedate;
          //
        }
        //end       
        this.form1?.patchValue({
          TSeries: resobj.objdh.tseries,
          TNo: resobj.objdh.tno,
          BranchID: brid,
          SpId: spid,
          actId: actId,
          Narration: resobj.objdh.narration,
          Message: resobj.objdh.message,
          BillAmt: Number(resobj.objdh.billAmt),

        });
        //       
      },
      error => {
        console.log(error);
      });
  }
  ngOnInit(): void {
  }
  endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }
  clearSettlements() {
    this.form1?.patchValue({
      cashmodeamount: 0,
      cashmodeactid: 0,
      upimodeamount: 0,
      upimodeactid: 0,
      cardmodeamount: 0,
      cardmodeactid: 0,
      creditmodeamount: 0,
      creditmodeactid: 0,
    });
  }
  onStartDateTimeChangeDocDate(dt: any) {
    this.DocDate = dt;
    let dtDocDate = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      DocDate: dtDocDate
    });
  }
  SaveOrSubmitForm(evn: any) {
    if (this.totalitemsbillamount > 0) {
      Swal.fire('Warning', 'Due Amount Pending .. So Saving is not Possible ..', 'warning');
      return;
    }
    this.submitted = true;
    let sqlTimeAsString = formatDate(new Date(), "yyyy-MM-dd", "en_US");
    //
    let fyid: any = 0; let compid: any = 0;
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }
    let editid: any = Number(this.form1?.get('DocId').value);
    if (editid == 0) {
      this.form1?.patchValue({
        BranchID: Number(this.form1?.get('BranchID').value),
        SpId: Number(this.form1?.get('SpId').value),
        TNo: Number(this.form1?.get('TNo').value),
      });
    }
    this.form1?.patchValue({
      CalculatedAmt: Number(this.form1?.get('CalculatedAmt').value),
      ActID: Number(this.form1?.get('orderdocid').value),
      DiscValue: Number(this.form1?.get('DiscValue').value),
      VisitDate: sqlTimeAsString,
      WayBillDate: sqlTimeAsString,
      CformDate: sqlTimeAsString,
      ConfirmDate: sqlTimeAsString,
      AuditDate: sqlTimeAsString,
      CreatedBy: Number(localStorage.getItem("Uid")),
      ModifiedBy: Number(localStorage.getItem("Uid")),
      CompID: compid,
      CreatedDate: sqlTimeAsString,
      ModifiedDate: sqlTimeAsString,
      BillAmt: (Number(this.totalitemsbillamount)),
      CashDiscount: Number(this.form1?.get('CashDiscount').value),
      ActControlID: Number(this.form1?.get('ActControlID').value),
      HeadActID: Number(this.form1?.get('HeadActID').value),
      Mode: Number(this.form1?.get('ActControlID').value),
    });
    if (this.form1?.invalid) {
      return;
    }
    let ValiddynamicArray = this.dynamicArray.filter((x: { state: any; }) => x.state == true);
    if (ValiddynamicArray == null) {
      Swal.fire('Warning', 'Atleast One Valid Item should be Enter for Saving Invoice', 'warning');
      return;
    }
    if (ValiddynamicArray.length == 0) {
      Swal.fire('Warning', 'Atleast One Valid Item should be Enter for Saving Invoice', 'warning');
      return;
    }
    this.deleteIDIDs = "";
    let ValiddynamicArray2 = this.dynamicArray.filter((x: { cnt: any; state: any; }) => x.cnt > 0 && x.state == false);
    for (var xx = 0; xx < ValiddynamicArray2.length; xx++) {
      if (Number(ValiddynamicArray2[xx].odid) > 0) {
        this.deleteIDIDs += Number(ValiddynamicArray2[xx].odid).toString() + ",";
      }
    }
    if (this.deleteIDIDs != "") {
      if (this.endsWith(this.deleteIDIDs, ",")) {
        this.deleteIDIDs = this.deleteIDIDs.slice(0, -1);
      }
    }
    let ActID: any = Number(this.form1?.get('orderdocid').value);
    if (ActID == 0) {
      Swal.fire('Warning', 'Please Select Order Bill Number', 'warning');
      return;
    }
    var obj = { objheader: this.form1?.value, listitem: ValiddynamicArray, deleteidids: this.deleteIDIDs };
    this.httpClient.post(this._services.APIUrl + '/InsertDeliveryEntry', obj).subscribe(res => {
      var result: any = res;
      if (result.msg.toString().includes('Successfull')) {
        Swal.fire('Delivery Entry Saved', result.msg.toString(), 'success');
        this.SearchOrderBills(1);
        //
        let id: any = 0;
        try {
          id = Number(result.status);
        } catch { }
        this.clearform();
        if (Number(id) > 0) {
          if (evn.submitter.value == "Save") { }
          else {
            this.printAllbyitemwiseOnly(id, 0, 1);
          }
        }
        //End
      }
    },
      error => {
        console.log(error);
      });
  }
  printAllbyitemwiseOnly(id: any, itemid: any, itemwiseonly: any) {
    this.router.navigateByUrl('/printorder', { state: { id: id, itemid: itemid, itemwiseonly: 1, isdelevery: 1 } });
  }
  clearform() {
    this.editable = true;
    this.editid = 0;
    if (this.BranchList != null) {
      let id: any = Number(this.BranchList[0].branchId);
      const request = {
        method: 'GET',
        action_url: 'GetStockPointsByBranchwithItems?id=' + id + '&vtype=Sales'
      };
      this._services.doHttp(request).subscribe(
        res => {
          let resobj: any = res;
          let spid: any = 0;
          this.SPList = resobj.listsplist;
          if (this.SPList != null) {
            spid = Number(this.SPList[0].spid);
          }
          this.GetItems = resobj.listitems;
          this.form1?.patchValue({
            TSeries: resobj.billseries,
            TNo: resobj.maxbillno,
            BranchID: id,
            SpId: spid,
            DocId: [0],
            CompID: [0],
            DivId: [0],
            CCID: [0],
            SMId: [0],
            TtId: [0],
            DocDate: [''],
            DocDuedate: [''],
            TExtn: [''],
            ActID: [0],
            Dcno: [''],
            DcAmt: [0],
            BillAmt: [0],
            Mode: [1],
            SuppBillno: [''],
            SuppBilldate: [''],
            SuppBillAmt: [0],
            DbSave: [false],
            TcBook: [false],
            DocCancel: [0],
            CashDiscount: [0],
            DocType: [0],
            ZDocId: [0],
            BillTime: [''],
            Narration: [''],
            BillQty: [0],
            VisitDate: [''],
            BillCost: [0],
            Etseries: [''],
            ETno: [0],
            Exclusive: [0],
            EtExtn: [''],
            EstAmt: [0],
            RecdAmt: [0],
            RendedAmt: [0],
            Pack: [0],
            AmcSeries: [''],
            AmcNo: [0],
            WayBillSeries: [''],
            WayBillNo: [0],
            WayBillDate: [''],
            DiscValue: [0],
            AdjValue: [0],
            DoctorID: [0],
            Comments: [''],
            BillPrinted: [0],
            FromSPID: [0],
            HeadActID: [0],
            ActControlID: [0],
            ProductValue: [0],
            ChrgValue: [0],
            SalRetAdjIDs: [''],
            Message: [''],
            MemoID: [0],
            BillVerified: [0],
            CreatedBy: [0],
            CreatedDate: [''],
            ModifiedBy: [0],
            ModifiedDate: [''],
            RptSel: [0],
            TransCommitted: [false],
            CashAdvance: [0],
            CformNo: [''],
            CformDate: [''],
            JobId: [0],
            InvoiceCost: [0],
            ToSPId: [0],
            TransferStatus: [false],
            DispatchStatus: [0],
            Remarks: [''],
            BillCancel: [false],
            CalculatedAmt: [0],
            ConfirmBy: [0],
            ConfirmDate: [''],
            AuditBy: [0],
            AuditDate: [''],
            TotalAdjValue: [0],
            Status: [1],
            ActName: [''],
            ActAddr: [''],
            ActTown: [''],
            ActPhone: [''],
            ActMailId: [''],
            ActCSTNo: [''],
            ActGRNNo: [''],
            ActTINNo: [''],
            ActPAN: [''],
            TradeType: [0],
            PointType: [0],
            ToDocId: [0],
            ToBranchId: [0],
            Note1: [''],
            Note2: [''],
            EntryMode: [0],
            DocActID: [0],
            VANID: [0],
            IsApproved: [0],
            ShipID: [0],
            ActStateID: [0],
            RefDocID: [0],
            WorkstationID: [''],
            ServiceOrGoods: [false],
            isPosted: [false],
            SM2: [0],
            //end IQDoc_Header Table
            AccountCode: [''],
            AccountName: [''],
            AccountFullAddress: [''],
            mobile: [''],
            orderdocid: [0],
          });
        },
        error => {
          console.log(error);
        });
      const invalidControl = this.input.nativeElement;
      invalidControl.focus();
      this.titletextneworedit = "New";
      let todaydate = new Date();
      this.DocDate = todaydate;
      this.SuppBilldate = todaydate;
      this.DocDuedate = todaydate;
      this.SizeList2 = [];
      this.dynamicArray = [];
      this.totalitemsadvamount = 0;
      this.defaultclear();
      this.submitted = false;
      this.selectall = false;
      this.OrderBillsListfilter = this.OrderBillsList;
    }
  }
  tablerowclick(obj: any, index: any) {
    this.HighlightRow = index;
    this.SizeList2 = [];
    if (obj.listsizes != null) {
      if (obj.listsizes.length > 0) {
        this.SizeList2 = obj.listsizes;
      }
    }
  }
  checkOrderItems(obj: any, val: any) {
    this.dynamicArray.filter((x: { itemId: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { state: any; }) => x.state = val);
    let isselectall: any = true;
    var list = this.dynamicArray.filter((obj: { state: any; }) => obj.state == false);
    if (list != null) {
      if (list.length > 0) {
        isselectall = false;
      }
    }
    this.selectall = isselectall;
    if (this.selectall) {
      this.defaultclear();
      var dynamicArray2 = this.dynamicArray.filter((obj: { state: any; }) => obj.state == true);
      if (dynamicArray2 != null) {
        if (dynamicArray2.length > 0) {
          this.totalitemscount = dynamicArray2.length;
          dynamicArray2.forEach((x: { reqQty: number; }) => this.totalitemsqty += x.reqQty);
          dynamicArray2.forEach((x: { itemValue: number; }) => this.totalitemsamount += x.itemValue);
          this.focusoutadvamt();
        }
      }
    }
    else {
      this.defaultclear();
      var dynamicArray2 = this.dynamicArray.filter((obj: { state: any; }) => obj.state == true);
      if (dynamicArray2 != null) {
        if (dynamicArray2.length > 0) {
          this.totalitemscount = dynamicArray2.length;
          dynamicArray2.forEach((x: { reqQty: number; }) => this.totalitemsqty += x.reqQty);
          dynamicArray2.forEach((x: { itemValue: number; }) => this.totalitemsamount += x.itemValue);
          this.focusoutadvamt();
        }
      }
    }
    //
    this.submittedorderItems = true;
    var listi = this.dynamicArray.filter((obj: { state: any; }) => obj.state == true);
    if (listi != null) {
      if (listi.length > 0) {
        this.submittedorderItems = false;
      }
    }
    //
  }
  checkallOrderItems(val: any) {
    let isselectall: any = val; this.submittedorderItems = val;
    this.defaultclear();
    if (this.dynamicArray != null) {
      this.dynamicArray.forEach((x: { state: any; }) => x.state = val);
      isselectall = true;
      var list = this.dynamicArray.filter((obj: { state: any; }) => obj.state == false);
      if (list != null) {
        if (list.length > 0) {
          isselectall = false;
        }
      }
      this.selectall = isselectall;
      if (this.selectall) {
        //       
        this.totalitemscount = this.dynamicArray.length;
        this.dynamicArray.forEach((x: { reqQty: number; }) => this.totalitemsqty += x.reqQty);
        this.dynamicArray.forEach((x: { itemValue: number; }) => this.totalitemsamount += x.itemValue);
        this.focusoutadvamt();
        //
      }
      //
      this.submittedorderItems = true;
      var listi = this.dynamicArray.filter((obj: { state: any; }) => obj.state == true);
      if (listi != null) {
        if (listi.length > 0) {
          this.submittedorderItems = false;
        }
      }
      //
    }
  }
  changeBranch(event: any) {
    let id: any = 0; id = Number(event.target.value);
    const request = {
      method: 'GET',
      action_url: 'GetStockPointsByBranch?id=' + id + '&vtype=Sales'
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.SPList = resobj.listsplist;
        this.form1?.patchValue({
          TSeries: resobj.billseries,
          TNo: resobj.maxbillno,
        });
      },
      error => {
        console.log(error);
      });
  }
  changeOrderBillNo(event: any) {
    let fyid: any = 0; let compid: any = 0;
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }
    this.dynamicArray = [];
    this.defaultclear();
    this.selectall = false;
    this.SizeList2 = [];
    let id: any = 0; id = Number(event.target.value);
    if (id > 0) {
      const request = {
        method: 'GET',
        action_url: 'GetOrderItemsDetailsbyId?id=' + id + '&compid=' + compid + ''
      };
      this._services.doHttp(request).subscribe(
        res => {
          let resobj: any = res;
          this.itemlist = [];
          if (resobj != null) {
            this.dynamicArray = resobj.listitems;
            this.itemlist = resobj.listitems;
            if (this.dynamicArray != null) {
              if (this.dynamicArray.length > 0) {
                //           
                this.totalitemscount = this.dynamicArray.length;
                this.dynamicArray.forEach((x: { reqQty: number; }) => this.totalitemsqty += x.reqQty);
                this.dynamicArray.forEach((x: { itemValue: number; }) => this.totalitemsamount += x.itemValue);
                this.form1?.patchValue({
                  CalculatedAmt: Number(resobj.advamt),
                  creditmodeactid: Number(resobj.listitems[0].actId),
                });
                this.focusoutadvamt();
                //end
                this.selectall = true;
                //
                this.tablerowclick(this.dynamicArray[0], 0);
                //
              }
            }

          }
        },
        error => {
          console.log(error);
        });
    }
  }
  changeitemqty(eventvalue: any, obj: any) {

    let qtyitm: any = Number(eventvalue);
    let balQty: any = Number(obj.balQty);
    if (qtyitm > balQty) {
      obj.reqQty = Number(obj.reqQtye);
      qtyitm = Number(obj.reqQtye);
      Swal.fire('Warning', 'Require Qty Should not be greater than balance Qty', 'warning');
      obj.reqQty = Number(obj.reqQtye);
      return;
    }
    //   
    obj.qty = (qtyitm);
    obj.rate = Number(obj.rate);
    obj.itemValue = (Number(qtyitm) * Number(obj.rate));

    this.dynamicArray.filter((x: { itemId: any, reqQty: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { reqQty: any; }) => x.reqQty = Number((qtyitm)));
    this.dynamicArray.filter((x: { itemId: any, rate: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { rate: any; }) => x.rate = Number(obj.rate));
    this.dynamicArray.filter((x: { itemId: any, itemValue: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { itemValue: any; }) => x.itemValue = Number(((qtyitm) * obj.rate)));
    //    
    this.totalitemscount = this.dynamicArray.length;
    this.dynamicArray.forEach((x: { reqQty: number; }) => this.totalitemsqty += x.reqQty);
    this.dynamicArray.forEach((x: { itemValue: number; }) => this.totalitemsamount += x.itemValue);
    this.totalitemsbillamount = (Number(this.totalitemsamount));
    //end
    this.defaultclear();
    var dynamicArray2 = this.dynamicArray.filter((obj: { state: any; }) => obj.state == true);
    if (dynamicArray2 != null) {
      if (dynamicArray2.length > 0) {
        this.totalitemscount = dynamicArray2.length;
        dynamicArray2.forEach((x: { reqQty: number; }) => this.totalitemsqty += x.reqQty);
        dynamicArray2.forEach((x: { itemValue: number; }) => this.totalitemsamount += x.itemValue);
        this.focusoutadvamt();
      }
    }
  }
  defaultclear() {
    this.totalitemscount = 0;
    this.totalitemsqty = 0;
    this.totalitemsamount = 0;
    this.totalitemsbillamount = 0;
  }
  SearchOrderBills(isall: any) {
    this.SizeList2 = [];
    this.dynamicArray = [];
    this.totalitemsadvamount = 0;
    this.defaultclear();
    let short: any = this.form1?.get('AccountCode')?.value;
    let name: any = this.form1?.get('AccountName')?.value;
    let mobile: any = this.form1?.get('mobile')?.value;
    if (isall == 0) {
      if ((short.length == 0) && (name.length == 0) && (mobile.length == 0)) {
        this.OrderBillsListfilter = this.OrderBillsList;
        return;
      }     
    }
    let fyid: any = 0; let compid: any = 0;
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }
    const request = {
      method: 'GET',
      action_url: 'GetSearchOrderbyactnamcdemob?actcode=' + short + '&actname=' + name + '&actmob=' + mobile + '&compid=' + compid+''
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.OrderBillsListfilter = resobj;
        if (this.OrderBillsListfilter != null) {
          if (this.OrderBillsListfilter.length>0) {
            this.form1?.patchValue({
              AccountCode: resobj[0].actCode,
              AccountName: resobj[0].actName,
              mobile: resobj[0].actMob1,
            });
          }
        }
      },
      error => {
        console.log(error);
      });
  }
  focusoutadvamtDiscount() {
    this.focusoutadvamt();  
  }
  focusoutadvamt() {
    let advamt = Number(this.form1?.get('CalculatedAmt').value);
    let Discamt = Number(this.form1?.get('DiscValue').value);
    let recdamt = Number(this.form1?.get('CashDiscount').value);
    this.totalitemsbillamount = (this.totalitemsamount - advamt - Discamt - recdamt);
  }
  displayStyle = "none";
  addRowItem() {
    let modeno: any = Number(this.form1?.get('ActControlID').value);
    let modeactid: any = 0; let modeactamount: any = 0;
    if (modeno == 1) {
      modeactid = Number(this.form1?.get('creditmodeactid').value);
      modeactamount = Number(this.form1?.get('creditmodeamount').value);
      this.form1?.patchValue({
        cardmodeamount: 0,
        upimodeamount: 0,
        cashmodeamount: 0,
      });
    }
    else if (modeno == 2) {
      modeactid = Number(this.form1?.get('cardmodeactid').value);
      modeactamount = Number(this.form1?.get('cardmodeamount').value);
      this.form1?.patchValue({
        creditmodeamount: 0,
        upimodeamount: 0,
        cashmodeamount: 0,
      });
    }
    else if (modeno == 4) {
      modeactid = Number(this.form1?.get('upimodeactid').value);
      modeactamount = Number(this.form1?.get('upimodeamount').value);
      this.form1?.patchValue({
        creditmodeamount: 0,
        cardmodeamount: 0,
        cashmodeamount: 0,
      });
    }
    else {
      modeactid = Number(this.form1?.get('cashmodeactid').value);
      modeactamount = Number(this.form1?.get('cashmodeamount').value);
      this.form1?.patchValue({
        creditmodeamount: 0,
        cardmodeamount: 0,
        upimodeamount: 0,
      });
    }
    this.form1?.patchValue({
      ActControlID: modeno,
      Mode: modeno,
      CashDiscount: modeactamount,
      HeadActID: modeactid,
    });
    this.focusoutadvamt();
    this.displayStyle = "none";
  }
  openPopup() {
    if (this.dynamicArray == null) {
      return;
    }
    if (this.dynamicArray.length==0) {
      return;
    }
    this.displayStyle = "block";
  }
  closePopup() {
    this.addRowItem();
    this.displayStyle = "none";
  }
  //
  clearaccountdetails() {
    this.form1?.patchValue({
      AccountCode: "",
      AccountName: "",
      mobile: "",
    });
  }
  //Filter CustId
  onselectCustId(item: any, isname: any) {
    this.clearaccountdetails();
    this.form1?.patchValue({
      AccountCode: item.actCode,
      AccountName: '',
      mobile: '',
    });
    this.flag3 = false;
  }
  changeCustId(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag3 = false;
    }
  }
  clickCustId(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag3 = false;
    }
  }
  keypressCustId(event: any, isname: any) {

    if (event.target.value == "") {
      this.filteredaccountList = [];
      if (isname == 0) {
        this.flag3 = false;
      }
      else {
        this.flag3 = false;
      }
    }
    else {
      this.flag3 = false;
      this.filteredaccountList = this.accountList.filter((x: { actType: any; actCode: any; }) => x.actCode.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredaccountList != null) {
        if (this.filteredaccountList.length > 0) {
          this.flag3 = true;
        }
      }
    }
  }
  //Filter CustId
  //Filter AccountName
  onselectAccountName(item: any, isname: any) {
    this.clearaccountdetails();
    this.form1?.patchValue({
      AccountCode: '',
      AccountName: item.actName,
      mobile: '',
    });
    this.flag2 = false;
  }
  changeAccountName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag2 = false;
    }
  }
  clickAccountName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag2 = false;
    }
  }
  keypressAccountName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag2 = false;
    }
    else {
      this.flag2 = false;
      this.filteredaccountList = this.accountList.filter((x: { actType: any; actName: any; }) => x.actName.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredaccountList != null) {
        if (this.filteredaccountList.length > 0) {
          this.flag2 = true;
        }
      }
    }
  }
  //Filter AccountName
  //Filter AccountMobile
  onselectAccountMobile(item: any, isname: any) {
    this.clearaccountdetails();
    this.form1?.patchValue({
      AccountCode: '',
      AccountName: '',
      mobile: item.actMob1,
    });
    this.flag1 = false;
  }
  changeAccountMobile(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag1 = false;
    }
  }
  clickAccountMobile(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag1 = false;
    }
  }
  keypressAccountMobile(event: any, isname: any) {

    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag1 = false;
    }
    else {
      this.flag1 = false;
      this.filteredaccountList = this.accountList.filter((x: { actType: any; actMob1: any; }) => x.actMob1.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredaccountList != null) {
        if (this.filteredaccountList.length > 0) {
          this.flag1 = true;
        }
      }
    }
  }
  //Filter AccountMobile
}
