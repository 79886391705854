<div class="contentMain">
  <!-- breadcrumb -->
  <div class="row">
    <div class="col-md-12 mainheader header-container">
      <h5>Sales</h5>
    </div>
  </div>
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <!-- card -->
    <div class="card">
      <!-- card body -->
      <div class="card-body">
        <!-- filters radio-->
        <div class="row">
          <div class="col-6 form-group">
            <div class="radio inlineblock m-r-20">
              <input type="radio" name="pack" id="Cash" class="with-gap" value="option1">
              <label for="Cash" class="pt-1">Cash</label>
            </div>
            <div class="radio inlineblock m-r-20">
              <input type="radio" name="pack" id="Credit" class="with-gap" value="option2" checked="">
              <label for="Credit" class="pt-1">Credit</label>
            </div>
            <div class="radio inlineblock m-r-20">
              <input type="radio" name="pack" id="Finance" class="with-gap" value="option2" checked="">
              <label for="Finance" class="pt-1">Card / Finance</label>
            </div>
            <div class="radio inlineblock m-r-20">
              <input type="radio" name="pack" id="UPI" class="with-gap" value="option2" checked="">
              <label for="UPI" class="pt-1">UPI</label>
            </div>
          </div>
          <div class=" col-6 form-group">
            <div class="radio inlineblock m-r-20">
              <input type="radio" name="pack" id="Cash" class="with-gap" value="option1">
              <label for="Cash" class="pt-1">Exclusive</label>
            </div>
            <div class="radio inlineblock m-r-20">
              <input type="radio" name="pack" id="Credit" class="with-gap" value="option2" checked="">
              <label for="Credit" class="pt-1">Inclusive</label>
            </div>
          </div>
        </div>
        <!--/ filters radio-->
        <!-- filters -->
        <div class="row">
          <!-- left col -->
          <div class="col-md-6 ">
            <div class="border p-3 h-100">
              <ul class="nav nav-tabs p-0 mb-3">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab"
                     href="#general">General</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#Parameters">Other</a>
                </li>
              </ul>
              <!-- row -->
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane in active p-3" id="general">
                  <div class="row">
                    <!-- col -->
                    <div class="col-lg-6 col-md-8 col-sm-6">
                      <div class="form-group">
                        <label>Branch</label>
                        <select class="form-control show-tick ms select2"
                                data-placeholder="Select">
                          <option>Head Office</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-6 col-md-8 col-sm-6">
                      <div class="form-group">
                        <label>Stock Point</label>
                        <select class="form-control show-tick ms select2"
                                data-placeholder="Select">
                          <option>Select Stock Point</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-12 col-md-12 col-sm-6">
                      <div class="form-group">
                        <label>TType</label>
                        <select class="form-control show-tick ms select2"
                                data-placeholder="Select">
                          <option>GST Sales</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-12 col-md-12 col-sm-6">
                      <div class="row">
                        <!-- col -->
                        <div class="col-md-5">
                          <div class="form-group">
                            <label>Account</label>
                            <input type="text" class="form-control" placeholder="Account">
                          </div>
                        </div>
                        <!--/ col -->
                        <!-- col -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>&nbsp;</label>
                            <select class="form-control show-tick ms select2"
                                    data-placeholder="Select">
                              <option>Select Account</option>
                              <option>Select Item</option>
                              <option>Select Item</option>
                              <option>Select Item</option>
                              <option>Select Item</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-1 pt-4">
                          <button class="btn redbtn" style="margin-left: -20px;" type="button">A</button>
                        </div>
                        <!--/ col -->
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-12 col-md-12 col-sm-6">
                      <div class="form-group">
                        <label>Address</label>
                        <input type="text" class="form-control" placeholder="Address">
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>City</label>
                        <input type="text" class="form-control" placeholder="City">
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Pin</label>
                        <input type="text" class="form-control" placeholder="Pin">
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>State</label>
                        <select class="form-control show-tick ms select2"
                                data-placeholder="Select">
                          <option>Select State</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-6 col-md-8 col-sm-6">
                      <div class="form-group">
                        <label>GSTIN</label>
                        <input type="text" class="form-control" placeholder="GSTIN">
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-6 col-md-8 col-sm-6">
                      <div class="form-group">
                        <label>Mobile</label>
                        <input type="text" class="form-control" placeholder="Mobile">
                      </div>
                    </div>
                    <!--/ col -->
                  </div>
                </div>
                <div role="tabpanel" class="tab-pane p-3" id="Parameters">
                  <div class="row">
                    <!-- col -->
                    <div class="col-lg-12 col-md-12 col-sm-6">
                      <div class="form-group">
                        <label>Shipping</label>
                        <select class="form-control show-tick ms select2"
                                data-placeholder="Select">
                          <option>Head Office</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Waybill No</label>
                        <select class="form-control show-tick ms select2"
                                data-placeholder="Select">
                          <option>Select Stock Point</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Way Bill Date</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="zmdi zmdi-calendar"></i>
                            </span>
                          </div>
                          <input type="text" class="form-control datepicker"
                                 placeholder="Bill Date...">
                        </div>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Aadhar No</label>
                        <input type="text" class="form-control" placeholder="Address">
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>PAN No</label>
                        <input type="text" class="form-control" placeholder="City">
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="form-group">
                        <label>Quotes</label>
                        <select class="form-control show-tick ms select2"
                                data-placeholder="Select">
                          <option>Select State</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <!--/ col -->
                  </div>
                </div>
              </div>
              <!--/ row -->
            </div>
          </div>
          <!--/ left col -->
          <!-- right col -->
          <div class="col-md-6 ">
            <div class="p-3 border h-100">
              <ul class="nav nav-tabs p-0 mb-3">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab"
                     href="#Othergeneral">Other</a>
                <li class="nav-item"><a class="nav-link " data-toggle="tab" href="#Gstinfo">GST Info</a>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab"
                     href="#Nextparameter">Parameter</a>
                </li>
              </ul>
              <!-- row -->
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane in active p-3" id="Othergeneral">
                  <div class="row">
                    <!-- col -->
                    <div class="col-lg-6 col-md-8 col-sm-6">
                      <div class="form-group">
                        <label>Bill Number</label>
                        <input type="text" class="form-control" placeholder="Account"
                               value="HO/GSAL">
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-md-3 col-sm-6">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <input type="text" class="form-control" placeholder="Account"
                               value="42">
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-md-3 col-sm-6">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <input type="text" class="form-control" placeholder="Account"
                               value="0.00">
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-6 col-md-8 col-sm-6">
                      <div class="form-group">
                        <label>Bill Date</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="zmdi zmdi-calendar"></i>
                            </span>
                          </div>
                          <input type="text" class="form-control datepicker"
                                 placeholder="Bill Date...">
                        </div>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-6 col-md-8 col-sm-6">
                      <div class="form-group">
                        <label>Due Date</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="zmdi zmdi-calendar"></i>
                            </span>
                          </div>
                          <input type="text" class="form-control datepicker"
                                 placeholder="Due Date...">
                        </div>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-lg-6 col-md-8 col-sm-6">
                      <div class="form-group">
                        <label>Salesman</label>
                        <select class="form-control show-tick ms select2"
                                data-placeholder="Select">
                          <option>Select Salesman</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-8 col-sm-6">
                      <div class="form-group">
                        <label></label>
                        <select class="form-control show-tick ms select2"
                                data-placeholder="Select">
                          <option>Select Salesman</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-md-12 col-sm-6">
                      <div class="form-group">
                        <label>Narration</label>
                        <input type="text" class="form-control" placeholder="Narration">
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>S.O</label>
                        <select class="form-control show-tick ms select2"
                                data-placeholder="Select">
                          <option>Select S.O</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>S.O.C</label>
                        <select class="form-control show-tick ms select2"
                                data-placeholder="Select">
                          <option>Select S.O.C</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <!--/ col -->
                    <!-- col -->
                    <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                        <label>SDC's</label>
                        <select class="form-control show-tick ms select2"
                                data-placeholder="Select">
                          <option>Select SDC's</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                          <option>Select Item</option>
                        </select>
                      </div>
                    </div>
                    <!--/ col -->
                  </div>
                  <div class="row">
                    <div class="col-md-4 col-sm-4">
                      <h6>P.Rate</h6>
                      <p>0.00</p>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <h6>Net Rate</h6>
                      <p>0.00</p>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <h6>LS.Rate</h6>
                      <p>0.00</p>
                    </div>
                  </div>
                </div>
                <!--/ row -->
                <div role="tabpanel" class="tab-pane p-3" id="Gstinfo">
                  <div class="table-responsive">
                    <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
                      <thead>
                        <tr>
                          <th>GST</th>
                          <th>Taxable Amount</th>
                          <th>SGST</th>
                          <th>CSGT</th>
                          <th>IGST</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>3% </td>
                          <td> 0.00 </td>
                          <td> 0.00 </td>
                          <td> 0.00 </td>
                          <td> 0.00 </td>
                          <td> 0.00 </td>
                        </tr>
                        <tr>
                          <td>5% </td>
                          <td> 0.00 </td>
                          <td>0.00 </td>
                          <td> 0.00 </td>
                          <td> 0.00 </td>
                          <td> 0.00 </td>
                        </tr>
                        <tr>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                        </tr>
                        <tr>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                        </tr>
                        <tr>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                        </tr>
                        <tr>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div role="tabpanel" class="tab-pane p-3" id="Nextparameter">
                  <div class="table-responsive">
                    <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Param Name</th>
                          <th>Param Value Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>13% </td>
                          <td> 0.00 </td>
                          <td> 0.00 </td>
                        </tr>
                        <tr>
                          <td>2 </td>
                          <td> 0.00 </td>
                          <td>0.00 </td>
                        </tr>
                        <tr>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                        </tr>
                        <tr>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                        </tr>
                        <tr>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                        </tr>
                        <tr>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!--/ right col -->
          </div>
          <!--/ filters -->
          <!-- table -->
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
              <thead>
                <tr>
                  <th>S.No:</th>
                  <th>Item Code</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Dis%</th>
                  <th>Disc Amount</th>
                  <th>Gross Amount</th>
                  <th>Tax..</th>
                  <th>Tax Amount</th>
                  <th>Net Value</th>
                  <th>Stock Point</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--/ table-->
          <div class="d-flex py-3 flex-wrap">
            <div class="px-2">
              <p>Qty: <b>0</b></p>
            </div>
            <div class="px-2">
              <p>Total Items: <b>0</b></p>
            </div>
            <div class="px-2">
              <p>Total Amount: <b>0</b></p>
            </div>
            <div class="px-2">
              <p>Total Disc: <b>0</b></p>
            </div>
            <div class="px-2">
              <p>Total Gross: <b>0</b></p>
            </div>
            <div class="px-2">
              <p>Total GST: <b>0</b></p>
            </div>
            <div class="px-2">
              <p>Total Net: <b>0</b></p>
            </div>
            <div class="px-2">
              <p>Item Value: <b>0</b></p>
            </div>
            <div class="px-2">
              <p>Total Adj: <b>0</b></p>
            </div>
            <div class="px-2">
              <p>Bill Amount: <b>0</b></p>
            </div>
          </div>
        </div>
        <!--/ card body -->
        <!-- card footer -->
        <!--/ card footer -->
      </div>
      <div class="card-footer text-left w-100">
        <button class="btn redbtn" type="button">Save</button>
        <button class="btn redbtn" type="button">Save&&Print</button>
        <button class="btn redbtn" type="button">F10-Save&&Mail</button>
        <button class="btn redbtn" type="button">Close</button>
      </div>
      <!--/ card -->
      <!-- </main> -->
      <!-- main body -->
    </div>
  </main>
