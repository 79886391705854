<div class="contentMain">
  <!-- breadcrumb -->
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <!-- card -->
    <div class="card">
      <div class="card-header mainheader header-container-btn">
        <h5>Manufacture Creation  </h5>
        <button class="btn redbtn" [routerLink]="['/newManufacture']" type="button">
          Create New
        </button>
      </div>

      <!-- <button class="btn redbtn" [routerLink]="/newManufacture" type="button">New</button> -->

      <form class="form-inline custom-form-inline">
        <label>Manufacture Name:</label>
        <input type="text" class="form-control" placeholder="Manufacture Name" />
        <label>Manufacture code:</label>
        <input type="text" class="form-control" placeholder="Manufacture Code" />
        <button class="btn redbtn" type="button">Search</button>
      </form>
      <br>
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
          <thead>
            <tr>
              <th><input id="remember_me" type="checkbox"> Select</th>
              <th>Manufacture Name</th>
              <th>Manufacture Code</th>
              <th>Pin code</th>
              <th>Edit Delete View</th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input id="activestatus" type="checkbox">

              </td>
              <td>Vijayawada </td>
              <td> VJ2445 </td>
              <td> 5465667 </td>
              <td>
                <a><img src="../../../../../assets/images/edit-small.png"> </a>
                <a> <img src="../../../../../assets/images/delete.png"></a>
                <a><img src="../../../../../assets/images/visibility.png"> </a>
              </td>

            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox">

              </td>
              <td>Vijayawada </td>
              <td> VJ2445 </td>
              <td> 5465667 </td>
              <td>
                <a><img src="../../../../../assets/images/edit-small.png"> </a>
                <a> <img src="../../../../../assets/images/delete.png"></a>
                <a><img src="../../../../../assets/images/visibility.png"> </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox">

              </td>
              <td>Vijayawada </td>
              <td> VJ2445 </td>
              <td> 5465667 </td>
              <td>
                <a><img src="../../../../../assets/images/edit-small.png"> </a>
                <a> <img src="../../../../../assets/images/delete.png"></a>
                <a><img src="../../../../../assets/images/visibility.png"> </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox">

              </td>
              <td>Vijayawada </td>
              <td> VJ2445 </td>
              <td> 5465667 </td>
              <td>
                <a><img src="../../../../../assets/images/edit-small.png"> </a>
                <a> <img src="../../../../../assets/images/delete.png"></a>
                <a><img src="../../../../../assets/images/visibility.png"> </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox">

              </td>
              <td>Vijayawada </td>
              <td> VJ2445 </td>
              <td> 5465667 </td>
              <td>
                <a><img src="../../../../../assets/images/edit-small.png"> </a>
                <a> <img src="../../../../../assets/images/delete.png"></a>
                <a><img src="../../../../../assets/images/visibility.png"> </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox">

              </td>
              <td>Vijayawada </td>
              <td> VJ2445 </td>
              <td> 5465667 </td>
              <td>
                <a><img src="../../../../../assets/images/edit-small.png"> </a>
                <a> <img src="../../../../../assets/images/delete.png"></a>
                <a><img src="../../../../../assets/images/visibility.png"> </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox">

              </td>
              <td>Vijayawada </td>
              <td> VJ2445 </td>
              <td> 5465667 </td>
              <td>
                <a><img src="../../../../../assets/images/edit-small.png"> </a>
                <a> <img src="../../../../../assets/images/delete.png"></a>
                <a><img src="../../../../../assets/images/visibility.png"> </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox">

              </td>
              <td>Vijayawada </td>
              <td> VJ2445 </td>
              <td> 5465667 </td>
              <td>
                <a><img src="../../../../../assets/images/edit-small.png"> </a>
                <a> <img src="../../../../../assets/images/delete.png"></a>
                <a><img src="../../../../../assets/images/visibility.png"> </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox">

              </td>
              <td>Vijayawada </td>
              <td> VJ2445 </td>
              <td> 5465667 </td>
              <td>
                <a><img src="../../../../../assets/images/edit-small.png"> </a>
                <a> <img src="../../../../../assets/images/delete.png"></a>
                <a><img src="../../../../../assets/images/visibility.png"> </a>
              </td>
            </tr>
            <tr>
              <td>
                <input id="activestatus" type="checkbox">

              </td>
              <td>Vijayawada </td>
              <td> VJ2445 </td>
              <td> 5465667 </td>
              <td>
                <a><img src="../../../../../assets/images/edit-small.png"> </a>
                <a> <img src="../../../../../assets/images/delete.png"></a>
                <a><img src="../../../../../assets/images/visibility.png"> </a>
              </td>
            </tr>


          </tbody>
        </table>
        <div class="pagination-container">
          <div class="show ml-8">
            <span>Show</span>
            <select class="ms form-control">
              <option selected value="10">10</option>
              <option value="25">25</option>
            </select>
          </div>
          <div>
            <nav aria-label="Page navigation example" class="float-right mr-8">
              <ul class="pagination pagination-primary">
                <li class="page-item">
                  <a class="page-link" href="#">Previous</a>
                </li>
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="#">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="card-footer text-left w-100">



        <button class="btn redbtn" type="button">Clear</button>
        <button class="btn redbtn" type="button">Cancel</button>

        <button class="btn redbtn" type="button">Help</button>
      </div>
    </div>
    <!--/ card -->
  </main>
  <!-- main body -->
</div>
