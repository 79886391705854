<!-- Container Fluid-->
<div class="container-fluid" id="container-wrapper">
  <div class="d-sm-flex align-items-center justify-content-between">
    <p class="h3 mb-0" style="color: #18558a">Dashboard</p>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="./">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
    </ol>
  </div>
  <div class="row mb-3">
    <div class="col-xl-3 col-md-6">
      <div class="card h-40">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1">
                Today Orders
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{orderscount | currency:'INR':'' }}</div>
              <!--<div class="mt-2 mb-0 text-muted text-xs">
                <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                <span> last month</span>
              </div>-->
            </div>
            <div class="col-auto">
              <i class="fas fa-calendar fa-2x text-primary"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6">
      <div class="card h-40">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1">
                Today Trails
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{trailscount | currency:'INR':'' }}</div>
              <!--<div class="mt-2 mb-0 text-muted text-xs">
                <span class="text-success mr-2"><i class="fas fa-arrow-up"></i> 12%</span>
                <span> last years</span>
              </div>-->
            </div>
            <div class="col-auto">
              <i class="fas fa-bell fa-2x text-primary"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6">
      <div class="card h-40">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1">
                Ready To Delivery
              </div>
              <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{readytodelvcount | currency:'INR':'' }}</div>
              <!--<div class="mt-2 mb-0 text-muted text-xs">
                <span class="text-success mr-2"><i class="fas fa-arrow-up"></i> 20.4%</span>
                <span> Today</span>
              </div>-->
            </div>
            <div class="col-auto">
              <i class="fas fa-users fa-2x text-info"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6">
      <div class="card h-40">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1">
                Today Delivered
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{devlcount | currency:'INR':'' }}</div>
              <!--<div class="mt-2 mb-0 text-muted text-xs">
                <span class="text-danger mr-2"><i class="fas fa-arrow-down"></i> 1.10%</span>
                <span> yesterday</span>
              </div>-->
            </div>
            <div class="col-auto">
              <i class="fas fa-comments fa-2x text-warning"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6">
      <div class="card h-40">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1">
                Today Orders
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{ordercount | currency:'INR':'' }}</div>
              <!--<div class="mt-2 mb-0 text-muted text-xs">
                <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                <span> last month</span>
              </div>-->
            </div>
            <div class="col-auto">
              <i class="fas fa-stopwatch fa-2x text-primary"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6">
      <div class="card h-40">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1">
                Today Receipts
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{receiptcount | currency:'INR':'' }}</div>
              <!--<div class="mt-2 mb-0 text-muted text-xs">
                <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                <span> last month</span>
              </div>-->
            </div>
            <div class="col-auto">
              <i class="fas fa-calendar fa-2x text-primary"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6">
      <div class="card h-40">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1">
                Today Payments
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{paymentcount | currency:'INR':'' }}</div>
              <!--<div class="mt-2 mb-0 text-muted text-xs">
                <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                <span> last month</span>
              </div>-->
            </div>
            <div class="col-auto">
              <i class="fa fa-inr fa-2x text-primary" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6">
      <div class="card h-40">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1">
                Cutting Stock nos
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{cuttstockcount | currency:'INR':'' }}</div>
              <!--<div class="mt-2 mb-0 text-muted text-xs">
                <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                <span> last month</span>
              </div>-->
            </div>
            <div class="col-auto">
              <i class="fas fa-cut fa-2x text-primary"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-7">
      <div class="card">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Invoice</h6>
          <a class="m-0 float-right btn btn-danger btn-sm" >
            View More <i class="fas fa-chevron-right"></i>
          </a>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
            <thead class="thead-light">
              <tr>
                <th>Order ID</th>
                <th>Customer</th>
                <th>Item</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><a href="#">RA0449</a></td>
                <td>Udin Wayang</td>
                <td>Nasi Padang</td>
                <td><span class="badge badge-success">Delivered</span></td>
                <td><a href="#" class="btn btn-sm btn-primary">Detail</a></td>
              </tr>
              <tr>
                <td><a href="#">RA5324</a></td>
                <td>Jaenab Bajigur</td>
                <td>Gundam 90' Edition</td>
                <td><span class="badge badge-warning">Shipping</span></td>
                <td><a href="#" class="btn btn-sm btn-primary">Detail</a></td>
              </tr>
              <tr>
                <td><a href="#">RA8568</a></td>
                <td>Rivat Mahesa</td>
                <td>Oblong T-Shirt</td>
                <td><span class="badge badge-danger">Pending</span></td>
                <td><a href="#" class="btn btn-sm btn-primary">Detail</a></td>
              </tr>
              <tr>
                <td><a href="#">RA1453</a></td>
                <td>Indri Junanda</td>
                <td>Hat Rounded</td>
                <td><span class="badge badge-info">Processing</span></td>
                <td><a href="#" class="btn btn-sm btn-primary">Detail</a></td>
              </tr>
              <tr>
                <td><a href="#">RA1998</a></td>
                <td>Udin Cilok</td>
                <td>Baby Powder</td>
                <td><span class="badge badge-success">Delivered</span></td>
                <td><a href="#" class="btn btn-sm btn-primary">Detail</a></td>
              </tr>
              <tr>
                <td><a href="#">RA1998</a></td>
                <td>Udin Cilok</td>
                <td>Baby Powder</td>
                <td><span class="badge badge-success">Pending Details Report</span></td>
                <td><a href="#" class="btn btn-sm btn-primary">Detail</a></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-5">
      <div class="card">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between"></div>
        <div class="card-body">
          <div class="col-md-12">
            <!-- buttons -->
            <!--/ buttons -->
            <figure class="avatarImg text-center">
              <img src="./assets/images/avatar1.jpg"
                   class=""
                   alt="Profile Image"
                   height="200px"
                   width="100%" />
            </figure>
            <!-- buttons -->
            <div class="d-grid gap-2 d-md-block text-center">
              <a class="btn btn-outline-primary brdbtn rounded-pill"
                 data-target="#uploadImage"
                 data-toggle="modal">
                Kiran Tylors ,Guntur
              </a>
            </div>
            <!--/ buttons -->
          </div>
        </div>
      </div>
    </div>
    <!--<div class="col-xl-4 col-lg-5">
      <div class="card">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Products Sold</h6>
          <div class="dropdown no-arrow">
            <a class="dropdown-toggle btn btn-primary btn-sm"
               href="#"
               role="button"
               id="dropdownMenuLink"
               data-toggle="dropdown"
               aria-haspopup="true"
               aria-expanded="false">
              Month <i class="fas fa-chevron-down"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                 aria-labelledby="dropdownMenuLink">
              <div class="dropdown-header">Select Periode</div>
              <a class="dropdown-item" href="#">Today</a>
              <a class="dropdown-item" href="#">Week</a>
              <a class="dropdown-item active" href="#">Month</a>
              <a class="dropdown-item" href="#">This Year</a>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="mb-3">
            <div class="small text-gray-500">
              Oblong T-Shirt
              <div class="small float-right"><b>600 of 800 Items</b></div>
            </div>
            <div class="progress" style="height: 12px">
              <div class="progress-bar bg-warning"
                   role="progressbar"
                   style="width: 80%"
                   aria-valuenow="80"
                   aria-valuemin="0"
                   aria-valuemax="100"></div>
            </div>
          </div>
          <div class="mb-3">
            <div class="small text-gray-500">
              Gundam 90'Editions
              <div class="small float-right"><b>500 of 800 Items</b></div>
            </div>
            <div class="progress" style="height: 12px">
              <div class="progress-bar bg-success"
                   role="progressbar"
                   style="width: 70%"
                   aria-valuenow="70"
                   aria-valuemin="0"
                   aria-valuemax="100"></div>
            </div>
          </div>
          <div class="mb-3">
            <div class="small text-gray-500">
              Rounded Hat
              <div class="small float-right"><b>455 of 800 Items</b></div>
            </div>
            <div class="progress" style="height: 12px">
              <div class="progress-bar bg-danger"
                   role="progressbar"
                   style="width: 55%"
                   aria-valuenow="55"
                   aria-valuemin="0"
                   aria-valuemax="100"></div>
            </div>
          </div>
          <div class="mb-3">
            <div class="small text-gray-500">
              Indomie Goreng
              <div class="small float-right"><b>400 of 800 Items</b></div>
            </div>
            <div class="progress" style="height: 12px">
              <div class="progress-bar bg-info"
                   role="progressbar"
                   style="width: 50%"
                   aria-valuenow="50"
                   aria-valuemin="0"
                   aria-valuemax="100"></div>
            </div>
          </div>
          <div class="mb-3">
            <div class="small text-gray-500">
              Remote Control Car Racing
              <div class="small float-right"><b>200 of 800 Items</b></div>
            </div>
            <div class="progress" style="height: 12px">
              <div class="progress-bar bg-success"
                   role="progressbar"
                   style="width: 30%"
                   aria-valuenow="30"
                   aria-valuemin="0"
                   aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="card-footer text-center">
          <a class="m-0 small text-primary card-link" href="#">
            View More <i class="fas fa-chevron-right"></i>
          </a>
        </div>
      </div>
    </div>-->
  </div>
  <!-- Modal Logout -->
  <div class="modal fade"
       id="logoutModal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="exampleModalLabelLogout"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabelLogout">Ohh No!</h5>
          <button type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to logout?</p>
        </div>
        <div class="modal-footer">
          <button type="button"
                  class="btn btn-outline-primary"
                  data-dismiss="modal">
            Cancel
          </button>
          <a href="login.html" class="btn btn-primary">Logout</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!---Container Fluid-->
