<div class="contentMain">
  <div class="row">
    <div class="col-md-12 mainheader header-container">
      <h5 title="{{editid}}">Payment Entry - {{titletextneworedit}} Mode</h5>
    </div>
  </div>
  <main class="row">
    <div class="card">
      <form class="form" [formGroup]="form1" (ngSubmit)="SaveOrSubmitForm($event)">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
              <div class="border p-2 h-100" style="margin-top:-15px;">
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <!--<div class="form-group">
            <label>Account Code</label>
            <input type="text" class="form-control" [attr.readonly]="!editable ? '' : null" placeholder="Account Code" formControlName="AccountCode">
          </div>-->
                    <label>Account Code</label>
                    <div class="form-group">
                      <input type="text" formControlName="AccountCode" placeholder="Account Code" [attr.readonly]="!editable ? '' : null" class="form-control" (input)="keypressCustId($event,0)" (change)="changeCustId($event,0)" (click)="clickCustId($event,0)" style="width: 100%;" />
                      <div class="combobox-options" *ngIf="filteredaccountList.length>0 && flag3===true">
                        <ul style=" width:100%;">
                          <li *ngFor="let item of filteredaccountList" (click)="onselectCustId(item,0)">
                            <a>{{item.actCode}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <!--<div class="form-group">
            <label>Mobile No</label>
            <input type="text" class="form-control" [attr.readonly]="!editable ? '' : null" placeholder="Mobile No" formControlName="mobile">
          </div>-->
                    <label>Mobile No</label>
                    <div class="form-group">
                      <input type="text" class="form-control" [attr.readonly]="!editable ? '' : null" placeholder="Mobile No" formControlName="mobile" (input)="keypressAccountMobile($event,0)" (change)="changeAccountMobile($event,0)" (click)="clickAccountMobile($event,0)" style="width: 100%;">
                      <div class="combobox-options" *ngIf="filteredaccountList.length>0 && flag1===true">
                        <ul style=" width:100%;">
                          <li *ngFor="let item of filteredaccountList" (click)="onselectAccountMobile(item,0)">
                            <a>{{item.actMob1}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-9 col-sm-9">
                    <!--<div class="form-group">
            <label>Account Name</label>
            <input type="text" class="form-control" [attr.readonly]="!editable ? '' : null" placeholder="Account Name" formControlName="AccountName">
          </div>-->
                    <label>Account Name</label>
                    <div class="form-group">
                      <input type="text" formControlName="AccountName" placeholder="Account Name" [attr.readonly]="!editable ? '' : null" class="form-control" (input)="keypressAccountName($event,0)" (change)="changeAccountName($event,0)" (click)="clickAccountName($event,0)" style="width: 100%;" />
                      <div class="combobox-options" *ngIf="filteredaccountList.length>0 && flag2===true">
                        <ul style=" width:100%;">
                          <li *ngFor="let item of filteredaccountList" (click)="onselectAccountName(item,0)">
                            <a>{{item.actName}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="form-group">
                      <label style="margin-top:37px"></label>
                      <button class="btn redbtn" type="button" [disabled]="!editable" (click)="SearchOrderBills(0)">Search</button>
                    </div>
                  </div>
                  <div class="col-md-9 col-sm-9">
                    <label>Amount</label>
                    <div class="form-group">
                      <input type="text"  formControlName="CalculatedAmt" id="CalculatedAmt" placeholder="Amount" class="form-control" style="width: 100%; text-align: right;" />
                    </div>
                  </div>

                  <!--<div class="col-md-12 col-sm-6">
          <div class="form-group">
            <label>Order Bill No</label>
            <select class="form-control" [attr.disabled]="!editable ? '' : null" (change)="changeOrderBillNo($event)" formControlName="orderdocid">
              <option [value]="0">Select Order Bill</option>
              <option *ngFor="let item of OrderBillsListfilter" [value]="item.docId">{{item.billno }}</option>
            </select>
          </div>
        </div>-->
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
              <div class="border p-2 h-100" style="margin-top:-15px;">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label>Branch</label>
                      <select class="form-control" [attr.disabled]="!editable ? '' : null" (change)="changeBranch($event)" formControlName="BranchID" #myname>
                        <option *ngFor="let Brlist of BranchList" [value]="Brlist.branchId">{{Brlist.branchName }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label>Stock point</label>
                      <select class="form-control" [attr.disabled]="!editable ? '' : null" formControlName="SpId">
                        <option *ngFor="let item of SPList" [value]="item.spid">{{item.spname }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <div class="form-group">
                      <label>Series</label>
                      <input type="text" class="form-control" [attr.readonly]="!editable ? '' : null" placeholder="Order Series" [ngClass]="{ 'is-invalid': submitted && fc.TSeries.errors }" formControlName="TSeries">
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <div class="form-group">
                      <label>No</label>
                      <input type="text" class="form-control" [attr.readonly]="!editable ? '' : null" placeholder="Order Number" [ngClass]="{ 'is-invalid': submitted && fc.TNo.errors }" formControlName="TNo">
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label>Date</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                        </div>
                        <input type="text" class="form-control" style="width: 60%;" id="Fromdate" [(ngModel)]="DocDate" name="DocDate" [ngModelOptions]="{standalone: true}" (ngModelChange)="onStartDateTimeChangeDocDate($event)" [bsValue]="DocDate" bsDatepicker [bsConfig]="{containerClass:'theme-green', dateInputFormat: 'DD-MMM-YYYY' }">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-sm-12">
                    <div class="form-group">
                      <label>Notes</label>
                      <textarea class="form-control" formControlName="Narration"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <!--<div class="col-md-3 col-lg-3 col-sm-3 col-xl-3" style="overflow-y: scroll; height: 450px;">
              <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable" style="overflow-y: scroll; width: 100%;">
                <thead>
                  <tr>
                    <th>ShirtSize</th>
                    <th>ShirtValue</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of SizeList2">
                    <td>{{item.sizeName}}</td>
                    <td>{{item.sizeValue}}</td>
                  </tr>
                </tbody>
              </table>
            </div>-->
            <!--<div class="col-md-12 col-lg-12 col-sm-12 col-xl-12" style="width: 100%; height: 450px; overflow: auto;">
              <div style="width: 100%; height: 250px; overflow-y: scroll;overflow:auto;">
                <table class="tableclass table table-bordered table-striped table-hover js-basic-example dataTable kliqtable" style="width:100%; overflow:auto;">
                  <thead>
                    <tr>
                      <th><input type="checkbox" [checked]="selectall" (change)="checkallOrderItems($event.target.checked)"></th>
                      <th>Order.Date</th>
                      <th>Trail.Date</th>
                      <th>Delv.Date</th>
                      <th>Item.Name</th>
                      <th>Ord.Qty</th>
                      <th>Delv.Qty</th>
                      <th>Bal.Qty</th>
                      <th>Req.Qty</th>
                      <th>Rate</th>
                      <th>ItemValue</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of dynamicArray;let i=index;" (click)="tablerowclick(item,i)" [class.active]="i == HighlightRow" [class.activeeditrows]="item.cnt>0">
                      <td scope="row">
                        <div class="checkbox">
                          <input id="check{{i}}" type="checkbox" [checked]="item.state==true" [value]="item.state" (change)="checkOrderItems(item,$event.target.checked)" />
                          <label for="check{{i}}"> &nbsp; </label>
                        </div>
                      </td>
                      <td>{{item.docDate}}</td>
                      <td>{{item.suppBilldate}}</td>
                      <td>{{item.docDuedate}}</td>
                      <td>{{item.itemName}}</td>
                      <td class="text-right"> {{item.orderQty | currency:'INR':'' }}</td>
                      <td class="text-right"> {{item.delvQty | currency:'INR':'' }}</td>
                      <td class="text-right"> {{item.balQty | currency:'INR':'' }}</td>
                      <td class="text-right">
                        <input type="text" class="form-control" style="text-align: right"
                               [value]="item.reqQty" [title]="item.reqQtye" (focusout)="changeitemqty($event.target.value,item)" />
                      </td>
                      <td class="text-right"> {{item.rate | currency:'INR':'' }}</td>
                      <td class="text-right"> {{item.itemValue | currency:'INR':'' }}</td>
                    </tr>
                    <tr *ngIf="submittedorderItems">
                      <td colspan="11"><label style=" color: red;">* Plz Select Atleast One Item</label></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row" style="width: 100%; height: 100px;overflow:auto;">
                <table style="width: 100%;">
                  <tr>
                    <td>
                      <table style="width:100%;">
                        <tr>
                          <td><label>Tot.Items</label></td>
                        </tr>
                        <tr>
                          <td class="text-right"> <p><b><label class="form-check-label">{{totalitemscount | currency:'INR':''}}</label>  </b></p></td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table style="width:100%;">
                        <tr>
                          <td><label>Tot.Qty</label></td>
                        </tr>
                        <tr>
                          <td class="text-right"> <p><b><label class="form-check-label">{{totalitemsqty | currency:'INR':''}}</label>  </b></p></td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table style="width:100%;">
                        <tr>
                          <td><label>Tot.Amount</label></td>
                        </tr>
                        <tr>
                          <td class="text-right"> <p><b><label class="form-check-label">{{totalitemsamount | currency:'INR':''}}</label>  </b></p></td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table style="width:100%;">
                        <tr>
                          <td><label>Adv.Amount</label></td>
                        </tr>
                        <tr>
                          <td style="width: 80px;"> <p><b><input type="text" class="form-control" style="text-align: right;" formControlName="CalculatedAmt" (focusout)="focusoutadvamt()" /> </b></p></td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table style="width:100%;">
                        <tr>
                          <td><label>Disc.Amount</label></td>
                        </tr>
                        <tr>
                          <td style="width: 80px;"> <p><b><input type="text" class="form-control" [attr.readonly]="''" style="text-align: right;" formControlName="DiscValue" (focusout)="focusoutadvamtDiscount()" /> </b></p></td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table style="width:100%;">
                        <tr style="text-align:right;">
                          <td><button class="btn btn-sm btn-primary" type="button" (click)="openPopup()">Settlement</button></td>
                        </tr>
                        <tr>
                          <td style="width: 80px;"> <p><b><input type="text" class="form-control" [attr.readonly]="''" style="text-align: right;" formControlName="CashDiscount" (focusout)="focusoutadvamt()" /> </b></p></td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table style="width:100%;">
                        <tr style="text-align:right;">
                          <td><label>Due.Amount</label></td>
                        </tr>
                        <tr>
                          <td class="text-right"> <p><b><label class="form-check-label">{{totalitemsbillamount | currency:'INR':''}}</label>  </b></p></td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>-->
                <!--<div class="col-md-2 col-sm-4 text-right">
                  <table style="width:100%;">
                    <tr>
                      <td><label>Tot. Items </label></td>
                    </tr>
                    <tr>
                      <td> <p><b><label class="form-check-label">{{totalitemscount | currency:'INR':''}}</label>  </b></p></td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-2 col-sm-4 text-right">
                  <table style="width:100%;">
                    <tr>
                      <td><label>Total Qty </label></td>
                    </tr>
                    <tr>
                      <td> <p><b><label class="form-check-label">{{totalitemsqty | currency:'INR':''}}</label>  </b></p></td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-2 col-sm-4 text-right">
                  <table style="width:100%;">
                    <tr>
                      <td><label>Tot.Amount </label></td>
                    </tr>
                    <tr>
                      <td> <p><b><label class="form-check-label">{{totalitemsamount | currency:'INR':''}}</label>  </b></p></td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-2 col-sm-4 text-right">
                  <table style="width:100%;">
                    <tr>
                      <td><label>Adv.Amount </label></td>
                    </tr>
                    <tr>
                      <td> <p><b><input type="text" class="form-control" style="text-align: right" formControlName="CalculatedAmt" (focusout)="focusoutadvamt()" /> </b></p></td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-2 col-sm-4 text-right">
                  <table style="width:100%;">
                    <tr>
                      <td><label>Disc.Amount </label></td>
                    </tr>
                    <tr>
                      <td> <p><b><input type="text" class="form-control" style="text-align: right" formControlName="DiscValue" (focusout)="focusoutadvamt()" /> </b></p></td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-2 col-sm-4 text-right">
                  <table style="width:100%;">
                    <tr>
                      <td><label>Due Amount </label></td>
                    </tr>
                    <tr>
                      <td> <p><b><label class="form-check-label">{{totalitemsbillamount | currency:'INR':''}}</label>  </b></p></td>
                    </tr>
                  </table>
                </div>-->
              <!--</div>
              <div class="row" style="width: 100%; height: 100px;">
                <div class="col-md-12 col-lg-12">
                  <div class="form-group" style="width: 100%;">
                    <label>SMS Message</label>
                    <textarea rows="1" class="form-control" formControlName="Message"></textarea>
                  </div>
                </div>
              </div>
            </div>-->
          </div>
        </div>
        <div class="card-footer text-left w-100">
          <button class="btn redbtn" type="submit" value="Save">Save</button>
          <button class="btn redbtn" type="submit" value="Save & Print">Save & Print</button>
          <button class="btn redbtn" type="button" (click)="clearform()">Refresh</button>
          <button class="btn redbtn" type="button">Close</button>
        </div>
        <!-- Bootstrap Modal for Upload Multiple images -->
        <div class="modal"
             tabindex="-1"
             role="dialog"
             [ngStyle]="{'display':displayStyle}">
          <!--<div class="modal-dialog " role="document">
            <h4 class="modal-title" style="background-color:cornflowerblue;color:white;text-align:center;">Choose Transaction Mode</h4>
            <div class="modal-content">
              <div class="modal-body">
                <div style="width: 100%;height:100%;margin-top:-20px;">
                  <div style="width: 100%; height: 220px; overflow-y: auto; overflow-x: auto;">
                    <table style="width: 100%; overflow:auto;" class="table">
                      <tbody>
                        <tr>
                          <td>
                            <div class="form-group">
                              <div class="radio inlineblock m-r-20">
                                <input type="radio" id="mode0" class="with-gap" [value]="0" formControlName="ActControlID">
                                <label for="mode0" class="pt-1">Cash</label>
                              </div>
                            </div>
                          </td>
                          <td class="text-right">
                            <input type="text" class="form-control text-right" [value]="0.00" formControlName="cashmodeamount" />
                          </td>
                          <td class="text-right">
                            <select class="form-control" formControlName="cashmodeactid">
                              <option *ngFor="let item of CashAccounts" [value]="item.actId">{{item.actName }}</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-group">
                              <div class="radio inlineblock m-r-20">
                                <input type="radio" id="mode2" class="with-gap" [value]="4" formControlName="ActControlID">
                                <label for="mode2" class="pt-1">UPI</label>
                              </div>
                            </div>
                          </td>
                          <td class="text-right">
                            <input type="text" class="form-control text-right" [value]="0.00" formControlName="upimodeamount" />
                          </td>
                          <td class="text-right">
                            <select class="form-control" formControlName="upimodeactid">
                              <option *ngFor="let item of BankAccounts" [value]="item.actId">{{item.actName }}</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-group">
                              <div class="radio inlineblock m-r-20">
                                <input type="radio" id="mode3" class="with-gap" [value]="2" formControlName="ActControlID">
                                <label for="mode3" class="pt-1">Card</label>
                              </div>
                            </div>
                          </td>
                          <td class="text-right">
                            <input type="text" class="form-control text-right" [value]="0.00" formControlName="cardmodeamount" />
                          </td>
                          <td class="text-right">
                            <select class="form-control" formControlName="cardmodeactid">
                              <option *ngFor="let item of BankAccounts" [value]="item.actId">{{item.actName }}</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-group">
                              <div class="radio inlineblock m-r-20">
                                <input type="radio" id="mode4" class="with-gap" [value]="1" formControlName="ActControlID">
                                <label for="mode4" class="pt-1">Credit</label>
                              </div>
                            </div>
                          </td>
                          <td class="text-right">
                            <input type="text" class="form-control text-right" [value]="0.00" formControlName="creditmodeamount" />
                          </td>
                          <td class="text-right">
                            <select class="form-control" formControlName="creditmodeactid">
                              <option *ngFor="let item of CreditAccounts" [value]="item.actId">{{item.actName }}</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn redbtn" (click)="addRowItem()">Add </button>
                <button type="button" class="btn btn-danger" (click)="closePopup()">Close</button>
              </div>
            </div>
          </div>-->
        </div>
        <!-- Bootstrap Modal for Upload Multiple images -->
      </form>
    </div>
  </main>

</div>
