import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-item-category',
  templateUrl: './new-item-category.component.html',
  styleUrls: ['./new-item-category.component.scss']
})
export class NewItemCategoryComponent implements OnInit {

  constructor() {

  }
  ngOnInit(): void {

  }

}
