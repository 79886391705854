import { Component, ElementRef, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from '../../../../http.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
declare var require: any;
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss']
})
export class NewOrderComponent implements OnInit {
  h1HdocId: any = 0;
  h2HdocId: any = 0;
  h3HdocId: any = 0;
  cssPrintingPath: any = "./assets/fonts/Printing.css";
  form1: FormGroup | undefined;
  isShown: boolean = false;
  itemlist: any = [];
  BranchList: any = []; SPList: any = [];
  SizeList: any = []; SizeList2: any = [];
  GetItems: any = [];
  SelectedItem: any;
  SelectedAccount: any;
  DocDate: any = "12-Jan-2021";
  SuppBilldate: any = "12-Jan-2021";
  DocDuedate: any = "12-Jan-2021";
  dynamicArray: any = [];
  totalitemscount: any = 0;
  totalitemsqty: any = 0;
  totalitemsamount: any = 0;
  totalitemsadvamount: any = 0;
  totalitemsbillamount: any = 0;
  @ViewChild('myname') input;
  get fc(): any | undefined { return this.form1?.controls; }
  titletextneworedit: string = "New";
  submitted = false;
  //
  cuttstichList: any = [];
  accountList: any = [];
  filteredaccountList: any = [];
  flag1: any = false;
  flag2: any = false;
  flag3: any = false;
  //
  imgUrl: any = "./assets/images/transactions/1.jpg";
  imgUrle: any = "./assets/images/transactions/1.jpg";
  @ViewChild('fileInput') fileInput: ElementRef;
  selectedFile: File = null;
  //
  imgUrl2: any = "./assets/images/transactions/2.jpg";
  imgUrle2: any = "./assets/images/transactions/2.jpg";
  @ViewChild('fileInput2') fileInput2: ElementRef;
  selectedFile2: File = null;
  //
  imgUrl3: any = "./assets/images/transactions/3.jpg";
  imgUrle3: any = "./assets/images/transactions/3.jpg";
  @ViewChild('fileInput3') fileInput3: ElementRef;
  selectedFile3: File = null;
  //
  imgUrl4: any = "./assets/images/transactions/4.jpg";
  imgUrle4: any = "./assets/images/transactions/4.jpg";
  @ViewChild('fileInput4') fileInput4: ElementRef;
  selectedFile4: File = null;
  //
  editable: any = true;
  editdisabled: any = true;
  editid: any = 0;
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private datePipe: DatePipe, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    let todaydate = new Date();
    this.DocDate = todaydate;
    this.SuppBilldate = todaydate;
    this.DocDuedate = todaydate;
    //form Defining Start
    this.form1 = this._fb.group({
      //IQDoc_Header Table
      DocId: [0],
      CompID: [0],
      DivId: [0],
      BranchID: [0],
      SpId: [0],
      CCID: [0],
      SMId: [0],
      TtId: [0],
      DocDate: [''],
      DocDuedate: [''],
      TSeries: ['', [Validators.required]],
      TNo: [0, [Validators.required]],
      TExtn: [''],
      ActID: [0],
      Dcno: [''],
      DcAmt: [0],
      BillAmt: [0],
      Mode: [1],
      SuppBillno: [''],
      SuppBilldate: [''],
      SuppBillAmt: [0],
      DbSave: [false],
      TcBook: [false],
      DocCancel: [0],
      CashDiscount: [0],
      DocType: [0],
      ZDocId: [0],
      BillTime: [''],
      Narration: [''],
      BillQty: [0],
      VisitDate: [''],
      BillCost: [0],
      Etseries: [''],
      ETno: [0],
      Exclusive: [0],
      EtExtn: [''],
      EstAmt: [0],
      RecdAmt: [0],
      RendedAmt: [0],
      Pack: [0],
      AmcSeries: [''],
      AmcNo: [0],
      WayBillSeries: [''],
      WayBillNo: [0],
      WayBillDate: [''],
      DiscValue: [0],
      AdjValue: [0],
      DoctorID: [0],
      Comments: [''],
      BillPrinted: [0],
      FromSPID: [0],
      HeadActID: [0],
      ActControlID: [0],
      ProductValue: [0],
      ChrgValue: [0],
      SalRetAdjIDs: [''],
      Message: [''],
      MemoID: [0],
      BillVerified: [0],
      CreatedBy: [0],
      CreatedDate: [''],
      ModifiedBy: [0],
      ModifiedDate: [''],
      RptSel: [0],
      TransCommitted: [false],
      CashAdvance: [0],
      CformNo: [''],
      CformDate: [''],
      JobId: [0],
      InvoiceCost: [0],
      ToSPId: [0],
      TransferStatus: [false],
      DispatchStatus: [0],
      Remarks: [''],
      BillCancel: [false],
      CalculatedAmt: [0],
      ConfirmBy: [0],
      ConfirmDate: [''],
      AuditBy: [0],
      AuditDate: [''],
      TotalAdjValue: [0],
      Status: [1],
      ActName: [''],
      ActAddr: [''],
      ActTown: [''],
      ActPhone: [''],
      ActMailId: [''],
      ActCSTNo: [''],
      ActGRNNo: [''],
      ActTINNo: [''],
      ActPAN: [''],
      TradeType: [0],
      PointType: [0],
      ToDocId: [0],
      ToBranchId: [0],
      Note1: [''],
      Note2: [''],
      EntryMode: [0],
      DocActID: [0],
      VANID: [0],
      IsApproved: [0],
      ShipID: [0],
      ActStateID: [0],
      RefDocID: [0],
      WorkstationID: [''],
      ServiceOrGoods: [false],
      isPosted: [false],
      SM2: [0],
      //end IQDoc_Header Table
      AccountCode: [''],
      AccountName: [''],
      AccountFullAddress: [''],
      mobile: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
      filename: ['1.jpg'],
      filename2: ['2.jpg'],
      filename3: ['3.jpg'],
      filename4: ['4.jpg'],
    });
    let todaydtStr = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      DocDate: todaydtStr,
      DocDuedate: todaydtStr,
      SuppBilldate: todaydtStr,
    });
    let objdata: any = null;
    this.editid = 0;
    if (this.router.getCurrentNavigation() == null) {
    }
    else {
      if (this.router.getCurrentNavigation().extras != undefined) {
        if (this.router.getCurrentNavigation().extras.state != undefined) {
          if (this.router.getCurrentNavigation().extras.state.id != undefined) {
            this.editid = Number(this.router.getCurrentNavigation().extras.state.id);
            this.titletextneworedit = this.router.getCurrentNavigation().extras.state.text;
            objdata = this.router.getCurrentNavigation().extras.state.obj;
          }
        }
      }
    }
    if (Number(this.editid) > 0) {
      this.editable = false;
    }
    else {
      this.editable = true;
    }
    //when page Load function
    const request = {
      method: 'GET',
      action_url: 'GetOrderEntryload?id=' + this.editid
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.BranchList = resobj.listbranches;
        this.SPList = resobj.liststockpoints;
        this.GetItems = resobj.listitems;
        console.log(resobj.listsizes);
        this.SizeList = resobj.listsizes;
        this.cuttstichList = resobj.listaccounts;
        if (this.cuttstichList != null) {
          if (this.cuttstichList.length > 0) {
            this.accountList = this.cuttstichList.filter((x: { actType: any; }) => x.actType == "Customer" || x.actType == "Customer-Supplier" || x.actType == "Supplier");
          }
        }
        let brid: any = Number(resobj.objdh.branchId);
        let spid: any = Number(resobj.objdh.spId);
        let actId: any = Number(resobj.objdh.actId);
        //Edit
        if (Number(this.editid) > 0) {

          if (resobj.listitemsedit != null) {
            this.dynamicArray = resobj.listitemsedit;
            this.dynamicArray = this.dynamicArray.sort((a, b) => (a.idid > b.idid) ? 1 : -1);
            this.SizeList2 = this.dynamicArray[0].listsizes;
            this.defaultclear();
            this.totalitemscount = this.dynamicArray.length;
            this.dynamicArray.forEach((x: { qty: number; }) => this.totalitemsqty += x.qty);
            this.dynamicArray.forEach((x: { amt: number; }) => this.totalitemsamount += x.amt);
            this.focusoutadvamt();
            //end
          }
          let docDatef: any = resobj.objdh.docDate.slice(0, 10);
          let docDateS = formatDate(new Date(docDatef.toString().trim()), 'yyyy-MM-dd', 'en_US');
          let docDatedt = new Date(docDateS);
          let docDate = new Date(docDatedt.getFullYear(), docDatedt.getMonth(), docDatedt.getDate());
          //
          let suppBilldatef: any = resobj.objdh.suppBilldate.slice(0, 10);
          let suppBilldateS = formatDate(new Date(suppBilldatef.toString().trim()), 'yyyy-MM-dd', 'en_US');
          let suppBilldatedt = new Date(suppBilldateS);
          let suppBilldate = new Date(suppBilldatedt.getFullYear(), suppBilldatedt.getMonth(), suppBilldatedt.getDate());
          //
          let docDuedatef: any = resobj.objdh.docDuedate.slice(0, 10);
          let docDuedateS = formatDate(new Date(docDuedatef.toString().trim()), 'yyyy-MM-dd', 'en_US');
          let docDuedatedt = new Date(docDuedateS);
          let docDuedate = new Date(docDuedatedt.getFullYear(), docDuedatedt.getMonth(), docDuedatedt.getDate());
          //
          this.form1?.patchValue({
            DocDate: docDateS,
            DocDuedate: docDuedateS,
            SuppBilldate: suppBilldateS,
          });
          this.DocDate = docDate;
          this.SuppBilldate = suppBilldate;
          this.DocDuedate = docDuedate;
          //
          this.form1?.patchValue({
            AccountCode: resobj.accountCode,
            AccountName: resobj.accountName,
            AccountFullAddress: resobj.accountFullAddress,
            mobile: resobj.actMob1,
            ActID: actId,
            DocId: Number(this.editid),
            CalculatedAmt: Number(resobj.objdh.calculatedAmt),
          });
          this.focusoutadvamt();
          this.h1HdocId = resobj.h1HdocId;
          this.h2HdocId = resobj.h2HdocId;
          this.h3HdocId = resobj.h3HdocId;
        }
        //end       
        this.form1?.patchValue({
          TSeries: resobj.objdh.tseries,
          TNo: resobj.objdh.tno,
          BranchID: brid,
          SpId: spid,
          actId: actId,
          Narration: resobj.objdh.narration,
          Message: resobj.objdh.message,
          BillAmt: Number(resobj.objdh.billAmt),
        });
        if (Number(this.editid) > 0) {
          this.editdisabled = Number(resobj.editdisabled) == 0 ? false : true;
          if (resobj != null) {
            if (resobj.imgurle != "") {
              this.imgUrl = resobj.imgurle;
            }
            if (resobj.imgurle != "") {
              this.imgUrl2 = resobj.imgurle2;
            }
            if (resobj.imgurle != "") {
              this.imgUrl3 = resobj.imgurle3;
            }
            if (resobj.imgurle != "") {
              this.imgUrl4 = resobj.imgurle4;
            }
          }
        }
        else {
          this.imgUrl = "./assets/images/transactions/1.jpg";
          this.imgUrl2 = "./assets/images/transactions/2.jpg";
          this.imgUrl3 = "./assets/images/transactions/3.jpg";
          this.imgUrl4 = "./assets/images/transactions/4.jpg";
        }
        //       
      },
      error => {
        console.log(error);
      });
  }
  //1
  cancelImageasDefault() {
    this.imgUrl = "./assets/images/transactions/1.jpg";
    this.form1?.patchValue({
      filename: '1.jpg'
    });
  }
  cancelImageasDefaultedit() {
    this.imgUrl = "./assets/images/transactions/1.jpg";
    try {
      this.imgUrl = this.imgUrle;
      this.form1?.patchValue({
        filename: '1.jpg'
      });
    }
    catch {
      this.imgUrl = "./assets/images/transactions/1.jpg";
      this.imgUrle = "./assets/images/transactions/1.jpg";
      this.imgUrl = this.imgUrle;
      this.form1?.patchValue({
        filename: '1.jpg'
      });
    }
  }
  onSelectFile(fileInput: any) {
    let myFile: File = null;
    this.selectedFile = <File>fileInput.target.files[0];
    if (this.selectedFile.size > 512000) {
      Swal.fire('Error', 'File is too large. Over 500KB', 'error');
      return;
    }
    let _reader = new FileReader();
    _reader.onload = (event: any) => {
      this.imgUrl = event.target.result;
      var fileExtension = '.' + this.selectedFile.name.split('.').pop();
      var myBlob = new Blob();
      myFile = this.blobToFile(myBlob, "1", this.selectedFile.type);
      this.form1?.patchValue({
        filename: (myFile.name + fileExtension)
      });
    }
    _reader.readAsDataURL(this.selectedFile);
  }
  //2
  cancelImageasDefault2() {
    this.imgUrl2 = "./assets/images/transactions/2.jpg";
    this.form1?.patchValue({
      filename2: '2.jpg'
    });
  }
  cancelImageasDefaultedit2() {
    this.imgUrl2 = "./assets/images/transactions/2.jpg";
    try {
      this.imgUrl2 = this.imgUrle2;
      this.form1?.patchValue({
        filename2: '2.jpg'
      });
    }
    catch {
      this.imgUrl2 = "./assets/images/transactions/2.jpg";
      this.imgUrle2 = "./assets/images/transactions/2.jpg";
      this.imgUrl2 = this.imgUrle2;
      this.form1?.patchValue({
        filename2: '2.jpg'
      });
    }
  }
  onSelectFile2(fileInput2: any) {
    let myFile: File = null;
    this.selectedFile2 = <File>fileInput2.target.files[0];
    if (this.selectedFile2.size > 512000) {
      Swal.fire('Error', 'File is too large. Over 500KB', 'error');
      return;
    }
    let _reader = new FileReader();
    _reader.onload = (event: any) => {
      this.imgUrl2 = event.target.result;
      var fileExtension = '.' + this.selectedFile2.name.split('.').pop();
      var myBlob = new Blob();
      myFile = this.blobToFile(myBlob, "2", this.selectedFile2.type);
      this.form1?.patchValue({
        filename2: (myFile.name + fileExtension)
      });
    }
    _reader.readAsDataURL(this.selectedFile2);
  }
  //3
  cancelImageasDefault3() {
    this.imgUrl3 = "./assets/images/transactions/3.jpg";
    this.form1?.patchValue({
      filename3: '3.jpg'
    });
  }
  cancelImageasDefaultedit3() {
    this.imgUrl3 = "./assets/images/transactions/3.jpg";
    try {
      this.imgUrl3 = this.imgUrle3;
      this.form1?.patchValue({
        filename3: '3.jpg'
      });
    }
    catch {
      this.imgUrl3 = "./assets/images/transactions/3.jpg";
      this.imgUrle3 = "./assets/images/transactions/3.jpg";
      this.imgUrl3 = this.imgUrle3;
      this.form1?.patchValue({
        filename3: '3.jpg'
      });
    }
  }
  onSelectFile3(fileInput3: any) {
    this.selectedFile3 = <File>fileInput3.target.files[0];
    if (this.selectedFile3.size > 512000) {
      Swal.fire('Error', 'File is too large. Over 500KB', 'error');
      return;
    }
    let _reader = new FileReader();
    _reader.onload = (event: any) => {
      this.imgUrl3 = event.target.result;
      var fileExtension = '.' + this.selectedFile3.name.split('.').pop();
      var myBlob = new Blob();
      var myFile = this.blobToFile(myBlob, "3", this.selectedFile3.type);
      this.form1?.patchValue({
        filename3: (myFile.name + fileExtension)
      });
    }
    _reader.readAsDataURL(this.selectedFile3);
  }
  //4
  cancelImageasDefault4() {
    this.imgUrl4 = "./assets/images/transactions/4.jpg";
    this.form1?.patchValue({
      filename4: '4.jpg'
    });
  }
  cancelImageasDefaultedit4() {
    this.imgUrl4 = "./assets/images/transactions/4.jpg";
    try {
      this.imgUrl4 = this.imgUrle4;
      this.form1?.patchValue({
        filename4: '4.jpg'
      });
    }
    catch {
      this.imgUrl4 = "./assets/images/transactions/4.jpg";
      this.imgUrle4 = "./assets/images/transactions/4.jpg";
      this.imgUrl4 = this.imgUrle4;
      this.form1?.patchValue({
        filename4: '4.jpg'
      });
    }
  }
  onSelectFile4(fileInput4: any) {
    this.selectedFile4 = <File>fileInput4.target.files[0];
    if (this.selectedFile4.size > 512000) {
      Swal.fire('Error', 'File is too large. Over 500KB', 'error');
      return;
    }
    let _reader = new FileReader();
    _reader.onload = (event: any) => {
      this.imgUrl4 = event.target.result;
      var fileExtension = '.' + this.selectedFile4.name.split('.').pop();
      var myBlob = new Blob();
      var myFile = this.blobToFile(myBlob, "4", this.selectedFile4.type);
      this.form1?.patchValue({
        filename4: (myFile.name + fileExtension)
      });
    }
    _reader.readAsDataURL(this.selectedFile4);
  }
  //
  ClickedRow: any;
  HighlightRow: Number | undefined = 0;
  public blobToFile = (theBlob: Blob, fileName: string, type: any): File => {
    var b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return <File>theBlob;
  }
  ngOnInit(): void {

  }
  onStartDateTimeChangeDocDate(dt: any) {
    this.DocDate = dt;
    let dtDocDate = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      DocDate: dtDocDate
    });
  }
  onStartDateTimeChangeSuppBilldate(dt: any) {
    this.SuppBilldate = dt;
    let dtSuppBilldate = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      SuppBilldate: dtSuppBilldate
    });
  }
  onStartDateTimeChangeDocDuedate(dt: any) {
    this.DocDuedate = dt;
    let dtDocDuedate = formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      DocDuedate: dtDocDuedate
    });
  }
  SaveOrSubmitForm(evn: any) {

    this.submitted = true;
    let sqlTimeAsString = formatDate(new Date(), "yyyy-MM-dd", "en_US");
    //
    let fyid: any = 0; let compid: any = 0;
    try {
      fyid = Number(localStorage.getItem("FyID"));
    } catch { }
    try {
      compid = Number(localStorage.getItem("CompID"));
    } catch { }
    let editid: any = Number(this.form1?.get('DocId').value);
    if (editid == 0) {
      this.form1?.patchValue({
        BranchID: Number(this.form1?.get('BranchID').value),
        SpId: Number(this.form1?.get('SpId').value),
        TNo: Number(this.form1?.get('TNo').value),
      });
    }
    this.form1?.patchValue({
      CalculatedAmt: Number(this.form1?.get('CalculatedAmt').value),
      VisitDate: sqlTimeAsString,
      WayBillDate: sqlTimeAsString,
      CformDate: sqlTimeAsString,
      ConfirmDate: sqlTimeAsString,
      AuditDate: sqlTimeAsString,
      CreatedBy: Number(localStorage.getItem("Uid")),
      ModifiedBy: Number(localStorage.getItem("Uid")),
      CompID: compid,
      CreatedDate: sqlTimeAsString,
      ModifiedDate: sqlTimeAsString,
      BillAmt: Number(this.totalitemsbillamount),
    });
    if (this.form1?.invalid) {
      return;
    }
    if (this.dynamicArray == null) {
      Swal.fire('Warning', 'Atleast One Valid Item should be Enter for Saving Invoice', 'warning');
      return;
    }
    if (this.dynamicArray.length == 0) {
      Swal.fire('Warning', 'Atleast One Valid Item should be Enter for Saving Invoice', 'warning');
      return;
    }
    let ActID: any = Number(this.form1?.get('ActID').value);
    if (ActID == 0) {
      Swal.fire('Warning', 'Please Select Account', 'warning');
      return;
    }
    var obj = { objheader: this.form1?.value, listitem: this.dynamicArray, deleteidids: this.deleteIDIDs };
    this.httpClient.post(this._services.APIUrl + '/InsertOrderEntry', obj).subscribe(res => {
      var result: any = res;
      if (result.msg.toString().includes('Successfull')) {
        Swal.fire('OrderEntry Saved', result.msg.toString(), 'success');
        //
        let id: any = 0;
        try {
          id = Number(result.status);
        } catch { }
        const formData = new FormData();
        formData.append('id', id);
        formData.append('Image', this.selectedFile);
        formData.append('filepath', this.form1?.get('filename')?.value);
        formData.append('Image2', this.selectedFile2);
        formData.append('filepath2', this.form1?.get('filename2')?.value);
        formData.append('Image3', this.selectedFile3);
        formData.append('filepath3', this.form1?.get('filename3')?.value);
        formData.append('Image4', this.selectedFile4);
        formData.append('filepath4', this.form1?.get('filename4')?.value);
        const uploadReq = new HttpRequest('POST', `api/uploadimage`, formData, {
          reportProgress: true,
        });
        this.httpClient.request(uploadReq).subscribe(event => {
        });
        this.clearform();
        if (Number(id) > 0) {
          if (evn.submitter.value == "Save") { }
          else if (evn.submitter.value == "Save & Print") {
            this.printorderbyid(id, 0);
          }
          else {
            this.printAllbyitemwiseOnly(id, 0, 1);
          }
        }

        //End
      }
    },
      error => {
        console.log(error);
      });
  }
  reload() {
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/neworder']);
    });
  }
  clearform() {
    this.editable = true;
    this.imgUrl = "./assets/images/transactions/1.jpg";
    this.imgUrl2 = "./assets/images/transactions/2.jpg";
    this.imgUrl3 = "./assets/images/transactions/3.jpg";
    this.imgUrl4 = "./assets/images/transactions/4.jpg";
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    this.titletextneworedit = "New";
    let todaydate = new Date();
    this.DocDate = todaydate;
    this.SuppBilldate = todaydate;
    this.DocDuedate = todaydate;
    this.SizeList2 = [];
    this.dynamicArray = [];
    this.totalitemsadvamount = 0;
    this.defaultclear();
    this.submitted = false;
    this.h1HdocId = 0;
    this.h2HdocId = 0;
    this.h3HdocId = 0;
    this.editdisabled = true;
    this.editid = 0;
    //this.reload();
    if (this.BranchList != null) {
      let id: any = Number(this.BranchList[0].branchId);
      const request = {
        method: 'GET',
        action_url: 'GetStockPointsByBranchwithItems?id=' + id + '&vtype=Purchase'
      };
      this._services.doHttp(request).subscribe(
        res => {
          let resobj: any = res;
          let spid: any = 0;
          this.SPList = resobj.listsplist;
          if (this.SPList != null) {
            spid = Number(this.SPList[0].spid);
          }
          this.GetItems = resobj.listitems;
          this.form1?.patchValue({
            TSeries: resobj.billseries,
            TNo: resobj.maxbillno,
            BranchID: id,
            SpId: spid,
            DocId: 0,
            CompID: 0,
            DivId: 0,
            CCID: 0,
            SMId: 0,
            TtId: 0,
            DocDate: '',
            DocDuedate: '',
            TExtn: '',
            ActID: 0,
            Dcno: '',
            DcAmt: 0,
            BillAmt: 0,
            Mode: 1,
            SuppBillno: '',
            SuppBilldate: '',
            SuppBillAmt: 0,
            DbSave: false,
            TcBook: false,
            DocCancel: 0,
            CashDiscount: 0,
            DocType: 0,
            ZDocId: 0,
            BillTime: '',
            Narration: '',
            BillQty: 0,
            VisitDate: '',
            BillCost: 0,
            Etseries: '',
            ETno: 0,
            Exclusive: 0,
            EtExtn: '',
            EstAmt: 0,
            RecdAmt: 0,
            RendedAmt: 0,
            Pack: 0,
            AmcSeries: '',
            AmcNo: 0,
            WayBillSeries: '',
            WayBillNo: 0,
            WayBillDate: '',
            DiscValue: 0,
            AdjValue: 0,
            DoctorID: 0,
            Comments: '',
            BillPrinted: 0,
            FromSPID: 0,
            HeadActID: 0,
            ActControlID: 0,
            ProductValue: 0,
            ChrgValue: 0,
            SalRetAdjIDs: '',
            Message: '',
            MemoID: 0,
            BillVerified: 0,
            CreatedBy: 0,
            CreatedDate: '',
            ModifiedBy: 0,
            ModifiedDate: '',
            RptSel: 0,
            TransCommitted: false,
            CashAdvance: 0,
            CformNo: '',
            CformDate: '',
            JobId: 0,
            InvoiceCost: 0,
            ToSPId: 0,
            TransferStatus: false,
            DispatchStatus: 0,
            Remarks: '',
            BillCancel: false,
            CalculatedAmt: 0,
            ConfirmBy: 0,
            ConfirmDate: '',
            AuditBy: 0,
            AuditDate: '',
            TotalAdjValue: 0,
            Status: 1,
            ActName: '',
            ActAddr: '',
            ActTown: '',
            ActPhone: '',
            ActMailId: '',
            ActCSTNo: '',
            ActGRNNo: '',
            ActTINNo: '',
            ActPAN: '',
            TradeType: 0,
            PointType: 0,
            ToDocId: 0,
            ToBranchId: 0,
            Note1: '',
            Note2: '',
            EntryMode: 0,
            DocActID: 0,
            VANID: 0,
            IsApproved: 0,
            ShipID: 0,
            ActStateID: 0,
            RefDocID: 0,
            WorkstationID: '',
            ServiceOrGoods: false,
            isPosted: false,
            SM2: 0,
            //end IQDoc_Header Table
            AccountCode: '',
            AccountName: '',
            AccountFullAddress: '',
            mobile: '',
            filename: '1.jpg',
            filename2: '2.jpg',
            filename3: '3.jpg',
            filename4: '4.jpg',
          });
        },
        error => {
          console.log(error);
        });
      const invalidControl = this.input.nativeElement;
      invalidControl.focus();
      this.titletextneworedit = "New";
      let todaydate = new Date();
      this.DocDate = todaydate;
      this.SuppBilldate = todaydate;
      this.DocDuedate = todaydate;
      this.SizeList2 = [];
      this.dynamicArray = [];
      this.totalitemsadvamount = 0;
      this.defaultclear();
      this.submitted = false;
    }
  }
  printorderbyid(id: any, itemid: any) {
    this.router.navigateByUrl('/printorder', { state: { id: id, itemid: itemid, itemwiseonly: 0, isdelevery: 0 } });
  }
  printbyitemwiseWithSizes(itemid: any) {
    if (this.editid > 0) {
      let id: any = this.editid;
      this.router.navigateByUrl('/printorder', { state: { id: id, itemid: itemid, itemwiseonly: 0, isdelevery: 0 } });
    }
  }
  printAllbyitemwiseOnly(id: any, itemid: any, itemwiseonly: any) {
    this.router.navigateByUrl('/printorder', { state: { id: id, itemid: itemid, itemwiseonly: 1, isdelevery: 0 } });
  }
  SaveOrSubmitForm2() {
    let id: any = Number(this.form1?.get('DocId').value);
    const formData = new FormData();
    formData.append('id', id);
    formData.append('Image', this.selectedFile);
    formData.append('filepath', this.form1?.get('filename')?.value);
    formData.append('Image2', this.selectedFile2);
    formData.append('filepath2', this.form1?.get('filename2')?.value);
    formData.append('Image3', this.selectedFile3);
    formData.append('filepath3', this.form1?.get('filename3')?.value);
    formData.append('Image4', this.selectedFile4);
    formData.append('filepath4', this.form1?.get('filename4')?.value);
    const uploadReq = new HttpRequest('POST', `api/uploadimage`, formData, {
      reportProgress: true,
    });
    this.httpClient.request(uploadReq).subscribe(event => {
    });
  }
  tablerowclick(obj: any, index: any) {
    //
    this.HighlightRow = index;
    this.SizeList2 = [];
    let sizestring: any = obj.size;
    if (sizestring != null) {
      if (sizestring.length > 0) {
        let activeIds: any = sizestring.split(',');
        if (obj.listsizes != null) {
          if (obj.listsizes.length > 0) {
            obj.listsizes.forEach((x: { state: boolean; }) => x.state = false);
            obj.listsizes.filter(({ sizeName }) => activeIds.includes(sizeName)).forEach((x: { state: boolean; }) => x.state = true);
            this.SizeList2 = obj.listsizes.filter(({ sizeName }) => activeIds.includes(sizeName));
          }
        }
      }
    }
  }
  changeitemsize(eventvalue: any, obj: any) {
    obj.sizeValue = (eventvalue);
  }
  changeitemsizename(eventvalue: any, obj: any) {
    obj.sizeName2 = (eventvalue);
    //console.log(obj);
  }
  changeitemqty(eventvalue: any, obj: any) {
    //
    let qtyitm: any = Number(eventvalue);
    obj.qty = (qtyitm);
    obj.rate = Number(obj.rate);
    obj.amt = (Number(qtyitm) * Number(obj.rate));
    obj.netValue = Number((obj.qty) * (obj.rate));
    this.dynamicArray.filter((x: { itemId: any, qty: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { qty: any; }) => x.qty = Number((qtyitm)));
    this.dynamicArray.filter((x: { itemId: any, rate: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { rate: any; }) => x.rate = Number(obj.rate));
    this.dynamicArray.filter((x: { itemId: any, amt: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { amt: any; }) => x.amt = Number(((qtyitm) * obj.rate)));
    //
    this.defaultclear();
    this.totalitemscount = this.dynamicArray.length;
    this.dynamicArray.forEach((x: { qty: number; }) => this.totalitemsqty += x.qty);
    this.dynamicArray.forEach((x: { amt: number; }) => this.totalitemsamount += x.amt);
    this.totalitemsbillamount = (Number(this.totalitemsamount));
    //end
  }
  changeitemrate(eventvalue: any, obj: any) {
    //
    let qtyitm: any = Number(obj.qty);
    obj.qty = (qtyitm);
    obj.rate = Number(eventvalue);
    obj.amt = (Number(qtyitm) * Number(eventvalue));
    obj.netValue = Number((obj.qty) * Number(eventvalue));
    this.dynamicArray.filter((x: { itemId: any, qty: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { qty: any; }) => x.qty = Number((qtyitm)));
    this.dynamicArray.filter((x: { itemId: any, rate: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { rate: any; }) => x.rate = Number(eventvalue));
    this.dynamicArray.filter((x: { itemId: any, amt: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { amt: any; }) => x.amt = Number(((qtyitm) * Number(eventvalue))));
    //
    this.defaultclear();
    this.totalitemscount = this.dynamicArray.length;
    this.dynamicArray.forEach((x: { qty: number; }) => this.totalitemsqty += x.qty);
    this.dynamicArray.forEach((x: { amt: number; }) => this.totalitemsamount += x.amt);
    this.totalitemsbillamount = (Number(this.totalitemsamount));
    //end
  }
  focusoutadvamt() {
    let advamt = Number(this.form1?.get('CalculatedAmt').value);
    this.totalitemsbillamount = (Number(this.totalitemsamount) - advamt);
  }
  additem(obj: any) {
    //
    //let NewSizes: any ;
    //const request = {
    //  method: 'GET',
    //  action_url: 'GetItemWiseSizes?id=' + obj.itemId
    //};
    //  this._services.doHttp(request).subscribe(
    //    res => {
    //      let resobj: any = res;
    //      //console.log(obj.size);

    //      NewSizes = resobj.listsizes;
    //      this.SizeList = resobj.listsizes;
    //    })

    this.HighlightRow = 0;

    this.SizeList2 = [];
    //  this.SizeList2 = NewSizes;
    let sizestring: any = obj.size;
    // obj.listsizes;
    //console.log(sizestring);
    if (sizestring != null) {
      if (sizestring.length > 0) {
        let activeIds: any = sizestring.split(',');
        //console.log(activeIds);
        if (obj.listsizes != null) {
          if (obj.listsizes.length > 0) {
            obj.listsizes.forEach((x: { state: boolean; }) => x.state = false);
            obj.listsizes.filter(({ sizeName }) => activeIds.includes(sizeName)).forEach((x: { state: boolean; }) => x.state = true);
            this.SizeList2 = obj.listsizes.filter(({ sizeName }) => activeIds.includes(sizeName))
              .sort((a, b) => activeIds.indexOf(a.sizeName) - activeIds.indexOf(b.sizeName));
            //console.log(this.SizeList2);
          }
        }
      }
    }

    if (this.dynamicArray != null) {
      if (this.dynamicArray.length > 0) {
        var list = this.dynamicArray.filter((x: { itemId: any }) => x.itemId == Number(obj.itemId));
        if (list != null) {
          if (list.length > 0) {
            //
            let qtyitm: any = Number(list[0].qty);
            obj.qty = (qtyitm + 1);
            obj.rate = Number(obj.mrp);
            obj.amt = (Number(qtyitm + 1) * Number(obj.mrp));
            obj.netValue = Number((obj.qty) * (obj.rate));
            this.dynamicArray.filter((x: { itemId: any, qty: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { qty: any; }) => x.qty = Number((qtyitm + 1)));
            this.dynamicArray.filter((x: { itemId: any, rate: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { rate: any; }) => x.rate = Number(obj.rate));
            this.dynamicArray.filter((x: { itemId: any, amt: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { amt: any; }) => x.amt = Number(((qtyitm + 1) * obj.rate)));
            //
            this.defaultclear();
            this.totalitemscount = this.dynamicArray.length;
            this.dynamicArray.forEach((x: { qty: number; }) => this.totalitemsqty += x.qty);
            this.dynamicArray.forEach((x: { amt: number; }) => this.totalitemsamount += x.amt);
            this.totalitemsbillamount = (Number(this.totalitemsamount));
            //end
            var indexOflist = this.dynamicArray.findIndex(i => i.itemId === Number(obj.itemId));
            this.HighlightRow = Number(indexOflist);
            //
            return;
          }
        }
      }
    }
    this.dynamicArray.push(obj);
    var indexOflist = this.dynamicArray.findIndex(i => i.itemId === Number(obj.itemId));
    this.HighlightRow = Number(indexOflist);
    this.defaultclear();
    if (this.dynamicArray != null) {
      if (this.dynamicArray.length > 0) {
        this.totalitemscount = this.dynamicArray.length;
        obj.qty = 1;
        obj.rate = Number(obj.rate);
        obj.amt = Number(obj.rate);
        this.dynamicArray.filter((x: { itemId: any, qty: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { qty: any; }) => x.qty = 1);
        this.dynamicArray.filter((x: { itemId: any, rate: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { rate: any; }) => x.rate = Number(obj.rate));
        this.dynamicArray.filter((x: { itemId: any, amt: any; }) => x.itemId == Number(obj.itemId)).forEach((x: { amt: any; }) => x.amt = Number(obj.rate));
        obj.netValue = Number((obj.qty) * (obj.rate));
        this.dynamicArray.forEach((x: { qty: number; }) => this.totalitemsqty += x.qty);
        this.dynamicArray.forEach((x: { amt: number; }) => this.totalitemsamount += x.amt);
        this.focusoutadvamt();
      }
      else {
        this.totalitemsadvamount = 0;
      }
    }
    else { this.totalitemsadvamount = 0; }
  }
  deleteIDIDs: any = "";
  removeitem(obj: any, index: any) {
    if (Number(obj.idid) > 0) {
      this.deleteIDIDs += Number(obj.idid) + ",";
    }
    this.dynamicArray.splice(index, 1);
    this.defaultclear();
    if (this.dynamicArray != null) {
      if (this.dynamicArray.length > 0) {
        this.totalitemscount = this.dynamicArray.length;
        this.dynamicArray.forEach((x: { qty: number; }) => this.totalitemsqty += x.qty);
        this.dynamicArray.forEach((x: { amt: number; }) => this.totalitemsamount += x.amt);
        this.focusoutadvamt();
      }
      else { this.totalitemsadvamount = 0; this.SizeList2 = null; }
    }
    else {
      this.totalitemsadvamount = 0;
      this.SizeList2 = null;
    }
  }
  defaultclear() {
    this.totalitemscount = 0;
    this.totalitemsqty = 0;
    this.totalitemsamount = 0;
    this.totalitemsbillamount = 0;
  }
  HistoryOrAutoBillNo: any = false;
  btnAccountSearch(editable: any) {
    if (editable == false) { return; }
    this.dynamicArray = [];
    this.SizeList2 = [];
    this.defaultclear();
    this.form1?.patchValue({
      CalculatedAmt: 0,
      Message: '',
      Narration: '',
      ActID: 0,
    });
    //
    this.h1HdocId = 0;
    this.h2HdocId = 0;
    this.h3HdocId = 0;
    let short: any = this.form1?.get('AccountCode')?.value;
    let name: any = this.form1?.get('AccountName')?.value;
    let mobile: any = this.form1?.get('mobile')?.value;
    let BranchID: any = this.form1?.get('BranchID')?.value;
    //
    let todaydtStr = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.form1?.patchValue({
      DocDate: todaydtStr,
      DocDuedate: todaydtStr,
      SuppBilldate: todaydtStr,
    });
    let todaydate = new Date();
    this.DocDate = todaydate;
    this.SuppBilldate = todaydate;
    this.DocDuedate = todaydate;
    if (short.length == 0 && name.length == 0 && mobile.length == 0) {
      this.form1?.patchValue({
        ActID: 0,
        AccountFullAddress: '',
      });
      return;
    }
    const request = {
      method: 'GET',
      action_url: 'GetActAddressUsingCodeOrNameWithHistory?Code=' + short + '&Name=' + name + '&mobile=' + mobile + '&brid=' + BranchID + ''
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobjr: any = res;
        if (resobjr != null) {
          this.form1?.patchValue({
            TSeries: resobjr.tseries,
            TNo: resobjr.tno,
          });
        }
        let resobj: any = resobjr.list;
        if (resobj != null) {
          if (resobj.length > 0) {
            let fulladress: any = "";
            fulladress += resobj[0].actName + (resobj[0].obj2.addLine1.length > 0 ? " ," : "");
            fulladress += resobj[0].obj2.addLine1 + (resobj[0].obj2.addLine2.length > 0 ? " ," : "");
            fulladress += resobj[0].obj2.addLine2 + (resobj[0].townName.length > 0 ? " ," : "");
            fulladress += resobj[0].townName + (resobj[0].obj2.actMob1.length > 0 ? " ," : "");
            fulladress += resobj[0].obj2.actMob1 + (resobj[0].obj2.actMob2.length > 0 ? " ," : "");
            fulladress += resobj[0].obj2.actMob2 + (resobj[0].obj2.actEmail.length > 0 ? " ," : "");
            fulladress += resobj[0].obj2.actEmail;
            this.form1?.patchValue({
              AccountCode: resobj[0].actCode,
              AccountName: resobj[0].actName,
              AccountFullAddress: fulladress,
              ActID: Number(resobj[0].obj2.actId),
              mobile: resobj[0].actMob1,
              TSeries: resobjr.tseries,
              TNo: resobjr.tno,
            });
          }
          else {
            this.form1?.patchValue({
              ActID: 0,
              AccountFullAddress: '',
            });
          }
        }
        this.h1HdocId = resobjr.h1HdocId;
        this.h2HdocId = resobjr.h2HdocId;
        this.h3HdocId = resobjr.h3HdocId;
      },
      error => {
        console.log(error);
      });
  }
  cancelaccountsearch(editable: any) {
    if (editable == false) { return; }
    const request = {
      method: 'GET',
      action_url: 'GetAccountItemsClearAll'
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.GetItems = resobj.listitems;
        this.dynamicArray = resobj.listitemsedit;
        this.SizeList2 = null;
        this.defaultclear();
        this.form1?.patchValue({
          AccountCode: '',
          AccountFullAddress: '',
          AccountName: '',
          mobile: '',
          ActID: 0,
          CalculatedAmt: 0,
        });
        this.h1HdocId = 0;
        this.h2HdocId = 0;
        this.h3HdocId = 0;
      },
      error => {
        console.log(error);
      });
  }
  changeBranch(event: any) {
    let id: any = 0; id = Number(event.target.value);
    const request = {
      method: 'GET',
      action_url: 'GetStockPointsByBranch?id=' + id + '&vtype=Purchase'
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        this.SPList = resobj.listsplist;
        this.form1?.patchValue({
          TSeries: resobj.billseries,
          TNo: resobj.maxbillno,
        });
      },
      error => {
        console.log(error);
      });
  }
  bindSizes() {
    const request = {
      method: 'GET',
      action_url: 'GetSizes'
    };
    this._services.doHttp(request).subscribe(
      res => {
        if (res != null) {
          this.SizeList = res;
        }
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  viewprintmethod() {
    const request = {
      method: 'GET',
      action_url: 'ViewPreport?mDocID=' + 10014
    };
    this._services.doHttp(request).subscribe(
      res => {
        this.itemlist = res;
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  clickEvent(id: any) {
    const request = {
      method: 'GET',
      action_url: 'GetItemsWithSizesByHistoryId?id=' + Number(id)
    };
    this._services.doHttp(request).subscribe(
      res => {
        let resobj: any = res;
        //
        let docDatef: any = resobj.objdh.docDate.slice(0, 10);
        let docDateS = formatDate(new Date(docDatef.toString().trim()), 'yyyy-MM-dd', 'en_US');
        let docDatedt = new Date(docDateS);
        let docDate = new Date(docDatedt.getFullYear(), docDatedt.getMonth(), docDatedt.getDate());
        //
        let suppBilldatef: any = resobj.objdh.suppBilldate.slice(0, 10);
        let suppBilldateS = formatDate(new Date(suppBilldatef.toString().trim()), 'yyyy-MM-dd', 'en_US');
        let suppBilldatedt = new Date(suppBilldateS);
        let suppBilldate = new Date(suppBilldatedt.getFullYear(), suppBilldatedt.getMonth(), suppBilldatedt.getDate());
        //
        let docDuedatef: any = resobj.objdh.docDuedate.slice(0, 10);
        let docDuedateS = formatDate(new Date(docDuedatef.toString().trim()), 'yyyy-MM-dd', 'en_US');
        let docDuedatedt = new Date(docDuedateS);
        let docDuedate = new Date(docDuedatedt.getFullYear(), docDuedatedt.getMonth(), docDuedatedt.getDate());
        //prasad
        if (resobj != null) {
          if (resobj.imgurle != "") {
            this.imgUrl = resobj.imgurle;
            // this.selectedFile = <File>this.imgUrl;
            // this.onSelectFile(resobj.imgurle);//<File><any>this.onSelectFile(resobj.imgurle);
          }
          if (resobj.imgurle != "") {

            this.imgUrl2 = resobj.imgurle2;
            //this.onSelectFile2(resobj.imgurle2);
            //   this.selectedFile2 = null;
            //this.selectedFile2 = <File> resobj.imgurle2.target.files[0];
          }
          if (resobj.imgurle != "") {

            this.imgUrl3 = resobj.imgurle3;
            //   this.selectedFile3 = null;
            //  this.onSelectFile3(resobj.imgurle3);
            //this.selectedFile3 = this.imgUrl3;
            //this.selectedFile3 = <File> resobj.imgurle3.target.files[0];
          }
          if (resobj.imgurle != "") {

            this.imgUrl4 = resobj.imgurle4;
            // this.selectedFile4 = null;
            //this.onSelectFile4(resobj.imgurle4);
            //this.selectedFile4 = this.imgUrl4;
            //this.selectedFile4 = <File> resobj.imgurle4.target.files[0];
          }
        }
        //end prasad
        //
        this.form1?.patchValue({
          DocDate: docDateS,
          DocDuedate: docDuedateS,
          SuppBilldate: suppBilldateS,
        });
        this.DocDate = docDate;
        this.SuppBilldate = suppBilldate;
        this.DocDuedate = docDuedate;
        //
        this.form1?.patchValue({
          //
          TSeries: resobj.objdh.tseries,
          TNo: Number(resobj.objdh.tno),
          BranchID: resobj.objdh.branchId,
          SpId: resobj.objdh.spId,
          //DocDate: resobj.objdh.docDate,
          //DocDuedate: resobj.objdh.docDuedate,
          //SuppBilldate: resobj.objdh.suppBilldate,
          DocId: 0,
          CompID: 0,
          DivId: 0,
          CCID: 0,
          SMId: 0,
          TtId: 0,
          TExtn: '',
          ActID: Number(resobj.objdh.actId),
          Dcno: '',
          DcAmt: 0,
          //BillAmt: 0,
          Mode: 1,
          SuppBillno: '',
          SuppBillAmt: 0,
          DbSave: false,
          TcBook: false,
          DocCancel: 0,
          CashDiscount: 0,
          DocType: 0,
          ZDocId: 0,
          BillTime: '',
          Narration: resobj.objdh.narration,
          BillQty: 0,
          BillCost: 0,
          Etseries: '',
          ETno: 0,
          Exclusive: 0,
          EtExtn: '',
          EstAmt: 0,
          RecdAmt: 0,
          RendedAmt: 0,
          Pack: 0,
          AmcSeries: '',
          AmcNo: 0,
          WayBillSeries: '',
          WayBillNo: 0,
          WayBillDate: '',
          DiscValue: 0,
          AdjValue: 0,
          DoctorID: 0,
          Comments: '',
          BillPrinted: 0,
          FromSPID: 0,
          HeadActID: 0,
          ActControlID: 0,
          ProductValue: 0,
          ChrgValue: 0,
          SalRetAdjIDs: '',
          Message: resobj.objdh.message,
          MemoID: 0,
          BillVerified: 0,
          CreatedBy: 1,
          ModifiedBy: 1,
          RptSel: 0,
          TransCommitted: true,
          CashAdvance: 0,
          CformNo: '',
          CformDate: '',
          JobId: 0,
          InvoiceCost: 0,
          ToSPId: 0,
          TransferStatus: false,
          DispatchStatus: 0,
          Remarks: '',
          BillCancel: false,
          CalculatedAmt: Number(resobj.objdh.calculatedAmt),
          ConfirmBy: 0,
          AuditBy: 0,
          TotalAdjValue: 0,
          Status: 1,
          ActName: '',
          ActAddr: '',
          ActTown: '',
          ActPhone: '',
          ActMailId: '',
          ActCSTNo: '',
          ActGRNNo: '',
          ActTINNo: '',
          ActPAN: '',
          TradeType: 0,
          PointType: 0,
          ToDocId: 0,
          ToBranchId: 0,
          Note1: '',
          Note2: '',
          EntryMode: 0,
          DocActID: 0,
          VANID: 0,
          IsApproved: 0,
          ShipID: 0,
          ActStateID: 0,
          RefDocID: 0,
          WorkstationID: '',
          ServiceOrGoods: false,
          isPosted: false,
          SM2: 0,
        });
        this.GetItems = resobj.listitems;
        this.dynamicArray = resobj.listitemsedit;
        this.dynamicArray = this.dynamicArray.sort((a, b) => (a.idid > b.idid) ? 1 : -1);
        this.SizeList2 = this.dynamicArray[0].listsizes;
        this.defaultclear();
        this.totalitemscount = this.dynamicArray.length;
        this.dynamicArray.forEach((x: { qty: number; }) => this.totalitemsqty += x.qty);
        this.dynamicArray.forEach((x: { amt: number; }) => this.totalitemsamount += x.amt);
        this.totalitemsbillamount = (Number(this.totalitemsamount));
        this.focusoutadvamt();
      },
      error => {
        console.log(error);
        this._services.errorMsg(error);
      });
  }
  dynamicArrayP: any;
  public itemlistH: any = [];
  //
  title: any = '';
  actName: any = '';
  actCode: any = '';
  billNo: any = '';
  docDate: any = '';
  docDuedate: any = '';
  acttownname: any = '';
  actmob1: any = '';
  suppBilldate: any = '';
  totitemscount: any = 0;
  totqty: any = 0;
  totnetvalue: any = 0;
  advanceamt: any = 0;
  billamt: any = 0;
  //
  clearaccountdetails() {
    this.form1?.patchValue({
      AccountCode: "",
      AccountName: "",
      mobile: "",
      AccountFullAddress: "",
    });
  }
  //Filter CustId
  onselectCustId(item: any, isname: any) {
    this.clearaccountdetails();
    this.form1?.patchValue({
      AccountCode: item.actCode,
      AccountName: '',
      mobile: '',
      AccountFullAddress: ''
    });
    this.flag3 = false;
  }
  changeCustId(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag3 = false;
    }
  }
  clickCustId(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag3 = false;
    }
  }
  keypressCustId(event: any, isname: any) {

    if (event.target.value == "") {
      this.filteredaccountList = [];
      if (isname == 0) {
        this.flag3 = false;
      }
      else {
        this.flag3 = false;
      }
    }
    else {
      this.flag3 = false;
      this.filteredaccountList = this.accountList.filter((x: { actType: any; actCode: any; }) => x.actCode.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredaccountList != null) {
        if (this.filteredaccountList.length > 0) {
          this.flag3 = true;
        }
      }
    }
  }
  //Filter CustId
  //Filter AccountName
  onselectAccountName(item: any, isname: any) {
    this.clearaccountdetails();
    this.form1?.patchValue({
      AccountCode: '',
      AccountName: item.actName,
      mobile: '',
      AccountFullAddress: ''
    });
    this.flag2 = false;
  }
  changeAccountName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag2 = false;
    }
  }
  clickAccountName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag2 = false;
    }
  }
  keypressAccountName(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag2 = false;
    }
    else {
      this.flag2 = false;
      this.filteredaccountList = this.accountList.filter((x: { actType: any; actName: any; }) => x.actName.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredaccountList != null) {
        if (this.filteredaccountList.length > 0) {
          this.flag2 = true;
        }
      }
    }
  }
  //Filter AccountName
  //Filter AccountMobile
  onselectAccountMobile(item: any, isname: any) {
    this.clearaccountdetails();
    this.form1?.patchValue({
      AccountCode: '',
      AccountName: '',
      mobile: item.actMob1,
      AccountFullAddress: ''
    });
    this.flag1 = false;
  }
  changeAccountMobile(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag1 = false;
    }
  }
  clickAccountMobile(event: any, isname: any) {
    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag1 = false;
    }
  }
  keypressAccountMobile(event: any, isname: any) {

    if (event.target.value == "") {
      this.filteredaccountList = [];
      this.flag1 = false;
    }
    else {
      this.flag1 = false;
      this.filteredaccountList = this.accountList.filter((x: { actType: any; actMob1: any; }) => x.actMob1.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
      if (this.filteredaccountList != null) {
        if (this.filteredaccountList.length > 0) {
          this.flag1 = true;
        }
      }
    }
  }
  //Filter AccountMobile
}
