import { Component, ElementRef, OnInit,AfterContentInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpService } from '../../../../http.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-new-size',
  templateUrl: './new-size.component.html',
  styleUrls: ['./new-size.component.scss']
})
export class NewSizeComponent implements OnInit {
  form1: FormGroup | undefined;
  @ViewChild('myname') input;
  get fc(): any | undefined { return this.form1?.controls; }
  titletextneworedit: string = "New";
  submitted = false;
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    //form Defining Start
    this.form1 = this._fb.group({
      SizeName: ['', [Validators.required]],
      SizeId: [0],
      SizeCode: ['', [Validators.required, Validators.maxLength(5)]],
      TranslateText: [''],
      SizeOrder: [0, [Validators.required]],
      Status: [true],
      CreatedBy: [1],
      ModifiedBy: [1],
      CreatedDate: [''],
      ModifiedDate:['']
    });
    //End form Defining
    //Edit Start
    if (this.router.getCurrentNavigation().extras.state.id != undefined) {
      this.titletextneworedit = this.router.getCurrentNavigation().extras.state.text;
      var objdata = this.router.getCurrentNavigation().extras.state.obj;
      if (objdata != null) {
        this.form1?.patchValue({
          SizeName: objdata.sizeName,
          SizeId: objdata.sizeId,
          SizeCode: objdata.sizeCode,
          TranslateText: objdata.translateText,
          SizeOrder: objdata.sizeOrder,
          Status: objdata.status,
          ModifiedBy: objdata.modifiedBy,
          ModifiedDate: objdata.modifiedDate
        });
      }
    }
    //End Edit
  }
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    //Focus Start
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
    //End
  }
  SaveOrSubmitForm() {
    this.submitted = true;
    var SizeOrder = this.form1?.get('SizeOrder')?.value;
    let myDateTime: any = new Date();
    let sqlTimeAsString = formatDate(new Date(), "yyyy-MM-dd", "en_US");
    this.form1?.patchValue({
      CreatedBy: Number(localStorage.getItem("Uid")),
      ModifiedBy: Number(localStorage.getItem("Uid")),
      SizeOrder: Number(SizeOrder),
      CreatedDate: sqlTimeAsString,
      ModifiedDate: sqlTimeAsString
    });
    //console.log(this.form1?.value);
    if (this.form1?.invalid) {
      return;
    }    
    this.httpClient.post(this._services.APIUrl + '/InsertSizes', this.form1?.value).subscribe(res => {
      var result: any = res;
      //console.log(result.msg);
      if (result.msg.toString().includes('Successfull')) {
        Swal.fire('Size Creation', result.msg.toString(), 'success');
        this.clearform();
      }
    },
    error => {
      console.log(error);
    });
  }
  clearform() {
    this.form1?.patchValue({
      SizeName: '',
      SizeId: 0,
      SizeCode: '',
      SizeOrder: 0,
      Status: true,
      CreatedBy: 1,
      ModifiedBy: 1
    });
    this.titletextneworedit = "New";
    this.submitted = false;
    const invalidControl = this.input.nativeElement;
    invalidControl.focus();
  }
  onFocusOutEvent(event: any) {
    var short = this.form1?.get('SizeCode')?.value;
    if (short.length == 0) {
      var txtname = event.target.value;
      let strname: string = "";
      if (txtname != null) {
        if (txtname.length == 0) {
          this.form1?.patchValue({
            SizeCode: ''
          });
          return;
        }
        if (txtname.length <= 5) {
          strname = txtname;
        }
        else {
          strname = txtname.slice(0, 5);
        }
        this.form1?.patchValue({
          SizeCode: strname
        });
      }
    }
  }

}
