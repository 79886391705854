<div class="contentMain">
  <!-- breadcrumb -->
  <div class="row">
    <div class="col-md-12 mainheader header-container">
      <h5>Outward Orders</h5>
    </div>
  </div>
  <!--/ breadcrumb-->
  <!-- main body -->
  <main class="row">
    <!-- card -->
    <div class="card">
      <div class="card-body">
        <!-- filters -->
        <div class="row">
          <!-- col -->
          <div class="col-md-3 col-sm-6">
            <div class="form-group">
              <label>&nbsp;</label>
              <select class="form-control show-tick ms select2" data-placeholder="Select">
                <option>Select </option>
                <option>Select Item1</option>
                <option>Select Item2</option>
                <option>Select Item3</option>
                <option>Select Item4</option>
              </select>
            </div>
          </div>
          <!--/ col -->
          <!-- col -->
          <div class="col-md-3 col-sm-6">
            <div class="form-group">
              <label>From Date</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                </div>
                <input type="text" class="form-control datepicker" placeholder="From Date...">
              </div>
            </div>
          </div>
          <!--/ col -->
          <!-- col -->
          <div class="col-md-3 col-sm-6">
            <div class="form-group">
              <label>To Date</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="zmdi zmdi-calendar"></i></span>
                </div>
                <input type="text" class="form-control datepicker" placeholder="To Date...">
              </div>
            </div>
          </div>
          <!--/ col -->
          <!-- col -->
          <div class="col-md-1 col-sm-6">
            <div class="form-group ">
              <label>&nbsp;</label>
              <select class="form-control show-tick ms select2" data-placeholder="Select">
                <option>S</option>
                <option>Today Date</option>
                <option>Previous Date</option>
                <option>Current Month</option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group pt-4">
              <label>&nbsp;</label>
              <button class="btn redbtn" type="button">Show</button>
            </div>
          </div>
          <!--/ col -->
        </div>
        <!--/ filters -->
        <!-- table -->
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover js-basic-example dataTable kliqtable">
            <thead>
              <tr>
                <th><input id="remember_me" type="checkbox"></th>
                <th>Date</th>
                <th>Bill No:</th>
                <th>Sup Bill No:</th>
                <th>Suplier Name</th>
                <th>Town</th>
                <th>Mode</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input id="remember_me" type="checkbox"></td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
              </tr>
              <tr>
                <td><input id="remember_me" type="checkbox"></td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
              </tr>
              <tr>
                <td><input id="remember_me" type="checkbox"></td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
              </tr>
              <tr>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
              </tr>
              <tr>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
              </tr>
              <tr>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--/ table-->
        <div class="row pt-4">
          <div class="col-md-3 col-sm-4">
            <p>Count : <b>0</b></p>
          </div>
          <div class="col-md-3 col-sm-4">
            <p>Average : <b>0</b></p>
          </div>
          <div class="col-md-3 col-sm-4">
            <p>Sum : <b>0</b></p>
          </div>
        </div>
      </div>
      <div class="card-footer text-left w-100">
        <button class="btn redbtn" type="button">Dispatch</button>
        <button class="btn redbtn" type="button">New</button>
        <button class="btn redbtn" type="button">Edit</button>
        <button class="btn redbtn" type="button">Delete</button>
        <button class="btn redbtn" type="button">Preview</button>
        <button class="btn redbtn" type="button">Print </button>
        <button class="btn redbtn" type="button">Close</button>
        <button class="btn redbtn" type="button">Report</button>
        <button class="btn redbtn" type="button">Filter Data</button>
        <button class="btn redbtn" type="button">Way Bill</button>
        <button class="btn redbtn" type="button">E-Way Bill</button>
      </div>
    </div>
    <!--/ card -->
  </main>
  <!-- main body -->
</div>
