export const SIDEBAR_MENU = [
  {
    menuText: 'Dashboard',
    icon: 'zmdi-home',
    routeLink: 'dashboard',
    extras: [],
    isClicked: false,
  },
  {
    menuText: 'Master',
    icon: 'zmdi-apps',
    routeLink: null,
    isClicked: false,
    extras: [
      {
        menuText: 'Branch',
        routeLink: 'createBranch',
        isClicked: false,

      },
      {
        menuText: 'Salesman',
        routeLink: 'createSalesman',
        isClicked: false,

      },
      {
        menuText: 'Area',
        routeLink: 'createArea',
        isClicked: false,

      },
      //{
      //  menuText: 'HSN Creation',
      //  routeLink: 'createHSN',
      //  isClicked: false,

      //},
      //{
      //  menuText: 'UOM',
      //  routeLink: 'UOM',
      //  isClicked: false,

      //},
      //{
      //  menuText: 'ItemsCategory',
      //  routeLink: 'itemsCategory',
      //  isClicked: false,

      //},
      {
        menuText: 'Create Size',
        routeLink: 'createSize',
        isClicked: false,

      },
      {
        menuText: 'Item Creation',
        routeLink: 'createItem',
        isClicked: false,

      },
      {
        menuText: 'Account Creation',
        routeLink: 'createAccount',
        isClicked: false,

      },  
      //{
      //  menuText: 'Manufacture',
      //  routeLink: 'manufacture',
      //  isClicked: false,

      //},
      //{
      //  menuText: 'Shipping Creation',
      //  routeLink: 'shippingCreation',
      //  isClicked: false,

      //},      
      {
        menuText: 'Charges',
        routeLink: 'charges',
        isClicked: false,

      },
      //{
      //  menuText: 'Branch Bank',
      //  routeLink: 'Branch Bank',
      //  isClicked: false,

      //},
      //{
      //  menuText: 'Transaction Types',
      //  routeLink: 'transactionTypes',
      //  isClicked: false,

      //},
    ],
  },
  {
    menuText: 'Transactions',
    icon: 'zmdi-assignment',
    routeLink: null,
    isClicked: false,
    extras: [
      {
        menuText: 'Order Entry',
        routeLink: '/orderEntry',
        isClicked: false,
      },
      {
        menuText: 'WorkEntry-CUTTING',
        routeLink: '/delivery-to-cut',
        isClicked: false,
        isstitching:0,
      },
      {
        menuText: 'WorkEntry-STITCH',
        routeLink: '/delivery-to-cut',
        isClicked: false,
        isstitching: 1,
      },
      {
        menuText: 'Delivery Entry',
        routeLink: '/delivery-entry',
        isClicked: false,
      },
      {
        menuText: 'Payment Entry',
        routeLink: '/paymentlist',
        isClicked: false,
      },
      //{
      //  menuText: 'Bank Receipt',
      //  routeLink: '/bank_receipt',
      //  isClicked: false,
      //},
      //{
      //  menuText: 'Sales',
      //  routeLink: '/sales',
      //  isClicked: false,
      //},
      //{
      //  menuText: 'Stack outward',
      //  routeLink: '/stock-Outward',
      //  isClicked: false,
      //},
    ],
  },
  {
    menuText: 'Enquiry Reports',
    icon: 'zmdi-folder',
    routeLink: null,
    isClicked: false,
    extras: [
      //{
      //  menuText: 'Order Report',
      //  routeLink: '/orderreport',
      //  isClicked: false,
      //},
      {
        menuText: 'Customer Enquiry',
        routeLink: '/custEnquiry',
        isClicked: false,
      },
      {
        menuText: 'Account Ledger',
        routeLink: '/accLedger',
        isClicked: false,
      },
      {
        menuText: 'Cash Book',
        routeLink: '/cashbook',
        isClicked: false,
      },
    ],
  },
  {
    menuText: 'Day Wise Report',
    icon: 'zmdi-shopping-cart',
    routeLink: null,
    isClicked: false,
    extras: [
      {
        menuText: 'Order',
        routeLink: '/daywisereport',
        isClicked: false,

      },
      {
        menuText: 'Issue To Cutting',
        routeLink: '/daywisereport',
        isClicked: false,

      },
      {
        menuText: 'Receive From Cutting',
        routeLink: '/daywisereport',
        isClicked: false,

      },
      {
        menuText: 'Issue To Stitching',
        routeLink: '/daywisereport',
        isClicked: false,
      },
      {
        menuText: 'Receive From Stitching',
        routeLink: '/daywisereport',
        isClicked: false,
      },
      {
        menuText: 'Delivery',
        routeLink: '/daywisereport',
        isClicked: false,
      },
      {
        menuText: 'Pending Details Report',
        routeLink: '/pendingdetailreport',
        isClicked: false,
      },
    ],
  },
  {
    menuText: 'Log Out',
    icon: 'zmdi-power-off',
    routeLink: '/login',
    isClicked: false,
    //extras: [
    //  {
    //    menuText: 'sales Register',
    //    routeLink: null,
    //    isClicked: false,
    //  },
    //],
  },
  
  //{
  //  menuText: 'Margins',
  //  icon: 'zmdi-swap-alt',
  //  routeLink: null,
  //  isClicked: false,
  //  extras: [
  //    {
  //      menuText: 'item Wise Margin',
  //      routeLink: null,
  //      isClicked: false,
  //    },
  //  ],
  //},
  //{
  //  menuText: 'Stock Movement',
  //  icon: 'zmdi-flower',
  //  routeLink: null,
  //  isClicked: false,
  //  extras: [
  //    {
  //      menuText: 'Moving Stock',
  //      routeLink: null,
  //      isClicked: false,
  //    },
  //  ],
  //},
  //{
  //  menuText: 'Company',
  //  icon: 'zmdi-swap-alt',
  //  routeLink: null,
  //  isClicked: false,
  //  extras: [
  //    {
  //      menuText: 'About US',
  //      routeLink: null,
  //      isClicked: false,
  //    },
  //  ],
  //},
];
