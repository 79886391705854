import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from '../../../../http.service';
import { DatePipe, formatDate } from '@angular/common';
declare var require: any;
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import Swal from 'sweetalert2';
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-printorder',
  templateUrl: './printorder.component.html',
  styleUrls: ['./printorder.component.scss']
})
export class PrintorderComponent implements OnInit {
  public itemlist: any = [];
  editid: any = 0; edititemid: any = 0; itemwiseonly: any = 0; isdelevery: any = 0;
  cssPrintingPath: any = "./assets/fonts/Printing.css";
  //
  CompName: any = '';
  title: any = '';
  actName: any = '';
  actCode: any = '';
  billNo: any = '';
  docDate: any = '';
  docDuedate: any = '';
  acttownname: any = '';
  actmob1: any = '';
  suppBilldate: any = '';
  totitemscount: any = 0;
  totqty: any = 0;
  totnetvalue: any = 0;
  advanceamt: any = 0;
  discValue: any = 0;
  billamt: any = 0;
  recdamt: any = 0;
  headingTitle: any = '';
  BillOrOrder: any = '';
  docDuedateCaption: any = '';
  //
  today: Date = new Date();
  pipe = new DatePipe('en-US');
  todayWithPipe = null;
  constructor(private _fb: FormBuilder, private httpClient: HttpClient, private _services: HttpService, private elRef: ElementRef, private router: Router, private datePipe: DatePipe) {
    this.editid = 0;
    if (this.router.getCurrentNavigation() == null) {

    }
    else {
      if (this.router.getCurrentNavigation().extras.state.id != undefined) {
        this.editid = Number(this.router.getCurrentNavigation().extras.state.id);
        try {
          this.edititemid = Number(this.router.getCurrentNavigation().extras.state.itemid);
        } catch { }
        try {
          this.itemwiseonly = Number(this.router.getCurrentNavigation().extras.state.itemwiseonly);
        } catch { }
        try {
          this.isdelevery = Number(this.router.getCurrentNavigation().extras.state.isdelevery);
        } catch { }
      }
    }

    if (Number(this.editid) > 0) {
      this.todayWithPipe = this.pipe.transform(Date.now(), 'h:mm:ss a');
      const request = {
        method: 'GET',
        action_url: 'GetPrintOrderSizewise?id=' + Number(this.editid) + '&itemid=' + this.edititemid + '&itemwiseonly=' + this.itemwiseonly + '&isdelevery=' + this.isdelevery + ''
      };
      this._services.doHttp(request).subscribe(
        res => {
          let resobj: any = res;
          if (resobj != null) {
            if (this.isdelevery == 1) {
              this.headingTitle = "GATE PASS / DELIVERY CHALLAN";
              this.BillOrOrder = "Bill ";
              this.docDuedate = "";
              this.docDuedateCaption = "";

            }
            else {
              this.headingTitle = "Cash Bill";
              this.BillOrOrder = "Bill ";
              this.docDuedateCaption = "Delv.Date";
            }

            this.title = resobj.objh.title;
            this.CompName = resobj.objh.compName;
            this.actName = resobj.objh.actName;
            this.actCode = resobj.objh.actCode;
            this.billNo = resobj.objh.billNo;
            this.docDate = resobj.objh.docDate;
            if (this.isdelevery == 0)
              this.docDuedate = resobj.objh.docDuedate;
            this.itemlist = resobj.listi;
            this.acttownname = resobj.objh.acttownname;
            this.actmob1 = resobj.objh.actmob1;
            this.suppBilldate = resobj.objh.suppBilldate;
            this.totitemscount = resobj.objh.totitemscount;
            this.totqty = resobj.objh.totqty;
            this.totnetvalue = resobj.objh.totnetvalue;
            this.advanceamt = resobj.objh.advanceamt;
            this.billamt = resobj.objh.billamt;
            this.recdamt = resobj.objh.recdamt;
            this.discValue = resobj.objh.discValue;
          }
        },
        error => {
          console.log(error);
          this._services.errorMsg(error);
        });
    }
    else {
      this.title = '';
      this.actName = '';
      this.actCode = '';
      this.billNo = '';
      this.docDate = '';
      this.docDuedate = '';
      this.itemlist = [];
      this.acttownname = '';
      this.actmob1 = '';
      this.suppBilldate = '';
      this.totitemscount = 0;
      this.totqty = 0;
      this.totnetvalue = 0;
      this.advanceamt = 0;
      this.billamt = 0;
      this.recdamt = 0;
    }
  }
  ngOnInit() {
  }
}
